export enum GrantType {
  CLIENT_CREDENTIALS = 'client_credentials',
}

interface PostTokenRequest {
  clientId: string;
  clientSecret: string;
  grantType: GrantType;
  scope: string;
}

export interface PostTokenResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: 'Bearer';
  scope: string;
}

export default async function createToken(payload: PostTokenRequest, url: string): Promise<PostTokenResponse> {
  const response = await fetch(`${url}/token`, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: payload.clientId,
      client_secret: payload.clientSecret,
      grant_type: payload.grantType,
      scope: payload.scope,
    }),
    credentials: 'include',
  })
  .then((res) => res.json());
  return response.results;
}