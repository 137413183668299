import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import Routes, { RoutesEnum } from '../routes';
import AuthenticationContext from './Authentication';
import { buildRoute, getRouteParams, isRouteMatching } from '../helpers/routingHelpers';

export interface RoutingContextInterface {
    currentRoute: RoutesEnum,
    setRoute: (route: RoutesEnum) => void,
}

const RoutingContext = createContext<RoutingContextInterface>({
    currentRoute: RoutesEnum.LOGIN,
});

export function Provider({ children }: React.ElementChildrenAttribute): React.Element {
    const { isLoggedIn } = useContext(AuthenticationContext);
    const [currentRoute, setInternalRoute] = useState<RoutesEnum>(RoutesEnum.LOGIN);

    function onHashChange(newPath: string): void {
        let newRoutePath = null;
        Routes.forEach((route, routeKey) => {
            if (newRoutePath !== null) return;
            if (isRouteMatching(route.path, newPath)) {
                newRoutePath = routeKey;
            }
        });
        if (newRoutePath === null) return;
        setInternalRoute(newRoutePath);
    }

    function onRawHashChange(e) {
        const newPath = e.newURL.split('#')[1];
        return onHashChange(newPath);
    }

    function generatePath(route: RoutesEnum, parameters: any = {}): string {
        return '#' + buildRoute(
            Routes.get(route).path,
            parameters,
        );
    }

    function setRoute(newRoute: RoutesEnum, parameters: any = {}): void {
        window.location.hash = generatePath(newRoute, parameters);
    }

    // Bind the hash change event and initialize the route
    useEffect(() => {
        window.addEventListener('hashchange', onRawHashChange, true);
        onHashChange(window.location.hash.split('#')[1]);
        if (isLoggedIn) {
            setRoute(RoutesEnum.HOME);
        }
        return () => window.removeEventListener('hashchange', onRawHashChange);
    }, []);

    const routeParams = useMemo(() => {
        const params = getRouteParams(
            Routes.get(currentRoute).path,
            window.location.hash.split('#')[1],
        );
        return params;
    }, [currentRoute]);

    const context = useMemo(
        () => ({
            currentRoute,
            setRoute,
            generatePath,
            routeParams,
        }),
        [currentRoute],
    );

    // Redirect on login page when logged out
    useEffect(() => {
        if (!isLoggedIn) {
            setRoute(RoutesEnum.LOGIN);
            return;
        }
    }, [isLoggedIn]);

    return (
        <RoutingContext.Provider value={context}>
            {children}
        </RoutingContext.Provider>
    );
}

export const { Consumer } = RoutingContext;

export default RoutingContext;
