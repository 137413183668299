import { SSMClient, PutParameterCommand, ParameterType, GetParameterCommand } from "@aws-sdk/client-ssm";
import { Question, Answer } from "./apiv1Questions";

export interface EventConfiguration {
    amctc_code: {
        id: Question['id'];
        label: string;
        isActivated: boolean;
        choices: {
            id: Answer['id'];
            source: string;
            target: string;
        }[];
    }[];
}

export async function setEventConfiguration(
    ssmClient: SSMClient,
    awsSsmParameterEventPrefix: string,
    configuration: EventConfiguration,
    eventId: number,
): Promise<boolean> {
    // Create a copy of the activated questions without the "isActivated" properties
    const config = {
        amctc_code: configuration.amctc_code
            .filter((q) => q.isActivated === true)
            .map((q) => {
                const { isActivated, ...rest } = q;
                return {
                    ...rest,
                    choices: q.choices.filter((a) => a.target.length > 0),
                };
            }),
    };
    try {
        await ssmClient.send(
            new PutParameterCommand({
                Name: `${awsSsmParameterEventPrefix}${eventId}`,
                Overwrite: true,
                Type: ParameterType.STRING,
                Value: JSON.stringify(config),
            })
        );
        return true;
    } catch (error) {
        console.error('setConfiguration error', error);
        return false;
    }
}

export async function getEventConfiguration(
    ssmClient: SSMClient,
    awsSsmParameterEventPrefix: string,
    eventId: number,
): Promise<Partial<EventConfiguration> | undefined> {
    try {        
        const ssmResponse = await ssmClient.send(
            new GetParameterCommand({
                Name: `${awsSsmParameterEventPrefix}${eventId}`,
                WithDecryption: true,
            })
        );
        if (ssmResponse.Parameter?.Value === undefined) {
            throw new Error('getEventConfiguration: No value in SSM parameter');
        }
        
        return JSON.parse(ssmResponse.Parameter.Value);
    } catch (error) {
        console.error('getEventConfiguration error', error);
    }
}
