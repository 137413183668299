import React, {
    useContext, useEffect,
} from 'react';
import getConfig from './api/getConfig';
import { useConfig, useConfigSetter } from './context/App';
import AwsClientsContext, { AwsClientsContextInterface } from './context/AwsClients';
import RoutingContext from './context/Routing';
import Routes from './routes';

function Router() {
    const localeConfig = useConfig();
    const { currentRoute } = useContext(RoutingContext);
    const { ssm } = useContext<AwsClientsContextInterface>(AwsClientsContext);
    const setConfig = useConfigSetter();

    useEffect(() => {        
        if (ssm === null) {
            return;
        }
        if (localeConfig !== null) {
            return;
        }
        (async function() {
            const config = await getConfig(ssm);
            setConfig(config);
        })();
    }, [localeConfig, ssm, getConfig, setConfig]);

    const child = React.createElement(Routes.get(currentRoute)?.component ?? null);
    return child;
}
export default Router;
