
/**
 * Builds a route from a given path according to the given parameters
 * @param path The path to build the route from (e.g. /events/:id)
 * @param params The parameters to use in the route (e.g. { id: '123' })
 * @returns The built route (e.g. /events/123)
 */
export function buildRoute(path: string, params: any): string {
    return path.replace(/(\:\w+)/ig, (_, key) => {
        return params[key.substr(1)];
    });
}

/**
 * Generate a regex from a given path
 * @param pathname The path to generate the regex from (e.g. /events/:id)
 * @param strict Should the regex be strict about the end of the path
 * @returns The generated regex (e.g. /events\/(\w+)$/)
 */
function getRegexFromPath(pathname, strict = false): RegExp {
    return new RegExp(`^${pathname.replace(/:\w+/ig, '[-[\\]\\w]+')}${strict ? '$' : ''}`);
}

/**
 * Checks if a given path matches a given path
 * @param routePath The path to check against (e.g. /events/:id)
 * @param path The current path (e.g. /events/123)
 * @param strict Should the path be checked strictly
 * @returns Whether the path matches the route path
 */
export function isRouteMatching(routePath: string, path: string, strict: boolean = true): boolean {
    return getRegexFromPath(routePath, strict).test(path);
}

/**
 * Extracts the parameters from a given path
 * @param routePath The path to check against (e.g. /events/:id)
 * @param path The current path (e.g. /events/123)
 * @returns The parameters extracted from the path
 */
export function getRouteParams(routePath: string, path: string,): any {
    const matches = /(\:\w+)/g.exec(routePath);
    if (!matches) return {};

    const params = {};
    for (let i = 1; i < matches.length; i++) {
        const regex = new RegExp(routePath.replace(matches[i], '(\\w+)'));
        const pathMatches = regex.exec(path);
        if (pathMatches) {
            params[matches[i].slice(1, Number.POSITIVE_INFINITY)] = pathMatches[1];
        }
    }

    return params;
}
