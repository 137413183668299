import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react";
import { Config } from "../api/getConfig";

interface IAppContext {
  config: Config | null;
  setConfig: Dispatch<SetStateAction<Config | null>>;
}

const AppContext = createContext<IAppContext | undefined>(undefined);

export function AppContextProvider({ children }: { children: ReactNode }) {
  const [config, setConfig] = useState<IAppContext['config']>(null);
  const value = useMemo(() => ({ config, setConfig }), [config]);

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
}

export function useConfig() {
  const context = useContext(AppContext);
  if (context === undefined) {
      throw new Error(
          'useConfig must be used within a AppContextProvider',
      );
  }
  return context.config;
}

export function useConfigSetter() {
  const context = useContext(AppContext);
  if (context === undefined) {
      throw new Error(
          'useConfigSetter must be used within a AppContextProvider',
      );
  }
  return context.setConfig;
}