import { useState } from 'react';
import { getCookieValue, setCookieValue, deleteCookie } from '../helpers/cookiesHelpers';

export default function useCookie(
    cookieName: string,
    defaultValue?: string,
): [string | null, (newValue: string, expiration: number | null) => void] {
    const cookieValue = getCookieValue(cookieName);
    const [value, setValue] = useState(cookieValue ?? defaultValue);

    if (cookieValue === null && defaultValue !== null) {
        setCookieValue(cookieName, defaultValue);
    }

    function setCookie(newValue: string | null, expiration: number | null) {
        if (newValue === null) {
            deleteCookie(cookieName);
        } else {
            setCookieValue(cookieName, newValue, expiration);
        }
        setValue(newValue);
    }

    return [value, setCookie];
}
