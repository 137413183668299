import { TaskDefinition } from '@aws-sdk/client-ecs';
import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import { Answer, Question } from '../api/apiv1Questions';
import { EventConfiguration } from '../api/eventConfiguration';
import { Schedule } from '../api/exportStatus';
import RoutingContext, { RoutingContextInterface } from './Routing';

export type Event = {
    id: number,
    name: string,
    begin: number,
    end: number,
    orgaId: number,
    isEnabled: boolean,
    taskDefinition: TaskDefinition | null,
}
export type CSVExportFile = {
    key: string,
    name: string,
    createdAt: Date;
    size: number;
}

export interface FluxesContextInterface {
    events: Array<Event>;
    setEvents: Dispatch<SetStateAction<Array<Event>>>;
    schedules: Array<Schedule>;
    setSchedules: Dispatch<SetStateAction<Array<Schedule>>>;
    questions: Array<Question>;
    setQuestions: Dispatch<SetStateAction<Array<Question>>>;
    exports: Array<CSVExportFile>;
    setExports: Dispatch<SetStateAction<Array<CSVExportFile>>>;
    upsertEvent(event: Event): void;
    upsertSchedule(schedule: Omit<Schedule, 'id'>, id: number): void;
    currentEvent: Event | null;
    configuration: EventConfiguration | null;
    setConfiguration: Dispatch<SetStateAction<EventConfiguration | null>>;
    getConfigurationTargetChoice(questionId: Question['id'], answerId: Answer['id']): string;
    setConfigurationTargetChoice(questionId: Question['id'], answerId: Answer['id'], newTargetChoice: string): void;
    isQuestionActivated(questionId: Question['id']): boolean;
    setIsQuestionActivated(questionId: Question['id'], isActivated: boolean): void;
}

const FluxesContext = createContext<FluxesContextInterface | undefined>(undefined);

export function Provider({ children }: React.ElementChildrenAttribute): React.Element {
    const { routeParams } = useContext<RoutingContextInterface>(RoutingContext);
    const [events, setEvents] = useState<Array<Event>>([]);
    const [questions, setQuestions] = useState<Array<Question>>([]);
    const [exports, setExports] = useState<Array<CSVExportFile>>([]);
    const [schedules, setSchedules] = useState<Array<Schedule>>([]);
    const [configuration, setConfiguration] = useState<EventConfiguration | null>(null);

    function upsertEvent(event: Event): void {
        const index = events.findIndex(e => e.id === event.id);
        if (index === -1) {
            setEvents([
                ...events,
                event,
            ]);
        } else {
            setEvents(events.map((currentEvent) => {
                if (currentEvent.id === event.id) {
                    return event;
                }
                return currentEvent;
            }));
        }
    }

    function upsertSchedule(schedule: Omit<Schedule, 'id'>, id: number): void {
        const index = schedules.findIndex(s => s.id === id);
        if (index === -1) {
            setSchedules((prevState) => [
                ...prevState,
                {
                    ...schedule,
                    id,
                },
            ]);
        } else {
            setSchedules((prevState) => prevState.map((currentSchedule) => {
                if (currentSchedule.id === id) {
                    return {
                        ...schedule,
                        id,
                    };
                }
                return currentSchedule;
            }));
        }
    }

    const getConfigurationTargetChoice = useCallback((questionId: Question['id'], answerId: Answer['id']) => {
        const questionIndex = configuration?.amctc_code.findIndex(q => q.id === questionId) ?? -1;
        if (questionIndex !== -1) {
            if (!configuration) {
                return '';
            }
            const answerIndex = configuration.amctc_code[questionIndex].choices.findIndex(a => a.id === answerId);

            if (answerIndex !== -1) {
                return configuration.amctc_code[questionIndex].choices[answerIndex].target;
            }
        }
        return '';
    }, [configuration]);

    const setConfigurationTargetChoice = useCallback((questionId: Question['id'], answerId: Answer['id'], newTargetChoice: string) => {
        setConfiguration(prevState => {
            const newState = { ...prevState };
            const questionIndex = newState.amctc_code?.findIndex(q => q.id === questionId) ?? -1;

            if (questionIndex !== -1) {
                if (!newState.amctc_code) {
                    return null;
                }
                const answerIndex = newState.amctc_code[questionIndex].choices.findIndex(a => a.id === answerId);

                if (answerIndex !== -1) {
                    newState.amctc_code[questionIndex].choices[answerIndex].target = newTargetChoice;
                }
            }

            return newState;
        });
    }, []);

    const isQuestionActivated = useCallback((questionId: Question['id']) => {
        const questionIndex = configuration?.amctc_code.findIndex(q => q.id === questionId) ?? -1;
        if (questionIndex !== -1) {
            if (!configuration) {
                return false;
            }
            return configuration.amctc_code[questionIndex].isActivated;
        }
        return false;
    }, [configuration]);

    const setIsQuestionActivated = useCallback((questionId: Question['id'], isActivated: boolean) => {        
        setConfiguration(prevState => {
            const newState = { ...prevState };
            const questionIndex = newState.amctc_code?.findIndex(q => q.id === questionId) ?? -1;

            if (questionIndex !== -1) {
                if (!newState.amctc_code) {
                    return null;
                }

                newState.amctc_code[questionIndex].isActivated = isActivated;
            }

            return newState;
        });
    }, []);

    const currentEvent = useMemo(() => {
        if (!routeParams.eventId) return null;
        const eventId = Number.parseInt(routeParams.eventId, 10);
        return events.find((event) => event.id === eventId) ?? null;
    }, [routeParams]);

    const context: FluxesContextInterface = useMemo(
        () => ({
            events: events.sort((a, b) => b.begin - a.begin),
            setEvents,
            schedules,
            setSchedules,
            questions,
            setQuestions,
            exports,
            setExports,
            upsertEvent,
            upsertSchedule,
            currentEvent,
            configuration,
            setConfiguration,
            getConfigurationTargetChoice,
            setConfigurationTargetChoice,
            isQuestionActivated,
            setIsQuestionActivated,
        }),
        [
            events,
            questions,
            exports,
            upsertEvent,
            currentEvent,
            configuration,
            getConfigurationTargetChoice,
            setConfigurationTargetChoice,
            isQuestionActivated,
            setIsQuestionActivated,
        ],
    );

    return (
        <FluxesContext.Provider value={context}>
            {children}
        </FluxesContext.Provider>
    );
}

export const { Consumer } = FluxesContext;

export default FluxesContext;
