import { ListObjectsV2Command, S3Client } from '@aws-sdk/client-s3';
import  { Config } from "./getConfig";
import { CSVExportFile } from '../context/Fluxes';

export default async function getExports(s3Client: S3Client, eventId: number, config: Config): Promise<Array<CSVExportFile>> {
    const command = new ListObjectsV2Command({
        Bucket: config.awsS3ExportBucketName,
        Prefix: `${eventId}/`,
    });
    const response = await s3Client.send(command);
    const exports = (response.Contents || []).map((item) => ({
        key: item.Key,
        name: item.Key,
        createdAt: item.LastModified,
        size: item.Size,
    } as CSVExportFile));
    return exports;
}
