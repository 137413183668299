{
    "partitions": [{
            "id": "aws",
            "outputs": {
                "dnsSuffix": "amazonaws.com",
                "dualStackDnsSuffix": "api.aws",
                "name": "aws",
                "supportsDualStack": true,
                "supportsFIPS": true
            },
            "regionRegex": "^(us|eu|ap|sa|ca|me|af)\\-\\w+\\-\\d+$",
            "regions": {
                "af-south-1": {
                    "description": "Africa (Cape Town)"
                },
                "ap-east-1": {
                    "description": "Asia Pacific (Hong Kong)"
                },
                "ap-northeast-1": {
                    "description": "Asia Pacific (Tokyo)"
                },
                "ap-northeast-2": {
                    "description": "Asia Pacific (Seoul)"
                },
                "ap-northeast-3": {
                    "description": "Asia Pacific (Osaka)"
                },
                "ap-south-1": {
                    "description": "Asia Pacific (Mumbai)"
                },
                "ap-south-2": {
                    "description": "Asia Pacific (Hyderabad)"
                },
                "ap-southeast-1": {
                    "description": "Asia Pacific (Singapore)"
                },
                "ap-southeast-2": {
                    "description": "Asia Pacific (Sydney)"
                },
                "ap-southeast-3": {
                    "description": "Asia Pacific (Jakarta)"
                },
                "aws-global": {
                    "description": "AWS Standard global region"
                },
                "ca-central-1": {
                    "description": "Canada (Central)"
                },
                "eu-central-1": {
                    "description": "Europe (Frankfurt)"
                },
                "eu-central-2": {
                    "description": "Europe (Zurich)"
                },
                "eu-north-1": {
                    "description": "Europe (Stockholm)"
                },
                "eu-south-1": {
                    "description": "Europe (Milan)"
                },
                "eu-south-2": {
                    "description": "Europe (Spain)"
                },
                "eu-west-1": {
                    "description": "Europe (Ireland)"
                },
                "eu-west-2": {
                    "description": "Europe (London)"
                },
                "eu-west-3": {
                    "description": "Europe (Paris)"
                },
                "me-central-1": {
                    "description": "Middle East (UAE)"
                },
                "me-south-1": {
                    "description": "Middle East (Bahrain)"
                },
                "sa-east-1": {
                    "description": "South America (São Paulo)"
                },
                "us-east-1": {
                    "description": "US East (N. Virginia)"
                },
                "us-east-2": {
                    "description": "US East (Ohio)"
                },
                "us-west-1": {
                    "description": "US West (N. California)"
                },
                "us-west-2": {
                    "description": "US West (Oregon)"
                }
            }
        }, {
            "id": "aws-cn",
            "outputs": {
                "dnsSuffix": "amazonaws.com.cn",
                "dualStackDnsSuffix": "api.amazonwebservices.com.cn",
                "name": "aws-cn",
                "supportsDualStack": true,
                "supportsFIPS": true
            },
            "regionRegex": "^cn\\-\\w+\\-\\d+$",
            "regions": {
                "aws-cn-global": {
                    "description": "AWS China global region"
                },
                "cn-north-1": {
                    "description": "China (Beijing)"
                },
                "cn-northwest-1": {
                    "description": "China (Ningxia)"
                }
            }
        }, {
            "id": "aws-us-gov",
            "outputs": {
                "dnsSuffix": "amazonaws.com",
                "dualStackDnsSuffix": "api.aws",
                "name": "aws-us-gov",
                "supportsDualStack": true,
                "supportsFIPS": true
            },
            "regionRegex": "^us\\-gov\\-\\w+\\-\\d+$",
            "regions": {
                "aws-us-gov-global": {
                    "description": "AWS GovCloud (US) global region"
                },
                "us-gov-east-1": {
                    "description": "AWS GovCloud (US-East)"
                },
                "us-gov-west-1": {
                    "description": "AWS GovCloud (US-West)"
                }
            }
        }, {
            "id": "aws-iso",
            "outputs": {
                "dnsSuffix": "c2s.ic.gov",
                "dualStackDnsSuffix": "c2s.ic.gov",
                "name": "aws-iso",
                "supportsDualStack": false,
                "supportsFIPS": true
            },
            "regionRegex": "^us\\-iso\\-\\w+\\-\\d+$",
            "regions": {
                "aws-iso-global": {
                    "description": "AWS ISO (US) global region"
                },
                "us-iso-east-1": {
                    "description": "US ISO East"
                },
                "us-iso-west-1": {
                    "description": "US ISO WEST"
                }
            }
        }, {
            "id": "aws-iso-b",
            "outputs": {
                "dnsSuffix": "sc2s.sgov.gov",
                "dualStackDnsSuffix": "sc2s.sgov.gov",
                "name": "aws-iso-b",
                "supportsDualStack": false,
                "supportsFIPS": true
            },
            "regionRegex": "^us\\-isob\\-\\w+\\-\\d+$",
            "regions": {
                "aws-iso-b-global": {
                    "description": "AWS ISOB (US) global region"
                },
                "us-isob-east-1": {
                    "description": "US ISOB East (Ohio)"
                }
            }
        }],
    "version": "1.1"
}
