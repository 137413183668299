import { getRangeFromPage } from "../helpers/paginationHelpers";
import createToken, { GrantType, PostTokenResponse } from "./apiv1Authentication";
import { Config } from "./getConfig";

export enum QuestionType {
    UNIQUE_CHOICE = 3,
    MULTIPLE_CHOICE = 4,
    DROP_DOWN_MENU = 5,
}

export interface Answer {
    id: number;
    label: string;
}

export interface Question {
    id: number;
    label: string;
    nature_id: number | null;
    type: QuestionType;
    choice_answers: Answer[];
}

interface GetQuestionsRequest {
    orgaId: number;
    eventId: number;
}

export async function getQuestions(payload: GetQuestionsRequest, apiV1Config: Config['apiV1Config'], accessToken: string): Promise<Question[] | PostTokenResponse> {
    let questions: Question[] = [];
    let questionsRemaining = false;
    let page = 0;
    const pageSize = 30;

    do {
        const range = getRangeFromPage(page, pageSize);

        try {            
            const requestParams = new URLSearchParams({
                has_nature: '0',
                is_global: '1',
                event_id: `${payload.eventId}`,
                range,
            });
            const rawResult = await fetch(
                `${apiV1Config.url}/orgas/${payload.orgaId}/questions?${requestParams}&multiple_type=[${QuestionType.DROP_DOWN_MENU},${QuestionType.MULTIPLE_CHOICE},${QuestionType.UNIQUE_CHOICE}]`,
                {
                    mode: 'cors',
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                    credentials: 'include',
                },
            ).then((res) => res.json());

            if (rawResult.status === 401) {
                const apiv1Result = await createToken({
                    clientId: apiV1Config.clientId,
                    clientSecret: apiV1Config.clientSecret,
                    grantType: GrantType.CLIENT_CREDENTIALS,
                    scope: apiV1Config.scope,
                }, apiV1Config.url);
                return apiv1Result;
            }
    
            questions = [
                ...questions,
                ...rawResult.results,
            ];
            questionsRemaining = (rawResult.paging?.total ?? 0) > questions.length;
            page += 1;
        } catch (error) {
            console.error('getQuestions error', error);
        }

    } while (questionsRemaining);

    // filter duplicates
    const groupedQuestions = Array.from(new Set(questions.map((q) => q.label))).map((label) => questions.find((q) => q.label === label));

    return (groupedQuestions ?? []).sort((a, b) => a.label.localeCompare(b.label));
}
