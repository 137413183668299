import React, { useContext } from 'react';
import { Answer, Question } from '../../api/apiv1Questions';
import FluxesContext, { FluxesContextInterface } from '../../context/Fluxes';

function AnswersListItem({ answer, questionId }: { answer: Answer, questionId: Question['id'] }) {
    const { getConfigurationTargetChoice, setConfigurationTargetChoice } = useContext<FluxesContextInterface>(FluxesContext);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setConfigurationTargetChoice(questionId, answer.id, e.currentTarget.value);
    }
    
    return (
        <div className='c-grid__row c-grid__row--align-vcenter c-grid__row--space-between ml-4 mt-3'>
            <i className="yp_font yp_font_arrow mr-1" aria-hidden="true">{"=>"}</i>
            {answer.label}
            <input
                value={getConfigurationTargetChoice(questionId, answer.id)}
                onChange={handleChange}
                type="text"
                className='c-form__input ml-a'
                style={{ width: '300px' }}
            />
        </div>
    );
}

export default function AnswersList({ answers, questionId }: { answers: Answer[], questionId: Question['id'] }) {
    return (
        <div>
            {answers.map((answer) => (
                <AnswersListItem
                    key={answer.id}
                    answer={answer}
                    questionId={questionId}
                />
            ))}
        </div>
    );
}