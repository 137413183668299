import React from 'react';
import Exports from './components/Exports/Exports';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Configure from './components/Configure/Configure';

export type Route = {
    path: string;
    component: React.Element;
}

export enum RoutesEnum {
    LOGIN = 'login',
    HOME = 'home',
    EXPORTS = 'exports',
    CONFIGURE = 'configure',
}

const Routes = new Map<RoutesEnum, Route>();

Routes.set(RoutesEnum.HOME, {
    path: '/home',
    component: Home,
});
Routes.set(RoutesEnum.LOGIN, {
    path: '/login',
    component: Login,
});
Routes.set(RoutesEnum.EXPORTS, {
    path: '/event/:eventId/exports',
    component: Exports,
});
Routes.set(RoutesEnum.CONFIGURE, {
    path: '/event/:eventId/configure',
    component: Configure,
});

export default Routes;
