import { HttpRequest as __HttpRequest } from "@aws-sdk/protocol-http";
import { decorateServiceException as __decorateServiceException, expectBoolean as __expectBoolean, expectInt32 as __expectInt32, expectNonNull as __expectNonNull, expectNumber as __expectNumber, expectObject as __expectObject, expectString as __expectString, map as __map, parseEpochTimestamp as __parseEpochTimestamp, resolvedPath as __resolvedPath, throwDefaultError, } from "@aws-sdk/smithy-client";
import { v4 as generateIdempotencyToken } from "uuid";
import { ConflictException, InternalServerException, ResourceNotFoundException, ServiceQuotaExceededException, ThrottlingException, ValidationException, } from "../models/models_0";
import { SchedulerServiceException as __BaseException } from "../models/SchedulerServiceException";
export const serializeAws_restJson1CreateScheduleCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        "content-type": "application/json",
    };
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/schedules/{Name}";
    resolvedPath = __resolvedPath(resolvedPath, input, "Name", () => input.Name, "{Name}", false);
    let body;
    body = JSON.stringify({
        ClientToken: input.ClientToken ?? generateIdempotencyToken(),
        ...(input.Description != null && { Description: input.Description }),
        ...(input.EndDate != null && { EndDate: Math.round(input.EndDate.getTime() / 1000) }),
        ...(input.FlexibleTimeWindow != null && {
            FlexibleTimeWindow: serializeAws_restJson1FlexibleTimeWindow(input.FlexibleTimeWindow, context),
        }),
        ...(input.GroupName != null && { GroupName: input.GroupName }),
        ...(input.KmsKeyArn != null && { KmsKeyArn: input.KmsKeyArn }),
        ...(input.ScheduleExpression != null && { ScheduleExpression: input.ScheduleExpression }),
        ...(input.ScheduleExpressionTimezone != null && { ScheduleExpressionTimezone: input.ScheduleExpressionTimezone }),
        ...(input.StartDate != null && { StartDate: Math.round(input.StartDate.getTime() / 1000) }),
        ...(input.State != null && { State: input.State }),
        ...(input.Target != null && { Target: serializeAws_restJson1Target(input.Target, context) }),
    });
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const serializeAws_restJson1CreateScheduleGroupCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        "content-type": "application/json",
    };
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/schedule-groups/{Name}";
    resolvedPath = __resolvedPath(resolvedPath, input, "Name", () => input.Name, "{Name}", false);
    let body;
    body = JSON.stringify({
        ClientToken: input.ClientToken ?? generateIdempotencyToken(),
        ...(input.Tags != null && { Tags: serializeAws_restJson1TagList(input.Tags, context) }),
    });
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const serializeAws_restJson1DeleteScheduleCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/schedules/{Name}";
    resolvedPath = __resolvedPath(resolvedPath, input, "Name", () => input.Name, "{Name}", false);
    const query = map({
        groupName: [, input.GroupName],
        clientToken: [, input.ClientToken ?? generateIdempotencyToken()],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const serializeAws_restJson1DeleteScheduleGroupCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/schedule-groups/{Name}";
    resolvedPath = __resolvedPath(resolvedPath, input, "Name", () => input.Name, "{Name}", false);
    const query = map({
        clientToken: [, input.ClientToken ?? generateIdempotencyToken()],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const serializeAws_restJson1GetScheduleCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/schedules/{Name}";
    resolvedPath = __resolvedPath(resolvedPath, input, "Name", () => input.Name, "{Name}", false);
    const query = map({
        groupName: [, input.GroupName],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const serializeAws_restJson1GetScheduleGroupCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/schedule-groups/{Name}";
    resolvedPath = __resolvedPath(resolvedPath, input, "Name", () => input.Name, "{Name}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        body,
    });
};
export const serializeAws_restJson1ListScheduleGroupsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/schedule-groups";
    const query = map({
        NamePrefix: [, input.NamePrefix],
        NextToken: [, input.NextToken],
        MaxResults: [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const serializeAws_restJson1ListSchedulesCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/schedules";
    const query = map({
        ScheduleGroup: [, input.GroupName],
        NamePrefix: [, input.NamePrefix],
        State: [, input.State],
        NextToken: [, input.NextToken],
        MaxResults: [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const serializeAws_restJson1ListTagsForResourceCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/tags/{ResourceArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ResourceArn", () => input.ResourceArn, "{ResourceArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        body,
    });
};
export const serializeAws_restJson1TagResourceCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        "content-type": "application/json",
    };
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/tags/{ResourceArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ResourceArn", () => input.ResourceArn, "{ResourceArn}", false);
    let body;
    body = JSON.stringify({
        ...(input.Tags != null && { Tags: serializeAws_restJson1TagList(input.Tags, context) }),
    });
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const serializeAws_restJson1UntagResourceCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/tags/{ResourceArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ResourceArn", () => input.ResourceArn, "{ResourceArn}", false);
    const query = map({
        TagKeys: [
            __expectNonNull(input.TagKeys, `TagKeys`) != null,
            () => (input.TagKeys || []).map((_entry) => _entry),
        ],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const serializeAws_restJson1UpdateScheduleCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        "content-type": "application/json",
    };
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/schedules/{Name}";
    resolvedPath = __resolvedPath(resolvedPath, input, "Name", () => input.Name, "{Name}", false);
    let body;
    body = JSON.stringify({
        ClientToken: input.ClientToken ?? generateIdempotencyToken(),
        ...(input.Description != null && { Description: input.Description }),
        ...(input.EndDate != null && { EndDate: Math.round(input.EndDate.getTime() / 1000) }),
        ...(input.FlexibleTimeWindow != null && {
            FlexibleTimeWindow: serializeAws_restJson1FlexibleTimeWindow(input.FlexibleTimeWindow, context),
        }),
        ...(input.GroupName != null && { GroupName: input.GroupName }),
        ...(input.KmsKeyArn != null && { KmsKeyArn: input.KmsKeyArn }),
        ...(input.ScheduleExpression != null && { ScheduleExpression: input.ScheduleExpression }),
        ...(input.ScheduleExpressionTimezone != null && { ScheduleExpressionTimezone: input.ScheduleExpressionTimezone }),
        ...(input.StartDate != null && { StartDate: Math.round(input.StartDate.getTime() / 1000) }),
        ...(input.State != null && { State: input.State }),
        ...(input.Target != null && { Target: serializeAws_restJson1Target(input.Target, context) }),
    });
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "PUT",
        headers,
        path: resolvedPath,
        body,
    });
};
export const deserializeAws_restJson1CreateScheduleCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1CreateScheduleCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    if (data.ScheduleArn != null) {
        contents.ScheduleArn = __expectString(data.ScheduleArn);
    }
    return contents;
};
const deserializeAws_restJson1CreateScheduleCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ConflictException":
        case "com.amazonaws.scheduler#ConflictException":
            throw await deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context);
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.scheduler#ResourceNotFoundException":
            throw await deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context);
        case "ServiceQuotaExceededException":
        case "com.amazonaws.scheduler#ServiceQuotaExceededException":
            throw await deserializeAws_restJson1ServiceQuotaExceededExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
export const deserializeAws_restJson1CreateScheduleGroupCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1CreateScheduleGroupCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    if (data.ScheduleGroupArn != null) {
        contents.ScheduleGroupArn = __expectString(data.ScheduleGroupArn);
    }
    return contents;
};
const deserializeAws_restJson1CreateScheduleGroupCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ConflictException":
        case "com.amazonaws.scheduler#ConflictException":
            throw await deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context);
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ServiceQuotaExceededException":
        case "com.amazonaws.scheduler#ServiceQuotaExceededException":
            throw await deserializeAws_restJson1ServiceQuotaExceededExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
export const deserializeAws_restJson1DeleteScheduleCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1DeleteScheduleCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const deserializeAws_restJson1DeleteScheduleCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ConflictException":
        case "com.amazonaws.scheduler#ConflictException":
            throw await deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context);
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.scheduler#ResourceNotFoundException":
            throw await deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
export const deserializeAws_restJson1DeleteScheduleGroupCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1DeleteScheduleGroupCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const deserializeAws_restJson1DeleteScheduleGroupCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ConflictException":
        case "com.amazonaws.scheduler#ConflictException":
            throw await deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context);
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.scheduler#ResourceNotFoundException":
            throw await deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
export const deserializeAws_restJson1GetScheduleCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1GetScheduleCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    if (data.Arn != null) {
        contents.Arn = __expectString(data.Arn);
    }
    if (data.CreationDate != null) {
        contents.CreationDate = __expectNonNull(__parseEpochTimestamp(__expectNumber(data.CreationDate)));
    }
    if (data.Description != null) {
        contents.Description = __expectString(data.Description);
    }
    if (data.EndDate != null) {
        contents.EndDate = __expectNonNull(__parseEpochTimestamp(__expectNumber(data.EndDate)));
    }
    if (data.FlexibleTimeWindow != null) {
        contents.FlexibleTimeWindow = deserializeAws_restJson1FlexibleTimeWindow(data.FlexibleTimeWindow, context);
    }
    if (data.GroupName != null) {
        contents.GroupName = __expectString(data.GroupName);
    }
    if (data.KmsKeyArn != null) {
        contents.KmsKeyArn = __expectString(data.KmsKeyArn);
    }
    if (data.LastModificationDate != null) {
        contents.LastModificationDate = __expectNonNull(__parseEpochTimestamp(__expectNumber(data.LastModificationDate)));
    }
    if (data.Name != null) {
        contents.Name = __expectString(data.Name);
    }
    if (data.ScheduleExpression != null) {
        contents.ScheduleExpression = __expectString(data.ScheduleExpression);
    }
    if (data.ScheduleExpressionTimezone != null) {
        contents.ScheduleExpressionTimezone = __expectString(data.ScheduleExpressionTimezone);
    }
    if (data.StartDate != null) {
        contents.StartDate = __expectNonNull(__parseEpochTimestamp(__expectNumber(data.StartDate)));
    }
    if (data.State != null) {
        contents.State = __expectString(data.State);
    }
    if (data.Target != null) {
        contents.Target = deserializeAws_restJson1Target(data.Target, context);
    }
    return contents;
};
const deserializeAws_restJson1GetScheduleCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.scheduler#ResourceNotFoundException":
            throw await deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
export const deserializeAws_restJson1GetScheduleGroupCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1GetScheduleGroupCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    if (data.Arn != null) {
        contents.Arn = __expectString(data.Arn);
    }
    if (data.CreationDate != null) {
        contents.CreationDate = __expectNonNull(__parseEpochTimestamp(__expectNumber(data.CreationDate)));
    }
    if (data.LastModificationDate != null) {
        contents.LastModificationDate = __expectNonNull(__parseEpochTimestamp(__expectNumber(data.LastModificationDate)));
    }
    if (data.Name != null) {
        contents.Name = __expectString(data.Name);
    }
    if (data.State != null) {
        contents.State = __expectString(data.State);
    }
    return contents;
};
const deserializeAws_restJson1GetScheduleGroupCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.scheduler#ResourceNotFoundException":
            throw await deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
export const deserializeAws_restJson1ListScheduleGroupsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1ListScheduleGroupsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    if (data.NextToken != null) {
        contents.NextToken = __expectString(data.NextToken);
    }
    if (data.ScheduleGroups != null) {
        contents.ScheduleGroups = deserializeAws_restJson1ScheduleGroupList(data.ScheduleGroups, context);
    }
    return contents;
};
const deserializeAws_restJson1ListScheduleGroupsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
export const deserializeAws_restJson1ListSchedulesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1ListSchedulesCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    if (data.NextToken != null) {
        contents.NextToken = __expectString(data.NextToken);
    }
    if (data.Schedules != null) {
        contents.Schedules = deserializeAws_restJson1ScheduleList(data.Schedules, context);
    }
    return contents;
};
const deserializeAws_restJson1ListSchedulesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.scheduler#ResourceNotFoundException":
            throw await deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
export const deserializeAws_restJson1ListTagsForResourceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1ListTagsForResourceCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    if (data.Tags != null) {
        contents.Tags = deserializeAws_restJson1TagList(data.Tags, context);
    }
    return contents;
};
const deserializeAws_restJson1ListTagsForResourceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.scheduler#ResourceNotFoundException":
            throw await deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
export const deserializeAws_restJson1TagResourceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1TagResourceCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const deserializeAws_restJson1TagResourceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ConflictException":
        case "com.amazonaws.scheduler#ConflictException":
            throw await deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context);
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.scheduler#ResourceNotFoundException":
            throw await deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
export const deserializeAws_restJson1UntagResourceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1UntagResourceCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const deserializeAws_restJson1UntagResourceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ConflictException":
        case "com.amazonaws.scheduler#ConflictException":
            throw await deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context);
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.scheduler#ResourceNotFoundException":
            throw await deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
export const deserializeAws_restJson1UpdateScheduleCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return deserializeAws_restJson1UpdateScheduleCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    if (data.ScheduleArn != null) {
        contents.ScheduleArn = __expectString(data.ScheduleArn);
    }
    return contents;
};
const deserializeAws_restJson1UpdateScheduleCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ConflictException":
        case "com.amazonaws.scheduler#ConflictException":
            throw await deserializeAws_restJson1ConflictExceptionResponse(parsedOutput, context);
        case "InternalServerException":
        case "com.amazonaws.scheduler#InternalServerException":
            throw await deserializeAws_restJson1InternalServerExceptionResponse(parsedOutput, context);
        case "ResourceNotFoundException":
        case "com.amazonaws.scheduler#ResourceNotFoundException":
            throw await deserializeAws_restJson1ResourceNotFoundExceptionResponse(parsedOutput, context);
        case "ThrottlingException":
        case "com.amazonaws.scheduler#ThrottlingException":
            throw await deserializeAws_restJson1ThrottlingExceptionResponse(parsedOutput, context);
        case "ValidationException":
        case "com.amazonaws.scheduler#ValidationException":
            throw await deserializeAws_restJson1ValidationExceptionResponse(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            throwDefaultError({
                output,
                parsedBody,
                exceptionCtor: __BaseException,
                errorCode,
            });
    }
};
const map = __map;
const deserializeAws_restJson1ConflictExceptionResponse = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    if (data.Message != null) {
        contents.Message = __expectString(data.Message);
    }
    const exception = new ConflictException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const deserializeAws_restJson1InternalServerExceptionResponse = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    if (data.Message != null) {
        contents.Message = __expectString(data.Message);
    }
    const exception = new InternalServerException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const deserializeAws_restJson1ResourceNotFoundExceptionResponse = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    if (data.Message != null) {
        contents.Message = __expectString(data.Message);
    }
    const exception = new ResourceNotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const deserializeAws_restJson1ServiceQuotaExceededExceptionResponse = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    if (data.Message != null) {
        contents.Message = __expectString(data.Message);
    }
    const exception = new ServiceQuotaExceededException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const deserializeAws_restJson1ThrottlingExceptionResponse = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    if (data.Message != null) {
        contents.Message = __expectString(data.Message);
    }
    const exception = new ThrottlingException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const deserializeAws_restJson1ValidationExceptionResponse = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    if (data.Message != null) {
        contents.Message = __expectString(data.Message);
    }
    const exception = new ValidationException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const serializeAws_restJson1AwsVpcConfiguration = (input, context) => {
    return {
        ...(input.AssignPublicIp != null && { AssignPublicIp: input.AssignPublicIp }),
        ...(input.SecurityGroups != null && {
            SecurityGroups: serializeAws_restJson1SecurityGroups(input.SecurityGroups, context),
        }),
        ...(input.Subnets != null && { Subnets: serializeAws_restJson1Subnets(input.Subnets, context) }),
    };
};
const serializeAws_restJson1CapacityProviderStrategy = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return serializeAws_restJson1CapacityProviderStrategyItem(entry, context);
    });
};
const serializeAws_restJson1CapacityProviderStrategyItem = (input, context) => {
    return {
        ...(input.base != null && { base: input.base }),
        ...(input.capacityProvider != null && { capacityProvider: input.capacityProvider }),
        ...(input.weight != null && { weight: input.weight }),
    };
};
const serializeAws_restJson1DeadLetterConfig = (input, context) => {
    return {
        ...(input.Arn != null && { Arn: input.Arn }),
    };
};
const serializeAws_restJson1EcsParameters = (input, context) => {
    return {
        ...(input.CapacityProviderStrategy != null && {
            CapacityProviderStrategy: serializeAws_restJson1CapacityProviderStrategy(input.CapacityProviderStrategy, context),
        }),
        ...(input.EnableECSManagedTags != null && { EnableECSManagedTags: input.EnableECSManagedTags }),
        ...(input.EnableExecuteCommand != null && { EnableExecuteCommand: input.EnableExecuteCommand }),
        ...(input.Group != null && { Group: input.Group }),
        ...(input.LaunchType != null && { LaunchType: input.LaunchType }),
        ...(input.NetworkConfiguration != null && {
            NetworkConfiguration: serializeAws_restJson1NetworkConfiguration(input.NetworkConfiguration, context),
        }),
        ...(input.PlacementConstraints != null && {
            PlacementConstraints: serializeAws_restJson1PlacementConstraints(input.PlacementConstraints, context),
        }),
        ...(input.PlacementStrategy != null && {
            PlacementStrategy: serializeAws_restJson1PlacementStrategies(input.PlacementStrategy, context),
        }),
        ...(input.PlatformVersion != null && { PlatformVersion: input.PlatformVersion }),
        ...(input.PropagateTags != null && { PropagateTags: input.PropagateTags }),
        ...(input.ReferenceId != null && { ReferenceId: input.ReferenceId }),
        ...(input.Tags != null && { Tags: serializeAws_restJson1Tags(input.Tags, context) }),
        ...(input.TaskCount != null && { TaskCount: input.TaskCount }),
        ...(input.TaskDefinitionArn != null && { TaskDefinitionArn: input.TaskDefinitionArn }),
    };
};
const serializeAws_restJson1EventBridgeParameters = (input, context) => {
    return {
        ...(input.DetailType != null && { DetailType: input.DetailType }),
        ...(input.Source != null && { Source: input.Source }),
    };
};
const serializeAws_restJson1FlexibleTimeWindow = (input, context) => {
    return {
        ...(input.MaximumWindowInMinutes != null && { MaximumWindowInMinutes: input.MaximumWindowInMinutes }),
        ...(input.Mode != null && { Mode: input.Mode }),
    };
};
const serializeAws_restJson1KinesisParameters = (input, context) => {
    return {
        ...(input.PartitionKey != null && { PartitionKey: input.PartitionKey }),
    };
};
const serializeAws_restJson1NetworkConfiguration = (input, context) => {
    return {
        ...(input.awsvpcConfiguration != null && {
            awsvpcConfiguration: serializeAws_restJson1AwsVpcConfiguration(input.awsvpcConfiguration, context),
        }),
    };
};
const serializeAws_restJson1PlacementConstraint = (input, context) => {
    return {
        ...(input.expression != null && { expression: input.expression }),
        ...(input.type != null && { type: input.type }),
    };
};
const serializeAws_restJson1PlacementConstraints = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return serializeAws_restJson1PlacementConstraint(entry, context);
    });
};
const serializeAws_restJson1PlacementStrategies = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return serializeAws_restJson1PlacementStrategy(entry, context);
    });
};
const serializeAws_restJson1PlacementStrategy = (input, context) => {
    return {
        ...(input.field != null && { field: input.field }),
        ...(input.type != null && { type: input.type }),
    };
};
const serializeAws_restJson1RetryPolicy = (input, context) => {
    return {
        ...(input.MaximumEventAgeInSeconds != null && { MaximumEventAgeInSeconds: input.MaximumEventAgeInSeconds }),
        ...(input.MaximumRetryAttempts != null && { MaximumRetryAttempts: input.MaximumRetryAttempts }),
    };
};
const serializeAws_restJson1SageMakerPipelineParameter = (input, context) => {
    return {
        ...(input.Name != null && { Name: input.Name }),
        ...(input.Value != null && { Value: input.Value }),
    };
};
const serializeAws_restJson1SageMakerPipelineParameterList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return serializeAws_restJson1SageMakerPipelineParameter(entry, context);
    });
};
const serializeAws_restJson1SageMakerPipelineParameters = (input, context) => {
    return {
        ...(input.PipelineParameterList != null && {
            PipelineParameterList: serializeAws_restJson1SageMakerPipelineParameterList(input.PipelineParameterList, context),
        }),
    };
};
const serializeAws_restJson1SecurityGroups = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return entry;
    });
};
const serializeAws_restJson1SqsParameters = (input, context) => {
    return {
        ...(input.MessageGroupId != null && { MessageGroupId: input.MessageGroupId }),
    };
};
const serializeAws_restJson1Subnets = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return entry;
    });
};
const serializeAws_restJson1Tag = (input, context) => {
    return {
        ...(input.Key != null && { Key: input.Key }),
        ...(input.Value != null && { Value: input.Value }),
    };
};
const serializeAws_restJson1TagList = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return serializeAws_restJson1Tag(entry, context);
    });
};
const serializeAws_restJson1TagMap = (input, context) => {
    return Object.entries(input).reduce((acc, [key, value]) => {
        if (value === null) {
            return acc;
        }
        acc[key] = value;
        return acc;
    }, {});
};
const serializeAws_restJson1Tags = (input, context) => {
    return input
        .filter((e) => e != null)
        .map((entry) => {
        return serializeAws_restJson1TagMap(entry, context);
    });
};
const serializeAws_restJson1Target = (input, context) => {
    return {
        ...(input.Arn != null && { Arn: input.Arn }),
        ...(input.DeadLetterConfig != null && {
            DeadLetterConfig: serializeAws_restJson1DeadLetterConfig(input.DeadLetterConfig, context),
        }),
        ...(input.EcsParameters != null && {
            EcsParameters: serializeAws_restJson1EcsParameters(input.EcsParameters, context),
        }),
        ...(input.EventBridgeParameters != null && {
            EventBridgeParameters: serializeAws_restJson1EventBridgeParameters(input.EventBridgeParameters, context),
        }),
        ...(input.Input != null && { Input: input.Input }),
        ...(input.KinesisParameters != null && {
            KinesisParameters: serializeAws_restJson1KinesisParameters(input.KinesisParameters, context),
        }),
        ...(input.RetryPolicy != null && { RetryPolicy: serializeAws_restJson1RetryPolicy(input.RetryPolicy, context) }),
        ...(input.RoleArn != null && { RoleArn: input.RoleArn }),
        ...(input.SageMakerPipelineParameters != null && {
            SageMakerPipelineParameters: serializeAws_restJson1SageMakerPipelineParameters(input.SageMakerPipelineParameters, context),
        }),
        ...(input.SqsParameters != null && {
            SqsParameters: serializeAws_restJson1SqsParameters(input.SqsParameters, context),
        }),
    };
};
const deserializeAws_restJson1AwsVpcConfiguration = (output, context) => {
    return {
        AssignPublicIp: __expectString(output.AssignPublicIp),
        SecurityGroups: output.SecurityGroups != null
            ? deserializeAws_restJson1SecurityGroups(output.SecurityGroups, context)
            : undefined,
        Subnets: output.Subnets != null ? deserializeAws_restJson1Subnets(output.Subnets, context) : undefined,
    };
};
const deserializeAws_restJson1CapacityProviderStrategy = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1CapacityProviderStrategyItem(entry, context);
    });
    return retVal;
};
const deserializeAws_restJson1CapacityProviderStrategyItem = (output, context) => {
    return {
        base: __expectInt32(output.base),
        capacityProvider: __expectString(output.capacityProvider),
        weight: __expectInt32(output.weight),
    };
};
const deserializeAws_restJson1DeadLetterConfig = (output, context) => {
    return {
        Arn: __expectString(output.Arn),
    };
};
const deserializeAws_restJson1EcsParameters = (output, context) => {
    return {
        CapacityProviderStrategy: output.CapacityProviderStrategy != null
            ? deserializeAws_restJson1CapacityProviderStrategy(output.CapacityProviderStrategy, context)
            : undefined,
        EnableECSManagedTags: __expectBoolean(output.EnableECSManagedTags),
        EnableExecuteCommand: __expectBoolean(output.EnableExecuteCommand),
        Group: __expectString(output.Group),
        LaunchType: __expectString(output.LaunchType),
        NetworkConfiguration: output.NetworkConfiguration != null
            ? deserializeAws_restJson1NetworkConfiguration(output.NetworkConfiguration, context)
            : undefined,
        PlacementConstraints: output.PlacementConstraints != null
            ? deserializeAws_restJson1PlacementConstraints(output.PlacementConstraints, context)
            : undefined,
        PlacementStrategy: output.PlacementStrategy != null
            ? deserializeAws_restJson1PlacementStrategies(output.PlacementStrategy, context)
            : undefined,
        PlatformVersion: __expectString(output.PlatformVersion),
        PropagateTags: __expectString(output.PropagateTags),
        ReferenceId: __expectString(output.ReferenceId),
        Tags: output.Tags != null ? deserializeAws_restJson1Tags(output.Tags, context) : undefined,
        TaskCount: __expectInt32(output.TaskCount),
        TaskDefinitionArn: __expectString(output.TaskDefinitionArn),
    };
};
const deserializeAws_restJson1EventBridgeParameters = (output, context) => {
    return {
        DetailType: __expectString(output.DetailType),
        Source: __expectString(output.Source),
    };
};
const deserializeAws_restJson1FlexibleTimeWindow = (output, context) => {
    return {
        MaximumWindowInMinutes: __expectInt32(output.MaximumWindowInMinutes),
        Mode: __expectString(output.Mode),
    };
};
const deserializeAws_restJson1KinesisParameters = (output, context) => {
    return {
        PartitionKey: __expectString(output.PartitionKey),
    };
};
const deserializeAws_restJson1NetworkConfiguration = (output, context) => {
    return {
        awsvpcConfiguration: output.awsvpcConfiguration != null
            ? deserializeAws_restJson1AwsVpcConfiguration(output.awsvpcConfiguration, context)
            : undefined,
    };
};
const deserializeAws_restJson1PlacementConstraint = (output, context) => {
    return {
        expression: __expectString(output.expression),
        type: __expectString(output.type),
    };
};
const deserializeAws_restJson1PlacementConstraints = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1PlacementConstraint(entry, context);
    });
    return retVal;
};
const deserializeAws_restJson1PlacementStrategies = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1PlacementStrategy(entry, context);
    });
    return retVal;
};
const deserializeAws_restJson1PlacementStrategy = (output, context) => {
    return {
        field: __expectString(output.field),
        type: __expectString(output.type),
    };
};
const deserializeAws_restJson1RetryPolicy = (output, context) => {
    return {
        MaximumEventAgeInSeconds: __expectInt32(output.MaximumEventAgeInSeconds),
        MaximumRetryAttempts: __expectInt32(output.MaximumRetryAttempts),
    };
};
const deserializeAws_restJson1SageMakerPipelineParameter = (output, context) => {
    return {
        Name: __expectString(output.Name),
        Value: __expectString(output.Value),
    };
};
const deserializeAws_restJson1SageMakerPipelineParameterList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1SageMakerPipelineParameter(entry, context);
    });
    return retVal;
};
const deserializeAws_restJson1SageMakerPipelineParameters = (output, context) => {
    return {
        PipelineParameterList: output.PipelineParameterList != null
            ? deserializeAws_restJson1SageMakerPipelineParameterList(output.PipelineParameterList, context)
            : undefined,
    };
};
const deserializeAws_restJson1ScheduleGroupList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ScheduleGroupSummary(entry, context);
    });
    return retVal;
};
const deserializeAws_restJson1ScheduleGroupSummary = (output, context) => {
    return {
        Arn: __expectString(output.Arn),
        CreationDate: output.CreationDate != null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreationDate)))
            : undefined,
        LastModificationDate: output.LastModificationDate != null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastModificationDate)))
            : undefined,
        Name: __expectString(output.Name),
        State: __expectString(output.State),
    };
};
const deserializeAws_restJson1ScheduleList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1ScheduleSummary(entry, context);
    });
    return retVal;
};
const deserializeAws_restJson1ScheduleSummary = (output, context) => {
    return {
        Arn: __expectString(output.Arn),
        CreationDate: output.CreationDate != null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreationDate)))
            : undefined,
        GroupName: __expectString(output.GroupName),
        LastModificationDate: output.LastModificationDate != null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastModificationDate)))
            : undefined,
        Name: __expectString(output.Name),
        State: __expectString(output.State),
        Target: output.Target != null ? deserializeAws_restJson1TargetSummary(output.Target, context) : undefined,
    };
};
const deserializeAws_restJson1SecurityGroups = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
    return retVal;
};
const deserializeAws_restJson1SqsParameters = (output, context) => {
    return {
        MessageGroupId: __expectString(output.MessageGroupId),
    };
};
const deserializeAws_restJson1Subnets = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
    return retVal;
};
const deserializeAws_restJson1Tag = (output, context) => {
    return {
        Key: __expectString(output.Key),
        Value: __expectString(output.Value),
    };
};
const deserializeAws_restJson1TagList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1Tag(entry, context);
    });
    return retVal;
};
const deserializeAws_restJson1TagMap = (output, context) => {
    return Object.entries(output).reduce((acc, [key, value]) => {
        if (value === null) {
            return acc;
        }
        acc[key] = __expectString(value);
        return acc;
    }, {});
};
const deserializeAws_restJson1Tags = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        if (entry === null) {
            return null;
        }
        return deserializeAws_restJson1TagMap(entry, context);
    });
    return retVal;
};
const deserializeAws_restJson1Target = (output, context) => {
    return {
        Arn: __expectString(output.Arn),
        DeadLetterConfig: output.DeadLetterConfig != null
            ? deserializeAws_restJson1DeadLetterConfig(output.DeadLetterConfig, context)
            : undefined,
        EcsParameters: output.EcsParameters != null ? deserializeAws_restJson1EcsParameters(output.EcsParameters, context) : undefined,
        EventBridgeParameters: output.EventBridgeParameters != null
            ? deserializeAws_restJson1EventBridgeParameters(output.EventBridgeParameters, context)
            : undefined,
        Input: __expectString(output.Input),
        KinesisParameters: output.KinesisParameters != null
            ? deserializeAws_restJson1KinesisParameters(output.KinesisParameters, context)
            : undefined,
        RetryPolicy: output.RetryPolicy != null ? deserializeAws_restJson1RetryPolicy(output.RetryPolicy, context) : undefined,
        RoleArn: __expectString(output.RoleArn),
        SageMakerPipelineParameters: output.SageMakerPipelineParameters != null
            ? deserializeAws_restJson1SageMakerPipelineParameters(output.SageMakerPipelineParameters, context)
            : undefined,
        SqsParameters: output.SqsParameters != null ? deserializeAws_restJson1SqsParameters(output.SqsParameters, context) : undefined,
    };
};
const deserializeAws_restJson1TargetSummary = (output, context) => {
    return {
        Arn: __expectString(output.Arn),
    };
};
const deserializeMetadata = (output) => ({
    httpStatusCode: output.statusCode,
    requestId: output.headers["x-amzn-requestid"] ?? output.headers["x-amzn-request-id"] ?? output.headers["x-amz-request-id"],
    extendedRequestId: output.headers["x-amz-id-2"],
    cfId: output.headers["x-amz-cf-id"],
});
const collectBody = (streamBody = new Uint8Array(), context) => {
    if (streamBody instanceof Uint8Array) {
        return Promise.resolve(streamBody);
    }
    return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
const collectBodyString = (streamBody, context) => collectBody(streamBody, context).then((body) => context.utf8Encoder(body));
const isSerializableHeaderValue = (value) => value !== undefined &&
    value !== null &&
    value !== "" &&
    (!Object.getOwnPropertyNames(value).includes("length") || value.length != 0) &&
    (!Object.getOwnPropertyNames(value).includes("size") || value.size != 0);
const parseBody = (streamBody, context) => collectBodyString(streamBody, context).then((encoded) => {
    if (encoded.length) {
        return JSON.parse(encoded);
    }
    return {};
});
const parseErrorBody = async (errorBody, context) => {
    const value = await parseBody(errorBody, context);
    value.message = value.message ?? value.Message;
    return value;
};
const loadRestJsonErrorCode = (output, data) => {
    const findKey = (object, key) => Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase());
    const sanitizeErrorCode = (rawValue) => {
        let cleanValue = rawValue;
        if (typeof cleanValue === "number") {
            cleanValue = cleanValue.toString();
        }
        if (cleanValue.indexOf(",") >= 0) {
            cleanValue = cleanValue.split(",")[0];
        }
        if (cleanValue.indexOf(":") >= 0) {
            cleanValue = cleanValue.split(":")[0];
        }
        if (cleanValue.indexOf("#") >= 0) {
            cleanValue = cleanValue.split("#")[1];
        }
        return cleanValue;
    };
    const headerKey = findKey(output.headers, "x-amzn-errortype");
    if (headerKey !== undefined) {
        return sanitizeErrorCode(output.headers[headerKey]);
    }
    if (data.code !== undefined) {
        return sanitizeErrorCode(data.code);
    }
    if (data["__type"] !== undefined) {
        return sanitizeErrorCode(data["__type"]);
    }
};
