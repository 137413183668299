import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

export default function getCredentialsFromCognito(idToken: string) {
    return fromCognitoIdentityPool({
        clientConfig: {
            region: process.env.AWS_REGION,
        },
        identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,
        logins: {
            [process.env.AWS_COGNITO_PROVIDER_URL]: idToken,
        }
    });
}
