import React from 'react';
import LoginForm from './LoginForm';

const backgroundStyle = {
    width: '100%',
    overflowY: 'auto',
    top: '0px',
    left: '0px',
};
const gridStyle = {
    maxWidth: '940px',
    minHeight: '100vh',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
};

function Login() {
    return (
        <div style={backgroundStyle}>
            <div className="c-grid c-grid--m" style={gridStyle}>
                <div className="c-grid__row c-grid__row--no-col c-grid__row--align-vcenter c-grid__row--align-hcenter py-6">
                    <div className="u-col--m-6 u-col--s-12">
                        <div className="c-block c-block_shadow py-5 px-4 ml-5 ml--m-0">
                            <h3 className="c-title u-text-align__center">
                                Connexion à votre gestionnaire de flux {process.env.ORGANISATION_NAME}
                            </h3>
                            <LoginForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
