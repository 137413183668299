import React, { useContext } from 'react';
import AuthenticationContext from '../context/Authentication';

function TopBar() {
    const {
        isLoggedIn,
        setAccessToken,
        setIdToken,
        setYpAccessToken,
    } = useContext(AuthenticationContext);

    function onLogout() {
        setIdToken(null);
        setAccessToken(null);
        setYpAccessToken(null);
    };

    if (!isLoggedIn) {
        return null;
    }

    return (
        <div data-title="topnav">
            <div className="c-topnav c-topnav__orga u-bg__white u-border u-border--bottom">
                <div className="c-topnav__left">
                    <a className="c-topnav__link c-topnav__link--large" href="/">
                        <img 
                            src="https://s3.eu-central-1.amazonaws.com/assets.yurplan.com/yurplan-v1/dist/yurplan_logo_white.svg" 
                            style={{ height: '40px', padding: '10px 0 0 0'}}
                        />
                    </a>
                </div>
                <div className="c-grid__row c-grid__row--no-col c-grid__row--align-vcenter c-grid__row--space-between">
                    <div 
                        className="c-type-title--3 mb-0 pl-4" 
                        style={{ lineHeight: '60px' }}
                    >
                        Interface de gestion de flux {process.env.ORGANISATION_NAME}
                    </div>
                    <div className="c-topnav__right">
                        <a className="c-link c-link--text-light" href="#" onClick={onLogout}>
                            Déconnexion
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
