import { AssignPublicIp } from '@aws-sdk/client-ecs';
import { FlexibleTimeWindowMode } from '@aws-sdk/client-scheduler';
import { SSMClient, GetParameterCommand } from '@aws-sdk/client-ssm';

export interface Config {
    awsS3ExportBucketName: string;
    awsSsmParameterStoreName: string;
    awsSsmParameterEventPrefix: string;
    awsSsmParameterTaskTemplateName: string;
    awsTaskDefinitionNamePrefix: string;
    awsEcs: {
        Cluster: string;
        LaunchType: string;
        LogsNamePrefix: string;
        NetworkConfiguration: {
            awsvpcConfiguration: {
                AssignPublicIp: AssignPublicIp;
                SecurityGroups: string[];
                Subnets: string[];
            };
        };
        PlatformVersion: string;
    };
    awsSchedule: {
        Arn: string;
        NamePrefix: string;
        FlexibleTimeWindow : {
            Mode: FlexibleTimeWindowMode;
        };
        ScheduleExpressionTimeZone: string;
        GroupName: string;
        ScheduleLastStartTime: string;
        RoleArn: string;
    };
    apiV1Config: {
        url: string;
        clientId: string;
        clientSecret: string;
        scope: string;
    };
}

export default async function getConfig(ssmClient: SSMClient): Promise<Config> {
    const ssmCommand = new GetParameterCommand({
        Name: process.env.AWS_SSM_PARAMETER_CONFIG_NAME,
        WithDecryption: true,
    });    
    const ssmResponse = await ssmClient.send(ssmCommand);
    if (ssmResponse.Parameter?.Value === undefined) {
        throw new Error('No value in SSM parameter');
    }   
    return JSON.parse(ssmResponse.Parameter.Value);
}
