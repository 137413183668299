import React, {
    createContext,
    useEffect,
    useMemo,
} from 'react';
import createToken, { GrantType } from '../api/apiv1Authentication';
import useCookie from '../hooks/useCookie';
import { useConfig } from './App';

export interface AuthenticationContextInterface {
    accessToken: string | null;
    setAccessToken: (accessToken: string | null, expiration: number | null) => void;
    ypAccessToken: string | null;
    setYpAccessToken: (ypAccessToken: string | null, expiration: number | null) => void;
    idToken: string | null;
    setIdToken: (idToken: string | null, expiration: number | null) => void;
    username: string | null;
    setUsername: (idToken: string | null, expiration: number | null) => void;
    isLoggedIn: boolean;
}

const AuthenticationContext = createContext<AuthenticationContextInterface>();

export function Provider({ children }: React.ElementChildrenAttribute): React.ReactElement {
    const config = useConfig();
    const [accessToken, setAccessToken] = useCookie('accessToken', null);
    const [ypAccessToken, setYpAccessToken] = useCookie('yp_apiv1_access_token', null);
    const [idToken, setIdToken] = useCookie('idToken', null);
    const [username, setUsername] = useCookie('username', null);

    const isLoggedIn = useMemo(() => accessToken !== null, [accessToken]);

    const context: AuthenticationContextInterface = useMemo(
        () => ({
            accessToken,
            setAccessToken,
            ypAccessToken,
            setYpAccessToken,
            idToken,
            setIdToken,
            username,
            setUsername,
            isLoggedIn,
        }),
        [accessToken, ypAccessToken, idToken, username, isLoggedIn],
    );

    useEffect(() => {
        if (config === null) {
            return;
        }
        if (ypAccessToken === null) {
            (async function() {
                const apiv1Result = await createToken({
                    clientId: config.apiV1Config.clientId,
                    clientSecret: config.apiV1Config.clientSecret,
                    grantType: GrantType.CLIENT_CREDENTIALS,
                    scope: config.apiV1Config.scope,
                }, config.apiV1Config.url);  
                setYpAccessToken(apiv1Result.access_token, Date.now() + (apiv1Result.expires_in * 1000));
            })();
        }
    }, [ypAccessToken, config]);

    return (
        <AuthenticationContext.Provider value={context}>
            {children}
        </AuthenticationContext.Provider>
    );
}

export const { Consumer } = AuthenticationContext;

export default AuthenticationContext;
