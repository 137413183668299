export function getCookieValue(cookieName): string | null {
    const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
    if (match) return match[2];
    return null;
}

export function setCookieValue(name: string, value: string, expiration: number | null = null) {
    let expirationDate = null;
    if (expiration) {
        expirationDate = new Date(expiration);
    } else {
        expirationDate = new Date();
        // 1 week from now
        expirationDate.setTime(expirationDate.getTime() + (1000 * 60 * 60 * 24 * 7));
    }
    const cookie = `${name}=${value};expires=${expirationDate.toUTCString()};path=/;samesite=strict;${process.env.NODE_ENV === 'production' ? 'secure;' : ''}`;
    document.cookie = cookie;
}

export function deleteCookie(name) {
    const expireDate = 'Thu, 01 Jan 1970 00:00:01 GMT';
    const cookie = `${name}=0;expires=${expireDate}`;
    document.cookie = cookie;
}