import React, {
    createContext,
    useContext,
    useMemo,
} from 'react';
import { SSMClient } from '@aws-sdk/client-ssm';
import {
    SchedulerClient,
} from '@aws-sdk/client-scheduler';
import { S3Client } from '@aws-sdk/client-s3';
import { S3RequestPresigner } from '@aws-sdk/s3-request-presigner';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { ECSClient } from '@aws-sdk/client-ecs';

import getCredentialsFromCognito from '../api/getCredentialsFromCognito';
import AuthenticationContext, { AuthenticationContextInterface } from './Authentication';

const region = process.env.AWS_REGION;
export interface AwsClientsContextInterface {
    ecs: ECSClient | null,
    scheduler: SchedulerClient | null,
    s3: S3Client | null,
    s3RequestPresigner: S3RequestPresigner | null,
    ssm: SSMClient | null,
}

const AwsClientsContext = createContext<AwsClientsContextInterface>();

export function Provider({ children }: React.ElementChildrenAttribute): React.ReactElement {
    if (region === undefined) {
        throw new Error('AWS region missing from environment variables.')
    }

    const { idToken } = useContext<AuthenticationContextInterface>(AuthenticationContext);

    const context: AwsClientsContextInterface = useMemo(() => {
        if (!idToken) {
            return {
                ecs: null,
                scheduler: null,
                s3: null,
                s3RequestPresigner: null,
                ssm: null,
            }
        }
        const ecs = new ECSClient({
            region,
            credentials: getCredentialsFromCognito(idToken),
        });
        const s3 = new S3Client({
            region,
            credentials: getCredentialsFromCognito(idToken),
        });
        const s3RequestPresigner = new S3RequestPresigner({
            region,
            sha256: Sha256,
            credentials: getCredentialsFromCognito(idToken),
        });
        const ssm = new SSMClient({
            region,
            credentials: getCredentialsFromCognito(idToken),
        });

        const scheduler = new SchedulerClient({
            region,
            credentials: getCredentialsFromCognito(idToken),
        });
        return {
            ecs,
            scheduler,
            s3,
            s3RequestPresigner,
            ssm,
        };
    }, [idToken]);

    return (
        <AwsClientsContext.Provider value={context}>
            {children}
        </AwsClientsContext.Provider>
    );
}

export const { Consumer } = AwsClientsContext;

export default AwsClientsContext;
