import React, { useContext, useEffect, useState } from 'react';
import login from '../../api/login';
import AuthenticationContext, { AuthenticationContextInterface } from '../../context/Authentication';
import useInputState from '../../hooks/useInputState';
import { RoutesEnum } from '../../routes';
import RoutingContext, { RoutingContextInterface } from '../../context/Routing';
import createToken, { GrantType } from '../../api/apiv1Authentication';
import getConfig from '../../api/getConfig';
import AwsClientsContext, { AwsClientsContextInterface } from '../../context/AwsClients';
import { useConfigSetter } from '../../context/App';

const EXPIRATION_THRESHOLD = 1000 * 60 * 5; // 5 minutes
function LoginForm() {
    const setConfig = useConfigSetter();
    const {
        setAccessToken,
        setYpAccessToken,
        setIdToken,
        setUsername: setUsernameCookie,
        username: usernameCookie,
    } = useContext<AuthenticationContextInterface>(AuthenticationContext);
    const { ssm } = useContext<AwsClientsContextInterface>(AwsClientsContext);
    const { setRoute } = useContext<RoutingContextInterface>(RoutingContext);
    const [username, setUsername] = useInputState(process.env.DEFAULT_USERNAME ?? usernameCookie ?? '');
    const [password, setPassword] = useInputState(process.env.DEFAULT_PASSWORD ?? '');
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [isOk, setIsOk] = useState(false);

    useEffect(() => {
        if (!isOk) {
            return;
        }
        if (ssm === null) {
            return;
        }
        (async function() {
            const config = await getConfig(ssm);
            setConfig(config);
            const apiv1Result = await createToken({
                clientId: config.apiV1Config.clientId,
                clientSecret: config.apiV1Config.clientSecret,
                grantType: GrantType.CLIENT_CREDENTIALS,
                scope: config.apiV1Config.scope,
            }, config.apiV1Config.url);  
            setYpAccessToken(apiv1Result.access_token, Date.now() + (apiv1Result.expires_in * 1000));
            setRoute(RoutesEnum.HOME);
        })();
    }, [isOk, ssm]);

    const onSubmit = async (event) => {
        event.preventDefault();
        if (isLoading) return;
        setIsLoading(true);
        try {
            const result = await login(username, password);
            setIsLoading(false);
            const accessToken = result.getAccessToken();
            const idToken = result.getIdToken();
            setHasError(false);
            setUsernameCookie(username);
            setIdToken(idToken.getJwtToken(), idToken.getExpiration() * 1000 - EXPIRATION_THRESHOLD);
            setAccessToken(accessToken.getJwtToken(), accessToken.getExpiration() * 1000 - EXPIRATION_THRESHOLD);
            setIsOk(true);
        } catch (error) {
            setHasError(true);
            setIsLoading(false);
            console.error(error);
        }
    };

    return (
        <form onSubmit={onSubmit} className="c-form mt-4">
            <div className="c-form__line">
                <label htmlFor="username" className="c-form__label">
                    Nom d'utilisateur *
                </label>
                <div className="c-form__field">
                    <input
                        id="username"
                        name="username"
                        type="text"
                        value={username}
                        onChange={setUsername}
                        className="c-form__input"
                        autoComplete="username"
                    />
                </div>
            </div>
            <div className="c-form__line">
                <label htmlFor="password" className="c-form__label">
                    Mot de passe *
                </label>
                <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={setPassword}
                    className="c-form__input"
                    autoComplete="current-password"
                />
            </div>
            {hasError && (
                <div className="c-msg-box c-msg-box--inner c-msg-box--error mb-3">
                    <div className="c-msg-box__icon">
                        <img src="https://assets.stage.yurplan.com/yurplan-v1/dist/d388def5ac5afd339842.svg" className="c-image" />
                    </div>
                    <div className="c-msg-box__text">
                        <div className="u-font-weight--bold">Compte utilisateur invalide</div>
                        Contactez l'équipe Yurplan pour plus d'information
                    </div>
                </div>
            )}
            <div className="c-grid__row c-grid__row--no-col">
                <button
                    type="submit"
                    disabled={isLoading}
                    className={`c-btn c-btn--primary c-btn--fluid ${isLoading ? 'c-btn--disabled' : ''}`}
                >
                    Connexion
                </button>
            </div>
        </form>
    );
}

export default LoginForm;
