import {
    SchedulerClient,
    GetScheduleCommand,
    ScheduleState,
    CreateScheduleCommand,
    DeleteScheduleCommand,
    GetScheduleCommandOutput,
    UpdateScheduleCommand,
} from '@aws-sdk/client-scheduler';
import { Event } from '../context/Fluxes';
import { Config } from './getConfig';


export interface Schedule extends GetScheduleCommandOutput {
    id: number;
}

export async function createExportStatus(
    schedulerClient: SchedulerClient,
    event: Event,
    config: Config,
    nbSchedules: number,
): Promise<boolean> {
    try {
        const [hour, minute] = config.awsSchedule.ScheduleLastStartTime.split(':');
        
        const { LogsNamePrefix, ...rest } = config.awsEcs;
        
        await schedulerClient.send(
            new CreateScheduleCommand({
                Name: `${config.awsSchedule.NamePrefix}${event.id}`,
                GroupName: config.awsSchedule.GroupName,
                State: ScheduleState.ENABLED,
                ScheduleExpression: `cron(${Number(minute) - ((nbSchedules * 5) % 60)} ${(Number(hour) - Math.floor(nbSchedules / 12) % 24)} * * ? *)`,
                ScheduleExpressionTimezone: config.awsSchedule.ScheduleExpressionTimeZone,
                Target: {
                    Arn: config.awsSchedule.Arn,
                    RoleArn: config.awsSchedule.RoleArn,
                    EcsParameters: {
                        ...rest,
                        TaskDefinitionArn: event.taskDefinition?.taskDefinitionArn,
                    },
                },
                FlexibleTimeWindow: config.awsSchedule.FlexibleTimeWindow,
            })
        );
        
        return true;
    } catch(e) {
        console.error('createExportStatus error', e);
        return false;
    }
}

export async function deleteExportStatus(
    schedulerClient: SchedulerClient,
    schedule: Schedule,
    schedules: Schedule[],
    config: Config
): Promise<boolean> {
    try {
        const time = config.awsSchedule.ScheduleLastStartTime.split(':');
        let hour = Number(time[0]);
        let minute = Number(time[1]);
        let isFirstSchedule = true;

        schedules.forEach(async (s) => {
            if (!isFirstSchedule) {  
                if (minute === 0) {
                    minute = 55;
                    hour = hour - 1;
                } else {
                    minute = minute - 5;
                }
            }
            isFirstSchedule = false;  

            await schedulerClient.send(
                new UpdateScheduleCommand({
                    Name: s.Name,
                    GroupName: s.GroupName,
                    Target: s.Target,
                    FlexibleTimeWindow: s.FlexibleTimeWindow,
                    ScheduleExpression: `cron(${minute} ${hour} * * ? *)`,
                    ScheduleExpressionTimezone: config.awsSchedule.ScheduleExpressionTimeZone,
                })
            );
        });
        await schedulerClient.send(
            new DeleteScheduleCommand({
                Name: schedule.Name,
                GroupName: schedule.GroupName,
            })
        );
        return true;
    } catch(e) {
        console.error('deleteExportStatus error', e);
        return false;
    }
}

export async function getEventSchedule(
    schedulerClient: SchedulerClient,
    scheduleName: string,
    eventId: number,
    groupeName: string,
): Promise<GetScheduleCommandOutput | null> {
    try {
        const currentSchedule = await schedulerClient.send(
            new GetScheduleCommand({
                Name: `${scheduleName}${eventId}`,
                GroupName: groupeName,
            })
        );        
        return currentSchedule;
    } catch(e) {
        switch (e.name) {
            case 'InternalServerException':
            case 'ThrottlingException':
                setTimeout(
                    async () => await getEventSchedule(schedulerClient, scheduleName, eventId, groupeName),
                    Math.floor(Math.random() * (1000 - 500) + 500),
                );
                break;
            case 'ValidationException':
                console.error('getEventSchedule ValidationException', e);
                throw new Error('ValidationException');
            case 'ResourceNotFoundException':
            default:
                break;
        }
        return null;
    }
}
