import React, { useContext, useEffect, useState } from 'react';
import getExports from '../../api/getExports';
import FluxesContext, { FluxesContextInterface } from '../../context/Fluxes';
import RoutingContext, { RoutingContextInterface } from '../../context/Routing';
import ExportsTable from './ExportsTable';
import { RoutesEnum } from '../../routes';
import { AwsClientsContextInterface } from '../../context/AwsClients';
import AwsClientsContext from '../../context/AwsClients';
import { useConfig } from '../../context/App';

function Exports() {
    const config = useConfig();
    const { s3 } = useContext<AwsClientsContextInterface>(AwsClientsContext);
    const { currentEvent, setExports } = useContext<FluxesContextInterface>(FluxesContext);
    const { generatePath } = useContext<RoutingContextInterface>(RoutingContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    if (!currentEvent) return null;
    async function loadExports(e) {
        if (e) {
            e.preventDefault();
        }
        if (isLoading) return;
        setIsLoading(true);
        const exports = await getExports(
            s3,
            currentEvent.id,
            config,
        );
        setExports(exports);
        setIsLoading(false);
    }
    useEffect(loadExports, []);
    return (
        <div className="c-grid c-grid--m" style={{ marginTop: 'calc(60px + 1.875rem)' }}>
            <div className="c-block c-block_shadow px-4 pb-4">
                <div className="c-block__header px-0">
                    <div className="pos_relative    ">
                        <div className="txt_left c-title py-3 m-0">
                            Exports de votre évènement {currentEvent.name}
                        </div>
                        <div className="c-grid__row c-grid__row--no-col pos_absolute t50p r0">
                            <a
                                className="c-btn c-btn--secondary"
                                href={generatePath(RoutesEnum.HOME)}
                            >
                                Retour
                            </a>
                            <a
                                className={`c-btn c-btn--secondary ${isLoading ? 'c-btn--disabled' : ''}`}
                                href="#"
                                onClick={loadExports}
                            >
                                ⟳ Rafraichir
                            </a>
                        </div>
                    </div>
                </div>
                {
                    isLoading
                        ? (
                            <div className="c-grid c-grid--fluid">
                                <div className="c-grid__row c-grid__row--align-hcenter">
                                    <div className="u-col--m-6 u-col--s-12 u-text-align__center my-3">
                                        <div>
                                            Chargement en cours...
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : <ExportsTable />
                }
            </div>
        </div>
    );
}

export default Exports;
