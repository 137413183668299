import React, { useContext } from 'react';
import downloadExport from '../../api/downloadExport';
import { CSVExportFile } from '../../context/Fluxes';
import FluxesContext, { FluxesContextInterface } from '../../context/Fluxes';
import AwsClientsContext, { AwsClientsContextInterface } from '../../context/AwsClients';
import { useConfig } from '../../context/App';

function getLocale() {
    return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
}

const dateTimeFormat = new Intl.DateTimeFormat(getLocale(), {
    dateStyle: 'long',
    timeStyle: 'medium',
});

const ExportItem: React.ReactElement = ({ exportFile }: { exportFile: CSVExportFile }) => {
    const { s3RequestPresigner } = useContext<AwsClientsContextInterface>(AwsClientsContext);
    const { currentEvent } = useContext<FluxesContextInterface>(FluxesContext);
    const config = useConfig();
    async function onDownload(e) {
        e.preventDefault();
        const downloadUrl = await downloadExport(s3RequestPresigner, exportFile.key, config);
        window.open(downloadUrl, '_blank');
    }
    return (
        <tr className="c-table__row">
            <td className="c-table__cell">
                <span>
                    {exportFile.name.replace(`${currentEvent.id}/`, '')}
                </span>
            </td>
            <td className="c-table__cell u-text-align__center">
                {dateTimeFormat.format(exportFile.createdAt)}
            </td>
            <td className="c-table__cell u-text-align__center">
                <a href="#" onClick={onDownload}>
                    Télécharger
                </a>
            </td>
        </tr>
    );
}

export default ExportItem;
