import { DescribeTaskDefinitionCommand, ECSClient, RegisterTaskDefinitionCommand } from "@aws-sdk/client-ecs";
import { GetParameterCommand, SSMClient } from "@aws-sdk/client-ssm";

export default async function createTaskDefinition(
    ssmClient: SSMClient,
    ecsClient: ECSClient,
    taskDefinitionName: string,
    eventId: number,
    templateName: string,
) {
    const ecsDescribeCommand = new DescribeTaskDefinitionCommand({
        taskDefinition: `${taskDefinitionName}${eventId}`,
    });
    try {
        const ecsDescribeResponse = await ecsClient.send(ecsDescribeCommand);
        if (ecsDescribeResponse.taskDefinition) {
            return ecsDescribeResponse.taskDefinition;
        }
    } catch (error) {
        console.error('DescribeTaskDefinitionCommand', error)
    }

    const ssmCommand = new GetParameterCommand({
        Name: templateName,
    });
    const ssmResponse = await ssmClient.send(ssmCommand);

    if (ssmResponse.Parameter?.Value === undefined) {
        throw new Error('No value in SSM parameter');
    }  

    const ecsRegisterCommand = new RegisterTaskDefinitionCommand(JSON.parse(ssmResponse.Parameter.Value.split('${eventId}').join(`${eventId}`)));

    try {
        const ecsRegisterResponse = await ecsClient.send(ecsRegisterCommand);
        return ecsRegisterResponse.taskDefinition;
    } catch (error) {
        console.error('RegisterTaskDefinitionCommand', error);
    }
}