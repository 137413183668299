import React from 'react';
import Router from './Router';
import { AppContextProvider } from './context/App';
import { Provider as AuthenticationContextProvider } from './context/Authentication';
import { Provider as AwsClientsContextProvider } from './context/AwsClients';
import { Provider as RoutingContextProvider } from './context/Routing';
import { Provider as FluxesContextProvider } from './context/Fluxes';
import TopBar from './components/TopBar';

function App() {
    return (
        <AppContextProvider>
            <AuthenticationContextProvider>
                <RoutingContextProvider>
                    <AwsClientsContextProvider>
                        <FluxesContextProvider>
                            <TopBar />
                            <Router />
                        </FluxesContextProvider>
                    </AwsClientsContextProvider>
                </RoutingContextProvider>
            </AuthenticationContextProvider>
        </AppContextProvider>
    );
}

export default App;
