import React, { useContext, useEffect, useState } from 'react';
import { Question, QuestionType } from '../../api/apiv1Questions';
import FluxesContext, { FluxesContextInterface } from '../../context/Fluxes';
import AnswersList from './AnswersList';

function QestionTypeLabel({ questionType }: { questionType: QuestionType}) {
    const [type, setType] = useState('');
    const [icon, setIcon] = useState('');

    useEffect(() => {
        switch (questionType) {
            case QuestionType.UNIQUE_CHOICE:
                setType('Choix unique');
                setIcon('checkmark-circle');
                break;
            case QuestionType.MULTIPLE_CHOICE:
                setType('Choix multiple');
                setIcon('checkmark-square');
                break;
            case QuestionType.DROP_DOWN_MENU:
                setType('Menu déroulant');
                setIcon('chevron-down');
                break;
            default:
                break;
        }
    }, [questionType]);


    return (
        <div className='c-grid__row c-grid__row--align-vcenter'>
            <i className={`yp_font yp_font_${icon} mr-1`} aria-hidden="true">O</i>
            {type}
        </div>
    );
}

export default function QuestionsListItem({ question }: { question: Question}) {
    const { isQuestionActivated, setIsQuestionActivated } = useContext<FluxesContextInterface>(FluxesContext);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setIsQuestionActivated(question.id, e.currentTarget.checked)
    };
    
    return (
        <div className="c-grid c-grid--fluid py-3" style={{ border: '1px solid #d2d8eb' }}>
            <div className='c-grid__row c-grid__row--align-vcenter'>
                <div className="c-type-title--1 u-font-weight--bold mb-1">
                    {question.label}
                </div>
                <div className="c-form__field c-form__field_toggle ml-1" style={{ display: 'block' }}>
                    <input
                        id={`question-active-${question.id}`}
                        name="question-active"
                        checked={isQuestionActivated(question.id)}
                        onChange={handleChange}
                        type="checkbox"
                        className='c-form__input'
                    />
                    <label htmlFor={`question-active-${question.id}`} className='c-form__label mb-0' />
                </div>
            </div>
            <QestionTypeLabel questionType={question.type} />
            <AnswersList answers={question.choice_answers} questionId={question.id} />
        </div>
    )
}