import { __assign, __awaiter, __generator, __read } from "tslib";
import { HttpRequest as __HttpRequest } from "@aws-sdk/protocol-http";
import { expectBoolean as __expectBoolean, expectInt32 as __expectInt32, expectLong as __expectLong, expectNonNull as __expectNonNull, expectNumber as __expectNumber, expectString as __expectString, parseEpochTimestamp as __parseEpochTimestamp, } from "@aws-sdk/smithy-client";
import { v4 as generateIdempotencyToken } from "uuid";
export var serializeAws_json1_1AddTagsToResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.AddTagsToResource",
        };
        body = JSON.stringify(serializeAws_json1_1AddTagsToResourceRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1AssociateOpsItemRelatedItemCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.AssociateOpsItemRelatedItem",
        };
        body = JSON.stringify(serializeAws_json1_1AssociateOpsItemRelatedItemRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1CancelCommandCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.CancelCommand",
        };
        body = JSON.stringify(serializeAws_json1_1CancelCommandRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1CancelMaintenanceWindowExecutionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.CancelMaintenanceWindowExecution",
        };
        body = JSON.stringify(serializeAws_json1_1CancelMaintenanceWindowExecutionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1CreateActivationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.CreateActivation",
        };
        body = JSON.stringify(serializeAws_json1_1CreateActivationRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1CreateAssociationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.CreateAssociation",
        };
        body = JSON.stringify(serializeAws_json1_1CreateAssociationRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1CreateAssociationBatchCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.CreateAssociationBatch",
        };
        body = JSON.stringify(serializeAws_json1_1CreateAssociationBatchRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1CreateDocumentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.CreateDocument",
        };
        body = JSON.stringify(serializeAws_json1_1CreateDocumentRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1CreateMaintenanceWindowCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.CreateMaintenanceWindow",
        };
        body = JSON.stringify(serializeAws_json1_1CreateMaintenanceWindowRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1CreateOpsItemCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.CreateOpsItem",
        };
        body = JSON.stringify(serializeAws_json1_1CreateOpsItemRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1CreateOpsMetadataCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.CreateOpsMetadata",
        };
        body = JSON.stringify(serializeAws_json1_1CreateOpsMetadataRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1CreatePatchBaselineCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.CreatePatchBaseline",
        };
        body = JSON.stringify(serializeAws_json1_1CreatePatchBaselineRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1CreateResourceDataSyncCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.CreateResourceDataSync",
        };
        body = JSON.stringify(serializeAws_json1_1CreateResourceDataSyncRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeleteActivationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeleteActivation",
        };
        body = JSON.stringify(serializeAws_json1_1DeleteActivationRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeleteAssociationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeleteAssociation",
        };
        body = JSON.stringify(serializeAws_json1_1DeleteAssociationRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeleteDocumentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeleteDocument",
        };
        body = JSON.stringify(serializeAws_json1_1DeleteDocumentRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeleteInventoryCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeleteInventory",
        };
        body = JSON.stringify(serializeAws_json1_1DeleteInventoryRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeleteMaintenanceWindowCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeleteMaintenanceWindow",
        };
        body = JSON.stringify(serializeAws_json1_1DeleteMaintenanceWindowRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeleteOpsMetadataCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeleteOpsMetadata",
        };
        body = JSON.stringify(serializeAws_json1_1DeleteOpsMetadataRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeleteParameterCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeleteParameter",
        };
        body = JSON.stringify(serializeAws_json1_1DeleteParameterRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeleteParametersCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeleteParameters",
        };
        body = JSON.stringify(serializeAws_json1_1DeleteParametersRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeletePatchBaselineCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeletePatchBaseline",
        };
        body = JSON.stringify(serializeAws_json1_1DeletePatchBaselineRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeleteResourceDataSyncCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeleteResourceDataSync",
        };
        body = JSON.stringify(serializeAws_json1_1DeleteResourceDataSyncRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeregisterManagedInstanceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeregisterManagedInstance",
        };
        body = JSON.stringify(serializeAws_json1_1DeregisterManagedInstanceRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeregisterPatchBaselineForPatchGroupCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeregisterPatchBaselineForPatchGroup",
        };
        body = JSON.stringify(serializeAws_json1_1DeregisterPatchBaselineForPatchGroupRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeregisterTargetFromMaintenanceWindowCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeregisterTargetFromMaintenanceWindow",
        };
        body = JSON.stringify(serializeAws_json1_1DeregisterTargetFromMaintenanceWindowRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DeregisterTaskFromMaintenanceWindowCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DeregisterTaskFromMaintenanceWindow",
        };
        body = JSON.stringify(serializeAws_json1_1DeregisterTaskFromMaintenanceWindowRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeActivationsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeActivations",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeActivationsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeAssociationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeAssociation",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeAssociationRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeAssociationExecutionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeAssociationExecutions",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeAssociationExecutionsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeAssociationExecutionTargetsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeAssociationExecutionTargets",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeAssociationExecutionTargetsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeAutomationExecutionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeAutomationExecutions",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeAutomationExecutionsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeAutomationStepExecutionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeAutomationStepExecutions",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeAutomationStepExecutionsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeAvailablePatchesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeAvailablePatches",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeAvailablePatchesRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeDocumentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeDocument",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeDocumentRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeDocumentPermissionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeDocumentPermission",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeDocumentPermissionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeEffectiveInstanceAssociationsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeEffectiveInstanceAssociations",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeEffectiveInstanceAssociationsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeEffectivePatchesForPatchBaselineCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeEffectivePatchesForPatchBaseline",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeEffectivePatchesForPatchBaselineRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeInstanceAssociationsStatusCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeInstanceAssociationsStatus",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeInstanceAssociationsStatusRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeInstanceInformationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeInstanceInformation",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeInstanceInformationRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeInstancePatchesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeInstancePatches",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeInstancePatchesRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeInstancePatchStatesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeInstancePatchStates",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeInstancePatchStatesRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeInstancePatchStatesForPatchGroupCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeInstancePatchStatesForPatchGroup",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeInstancePatchStatesForPatchGroupRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeInventoryDeletionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeInventoryDeletions",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeInventoryDeletionsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeMaintenanceWindowExecutionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeMaintenanceWindowExecutions",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeMaintenanceWindowExecutionsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeMaintenanceWindowExecutionTaskInvocationsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeMaintenanceWindowExecutionTaskInvocations",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeMaintenanceWindowExecutionTaskInvocationsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeMaintenanceWindowExecutionTasksCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeMaintenanceWindowExecutionTasks",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeMaintenanceWindowExecutionTasksRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeMaintenanceWindowsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeMaintenanceWindows",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeMaintenanceWindowsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeMaintenanceWindowScheduleCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeMaintenanceWindowSchedule",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeMaintenanceWindowScheduleRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeMaintenanceWindowsForTargetCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeMaintenanceWindowsForTarget",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeMaintenanceWindowsForTargetRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeMaintenanceWindowTargetsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeMaintenanceWindowTargets",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeMaintenanceWindowTargetsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeMaintenanceWindowTasksCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeMaintenanceWindowTasks",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeMaintenanceWindowTasksRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeOpsItemsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeOpsItems",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeOpsItemsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeParametersCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeParameters",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeParametersRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribePatchBaselinesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribePatchBaselines",
        };
        body = JSON.stringify(serializeAws_json1_1DescribePatchBaselinesRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribePatchGroupsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribePatchGroups",
        };
        body = JSON.stringify(serializeAws_json1_1DescribePatchGroupsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribePatchGroupStateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribePatchGroupState",
        };
        body = JSON.stringify(serializeAws_json1_1DescribePatchGroupStateRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribePatchPropertiesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribePatchProperties",
        };
        body = JSON.stringify(serializeAws_json1_1DescribePatchPropertiesRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DescribeSessionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DescribeSessions",
        };
        body = JSON.stringify(serializeAws_json1_1DescribeSessionsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1DisassociateOpsItemRelatedItemCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.DisassociateOpsItemRelatedItem",
        };
        body = JSON.stringify(serializeAws_json1_1DisassociateOpsItemRelatedItemRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetAutomationExecutionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetAutomationExecution",
        };
        body = JSON.stringify(serializeAws_json1_1GetAutomationExecutionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetCalendarStateCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetCalendarState",
        };
        body = JSON.stringify(serializeAws_json1_1GetCalendarStateRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetCommandInvocationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetCommandInvocation",
        };
        body = JSON.stringify(serializeAws_json1_1GetCommandInvocationRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetConnectionStatusCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetConnectionStatus",
        };
        body = JSON.stringify(serializeAws_json1_1GetConnectionStatusRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetDefaultPatchBaselineCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetDefaultPatchBaseline",
        };
        body = JSON.stringify(serializeAws_json1_1GetDefaultPatchBaselineRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetDeployablePatchSnapshotForInstanceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetDeployablePatchSnapshotForInstance",
        };
        body = JSON.stringify(serializeAws_json1_1GetDeployablePatchSnapshotForInstanceRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetDocumentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetDocument",
        };
        body = JSON.stringify(serializeAws_json1_1GetDocumentRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetInventoryCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetInventory",
        };
        body = JSON.stringify(serializeAws_json1_1GetInventoryRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetInventorySchemaCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetInventorySchema",
        };
        body = JSON.stringify(serializeAws_json1_1GetInventorySchemaRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetMaintenanceWindowCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetMaintenanceWindow",
        };
        body = JSON.stringify(serializeAws_json1_1GetMaintenanceWindowRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetMaintenanceWindowExecutionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetMaintenanceWindowExecution",
        };
        body = JSON.stringify(serializeAws_json1_1GetMaintenanceWindowExecutionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetMaintenanceWindowExecutionTaskCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetMaintenanceWindowExecutionTask",
        };
        body = JSON.stringify(serializeAws_json1_1GetMaintenanceWindowExecutionTaskRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetMaintenanceWindowExecutionTaskInvocationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetMaintenanceWindowExecutionTaskInvocation",
        };
        body = JSON.stringify(serializeAws_json1_1GetMaintenanceWindowExecutionTaskInvocationRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetMaintenanceWindowTaskCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetMaintenanceWindowTask",
        };
        body = JSON.stringify(serializeAws_json1_1GetMaintenanceWindowTaskRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetOpsItemCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetOpsItem",
        };
        body = JSON.stringify(serializeAws_json1_1GetOpsItemRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetOpsMetadataCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetOpsMetadata",
        };
        body = JSON.stringify(serializeAws_json1_1GetOpsMetadataRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetOpsSummaryCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetOpsSummary",
        };
        body = JSON.stringify(serializeAws_json1_1GetOpsSummaryRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetParameterCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetParameter",
        };
        body = JSON.stringify(serializeAws_json1_1GetParameterRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetParameterHistoryCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetParameterHistory",
        };
        body = JSON.stringify(serializeAws_json1_1GetParameterHistoryRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetParametersCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetParameters",
        };
        body = JSON.stringify(serializeAws_json1_1GetParametersRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetParametersByPathCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetParametersByPath",
        };
        body = JSON.stringify(serializeAws_json1_1GetParametersByPathRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetPatchBaselineCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetPatchBaseline",
        };
        body = JSON.stringify(serializeAws_json1_1GetPatchBaselineRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetPatchBaselineForPatchGroupCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetPatchBaselineForPatchGroup",
        };
        body = JSON.stringify(serializeAws_json1_1GetPatchBaselineForPatchGroupRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1GetServiceSettingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.GetServiceSetting",
        };
        body = JSON.stringify(serializeAws_json1_1GetServiceSettingRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1LabelParameterVersionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.LabelParameterVersion",
        };
        body = JSON.stringify(serializeAws_json1_1LabelParameterVersionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListAssociationsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListAssociations",
        };
        body = JSON.stringify(serializeAws_json1_1ListAssociationsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListAssociationVersionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListAssociationVersions",
        };
        body = JSON.stringify(serializeAws_json1_1ListAssociationVersionsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListCommandInvocationsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListCommandInvocations",
        };
        body = JSON.stringify(serializeAws_json1_1ListCommandInvocationsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListCommandsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListCommands",
        };
        body = JSON.stringify(serializeAws_json1_1ListCommandsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListComplianceItemsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListComplianceItems",
        };
        body = JSON.stringify(serializeAws_json1_1ListComplianceItemsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListComplianceSummariesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListComplianceSummaries",
        };
        body = JSON.stringify(serializeAws_json1_1ListComplianceSummariesRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListDocumentMetadataHistoryCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListDocumentMetadataHistory",
        };
        body = JSON.stringify(serializeAws_json1_1ListDocumentMetadataHistoryRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListDocumentsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListDocuments",
        };
        body = JSON.stringify(serializeAws_json1_1ListDocumentsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListDocumentVersionsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListDocumentVersions",
        };
        body = JSON.stringify(serializeAws_json1_1ListDocumentVersionsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListInventoryEntriesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListInventoryEntries",
        };
        body = JSON.stringify(serializeAws_json1_1ListInventoryEntriesRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListOpsItemEventsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListOpsItemEvents",
        };
        body = JSON.stringify(serializeAws_json1_1ListOpsItemEventsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListOpsItemRelatedItemsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListOpsItemRelatedItems",
        };
        body = JSON.stringify(serializeAws_json1_1ListOpsItemRelatedItemsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListOpsMetadataCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListOpsMetadata",
        };
        body = JSON.stringify(serializeAws_json1_1ListOpsMetadataRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListResourceComplianceSummariesCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListResourceComplianceSummaries",
        };
        body = JSON.stringify(serializeAws_json1_1ListResourceComplianceSummariesRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListResourceDataSyncCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListResourceDataSync",
        };
        body = JSON.stringify(serializeAws_json1_1ListResourceDataSyncRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ListTagsForResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ListTagsForResource",
        };
        body = JSON.stringify(serializeAws_json1_1ListTagsForResourceRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ModifyDocumentPermissionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ModifyDocumentPermission",
        };
        body = JSON.stringify(serializeAws_json1_1ModifyDocumentPermissionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1PutComplianceItemsCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.PutComplianceItems",
        };
        body = JSON.stringify(serializeAws_json1_1PutComplianceItemsRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1PutInventoryCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.PutInventory",
        };
        body = JSON.stringify(serializeAws_json1_1PutInventoryRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1PutParameterCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.PutParameter",
        };
        body = JSON.stringify(serializeAws_json1_1PutParameterRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1RegisterDefaultPatchBaselineCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.RegisterDefaultPatchBaseline",
        };
        body = JSON.stringify(serializeAws_json1_1RegisterDefaultPatchBaselineRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1RegisterPatchBaselineForPatchGroupCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.RegisterPatchBaselineForPatchGroup",
        };
        body = JSON.stringify(serializeAws_json1_1RegisterPatchBaselineForPatchGroupRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1RegisterTargetWithMaintenanceWindowCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.RegisterTargetWithMaintenanceWindow",
        };
        body = JSON.stringify(serializeAws_json1_1RegisterTargetWithMaintenanceWindowRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1RegisterTaskWithMaintenanceWindowCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.RegisterTaskWithMaintenanceWindow",
        };
        body = JSON.stringify(serializeAws_json1_1RegisterTaskWithMaintenanceWindowRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1RemoveTagsFromResourceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.RemoveTagsFromResource",
        };
        body = JSON.stringify(serializeAws_json1_1RemoveTagsFromResourceRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ResetServiceSettingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ResetServiceSetting",
        };
        body = JSON.stringify(serializeAws_json1_1ResetServiceSettingRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1ResumeSessionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.ResumeSession",
        };
        body = JSON.stringify(serializeAws_json1_1ResumeSessionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1SendAutomationSignalCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.SendAutomationSignal",
        };
        body = JSON.stringify(serializeAws_json1_1SendAutomationSignalRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1SendCommandCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.SendCommand",
        };
        body = JSON.stringify(serializeAws_json1_1SendCommandRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1StartAssociationsOnceCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.StartAssociationsOnce",
        };
        body = JSON.stringify(serializeAws_json1_1StartAssociationsOnceRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1StartAutomationExecutionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.StartAutomationExecution",
        };
        body = JSON.stringify(serializeAws_json1_1StartAutomationExecutionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1StartChangeRequestExecutionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.StartChangeRequestExecution",
        };
        body = JSON.stringify(serializeAws_json1_1StartChangeRequestExecutionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1StartSessionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.StartSession",
        };
        body = JSON.stringify(serializeAws_json1_1StartSessionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1StopAutomationExecutionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.StopAutomationExecution",
        };
        body = JSON.stringify(serializeAws_json1_1StopAutomationExecutionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1TerminateSessionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.TerminateSession",
        };
        body = JSON.stringify(serializeAws_json1_1TerminateSessionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UnlabelParameterVersionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UnlabelParameterVersion",
        };
        body = JSON.stringify(serializeAws_json1_1UnlabelParameterVersionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateAssociationCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateAssociation",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateAssociationRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateAssociationStatusCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateAssociationStatus",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateAssociationStatusRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateDocumentCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateDocument",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateDocumentRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateDocumentDefaultVersionCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateDocumentDefaultVersion",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateDocumentDefaultVersionRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateDocumentMetadataCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateDocumentMetadata",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateDocumentMetadataRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateMaintenanceWindowCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateMaintenanceWindow",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateMaintenanceWindowRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateMaintenanceWindowTargetCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateMaintenanceWindowTarget",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateMaintenanceWindowTargetRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateMaintenanceWindowTaskCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateMaintenanceWindowTask",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateMaintenanceWindowTaskRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateManagedInstanceRoleCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateManagedInstanceRole",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateManagedInstanceRoleRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateOpsItemCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateOpsItem",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateOpsItemRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateOpsMetadataCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateOpsMetadata",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateOpsMetadataRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdatePatchBaselineCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdatePatchBaseline",
        };
        body = JSON.stringify(serializeAws_json1_1UpdatePatchBaselineRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateResourceDataSyncCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateResourceDataSync",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateResourceDataSyncRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var serializeAws_json1_1UpdateServiceSettingCommand = function (input, context) { return __awaiter(void 0, void 0, void 0, function () {
    var headers, body;
    return __generator(this, function (_a) {
        headers = {
            "content-type": "application/x-amz-json-1.1",
            "x-amz-target": "AmazonSSM.UpdateServiceSetting",
        };
        body = JSON.stringify(serializeAws_json1_1UpdateServiceSettingRequest(input, context));
        return [2, buildHttpRpcRequest(context, headers, "/", undefined, body)];
    });
}); };
export var deserializeAws_json1_1AddTagsToResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1AddTagsToResourceCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1AddTagsToResourceResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1AddTagsToResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidResourceId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidResourceId": return [3, 4];
                    case "InvalidResourceType": return [3, 6];
                    case "com.amazonaws.ssm#InvalidResourceType": return [3, 6];
                    case "TooManyTagsError": return [3, 8];
                    case "com.amazonaws.ssm#TooManyTagsError": return [3, 8];
                    case "TooManyUpdates": return [3, 10];
                    case "com.amazonaws.ssm#TooManyUpdates": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidResourceIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidResourceTypeResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1TooManyTagsErrorResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1TooManyUpdatesResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1AssociateOpsItemRelatedItemCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1AssociateOpsItemRelatedItemCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1AssociateOpsItemRelatedItemResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1AssociateOpsItemRelatedItemCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsItemInvalidParameterException": return [3, 4];
                    case "com.amazonaws.ssm#OpsItemInvalidParameterException": return [3, 4];
                    case "OpsItemLimitExceededException": return [3, 6];
                    case "com.amazonaws.ssm#OpsItemLimitExceededException": return [3, 6];
                    case "OpsItemNotFoundException": return [3, 8];
                    case "com.amazonaws.ssm#OpsItemNotFoundException": return [3, 8];
                    case "OpsItemRelatedItemAlreadyExistsException": return [3, 10];
                    case "com.amazonaws.ssm#OpsItemRelatedItemAlreadyExistsException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsItemInvalidParameterExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1OpsItemLimitExceededExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1OpsItemNotFoundExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1OpsItemRelatedItemAlreadyExistsExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1CancelCommandCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1CancelCommandCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1CancelCommandResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1CancelCommandCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DuplicateInstanceId": return [3, 2];
                    case "com.amazonaws.ssm#DuplicateInstanceId": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidCommandId": return [3, 6];
                    case "com.amazonaws.ssm#InvalidCommandId": return [3, 6];
                    case "InvalidInstanceId": return [3, 8];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DuplicateInstanceIdResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidCommandIdResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1CancelMaintenanceWindowExecutionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1CancelMaintenanceWindowExecutionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1CancelMaintenanceWindowExecutionResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1CancelMaintenanceWindowExecutionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1CreateActivationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1CreateActivationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1CreateActivationResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1CreateActivationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidParameters": return [3, 4];
                    case "com.amazonaws.ssm#InvalidParameters": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidParametersResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1CreateAssociationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1CreateAssociationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1CreateAssociationResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1CreateAssociationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, parsedBody, message;
    var _p;
    return __generator(this, function (_q) {
        switch (_q.label) {
            case 0:
                _a = [__assign({}, output)];
                _p = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_p.body = _q.sent(), _p)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AssociationAlreadyExists": return [3, 2];
                    case "com.amazonaws.ssm#AssociationAlreadyExists": return [3, 2];
                    case "AssociationLimitExceeded": return [3, 4];
                    case "com.amazonaws.ssm#AssociationLimitExceeded": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 6];
                    case "InvalidDocument": return [3, 8];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 8];
                    case "InvalidDocumentVersion": return [3, 10];
                    case "com.amazonaws.ssm#InvalidDocumentVersion": return [3, 10];
                    case "InvalidInstanceId": return [3, 12];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 12];
                    case "InvalidOutputLocation": return [3, 14];
                    case "com.amazonaws.ssm#InvalidOutputLocation": return [3, 14];
                    case "InvalidParameters": return [3, 16];
                    case "com.amazonaws.ssm#InvalidParameters": return [3, 16];
                    case "InvalidSchedule": return [3, 18];
                    case "com.amazonaws.ssm#InvalidSchedule": return [3, 18];
                    case "InvalidTarget": return [3, 20];
                    case "com.amazonaws.ssm#InvalidTarget": return [3, 20];
                    case "UnsupportedPlatformType": return [3, 22];
                    case "com.amazonaws.ssm#UnsupportedPlatformType": return [3, 22];
                }
                return [3, 24];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AssociationAlreadyExistsResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1AssociationLimitExceededResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentVersionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 14:
                _j = [{}];
                return [4, deserializeAws_json1_1InvalidOutputLocationResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 16:
                _k = [{}];
                return [4, deserializeAws_json1_1InvalidParametersResponse(parsedOutput, context)];
            case 17:
                response = __assign.apply(void 0, [__assign.apply(void 0, _k.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 18:
                _l = [{}];
                return [4, deserializeAws_json1_1InvalidScheduleResponse(parsedOutput, context)];
            case 19:
                response = __assign.apply(void 0, [__assign.apply(void 0, _l.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 20:
                _m = [{}];
                return [4, deserializeAws_json1_1InvalidTargetResponse(parsedOutput, context)];
            case 21:
                response = __assign.apply(void 0, [__assign.apply(void 0, _m.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 22:
                _o = [{}];
                return [4, deserializeAws_json1_1UnsupportedPlatformTypeResponse(parsedOutput, context)];
            case 23:
                response = __assign.apply(void 0, [__assign.apply(void 0, _o.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 24:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _q.label = 25;
            case 25:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1CreateAssociationBatchCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1CreateAssociationBatchCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1CreateAssociationBatchResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1CreateAssociationBatchCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, parsedBody, message;
    var _p;
    return __generator(this, function (_q) {
        switch (_q.label) {
            case 0:
                _a = [__assign({}, output)];
                _p = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_p.body = _q.sent(), _p)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AssociationLimitExceeded": return [3, 2];
                    case "com.amazonaws.ssm#AssociationLimitExceeded": return [3, 2];
                    case "DuplicateInstanceId": return [3, 4];
                    case "com.amazonaws.ssm#DuplicateInstanceId": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 6];
                    case "InvalidDocument": return [3, 8];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 8];
                    case "InvalidDocumentVersion": return [3, 10];
                    case "com.amazonaws.ssm#InvalidDocumentVersion": return [3, 10];
                    case "InvalidInstanceId": return [3, 12];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 12];
                    case "InvalidOutputLocation": return [3, 14];
                    case "com.amazonaws.ssm#InvalidOutputLocation": return [3, 14];
                    case "InvalidParameters": return [3, 16];
                    case "com.amazonaws.ssm#InvalidParameters": return [3, 16];
                    case "InvalidSchedule": return [3, 18];
                    case "com.amazonaws.ssm#InvalidSchedule": return [3, 18];
                    case "InvalidTarget": return [3, 20];
                    case "com.amazonaws.ssm#InvalidTarget": return [3, 20];
                    case "UnsupportedPlatformType": return [3, 22];
                    case "com.amazonaws.ssm#UnsupportedPlatformType": return [3, 22];
                }
                return [3, 24];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AssociationLimitExceededResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1DuplicateInstanceIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentVersionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 14:
                _j = [{}];
                return [4, deserializeAws_json1_1InvalidOutputLocationResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 16:
                _k = [{}];
                return [4, deserializeAws_json1_1InvalidParametersResponse(parsedOutput, context)];
            case 17:
                response = __assign.apply(void 0, [__assign.apply(void 0, _k.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 18:
                _l = [{}];
                return [4, deserializeAws_json1_1InvalidScheduleResponse(parsedOutput, context)];
            case 19:
                response = __assign.apply(void 0, [__assign.apply(void 0, _l.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 20:
                _m = [{}];
                return [4, deserializeAws_json1_1InvalidTargetResponse(parsedOutput, context)];
            case 21:
                response = __assign.apply(void 0, [__assign.apply(void 0, _m.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 22:
                _o = [{}];
                return [4, deserializeAws_json1_1UnsupportedPlatformTypeResponse(parsedOutput, context)];
            case 23:
                response = __assign.apply(void 0, [__assign.apply(void 0, _o.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 24:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _q.label = 25;
            case 25:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1CreateDocumentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1CreateDocumentCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1CreateDocumentResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1CreateDocumentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, parsedBody, message;
    var _j;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                _a = [__assign({}, output)];
                _j = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_j.body = _k.sent(), _j)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DocumentAlreadyExists": return [3, 2];
                    case "com.amazonaws.ssm#DocumentAlreadyExists": return [3, 2];
                    case "DocumentLimitExceeded": return [3, 4];
                    case "com.amazonaws.ssm#DocumentLimitExceeded": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 6];
                    case "InvalidDocumentContent": return [3, 8];
                    case "com.amazonaws.ssm#InvalidDocumentContent": return [3, 8];
                    case "InvalidDocumentSchemaVersion": return [3, 10];
                    case "com.amazonaws.ssm#InvalidDocumentSchemaVersion": return [3, 10];
                    case "MaxDocumentSizeExceeded": return [3, 12];
                    case "com.amazonaws.ssm#MaxDocumentSizeExceeded": return [3, 12];
                }
                return [3, 14];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DocumentAlreadyExistsResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1DocumentLimitExceededResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentContentResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentSchemaVersionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1MaxDocumentSizeExceededResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 14:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _k.label = 15;
            case 15:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1CreateMaintenanceWindowCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1CreateMaintenanceWindowCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1CreateMaintenanceWindowResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1CreateMaintenanceWindowCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "IdempotentParameterMismatch": return [3, 2];
                    case "com.amazonaws.ssm#IdempotentParameterMismatch": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "ResourceLimitExceededException": return [3, 6];
                    case "com.amazonaws.ssm#ResourceLimitExceededException": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1IdempotentParameterMismatchResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1ResourceLimitExceededExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1CreateOpsItemCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1CreateOpsItemCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1CreateOpsItemResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1CreateOpsItemCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsItemAlreadyExistsException": return [3, 4];
                    case "com.amazonaws.ssm#OpsItemAlreadyExistsException": return [3, 4];
                    case "OpsItemInvalidParameterException": return [3, 6];
                    case "com.amazonaws.ssm#OpsItemInvalidParameterException": return [3, 6];
                    case "OpsItemLimitExceededException": return [3, 8];
                    case "com.amazonaws.ssm#OpsItemLimitExceededException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsItemAlreadyExistsExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1OpsItemInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1OpsItemLimitExceededExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1CreateOpsMetadataCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1CreateOpsMetadataCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1CreateOpsMetadataResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1CreateOpsMetadataCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsMetadataAlreadyExistsException": return [3, 4];
                    case "com.amazonaws.ssm#OpsMetadataAlreadyExistsException": return [3, 4];
                    case "OpsMetadataInvalidArgumentException": return [3, 6];
                    case "com.amazonaws.ssm#OpsMetadataInvalidArgumentException": return [3, 6];
                    case "OpsMetadataLimitExceededException": return [3, 8];
                    case "com.amazonaws.ssm#OpsMetadataLimitExceededException": return [3, 8];
                    case "OpsMetadataTooManyUpdatesException": return [3, 10];
                    case "com.amazonaws.ssm#OpsMetadataTooManyUpdatesException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsMetadataAlreadyExistsExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1OpsMetadataInvalidArgumentExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1OpsMetadataLimitExceededExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1OpsMetadataTooManyUpdatesExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1CreatePatchBaselineCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1CreatePatchBaselineCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1CreatePatchBaselineResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1CreatePatchBaselineCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "IdempotentParameterMismatch": return [3, 2];
                    case "com.amazonaws.ssm#IdempotentParameterMismatch": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "ResourceLimitExceededException": return [3, 6];
                    case "com.amazonaws.ssm#ResourceLimitExceededException": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1IdempotentParameterMismatchResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1ResourceLimitExceededExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1CreateResourceDataSyncCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1CreateResourceDataSyncCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1CreateResourceDataSyncResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1CreateResourceDataSyncCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "ResourceDataSyncAlreadyExistsException": return [3, 4];
                    case "com.amazonaws.ssm#ResourceDataSyncAlreadyExistsException": return [3, 4];
                    case "ResourceDataSyncCountExceededException": return [3, 6];
                    case "com.amazonaws.ssm#ResourceDataSyncCountExceededException": return [3, 6];
                    case "ResourceDataSyncInvalidConfigurationException": return [3, 8];
                    case "com.amazonaws.ssm#ResourceDataSyncInvalidConfigurationException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1ResourceDataSyncAlreadyExistsExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1ResourceDataSyncCountExceededExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1ResourceDataSyncInvalidConfigurationExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeleteActivationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeleteActivationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeleteActivationResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeleteActivationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidActivation": return [3, 4];
                    case "com.amazonaws.ssm#InvalidActivation": return [3, 4];
                    case "InvalidActivationId": return [3, 6];
                    case "com.amazonaws.ssm#InvalidActivationId": return [3, 6];
                    case "TooManyUpdates": return [3, 8];
                    case "com.amazonaws.ssm#TooManyUpdates": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidActivationResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidActivationIdResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1TooManyUpdatesResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeleteAssociationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeleteAssociationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeleteAssociationResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeleteAssociationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AssociationDoesNotExist": return [3, 2];
                    case "com.amazonaws.ssm#AssociationDoesNotExist": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidDocument": return [3, 6];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 6];
                    case "InvalidInstanceId": return [3, 8];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 8];
                    case "TooManyUpdates": return [3, 10];
                    case "com.amazonaws.ssm#TooManyUpdates": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AssociationDoesNotExistResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1TooManyUpdatesResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeleteDocumentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeleteDocumentCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeleteDocumentResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeleteDocumentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AssociatedInstances": return [3, 2];
                    case "com.amazonaws.ssm#AssociatedInstances": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidDocument": return [3, 6];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 6];
                    case "InvalidDocumentOperation": return [3, 8];
                    case "com.amazonaws.ssm#InvalidDocumentOperation": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AssociatedInstancesResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentOperationResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeleteInventoryCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeleteInventoryCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeleteInventoryResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeleteInventoryCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidDeleteInventoryParametersException": return [3, 4];
                    case "com.amazonaws.ssm#InvalidDeleteInventoryParametersException": return [3, 4];
                    case "InvalidInventoryRequestException": return [3, 6];
                    case "com.amazonaws.ssm#InvalidInventoryRequestException": return [3, 6];
                    case "InvalidOptionException": return [3, 8];
                    case "com.amazonaws.ssm#InvalidOptionException": return [3, 8];
                    case "InvalidTypeNameException": return [3, 10];
                    case "com.amazonaws.ssm#InvalidTypeNameException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidDeleteInventoryParametersExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidInventoryRequestExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidOptionExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidTypeNameExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeleteMaintenanceWindowCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeleteMaintenanceWindowCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeleteMaintenanceWindowResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeleteMaintenanceWindowCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeleteOpsMetadataCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeleteOpsMetadataCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeleteOpsMetadataResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeleteOpsMetadataCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsMetadataInvalidArgumentException": return [3, 4];
                    case "com.amazonaws.ssm#OpsMetadataInvalidArgumentException": return [3, 4];
                    case "OpsMetadataNotFoundException": return [3, 6];
                    case "com.amazonaws.ssm#OpsMetadataNotFoundException": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsMetadataInvalidArgumentExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1OpsMetadataNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeleteParameterCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeleteParameterCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeleteParameterResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeleteParameterCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "ParameterNotFound": return [3, 4];
                    case "com.amazonaws.ssm#ParameterNotFound": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1ParameterNotFoundResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeleteParametersCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeleteParametersCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeleteParametersResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeleteParametersCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeletePatchBaselineCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeletePatchBaselineCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeletePatchBaselineResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeletePatchBaselineCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "ResourceInUseException": return [3, 4];
                    case "com.amazonaws.ssm#ResourceInUseException": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1ResourceInUseExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeleteResourceDataSyncCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeleteResourceDataSyncCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeleteResourceDataSyncResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeleteResourceDataSyncCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "ResourceDataSyncInvalidConfigurationException": return [3, 4];
                    case "com.amazonaws.ssm#ResourceDataSyncInvalidConfigurationException": return [3, 4];
                    case "ResourceDataSyncNotFoundException": return [3, 6];
                    case "com.amazonaws.ssm#ResourceDataSyncNotFoundException": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1ResourceDataSyncInvalidConfigurationExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1ResourceDataSyncNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeregisterManagedInstanceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeregisterManagedInstanceCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeregisterManagedInstanceResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeregisterManagedInstanceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidInstanceId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeregisterPatchBaselineForPatchGroupCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeregisterPatchBaselineForPatchGroupCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeregisterPatchBaselineForPatchGroupResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeregisterPatchBaselineForPatchGroupCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidResourceId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidResourceId": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidResourceIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeregisterTargetFromMaintenanceWindowCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeregisterTargetFromMaintenanceWindowCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeregisterTargetFromMaintenanceWindowResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeregisterTargetFromMaintenanceWindowCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "TargetInUseException": return [3, 6];
                    case "com.amazonaws.ssm#TargetInUseException": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1TargetInUseExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DeregisterTaskFromMaintenanceWindowCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DeregisterTaskFromMaintenanceWindowCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DeregisterTaskFromMaintenanceWindowResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DeregisterTaskFromMaintenanceWindowCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeActivationsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeActivationsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeActivationsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeActivationsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilter": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilter": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeAssociationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeAssociationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeAssociationResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeAssociationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AssociationDoesNotExist": return [3, 2];
                    case "com.amazonaws.ssm#AssociationDoesNotExist": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidAssociationVersion": return [3, 6];
                    case "com.amazonaws.ssm#InvalidAssociationVersion": return [3, 6];
                    case "InvalidDocument": return [3, 8];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 8];
                    case "InvalidInstanceId": return [3, 10];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AssociationDoesNotExistResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidAssociationVersionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeAssociationExecutionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeAssociationExecutionsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeAssociationExecutionsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeAssociationExecutionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AssociationDoesNotExist": return [3, 2];
                    case "com.amazonaws.ssm#AssociationDoesNotExist": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AssociationDoesNotExistResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeAssociationExecutionTargetsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeAssociationExecutionTargetsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeAssociationExecutionTargetsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeAssociationExecutionTargetsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AssociationDoesNotExist": return [3, 2];
                    case "com.amazonaws.ssm#AssociationDoesNotExist": return [3, 2];
                    case "AssociationExecutionDoesNotExist": return [3, 4];
                    case "com.amazonaws.ssm#AssociationExecutionDoesNotExist": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 6];
                    case "InvalidNextToken": return [3, 8];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AssociationDoesNotExistResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1AssociationExecutionDoesNotExistResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeAutomationExecutionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeAutomationExecutionsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeAutomationExecutionsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeAutomationExecutionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilterKey": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilterKey": return [3, 4];
                    case "InvalidFilterValue": return [3, 6];
                    case "com.amazonaws.ssm#InvalidFilterValue": return [3, 6];
                    case "InvalidNextToken": return [3, 8];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterKeyResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidFilterValueResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeAutomationStepExecutionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeAutomationStepExecutionsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeAutomationStepExecutionsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeAutomationStepExecutionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AutomationExecutionNotFoundException": return [3, 2];
                    case "com.amazonaws.ssm#AutomationExecutionNotFoundException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidFilterKey": return [3, 6];
                    case "com.amazonaws.ssm#InvalidFilterKey": return [3, 6];
                    case "InvalidFilterValue": return [3, 8];
                    case "com.amazonaws.ssm#InvalidFilterValue": return [3, 8];
                    case "InvalidNextToken": return [3, 10];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AutomationExecutionNotFoundExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidFilterKeyResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidFilterValueResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeAvailablePatchesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeAvailablePatchesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeAvailablePatchesResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeAvailablePatchesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeDocumentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeDocumentCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeDocumentResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeDocumentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidDocument": return [3, 4];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 4];
                    case "InvalidDocumentVersion": return [3, 6];
                    case "com.amazonaws.ssm#InvalidDocumentVersion": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentVersionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeDocumentPermissionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeDocumentPermissionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeDocumentPermissionResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeDocumentPermissionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidDocument": return [3, 4];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 4];
                    case "InvalidDocumentOperation": return [3, 6];
                    case "com.amazonaws.ssm#InvalidDocumentOperation": return [3, 6];
                    case "InvalidNextToken": return [3, 8];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 8];
                    case "InvalidPermissionType": return [3, 10];
                    case "com.amazonaws.ssm#InvalidPermissionType": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentOperationResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidPermissionTypeResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeEffectiveInstanceAssociationsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeEffectiveInstanceAssociationsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeEffectiveInstanceAssociationsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeEffectiveInstanceAssociationsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidInstanceId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeEffectivePatchesForPatchBaselineCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeEffectivePatchesForPatchBaselineCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeEffectivePatchesForPatchBaselineResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeEffectivePatchesForPatchBaselineCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidResourceId": return [3, 6];
                    case "com.amazonaws.ssm#InvalidResourceId": return [3, 6];
                    case "UnsupportedOperatingSystem": return [3, 8];
                    case "com.amazonaws.ssm#UnsupportedOperatingSystem": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidResourceIdResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1UnsupportedOperatingSystemResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeInstanceAssociationsStatusCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeInstanceAssociationsStatusCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeInstanceAssociationsStatusResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeInstanceAssociationsStatusCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidInstanceId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeInstanceInformationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeInstanceInformationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeInstanceInformationResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeInstanceInformationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilterKey": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilterKey": return [3, 4];
                    case "InvalidInstanceId": return [3, 6];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 6];
                    case "InvalidInstanceInformationFilterValue": return [3, 8];
                    case "com.amazonaws.ssm#InvalidInstanceInformationFilterValue": return [3, 8];
                    case "InvalidNextToken": return [3, 10];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterKeyResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceInformationFilterValueResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeInstancePatchesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeInstancePatchesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeInstancePatchesResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeInstancePatchesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilter": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilter": return [3, 4];
                    case "InvalidInstanceId": return [3, 6];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 6];
                    case "InvalidNextToken": return [3, 8];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeInstancePatchStatesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeInstancePatchStatesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeInstancePatchStatesResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeInstancePatchStatesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidNextToken": return [3, 4];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeInstancePatchStatesForPatchGroupCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeInstancePatchStatesForPatchGroupCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeInstancePatchStatesForPatchGroupResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeInstancePatchStatesForPatchGroupCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilter": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilter": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeInventoryDeletionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeInventoryDeletionsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeInventoryDeletionsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeInventoryDeletionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidDeletionIdException": return [3, 4];
                    case "com.amazonaws.ssm#InvalidDeletionIdException": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidDeletionIdExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeMaintenanceWindowExecutionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeMaintenanceWindowExecutionsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeMaintenanceWindowExecutionsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeMaintenanceWindowExecutionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeMaintenanceWindowExecutionTaskInvocationsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeMaintenanceWindowExecutionTaskInvocationsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeMaintenanceWindowExecutionTaskInvocationsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeMaintenanceWindowExecutionTaskInvocationsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeMaintenanceWindowExecutionTasksCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeMaintenanceWindowExecutionTasksCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeMaintenanceWindowExecutionTasksResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeMaintenanceWindowExecutionTasksCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeMaintenanceWindowsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeMaintenanceWindowsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeMaintenanceWindowsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeMaintenanceWindowsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeMaintenanceWindowScheduleCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeMaintenanceWindowScheduleCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeMaintenanceWindowScheduleResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeMaintenanceWindowScheduleCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeMaintenanceWindowsForTargetCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeMaintenanceWindowsForTargetCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeMaintenanceWindowsForTargetResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeMaintenanceWindowsForTargetCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeMaintenanceWindowTargetsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeMaintenanceWindowTargetsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeMaintenanceWindowTargetsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeMaintenanceWindowTargetsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeMaintenanceWindowTasksCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeMaintenanceWindowTasksCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeMaintenanceWindowTasksResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeMaintenanceWindowTasksCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeOpsItemsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeOpsItemsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeOpsItemsResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeOpsItemsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeParametersCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeParametersCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeParametersResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeParametersCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilterKey": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilterKey": return [3, 4];
                    case "InvalidFilterOption": return [3, 6];
                    case "com.amazonaws.ssm#InvalidFilterOption": return [3, 6];
                    case "InvalidFilterValue": return [3, 8];
                    case "com.amazonaws.ssm#InvalidFilterValue": return [3, 8];
                    case "InvalidNextToken": return [3, 10];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterKeyResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidFilterOptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidFilterValueResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribePatchBaselinesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribePatchBaselinesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribePatchBaselinesResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribePatchBaselinesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribePatchGroupsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribePatchGroupsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribePatchGroupsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribePatchGroupsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribePatchGroupStateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribePatchGroupStateCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribePatchGroupStateResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribePatchGroupStateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidNextToken": return [3, 4];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribePatchPropertiesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribePatchPropertiesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribePatchPropertiesResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribePatchPropertiesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DescribeSessionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DescribeSessionsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DescribeSessionsResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DescribeSessionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilterKey": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilterKey": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterKeyResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1DisassociateOpsItemRelatedItemCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1DisassociateOpsItemRelatedItemCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1DisassociateOpsItemRelatedItemResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1DisassociateOpsItemRelatedItemCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsItemInvalidParameterException": return [3, 4];
                    case "com.amazonaws.ssm#OpsItemInvalidParameterException": return [3, 4];
                    case "OpsItemNotFoundException": return [3, 6];
                    case "com.amazonaws.ssm#OpsItemNotFoundException": return [3, 6];
                    case "OpsItemRelatedItemAssociationNotFoundException": return [3, 8];
                    case "com.amazonaws.ssm#OpsItemRelatedItemAssociationNotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsItemInvalidParameterExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1OpsItemNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1OpsItemRelatedItemAssociationNotFoundExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetAutomationExecutionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetAutomationExecutionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetAutomationExecutionResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetAutomationExecutionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AutomationExecutionNotFoundException": return [3, 2];
                    case "com.amazonaws.ssm#AutomationExecutionNotFoundException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AutomationExecutionNotFoundExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetCalendarStateCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetCalendarStateCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetCalendarStateResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetCalendarStateCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidDocument": return [3, 4];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 4];
                    case "InvalidDocumentType": return [3, 6];
                    case "com.amazonaws.ssm#InvalidDocumentType": return [3, 6];
                    case "UnsupportedCalendarException": return [3, 8];
                    case "com.amazonaws.ssm#UnsupportedCalendarException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentTypeResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1UnsupportedCalendarExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetCommandInvocationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetCommandInvocationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetCommandInvocationResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetCommandInvocationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidCommandId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidCommandId": return [3, 4];
                    case "InvalidInstanceId": return [3, 6];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 6];
                    case "InvalidPluginName": return [3, 8];
                    case "com.amazonaws.ssm#InvalidPluginName": return [3, 8];
                    case "InvocationDoesNotExist": return [3, 10];
                    case "com.amazonaws.ssm#InvocationDoesNotExist": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidCommandIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidPluginNameResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvocationDoesNotExistResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetConnectionStatusCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetConnectionStatusCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetConnectionStatusResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetConnectionStatusCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetDefaultPatchBaselineCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetDefaultPatchBaselineCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetDefaultPatchBaselineResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetDefaultPatchBaselineCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetDeployablePatchSnapshotForInstanceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetDeployablePatchSnapshotForInstanceCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetDeployablePatchSnapshotForInstanceResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetDeployablePatchSnapshotForInstanceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "UnsupportedFeatureRequiredException": return [3, 4];
                    case "com.amazonaws.ssm#UnsupportedFeatureRequiredException": return [3, 4];
                    case "UnsupportedOperatingSystem": return [3, 6];
                    case "com.amazonaws.ssm#UnsupportedOperatingSystem": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1UnsupportedFeatureRequiredExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1UnsupportedOperatingSystemResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetDocumentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetDocumentCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetDocumentResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetDocumentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidDocument": return [3, 4];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 4];
                    case "InvalidDocumentVersion": return [3, 6];
                    case "com.amazonaws.ssm#InvalidDocumentVersion": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentVersionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetInventoryCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetInventoryCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetInventoryResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetInventoryCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, parsedBody, message;
    var _k;
    return __generator(this, function (_l) {
        switch (_l.label) {
            case 0:
                _a = [__assign({}, output)];
                _k = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_k.body = _l.sent(), _k)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidAggregatorException": return [3, 4];
                    case "com.amazonaws.ssm#InvalidAggregatorException": return [3, 4];
                    case "InvalidFilter": return [3, 6];
                    case "com.amazonaws.ssm#InvalidFilter": return [3, 6];
                    case "InvalidInventoryGroupException": return [3, 8];
                    case "com.amazonaws.ssm#InvalidInventoryGroupException": return [3, 8];
                    case "InvalidNextToken": return [3, 10];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 10];
                    case "InvalidResultAttributeException": return [3, 12];
                    case "com.amazonaws.ssm#InvalidResultAttributeException": return [3, 12];
                    case "InvalidTypeNameException": return [3, 14];
                    case "com.amazonaws.ssm#InvalidTypeNameException": return [3, 14];
                }
                return [3, 16];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidAggregatorExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidFilterResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidInventoryGroupExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1InvalidResultAttributeExceptionResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 14:
                _j = [{}];
                return [4, deserializeAws_json1_1InvalidTypeNameExceptionResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 16:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _l.label = 17;
            case 17:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetInventorySchemaCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetInventorySchemaCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetInventorySchemaResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetInventorySchemaCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidNextToken": return [3, 4];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 4];
                    case "InvalidTypeNameException": return [3, 6];
                    case "com.amazonaws.ssm#InvalidTypeNameException": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidTypeNameExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetMaintenanceWindowCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetMaintenanceWindowCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetMaintenanceWindowResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetMaintenanceWindowCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetMaintenanceWindowExecutionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetMaintenanceWindowExecutionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetMaintenanceWindowExecutionResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetMaintenanceWindowExecutionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetMaintenanceWindowExecutionTaskCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetMaintenanceWindowExecutionTaskCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetMaintenanceWindowExecutionTaskResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetMaintenanceWindowExecutionTaskCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetMaintenanceWindowExecutionTaskInvocationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetMaintenanceWindowExecutionTaskInvocationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetMaintenanceWindowExecutionTaskInvocationResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetMaintenanceWindowExecutionTaskInvocationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetMaintenanceWindowTaskCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetMaintenanceWindowTaskCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetMaintenanceWindowTaskResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetMaintenanceWindowTaskCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetOpsItemCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetOpsItemCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetOpsItemResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetOpsItemCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsItemNotFoundException": return [3, 4];
                    case "com.amazonaws.ssm#OpsItemNotFoundException": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsItemNotFoundExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetOpsMetadataCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetOpsMetadataCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetOpsMetadataResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetOpsMetadataCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsMetadataInvalidArgumentException": return [3, 4];
                    case "com.amazonaws.ssm#OpsMetadataInvalidArgumentException": return [3, 4];
                    case "OpsMetadataNotFoundException": return [3, 6];
                    case "com.amazonaws.ssm#OpsMetadataNotFoundException": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsMetadataInvalidArgumentExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1OpsMetadataNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetOpsSummaryCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetOpsSummaryCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetOpsSummaryResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetOpsSummaryCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, parsedBody, message;
    var _j;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                _a = [__assign({}, output)];
                _j = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_j.body = _k.sent(), _j)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidAggregatorException": return [3, 4];
                    case "com.amazonaws.ssm#InvalidAggregatorException": return [3, 4];
                    case "InvalidFilter": return [3, 6];
                    case "com.amazonaws.ssm#InvalidFilter": return [3, 6];
                    case "InvalidNextToken": return [3, 8];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 8];
                    case "InvalidTypeNameException": return [3, 10];
                    case "com.amazonaws.ssm#InvalidTypeNameException": return [3, 10];
                    case "ResourceDataSyncNotFoundException": return [3, 12];
                    case "com.amazonaws.ssm#ResourceDataSyncNotFoundException": return [3, 12];
                }
                return [3, 14];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidAggregatorExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidFilterResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidTypeNameExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1ResourceDataSyncNotFoundExceptionResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 14:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _k.label = 15;
            case 15:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetParameterCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetParameterCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetParameterResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetParameterCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidKeyId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidKeyId": return [3, 4];
                    case "ParameterNotFound": return [3, 6];
                    case "com.amazonaws.ssm#ParameterNotFound": return [3, 6];
                    case "ParameterVersionNotFound": return [3, 8];
                    case "com.amazonaws.ssm#ParameterVersionNotFound": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidKeyIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1ParameterNotFoundResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1ParameterVersionNotFoundResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetParameterHistoryCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetParameterHistoryCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetParameterHistoryResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetParameterHistoryCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidKeyId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidKeyId": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                    case "ParameterNotFound": return [3, 8];
                    case "com.amazonaws.ssm#ParameterNotFound": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidKeyIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1ParameterNotFoundResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetParametersCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetParametersCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetParametersResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetParametersCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidKeyId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidKeyId": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidKeyIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetParametersByPathCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetParametersByPathCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetParametersByPathResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetParametersByPathCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, parsedBody, message;
    var _j;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                _a = [__assign({}, output)];
                _j = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_j.body = _k.sent(), _j)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilterKey": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilterKey": return [3, 4];
                    case "InvalidFilterOption": return [3, 6];
                    case "com.amazonaws.ssm#InvalidFilterOption": return [3, 6];
                    case "InvalidFilterValue": return [3, 8];
                    case "com.amazonaws.ssm#InvalidFilterValue": return [3, 8];
                    case "InvalidKeyId": return [3, 10];
                    case "com.amazonaws.ssm#InvalidKeyId": return [3, 10];
                    case "InvalidNextToken": return [3, 12];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 12];
                }
                return [3, 14];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterKeyResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidFilterOptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidFilterValueResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidKeyIdResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 14:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _k.label = 15;
            case 15:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetPatchBaselineCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetPatchBaselineCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetPatchBaselineResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetPatchBaselineCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidResourceId": return [3, 6];
                    case "com.amazonaws.ssm#InvalidResourceId": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidResourceIdResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetPatchBaselineForPatchGroupCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetPatchBaselineForPatchGroupCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetPatchBaselineForPatchGroupResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetPatchBaselineForPatchGroupCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, parsedBody, message;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _a = [__assign({}, output)];
                _d = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_d.body = _e.sent(), _d)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                }
                return [3, 4];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_e.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 5];
            case 4:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _e.label = 5;
            case 5:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1GetServiceSettingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1GetServiceSettingCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1GetServiceSettingResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1GetServiceSettingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "ServiceSettingNotFound": return [3, 4];
                    case "com.amazonaws.ssm#ServiceSettingNotFound": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1ServiceSettingNotFoundResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1LabelParameterVersionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1LabelParameterVersionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1LabelParameterVersionResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1LabelParameterVersionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "ParameterNotFound": return [3, 4];
                    case "com.amazonaws.ssm#ParameterNotFound": return [3, 4];
                    case "ParameterVersionLabelLimitExceeded": return [3, 6];
                    case "com.amazonaws.ssm#ParameterVersionLabelLimitExceeded": return [3, 6];
                    case "ParameterVersionNotFound": return [3, 8];
                    case "com.amazonaws.ssm#ParameterVersionNotFound": return [3, 8];
                    case "TooManyUpdates": return [3, 10];
                    case "com.amazonaws.ssm#TooManyUpdates": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1ParameterNotFoundResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1ParameterVersionLabelLimitExceededResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1ParameterVersionNotFoundResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1TooManyUpdatesResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListAssociationsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListAssociationsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListAssociationsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListAssociationsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidNextToken": return [3, 4];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListAssociationVersionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListAssociationVersionsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListAssociationVersionsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListAssociationVersionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AssociationDoesNotExist": return [3, 2];
                    case "com.amazonaws.ssm#AssociationDoesNotExist": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AssociationDoesNotExistResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListCommandInvocationsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListCommandInvocationsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListCommandInvocationsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListCommandInvocationsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidCommandId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidCommandId": return [3, 4];
                    case "InvalidFilterKey": return [3, 6];
                    case "com.amazonaws.ssm#InvalidFilterKey": return [3, 6];
                    case "InvalidInstanceId": return [3, 8];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 8];
                    case "InvalidNextToken": return [3, 10];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidCommandIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidFilterKeyResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListCommandsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListCommandsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListCommandsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListCommandsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidCommandId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidCommandId": return [3, 4];
                    case "InvalidFilterKey": return [3, 6];
                    case "com.amazonaws.ssm#InvalidFilterKey": return [3, 6];
                    case "InvalidInstanceId": return [3, 8];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 8];
                    case "InvalidNextToken": return [3, 10];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidCommandIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidFilterKeyResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListComplianceItemsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListComplianceItemsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListComplianceItemsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListComplianceItemsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilter": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilter": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                    case "InvalidResourceId": return [3, 8];
                    case "com.amazonaws.ssm#InvalidResourceId": return [3, 8];
                    case "InvalidResourceType": return [3, 10];
                    case "com.amazonaws.ssm#InvalidResourceType": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidResourceIdResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidResourceTypeResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListComplianceSummariesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListComplianceSummariesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListComplianceSummariesResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListComplianceSummariesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilter": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilter": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListDocumentMetadataHistoryCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListDocumentMetadataHistoryCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListDocumentMetadataHistoryResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListDocumentMetadataHistoryCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidDocument": return [3, 4];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 4];
                    case "InvalidDocumentVersion": return [3, 6];
                    case "com.amazonaws.ssm#InvalidDocumentVersion": return [3, 6];
                    case "InvalidNextToken": return [3, 8];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentVersionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListDocumentsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListDocumentsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListDocumentsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListDocumentsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilterKey": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilterKey": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterKeyResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListDocumentVersionsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListDocumentVersionsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListDocumentVersionsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListDocumentVersionsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidDocument": return [3, 4];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListInventoryEntriesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListInventoryEntriesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListInventoryEntriesResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListInventoryEntriesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilter": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilter": return [3, 4];
                    case "InvalidInstanceId": return [3, 6];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 6];
                    case "InvalidNextToken": return [3, 8];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 8];
                    case "InvalidTypeNameException": return [3, 10];
                    case "com.amazonaws.ssm#InvalidTypeNameException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidTypeNameExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListOpsItemEventsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListOpsItemEventsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListOpsItemEventsResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListOpsItemEventsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsItemInvalidParameterException": return [3, 4];
                    case "com.amazonaws.ssm#OpsItemInvalidParameterException": return [3, 4];
                    case "OpsItemLimitExceededException": return [3, 6];
                    case "com.amazonaws.ssm#OpsItemLimitExceededException": return [3, 6];
                    case "OpsItemNotFoundException": return [3, 8];
                    case "com.amazonaws.ssm#OpsItemNotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsItemInvalidParameterExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1OpsItemLimitExceededExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1OpsItemNotFoundExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListOpsItemRelatedItemsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListOpsItemRelatedItemsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListOpsItemRelatedItemsResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListOpsItemRelatedItemsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsItemInvalidParameterException": return [3, 4];
                    case "com.amazonaws.ssm#OpsItemInvalidParameterException": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsItemInvalidParameterExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListOpsMetadataCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListOpsMetadataCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListOpsMetadataResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListOpsMetadataCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsMetadataInvalidArgumentException": return [3, 4];
                    case "com.amazonaws.ssm#OpsMetadataInvalidArgumentException": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsMetadataInvalidArgumentExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListResourceComplianceSummariesCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListResourceComplianceSummariesCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListResourceComplianceSummariesResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListResourceComplianceSummariesCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidFilter": return [3, 4];
                    case "com.amazonaws.ssm#InvalidFilter": return [3, 4];
                    case "InvalidNextToken": return [3, 6];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidFilterResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListResourceDataSyncCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListResourceDataSyncCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListResourceDataSyncResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListResourceDataSyncCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidNextToken": return [3, 4];
                    case "com.amazonaws.ssm#InvalidNextToken": return [3, 4];
                    case "ResourceDataSyncInvalidConfigurationException": return [3, 6];
                    case "com.amazonaws.ssm#ResourceDataSyncInvalidConfigurationException": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidNextTokenResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1ResourceDataSyncInvalidConfigurationExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ListTagsForResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ListTagsForResourceCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ListTagsForResourceResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ListTagsForResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidResourceId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidResourceId": return [3, 4];
                    case "InvalidResourceType": return [3, 6];
                    case "com.amazonaws.ssm#InvalidResourceType": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidResourceIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidResourceTypeResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ModifyDocumentPermissionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ModifyDocumentPermissionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ModifyDocumentPermissionResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ModifyDocumentPermissionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DocumentLimitExceeded": return [3, 2];
                    case "com.amazonaws.ssm#DocumentLimitExceeded": return [3, 2];
                    case "DocumentPermissionLimit": return [3, 4];
                    case "com.amazonaws.ssm#DocumentPermissionLimit": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 6];
                    case "InvalidDocument": return [3, 8];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 8];
                    case "InvalidPermissionType": return [3, 10];
                    case "com.amazonaws.ssm#InvalidPermissionType": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DocumentLimitExceededResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1DocumentPermissionLimitResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidPermissionTypeResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1PutComplianceItemsCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1PutComplianceItemsCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1PutComplianceItemsResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1PutComplianceItemsCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, parsedBody, message;
    var _k;
    return __generator(this, function (_l) {
        switch (_l.label) {
            case 0:
                _a = [__assign({}, output)];
                _k = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_k.body = _l.sent(), _k)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "ComplianceTypeCountLimitExceededException": return [3, 2];
                    case "com.amazonaws.ssm#ComplianceTypeCountLimitExceededException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidItemContentException": return [3, 6];
                    case "com.amazonaws.ssm#InvalidItemContentException": return [3, 6];
                    case "InvalidResourceId": return [3, 8];
                    case "com.amazonaws.ssm#InvalidResourceId": return [3, 8];
                    case "InvalidResourceType": return [3, 10];
                    case "com.amazonaws.ssm#InvalidResourceType": return [3, 10];
                    case "ItemSizeLimitExceededException": return [3, 12];
                    case "com.amazonaws.ssm#ItemSizeLimitExceededException": return [3, 12];
                    case "TotalSizeLimitExceededException": return [3, 14];
                    case "com.amazonaws.ssm#TotalSizeLimitExceededException": return [3, 14];
                }
                return [3, 16];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1ComplianceTypeCountLimitExceededExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidItemContentExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidResourceIdResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidResourceTypeResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1ItemSizeLimitExceededExceptionResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 14:
                _j = [{}];
                return [4, deserializeAws_json1_1TotalSizeLimitExceededExceptionResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 16:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _l.label = 17;
            case 17:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1PutInventoryCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1PutInventoryCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1PutInventoryResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1PutInventoryCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, parsedBody, message;
    var _q;
    return __generator(this, function (_r) {
        switch (_r.label) {
            case 0:
                _a = [__assign({}, output)];
                _q = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_q.body = _r.sent(), _q)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "CustomSchemaCountLimitExceededException": return [3, 2];
                    case "com.amazonaws.ssm#CustomSchemaCountLimitExceededException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidInstanceId": return [3, 6];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 6];
                    case "InvalidInventoryItemContextException": return [3, 8];
                    case "com.amazonaws.ssm#InvalidInventoryItemContextException": return [3, 8];
                    case "InvalidItemContentException": return [3, 10];
                    case "com.amazonaws.ssm#InvalidItemContentException": return [3, 10];
                    case "InvalidTypeNameException": return [3, 12];
                    case "com.amazonaws.ssm#InvalidTypeNameException": return [3, 12];
                    case "ItemContentMismatchException": return [3, 14];
                    case "com.amazonaws.ssm#ItemContentMismatchException": return [3, 14];
                    case "ItemSizeLimitExceededException": return [3, 16];
                    case "com.amazonaws.ssm#ItemSizeLimitExceededException": return [3, 16];
                    case "SubTypeCountLimitExceededException": return [3, 18];
                    case "com.amazonaws.ssm#SubTypeCountLimitExceededException": return [3, 18];
                    case "TotalSizeLimitExceededException": return [3, 20];
                    case "com.amazonaws.ssm#TotalSizeLimitExceededException": return [3, 20];
                    case "UnsupportedInventoryItemContextException": return [3, 22];
                    case "com.amazonaws.ssm#UnsupportedInventoryItemContextException": return [3, 22];
                    case "UnsupportedInventorySchemaVersionException": return [3, 24];
                    case "com.amazonaws.ssm#UnsupportedInventorySchemaVersionException": return [3, 24];
                }
                return [3, 26];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1CustomSchemaCountLimitExceededExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidInventoryItemContextExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidItemContentExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1InvalidTypeNameExceptionResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 14:
                _j = [{}];
                return [4, deserializeAws_json1_1ItemContentMismatchExceptionResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 16:
                _k = [{}];
                return [4, deserializeAws_json1_1ItemSizeLimitExceededExceptionResponse(parsedOutput, context)];
            case 17:
                response = __assign.apply(void 0, [__assign.apply(void 0, _k.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 18:
                _l = [{}];
                return [4, deserializeAws_json1_1SubTypeCountLimitExceededExceptionResponse(parsedOutput, context)];
            case 19:
                response = __assign.apply(void 0, [__assign.apply(void 0, _l.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 20:
                _m = [{}];
                return [4, deserializeAws_json1_1TotalSizeLimitExceededExceptionResponse(parsedOutput, context)];
            case 21:
                response = __assign.apply(void 0, [__assign.apply(void 0, _m.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 22:
                _o = [{}];
                return [4, deserializeAws_json1_1UnsupportedInventoryItemContextExceptionResponse(parsedOutput, context)];
            case 23:
                response = __assign.apply(void 0, [__assign.apply(void 0, _o.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 24:
                _p = [{}];
                return [4, deserializeAws_json1_1UnsupportedInventorySchemaVersionExceptionResponse(parsedOutput, context)];
            case 25:
                response = __assign.apply(void 0, [__assign.apply(void 0, _p.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 26:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _r.label = 27;
            case 27:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1PutParameterCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1PutParameterCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1PutParameterResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1PutParameterCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, parsedBody, message;
    var _t;
    return __generator(this, function (_u) {
        switch (_u.label) {
            case 0:
                _a = [__assign({}, output)];
                _t = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_t.body = _u.sent(), _t)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "HierarchyLevelLimitExceededException": return [3, 2];
                    case "com.amazonaws.ssm#HierarchyLevelLimitExceededException": return [3, 2];
                    case "HierarchyTypeMismatchException": return [3, 4];
                    case "com.amazonaws.ssm#HierarchyTypeMismatchException": return [3, 4];
                    case "IncompatiblePolicyException": return [3, 6];
                    case "com.amazonaws.ssm#IncompatiblePolicyException": return [3, 6];
                    case "InternalServerError": return [3, 8];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 8];
                    case "InvalidAllowedPatternException": return [3, 10];
                    case "com.amazonaws.ssm#InvalidAllowedPatternException": return [3, 10];
                    case "InvalidKeyId": return [3, 12];
                    case "com.amazonaws.ssm#InvalidKeyId": return [3, 12];
                    case "InvalidPolicyAttributeException": return [3, 14];
                    case "com.amazonaws.ssm#InvalidPolicyAttributeException": return [3, 14];
                    case "InvalidPolicyTypeException": return [3, 16];
                    case "com.amazonaws.ssm#InvalidPolicyTypeException": return [3, 16];
                    case "ParameterAlreadyExists": return [3, 18];
                    case "com.amazonaws.ssm#ParameterAlreadyExists": return [3, 18];
                    case "ParameterLimitExceeded": return [3, 20];
                    case "com.amazonaws.ssm#ParameterLimitExceeded": return [3, 20];
                    case "ParameterMaxVersionLimitExceeded": return [3, 22];
                    case "com.amazonaws.ssm#ParameterMaxVersionLimitExceeded": return [3, 22];
                    case "ParameterPatternMismatchException": return [3, 24];
                    case "com.amazonaws.ssm#ParameterPatternMismatchException": return [3, 24];
                    case "PoliciesLimitExceededException": return [3, 26];
                    case "com.amazonaws.ssm#PoliciesLimitExceededException": return [3, 26];
                    case "TooManyUpdates": return [3, 28];
                    case "com.amazonaws.ssm#TooManyUpdates": return [3, 28];
                    case "UnsupportedParameterType": return [3, 30];
                    case "com.amazonaws.ssm#UnsupportedParameterType": return [3, 30];
                }
                return [3, 32];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1HierarchyLevelLimitExceededExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1HierarchyTypeMismatchExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1IncompatiblePolicyExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidAllowedPatternExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1InvalidKeyIdResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 14:
                _j = [{}];
                return [4, deserializeAws_json1_1InvalidPolicyAttributeExceptionResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 16:
                _k = [{}];
                return [4, deserializeAws_json1_1InvalidPolicyTypeExceptionResponse(parsedOutput, context)];
            case 17:
                response = __assign.apply(void 0, [__assign.apply(void 0, _k.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 18:
                _l = [{}];
                return [4, deserializeAws_json1_1ParameterAlreadyExistsResponse(parsedOutput, context)];
            case 19:
                response = __assign.apply(void 0, [__assign.apply(void 0, _l.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 20:
                _m = [{}];
                return [4, deserializeAws_json1_1ParameterLimitExceededResponse(parsedOutput, context)];
            case 21:
                response = __assign.apply(void 0, [__assign.apply(void 0, _m.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 22:
                _o = [{}];
                return [4, deserializeAws_json1_1ParameterMaxVersionLimitExceededResponse(parsedOutput, context)];
            case 23:
                response = __assign.apply(void 0, [__assign.apply(void 0, _o.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 24:
                _p = [{}];
                return [4, deserializeAws_json1_1ParameterPatternMismatchExceptionResponse(parsedOutput, context)];
            case 25:
                response = __assign.apply(void 0, [__assign.apply(void 0, _p.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 26:
                _q = [{}];
                return [4, deserializeAws_json1_1PoliciesLimitExceededExceptionResponse(parsedOutput, context)];
            case 27:
                response = __assign.apply(void 0, [__assign.apply(void 0, _q.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 28:
                _r = [{}];
                return [4, deserializeAws_json1_1TooManyUpdatesResponse(parsedOutput, context)];
            case 29:
                response = __assign.apply(void 0, [__assign.apply(void 0, _r.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 30:
                _s = [{}];
                return [4, deserializeAws_json1_1UnsupportedParameterTypeResponse(parsedOutput, context)];
            case 31:
                response = __assign.apply(void 0, [__assign.apply(void 0, _s.concat([(_u.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 33];
            case 32:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _u.label = 33;
            case 33:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1RegisterDefaultPatchBaselineCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1RegisterDefaultPatchBaselineCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1RegisterDefaultPatchBaselineResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1RegisterDefaultPatchBaselineCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidResourceId": return [3, 6];
                    case "com.amazonaws.ssm#InvalidResourceId": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidResourceIdResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1RegisterPatchBaselineForPatchGroupCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1RegisterPatchBaselineForPatchGroupCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1RegisterPatchBaselineForPatchGroupResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1RegisterPatchBaselineForPatchGroupCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AlreadyExistsException": return [3, 2];
                    case "com.amazonaws.ssm#AlreadyExistsException": return [3, 2];
                    case "DoesNotExistException": return [3, 4];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 6];
                    case "InvalidResourceId": return [3, 8];
                    case "com.amazonaws.ssm#InvalidResourceId": return [3, 8];
                    case "ResourceLimitExceededException": return [3, 10];
                    case "com.amazonaws.ssm#ResourceLimitExceededException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AlreadyExistsExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidResourceIdResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1ResourceLimitExceededExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1RegisterTargetWithMaintenanceWindowCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1RegisterTargetWithMaintenanceWindowCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1RegisterTargetWithMaintenanceWindowResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1RegisterTargetWithMaintenanceWindowCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "IdempotentParameterMismatch": return [3, 4];
                    case "com.amazonaws.ssm#IdempotentParameterMismatch": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 6];
                    case "ResourceLimitExceededException": return [3, 8];
                    case "com.amazonaws.ssm#ResourceLimitExceededException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1IdempotentParameterMismatchResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1ResourceLimitExceededExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1RegisterTaskWithMaintenanceWindowCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1RegisterTaskWithMaintenanceWindowCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1RegisterTaskWithMaintenanceWindowResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1RegisterTaskWithMaintenanceWindowCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "FeatureNotAvailableException": return [3, 4];
                    case "com.amazonaws.ssm#FeatureNotAvailableException": return [3, 4];
                    case "IdempotentParameterMismatch": return [3, 6];
                    case "com.amazonaws.ssm#IdempotentParameterMismatch": return [3, 6];
                    case "InternalServerError": return [3, 8];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 8];
                    case "ResourceLimitExceededException": return [3, 10];
                    case "com.amazonaws.ssm#ResourceLimitExceededException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1FeatureNotAvailableExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1IdempotentParameterMismatchResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1ResourceLimitExceededExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1RemoveTagsFromResourceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1RemoveTagsFromResourceCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1RemoveTagsFromResourceResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1RemoveTagsFromResourceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidResourceId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidResourceId": return [3, 4];
                    case "InvalidResourceType": return [3, 6];
                    case "com.amazonaws.ssm#InvalidResourceType": return [3, 6];
                    case "TooManyUpdates": return [3, 8];
                    case "com.amazonaws.ssm#TooManyUpdates": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidResourceIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidResourceTypeResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1TooManyUpdatesResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ResetServiceSettingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ResetServiceSettingCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ResetServiceSettingResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ResetServiceSettingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "ServiceSettingNotFound": return [3, 4];
                    case "com.amazonaws.ssm#ServiceSettingNotFound": return [3, 4];
                    case "TooManyUpdates": return [3, 6];
                    case "com.amazonaws.ssm#TooManyUpdates": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1ServiceSettingNotFoundResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1TooManyUpdatesResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1ResumeSessionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1ResumeSessionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1ResumeSessionResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1ResumeSessionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1SendAutomationSignalCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1SendAutomationSignalCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1SendAutomationSignalResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1SendAutomationSignalCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AutomationExecutionNotFoundException": return [3, 2];
                    case "com.amazonaws.ssm#AutomationExecutionNotFoundException": return [3, 2];
                    case "AutomationStepNotFoundException": return [3, 4];
                    case "com.amazonaws.ssm#AutomationStepNotFoundException": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 6];
                    case "InvalidAutomationSignalException": return [3, 8];
                    case "com.amazonaws.ssm#InvalidAutomationSignalException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AutomationExecutionNotFoundExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1AutomationStepNotFoundExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidAutomationSignalExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1SendCommandCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1SendCommandCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1SendCommandResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1SendCommandCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, parsedBody, message;
    var _p;
    return __generator(this, function (_q) {
        switch (_q.label) {
            case 0:
                _a = [__assign({}, output)];
                _p = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_p.body = _q.sent(), _p)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DuplicateInstanceId": return [3, 2];
                    case "com.amazonaws.ssm#DuplicateInstanceId": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidDocument": return [3, 6];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 6];
                    case "InvalidDocumentVersion": return [3, 8];
                    case "com.amazonaws.ssm#InvalidDocumentVersion": return [3, 8];
                    case "InvalidInstanceId": return [3, 10];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 10];
                    case "InvalidNotificationConfig": return [3, 12];
                    case "com.amazonaws.ssm#InvalidNotificationConfig": return [3, 12];
                    case "InvalidOutputFolder": return [3, 14];
                    case "com.amazonaws.ssm#InvalidOutputFolder": return [3, 14];
                    case "InvalidParameters": return [3, 16];
                    case "com.amazonaws.ssm#InvalidParameters": return [3, 16];
                    case "InvalidRole": return [3, 18];
                    case "com.amazonaws.ssm#InvalidRole": return [3, 18];
                    case "MaxDocumentSizeExceeded": return [3, 20];
                    case "com.amazonaws.ssm#MaxDocumentSizeExceeded": return [3, 20];
                    case "UnsupportedPlatformType": return [3, 22];
                    case "com.amazonaws.ssm#UnsupportedPlatformType": return [3, 22];
                }
                return [3, 24];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DuplicateInstanceIdResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentVersionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1InvalidNotificationConfigResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 14:
                _j = [{}];
                return [4, deserializeAws_json1_1InvalidOutputFolderResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 16:
                _k = [{}];
                return [4, deserializeAws_json1_1InvalidParametersResponse(parsedOutput, context)];
            case 17:
                response = __assign.apply(void 0, [__assign.apply(void 0, _k.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 18:
                _l = [{}];
                return [4, deserializeAws_json1_1InvalidRoleResponse(parsedOutput, context)];
            case 19:
                response = __assign.apply(void 0, [__assign.apply(void 0, _l.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 20:
                _m = [{}];
                return [4, deserializeAws_json1_1MaxDocumentSizeExceededResponse(parsedOutput, context)];
            case 21:
                response = __assign.apply(void 0, [__assign.apply(void 0, _m.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 22:
                _o = [{}];
                return [4, deserializeAws_json1_1UnsupportedPlatformTypeResponse(parsedOutput, context)];
            case 23:
                response = __assign.apply(void 0, [__assign.apply(void 0, _o.concat([(_q.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 25];
            case 24:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _q.label = 25;
            case 25:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1StartAssociationsOnceCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1StartAssociationsOnceCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1StartAssociationsOnceResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1StartAssociationsOnceCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AssociationDoesNotExist": return [3, 2];
                    case "com.amazonaws.ssm#AssociationDoesNotExist": return [3, 2];
                    case "InvalidAssociation": return [3, 4];
                    case "com.amazonaws.ssm#InvalidAssociation": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AssociationDoesNotExistResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidAssociationResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1StartAutomationExecutionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1StartAutomationExecutionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1StartAutomationExecutionResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1StartAutomationExecutionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, parsedBody, message;
    var _k;
    return __generator(this, function (_l) {
        switch (_l.label) {
            case 0:
                _a = [__assign({}, output)];
                _k = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_k.body = _l.sent(), _k)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AutomationDefinitionNotFoundException": return [3, 2];
                    case "com.amazonaws.ssm#AutomationDefinitionNotFoundException": return [3, 2];
                    case "AutomationDefinitionVersionNotFoundException": return [3, 4];
                    case "com.amazonaws.ssm#AutomationDefinitionVersionNotFoundException": return [3, 4];
                    case "AutomationExecutionLimitExceededException": return [3, 6];
                    case "com.amazonaws.ssm#AutomationExecutionLimitExceededException": return [3, 6];
                    case "IdempotentParameterMismatch": return [3, 8];
                    case "com.amazonaws.ssm#IdempotentParameterMismatch": return [3, 8];
                    case "InternalServerError": return [3, 10];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 10];
                    case "InvalidAutomationExecutionParametersException": return [3, 12];
                    case "com.amazonaws.ssm#InvalidAutomationExecutionParametersException": return [3, 12];
                    case "InvalidTarget": return [3, 14];
                    case "com.amazonaws.ssm#InvalidTarget": return [3, 14];
                }
                return [3, 16];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AutomationDefinitionNotFoundExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1AutomationDefinitionVersionNotFoundExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1AutomationExecutionLimitExceededExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1IdempotentParameterMismatchResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1InvalidAutomationExecutionParametersExceptionResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 14:
                _j = [{}];
                return [4, deserializeAws_json1_1InvalidTargetResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 16:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _l.label = 17;
            case 17:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1StartChangeRequestExecutionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1StartChangeRequestExecutionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1StartChangeRequestExecutionResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1StartChangeRequestExecutionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, parsedBody, message;
    var _k;
    return __generator(this, function (_l) {
        switch (_l.label) {
            case 0:
                _a = [__assign({}, output)];
                _k = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_k.body = _l.sent(), _k)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AutomationDefinitionNotApprovedException": return [3, 2];
                    case "com.amazonaws.ssm#AutomationDefinitionNotApprovedException": return [3, 2];
                    case "AutomationDefinitionNotFoundException": return [3, 4];
                    case "com.amazonaws.ssm#AutomationDefinitionNotFoundException": return [3, 4];
                    case "AutomationDefinitionVersionNotFoundException": return [3, 6];
                    case "com.amazonaws.ssm#AutomationDefinitionVersionNotFoundException": return [3, 6];
                    case "AutomationExecutionLimitExceededException": return [3, 8];
                    case "com.amazonaws.ssm#AutomationExecutionLimitExceededException": return [3, 8];
                    case "IdempotentParameterMismatch": return [3, 10];
                    case "com.amazonaws.ssm#IdempotentParameterMismatch": return [3, 10];
                    case "InternalServerError": return [3, 12];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 12];
                    case "InvalidAutomationExecutionParametersException": return [3, 14];
                    case "com.amazonaws.ssm#InvalidAutomationExecutionParametersException": return [3, 14];
                }
                return [3, 16];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AutomationDefinitionNotApprovedExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1AutomationDefinitionNotFoundExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1AutomationDefinitionVersionNotFoundExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1AutomationExecutionLimitExceededExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1IdempotentParameterMismatchResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 14:
                _j = [{}];
                return [4, deserializeAws_json1_1InvalidAutomationExecutionParametersExceptionResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_l.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 17];
            case 16:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _l.label = 17;
            case 17:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1StartSessionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1StartSessionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1StartSessionResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1StartSessionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidDocument": return [3, 4];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 4];
                    case "TargetNotConnected": return [3, 6];
                    case "com.amazonaws.ssm#TargetNotConnected": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1TargetNotConnectedResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1StopAutomationExecutionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1StopAutomationExecutionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1StopAutomationExecutionResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1StopAutomationExecutionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AutomationExecutionNotFoundException": return [3, 2];
                    case "com.amazonaws.ssm#AutomationExecutionNotFoundException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidAutomationStatusUpdateException": return [3, 6];
                    case "com.amazonaws.ssm#InvalidAutomationStatusUpdateException": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AutomationExecutionNotFoundExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidAutomationStatusUpdateExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1TerminateSessionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1TerminateSessionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1TerminateSessionResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1TerminateSessionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UnlabelParameterVersionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UnlabelParameterVersionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UnlabelParameterVersionResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UnlabelParameterVersionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "ParameterNotFound": return [3, 4];
                    case "com.amazonaws.ssm#ParameterNotFound": return [3, 4];
                    case "ParameterVersionNotFound": return [3, 6];
                    case "com.amazonaws.ssm#ParameterVersionNotFound": return [3, 6];
                    case "TooManyUpdates": return [3, 8];
                    case "com.amazonaws.ssm#TooManyUpdates": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1ParameterNotFoundResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1ParameterVersionNotFoundResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1TooManyUpdatesResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateAssociationCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateAssociationCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateAssociationResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateAssociationCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, parsedBody, message;
    var _q;
    return __generator(this, function (_r) {
        switch (_r.label) {
            case 0:
                _a = [__assign({}, output)];
                _q = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_q.body = _r.sent(), _q)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AssociationDoesNotExist": return [3, 2];
                    case "com.amazonaws.ssm#AssociationDoesNotExist": return [3, 2];
                    case "AssociationVersionLimitExceeded": return [3, 4];
                    case "com.amazonaws.ssm#AssociationVersionLimitExceeded": return [3, 4];
                    case "InternalServerError": return [3, 6];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 6];
                    case "InvalidAssociationVersion": return [3, 8];
                    case "com.amazonaws.ssm#InvalidAssociationVersion": return [3, 8];
                    case "InvalidDocument": return [3, 10];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 10];
                    case "InvalidDocumentVersion": return [3, 12];
                    case "com.amazonaws.ssm#InvalidDocumentVersion": return [3, 12];
                    case "InvalidOutputLocation": return [3, 14];
                    case "com.amazonaws.ssm#InvalidOutputLocation": return [3, 14];
                    case "InvalidParameters": return [3, 16];
                    case "com.amazonaws.ssm#InvalidParameters": return [3, 16];
                    case "InvalidSchedule": return [3, 18];
                    case "com.amazonaws.ssm#InvalidSchedule": return [3, 18];
                    case "InvalidTarget": return [3, 20];
                    case "com.amazonaws.ssm#InvalidTarget": return [3, 20];
                    case "InvalidUpdate": return [3, 22];
                    case "com.amazonaws.ssm#InvalidUpdate": return [3, 22];
                    case "TooManyUpdates": return [3, 24];
                    case "com.amazonaws.ssm#TooManyUpdates": return [3, 24];
                }
                return [3, 26];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AssociationDoesNotExistResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1AssociationVersionLimitExceededResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidAssociationVersionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentVersionResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 14:
                _j = [{}];
                return [4, deserializeAws_json1_1InvalidOutputLocationResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 16:
                _k = [{}];
                return [4, deserializeAws_json1_1InvalidParametersResponse(parsedOutput, context)];
            case 17:
                response = __assign.apply(void 0, [__assign.apply(void 0, _k.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 18:
                _l = [{}];
                return [4, deserializeAws_json1_1InvalidScheduleResponse(parsedOutput, context)];
            case 19:
                response = __assign.apply(void 0, [__assign.apply(void 0, _l.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 20:
                _m = [{}];
                return [4, deserializeAws_json1_1InvalidTargetResponse(parsedOutput, context)];
            case 21:
                response = __assign.apply(void 0, [__assign.apply(void 0, _m.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 22:
                _o = [{}];
                return [4, deserializeAws_json1_1InvalidUpdateResponse(parsedOutput, context)];
            case 23:
                response = __assign.apply(void 0, [__assign.apply(void 0, _o.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 24:
                _p = [{}];
                return [4, deserializeAws_json1_1TooManyUpdatesResponse(parsedOutput, context)];
            case 25:
                response = __assign.apply(void 0, [__assign.apply(void 0, _p.concat([(_r.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 27];
            case 26:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _r.label = 27;
            case 27:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateAssociationStatusCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateAssociationStatusCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateAssociationStatusResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateAssociationStatusCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, parsedBody, message;
    var _j;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                _a = [__assign({}, output)];
                _j = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_j.body = _k.sent(), _j)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "AssociationDoesNotExist": return [3, 2];
                    case "com.amazonaws.ssm#AssociationDoesNotExist": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                    case "InvalidDocument": return [3, 6];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 6];
                    case "InvalidInstanceId": return [3, 8];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 8];
                    case "StatusUnchanged": return [3, 10];
                    case "com.amazonaws.ssm#StatusUnchanged": return [3, 10];
                    case "TooManyUpdates": return [3, 12];
                    case "com.amazonaws.ssm#TooManyUpdates": return [3, 12];
                }
                return [3, 14];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1AssociationDoesNotExistResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1StatusUnchangedResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1TooManyUpdatesResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_k.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 15];
            case 14:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _k.label = 15;
            case 15:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateDocumentCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateDocumentCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateDocumentResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateDocumentCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, parsedBody, message;
    var _o;
    return __generator(this, function (_p) {
        switch (_p.label) {
            case 0:
                _a = [__assign({}, output)];
                _o = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_o.body = _p.sent(), _o)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DocumentVersionLimitExceeded": return [3, 2];
                    case "com.amazonaws.ssm#DocumentVersionLimitExceeded": return [3, 2];
                    case "DuplicateDocumentContent": return [3, 4];
                    case "com.amazonaws.ssm#DuplicateDocumentContent": return [3, 4];
                    case "DuplicateDocumentVersionName": return [3, 6];
                    case "com.amazonaws.ssm#DuplicateDocumentVersionName": return [3, 6];
                    case "InternalServerError": return [3, 8];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 8];
                    case "InvalidDocument": return [3, 10];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 10];
                    case "InvalidDocumentContent": return [3, 12];
                    case "com.amazonaws.ssm#InvalidDocumentContent": return [3, 12];
                    case "InvalidDocumentOperation": return [3, 14];
                    case "com.amazonaws.ssm#InvalidDocumentOperation": return [3, 14];
                    case "InvalidDocumentSchemaVersion": return [3, 16];
                    case "com.amazonaws.ssm#InvalidDocumentSchemaVersion": return [3, 16];
                    case "InvalidDocumentVersion": return [3, 18];
                    case "com.amazonaws.ssm#InvalidDocumentVersion": return [3, 18];
                    case "MaxDocumentSizeExceeded": return [3, 20];
                    case "com.amazonaws.ssm#MaxDocumentSizeExceeded": return [3, 20];
                }
                return [3, 22];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DocumentVersionLimitExceededResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_p.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 23];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1DuplicateDocumentContentResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_p.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 23];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1DuplicateDocumentVersionNameResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_p.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 23];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_p.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 23];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_p.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 23];
            case 12:
                _h = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentContentResponse(parsedOutput, context)];
            case 13:
                response = __assign.apply(void 0, [__assign.apply(void 0, _h.concat([(_p.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 23];
            case 14:
                _j = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentOperationResponse(parsedOutput, context)];
            case 15:
                response = __assign.apply(void 0, [__assign.apply(void 0, _j.concat([(_p.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 23];
            case 16:
                _k = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentSchemaVersionResponse(parsedOutput, context)];
            case 17:
                response = __assign.apply(void 0, [__assign.apply(void 0, _k.concat([(_p.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 23];
            case 18:
                _l = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentVersionResponse(parsedOutput, context)];
            case 19:
                response = __assign.apply(void 0, [__assign.apply(void 0, _l.concat([(_p.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 23];
            case 20:
                _m = [{}];
                return [4, deserializeAws_json1_1MaxDocumentSizeExceededResponse(parsedOutput, context)];
            case 21:
                response = __assign.apply(void 0, [__assign.apply(void 0, _m.concat([(_p.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 23];
            case 22:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _p.label = 23;
            case 23:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateDocumentDefaultVersionCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateDocumentDefaultVersionCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateDocumentDefaultVersionResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateDocumentDefaultVersionCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidDocument": return [3, 4];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 4];
                    case "InvalidDocumentSchemaVersion": return [3, 6];
                    case "com.amazonaws.ssm#InvalidDocumentSchemaVersion": return [3, 6];
                    case "InvalidDocumentVersion": return [3, 8];
                    case "com.amazonaws.ssm#InvalidDocumentVersion": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentSchemaVersionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentVersionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateDocumentMetadataCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateDocumentMetadataCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateDocumentMetadataResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateDocumentMetadataCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidDocument": return [3, 4];
                    case "com.amazonaws.ssm#InvalidDocument": return [3, 4];
                    case "InvalidDocumentOperation": return [3, 6];
                    case "com.amazonaws.ssm#InvalidDocumentOperation": return [3, 6];
                    case "InvalidDocumentVersion": return [3, 8];
                    case "com.amazonaws.ssm#InvalidDocumentVersion": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentOperationResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1InvalidDocumentVersionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateMaintenanceWindowCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateMaintenanceWindowCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateMaintenanceWindowResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateMaintenanceWindowCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateMaintenanceWindowTargetCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateMaintenanceWindowTargetCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateMaintenanceWindowTargetResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateMaintenanceWindowTargetCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateMaintenanceWindowTaskCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateMaintenanceWindowTaskCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateMaintenanceWindowTaskResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateMaintenanceWindowTaskCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateManagedInstanceRoleCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateManagedInstanceRoleCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateManagedInstanceRoleResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateManagedInstanceRoleCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "InvalidInstanceId": return [3, 4];
                    case "com.amazonaws.ssm#InvalidInstanceId": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InvalidInstanceIdResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateOpsItemCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateOpsItemCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateOpsItemResponse(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateOpsItemCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsItemAlreadyExistsException": return [3, 4];
                    case "com.amazonaws.ssm#OpsItemAlreadyExistsException": return [3, 4];
                    case "OpsItemInvalidParameterException": return [3, 6];
                    case "com.amazonaws.ssm#OpsItemInvalidParameterException": return [3, 6];
                    case "OpsItemLimitExceededException": return [3, 8];
                    case "com.amazonaws.ssm#OpsItemLimitExceededException": return [3, 8];
                    case "OpsItemNotFoundException": return [3, 10];
                    case "com.amazonaws.ssm#OpsItemNotFoundException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsItemAlreadyExistsExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1OpsItemInvalidParameterExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1OpsItemLimitExceededExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1OpsItemNotFoundExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateOpsMetadataCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateOpsMetadataCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateOpsMetadataResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateOpsMetadataCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, _g, parsedBody, message;
    var _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0:
                _a = [__assign({}, output)];
                _h = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_h.body = _j.sent(), _h)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "OpsMetadataInvalidArgumentException": return [3, 4];
                    case "com.amazonaws.ssm#OpsMetadataInvalidArgumentException": return [3, 4];
                    case "OpsMetadataKeyLimitExceededException": return [3, 6];
                    case "com.amazonaws.ssm#OpsMetadataKeyLimitExceededException": return [3, 6];
                    case "OpsMetadataNotFoundException": return [3, 8];
                    case "com.amazonaws.ssm#OpsMetadataNotFoundException": return [3, 8];
                    case "OpsMetadataTooManyUpdatesException": return [3, 10];
                    case "com.amazonaws.ssm#OpsMetadataTooManyUpdatesException": return [3, 10];
                }
                return [3, 12];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1OpsMetadataInvalidArgumentExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1OpsMetadataKeyLimitExceededExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1OpsMetadataNotFoundExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 10:
                _g = [{}];
                return [4, deserializeAws_json1_1OpsMetadataTooManyUpdatesExceptionResponse(parsedOutput, context)];
            case 11:
                response = __assign.apply(void 0, [__assign.apply(void 0, _g.concat([(_j.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 13];
            case 12:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _j.label = 13;
            case 13:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdatePatchBaselineCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdatePatchBaselineCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdatePatchBaselineResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdatePatchBaselineCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, parsedBody, message;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                _a = [__assign({}, output)];
                _e = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_e.body = _f.sent(), _e)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "DoesNotExistException": return [3, 2];
                    case "com.amazonaws.ssm#DoesNotExistException": return [3, 2];
                    case "InternalServerError": return [3, 4];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 4];
                }
                return [3, 6];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1DoesNotExistExceptionResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_f.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 7];
            case 6:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _f.label = 7;
            case 7:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateResourceDataSyncCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateResourceDataSyncCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateResourceDataSyncResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateResourceDataSyncCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, _f, parsedBody, message;
    var _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                _a = [__assign({}, output)];
                _g = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_g.body = _h.sent(), _g)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "ResourceDataSyncConflictException": return [3, 4];
                    case "com.amazonaws.ssm#ResourceDataSyncConflictException": return [3, 4];
                    case "ResourceDataSyncInvalidConfigurationException": return [3, 6];
                    case "com.amazonaws.ssm#ResourceDataSyncInvalidConfigurationException": return [3, 6];
                    case "ResourceDataSyncNotFoundException": return [3, 8];
                    case "com.amazonaws.ssm#ResourceDataSyncNotFoundException": return [3, 8];
                }
                return [3, 10];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1ResourceDataSyncConflictExceptionResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1ResourceDataSyncInvalidConfigurationExceptionResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 8:
                _f = [{}];
                return [4, deserializeAws_json1_1ResourceDataSyncNotFoundExceptionResponse(parsedOutput, context)];
            case 9:
                response = __assign.apply(void 0, [__assign.apply(void 0, _f.concat([(_h.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 11];
            case 10:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _h.label = 11;
            case 11:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
export var deserializeAws_json1_1UpdateServiceSettingCommand = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var data, contents, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (output.statusCode >= 300) {
                    return [2, deserializeAws_json1_1UpdateServiceSettingCommandError(output, context)];
                }
                return [4, parseBody(output.body, context)];
            case 1:
                data = _a.sent();
                contents = {};
                contents = deserializeAws_json1_1UpdateServiceSettingResult(data, context);
                response = __assign({ $metadata: deserializeMetadata(output) }, contents);
                return [2, Promise.resolve(response)];
        }
    });
}); };
var deserializeAws_json1_1UpdateServiceSettingCommandError = function (output, context) { return __awaiter(void 0, void 0, void 0, function () {
    var parsedOutput, _a, response, errorCode, _b, _c, _d, _e, parsedBody, message;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _a = [__assign({}, output)];
                _f = {};
                return [4, parseBody(output.body, context)];
            case 1:
                parsedOutput = __assign.apply(void 0, _a.concat([(_f.body = _g.sent(), _f)]));
                errorCode = "UnknownError";
                errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
                _b = errorCode;
                switch (_b) {
                    case "InternalServerError": return [3, 2];
                    case "com.amazonaws.ssm#InternalServerError": return [3, 2];
                    case "ServiceSettingNotFound": return [3, 4];
                    case "com.amazonaws.ssm#ServiceSettingNotFound": return [3, 4];
                    case "TooManyUpdates": return [3, 6];
                    case "com.amazonaws.ssm#TooManyUpdates": return [3, 6];
                }
                return [3, 8];
            case 2:
                _c = [{}];
                return [4, deserializeAws_json1_1InternalServerErrorResponse(parsedOutput, context)];
            case 3:
                response = __assign.apply(void 0, [__assign.apply(void 0, _c.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 4:
                _d = [{}];
                return [4, deserializeAws_json1_1ServiceSettingNotFoundResponse(parsedOutput, context)];
            case 5:
                response = __assign.apply(void 0, [__assign.apply(void 0, _d.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 6:
                _e = [{}];
                return [4, deserializeAws_json1_1TooManyUpdatesResponse(parsedOutput, context)];
            case 7:
                response = __assign.apply(void 0, [__assign.apply(void 0, _e.concat([(_g.sent())])), { name: errorCode, $metadata: deserializeMetadata(output) }]);
                return [3, 9];
            case 8:
                parsedBody = parsedOutput.body;
                errorCode = parsedBody.code || parsedBody.Code || errorCode;
                response = __assign(__assign({}, parsedBody), { name: "" + errorCode, message: parsedBody.message || parsedBody.Message || errorCode, $fault: "client", $metadata: deserializeMetadata(output) });
                _g.label = 9;
            case 9:
                message = response.message || response.Message || errorCode;
                response.message = message;
                delete response.Message;
                return [2, Promise.reject(Object.assign(new Error(message), response))];
        }
    });
}); };
var deserializeAws_json1_1AlreadyExistsExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AlreadyExistsException(body, context);
        contents = __assign({ name: "AlreadyExistsException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AssociatedInstancesResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AssociatedInstances(body, context);
        contents = __assign({ name: "AssociatedInstances", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AssociationAlreadyExistsResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AssociationAlreadyExists(body, context);
        contents = __assign({ name: "AssociationAlreadyExists", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AssociationDoesNotExistResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AssociationDoesNotExist(body, context);
        contents = __assign({ name: "AssociationDoesNotExist", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AssociationExecutionDoesNotExistResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AssociationExecutionDoesNotExist(body, context);
        contents = __assign({ name: "AssociationExecutionDoesNotExist", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AssociationLimitExceededResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AssociationLimitExceeded(body, context);
        contents = __assign({ name: "AssociationLimitExceeded", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AssociationVersionLimitExceededResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AssociationVersionLimitExceeded(body, context);
        contents = __assign({ name: "AssociationVersionLimitExceeded", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AutomationDefinitionNotApprovedExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AutomationDefinitionNotApprovedException(body, context);
        contents = __assign({ name: "AutomationDefinitionNotApprovedException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AutomationDefinitionNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AutomationDefinitionNotFoundException(body, context);
        contents = __assign({ name: "AutomationDefinitionNotFoundException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AutomationDefinitionVersionNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AutomationDefinitionVersionNotFoundException(body, context);
        contents = __assign({ name: "AutomationDefinitionVersionNotFoundException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AutomationExecutionLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AutomationExecutionLimitExceededException(body, context);
        contents = __assign({ name: "AutomationExecutionLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AutomationExecutionNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AutomationExecutionNotFoundException(body, context);
        contents = __assign({ name: "AutomationExecutionNotFoundException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1AutomationStepNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1AutomationStepNotFoundException(body, context);
        contents = __assign({ name: "AutomationStepNotFoundException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ComplianceTypeCountLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ComplianceTypeCountLimitExceededException(body, context);
        contents = __assign({ name: "ComplianceTypeCountLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1CustomSchemaCountLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1CustomSchemaCountLimitExceededException(body, context);
        contents = __assign({ name: "CustomSchemaCountLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1DocumentAlreadyExistsResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1DocumentAlreadyExists(body, context);
        contents = __assign({ name: "DocumentAlreadyExists", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1DocumentLimitExceededResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1DocumentLimitExceeded(body, context);
        contents = __assign({ name: "DocumentLimitExceeded", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1DocumentPermissionLimitResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1DocumentPermissionLimit(body, context);
        contents = __assign({ name: "DocumentPermissionLimit", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1DocumentVersionLimitExceededResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1DocumentVersionLimitExceeded(body, context);
        contents = __assign({ name: "DocumentVersionLimitExceeded", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1DoesNotExistExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1DoesNotExistException(body, context);
        contents = __assign({ name: "DoesNotExistException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1DuplicateDocumentContentResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1DuplicateDocumentContent(body, context);
        contents = __assign({ name: "DuplicateDocumentContent", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1DuplicateDocumentVersionNameResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1DuplicateDocumentVersionName(body, context);
        contents = __assign({ name: "DuplicateDocumentVersionName", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1DuplicateInstanceIdResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1DuplicateInstanceId(body, context);
        contents = __assign({ name: "DuplicateInstanceId", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1FeatureNotAvailableExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1FeatureNotAvailableException(body, context);
        contents = __assign({ name: "FeatureNotAvailableException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1HierarchyLevelLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1HierarchyLevelLimitExceededException(body, context);
        contents = __assign({ name: "HierarchyLevelLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1HierarchyTypeMismatchExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1HierarchyTypeMismatchException(body, context);
        contents = __assign({ name: "HierarchyTypeMismatchException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1IdempotentParameterMismatchResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1IdempotentParameterMismatch(body, context);
        contents = __assign({ name: "IdempotentParameterMismatch", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1IncompatiblePolicyExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1IncompatiblePolicyException(body, context);
        contents = __assign({ name: "IncompatiblePolicyException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InternalServerErrorResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InternalServerError(body, context);
        contents = __assign({ name: "InternalServerError", $fault: "server", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidActivationResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidActivation(body, context);
        contents = __assign({ name: "InvalidActivation", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidActivationIdResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidActivationId(body, context);
        contents = __assign({ name: "InvalidActivationId", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidAggregatorExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidAggregatorException(body, context);
        contents = __assign({ name: "InvalidAggregatorException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidAllowedPatternExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidAllowedPatternException(body, context);
        contents = __assign({ name: "InvalidAllowedPatternException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidAssociationResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidAssociation(body, context);
        contents = __assign({ name: "InvalidAssociation", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidAssociationVersionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidAssociationVersion(body, context);
        contents = __assign({ name: "InvalidAssociationVersion", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidAutomationExecutionParametersExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidAutomationExecutionParametersException(body, context);
        contents = __assign({ name: "InvalidAutomationExecutionParametersException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidAutomationSignalExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidAutomationSignalException(body, context);
        contents = __assign({ name: "InvalidAutomationSignalException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidAutomationStatusUpdateExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidAutomationStatusUpdateException(body, context);
        contents = __assign({ name: "InvalidAutomationStatusUpdateException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidCommandIdResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidCommandId(body, context);
        contents = __assign({ name: "InvalidCommandId", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidDeleteInventoryParametersExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidDeleteInventoryParametersException(body, context);
        contents = __assign({ name: "InvalidDeleteInventoryParametersException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidDeletionIdExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidDeletionIdException(body, context);
        contents = __assign({ name: "InvalidDeletionIdException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidDocumentResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidDocument(body, context);
        contents = __assign({ name: "InvalidDocument", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidDocumentContentResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidDocumentContent(body, context);
        contents = __assign({ name: "InvalidDocumentContent", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidDocumentOperationResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidDocumentOperation(body, context);
        contents = __assign({ name: "InvalidDocumentOperation", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidDocumentSchemaVersionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidDocumentSchemaVersion(body, context);
        contents = __assign({ name: "InvalidDocumentSchemaVersion", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidDocumentTypeResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidDocumentType(body, context);
        contents = __assign({ name: "InvalidDocumentType", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidDocumentVersionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidDocumentVersion(body, context);
        contents = __assign({ name: "InvalidDocumentVersion", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidFilterResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidFilter(body, context);
        contents = __assign({ name: "InvalidFilter", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidFilterKeyResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidFilterKey(body, context);
        contents = __assign({ name: "InvalidFilterKey", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidFilterOptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidFilterOption(body, context);
        contents = __assign({ name: "InvalidFilterOption", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidFilterValueResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidFilterValue(body, context);
        contents = __assign({ name: "InvalidFilterValue", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidInstanceIdResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidInstanceId(body, context);
        contents = __assign({ name: "InvalidInstanceId", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidInstanceInformationFilterValueResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidInstanceInformationFilterValue(body, context);
        contents = __assign({ name: "InvalidInstanceInformationFilterValue", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidInventoryGroupExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidInventoryGroupException(body, context);
        contents = __assign({ name: "InvalidInventoryGroupException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidInventoryItemContextExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidInventoryItemContextException(body, context);
        contents = __assign({ name: "InvalidInventoryItemContextException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidInventoryRequestExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidInventoryRequestException(body, context);
        contents = __assign({ name: "InvalidInventoryRequestException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidItemContentExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidItemContentException(body, context);
        contents = __assign({ name: "InvalidItemContentException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidKeyIdResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidKeyId(body, context);
        contents = __assign({ name: "InvalidKeyId", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidNextTokenResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidNextToken(body, context);
        contents = __assign({ name: "InvalidNextToken", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidNotificationConfigResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidNotificationConfig(body, context);
        contents = __assign({ name: "InvalidNotificationConfig", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidOptionExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidOptionException(body, context);
        contents = __assign({ name: "InvalidOptionException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidOutputFolderResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidOutputFolder(body, context);
        contents = __assign({ name: "InvalidOutputFolder", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidOutputLocationResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidOutputLocation(body, context);
        contents = __assign({ name: "InvalidOutputLocation", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidParametersResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidParameters(body, context);
        contents = __assign({ name: "InvalidParameters", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidPermissionTypeResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidPermissionType(body, context);
        contents = __assign({ name: "InvalidPermissionType", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidPluginNameResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidPluginName(body, context);
        contents = __assign({ name: "InvalidPluginName", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidPolicyAttributeExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidPolicyAttributeException(body, context);
        contents = __assign({ name: "InvalidPolicyAttributeException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidPolicyTypeExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidPolicyTypeException(body, context);
        contents = __assign({ name: "InvalidPolicyTypeException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidResourceIdResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidResourceId(body, context);
        contents = __assign({ name: "InvalidResourceId", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidResourceTypeResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidResourceType(body, context);
        contents = __assign({ name: "InvalidResourceType", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidResultAttributeExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidResultAttributeException(body, context);
        contents = __assign({ name: "InvalidResultAttributeException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidRoleResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidRole(body, context);
        contents = __assign({ name: "InvalidRole", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidScheduleResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidSchedule(body, context);
        contents = __assign({ name: "InvalidSchedule", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidTargetResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidTarget(body, context);
        contents = __assign({ name: "InvalidTarget", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidTypeNameExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidTypeNameException(body, context);
        contents = __assign({ name: "InvalidTypeNameException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvalidUpdateResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvalidUpdate(body, context);
        contents = __assign({ name: "InvalidUpdate", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1InvocationDoesNotExistResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1InvocationDoesNotExist(body, context);
        contents = __assign({ name: "InvocationDoesNotExist", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ItemContentMismatchExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ItemContentMismatchException(body, context);
        contents = __assign({ name: "ItemContentMismatchException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ItemSizeLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ItemSizeLimitExceededException(body, context);
        contents = __assign({ name: "ItemSizeLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1MaxDocumentSizeExceededResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1MaxDocumentSizeExceeded(body, context);
        contents = __assign({ name: "MaxDocumentSizeExceeded", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsItemAlreadyExistsExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsItemAlreadyExistsException(body, context);
        contents = __assign({ name: "OpsItemAlreadyExistsException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsItemInvalidParameterExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsItemInvalidParameterException(body, context);
        contents = __assign({ name: "OpsItemInvalidParameterException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsItemLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsItemLimitExceededException(body, context);
        contents = __assign({ name: "OpsItemLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsItemNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsItemNotFoundException(body, context);
        contents = __assign({ name: "OpsItemNotFoundException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsItemRelatedItemAlreadyExistsExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsItemRelatedItemAlreadyExistsException(body, context);
        contents = __assign({ name: "OpsItemRelatedItemAlreadyExistsException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsItemRelatedItemAssociationNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsItemRelatedItemAssociationNotFoundException(body, context);
        contents = __assign({ name: "OpsItemRelatedItemAssociationNotFoundException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsMetadataAlreadyExistsExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsMetadataAlreadyExistsException(body, context);
        contents = __assign({ name: "OpsMetadataAlreadyExistsException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsMetadataInvalidArgumentExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsMetadataInvalidArgumentException(body, context);
        contents = __assign({ name: "OpsMetadataInvalidArgumentException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsMetadataKeyLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsMetadataKeyLimitExceededException(body, context);
        contents = __assign({ name: "OpsMetadataKeyLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsMetadataLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsMetadataLimitExceededException(body, context);
        contents = __assign({ name: "OpsMetadataLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsMetadataNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsMetadataNotFoundException(body, context);
        contents = __assign({ name: "OpsMetadataNotFoundException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1OpsMetadataTooManyUpdatesExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1OpsMetadataTooManyUpdatesException(body, context);
        contents = __assign({ name: "OpsMetadataTooManyUpdatesException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ParameterAlreadyExistsResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ParameterAlreadyExists(body, context);
        contents = __assign({ name: "ParameterAlreadyExists", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ParameterLimitExceededResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ParameterLimitExceeded(body, context);
        contents = __assign({ name: "ParameterLimitExceeded", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ParameterMaxVersionLimitExceededResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ParameterMaxVersionLimitExceeded(body, context);
        contents = __assign({ name: "ParameterMaxVersionLimitExceeded", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ParameterNotFoundResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ParameterNotFound(body, context);
        contents = __assign({ name: "ParameterNotFound", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ParameterPatternMismatchExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ParameterPatternMismatchException(body, context);
        contents = __assign({ name: "ParameterPatternMismatchException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ParameterVersionLabelLimitExceededResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ParameterVersionLabelLimitExceeded(body, context);
        contents = __assign({ name: "ParameterVersionLabelLimitExceeded", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ParameterVersionNotFoundResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ParameterVersionNotFound(body, context);
        contents = __assign({ name: "ParameterVersionNotFound", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1PoliciesLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1PoliciesLimitExceededException(body, context);
        contents = __assign({ name: "PoliciesLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ResourceDataSyncAlreadyExistsExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ResourceDataSyncAlreadyExistsException(body, context);
        contents = __assign({ name: "ResourceDataSyncAlreadyExistsException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ResourceDataSyncConflictExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ResourceDataSyncConflictException(body, context);
        contents = __assign({ name: "ResourceDataSyncConflictException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ResourceDataSyncCountExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ResourceDataSyncCountExceededException(body, context);
        contents = __assign({ name: "ResourceDataSyncCountExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ResourceDataSyncInvalidConfigurationExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ResourceDataSyncInvalidConfigurationException(body, context);
        contents = __assign({ name: "ResourceDataSyncInvalidConfigurationException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ResourceDataSyncNotFoundExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ResourceDataSyncNotFoundException(body, context);
        contents = __assign({ name: "ResourceDataSyncNotFoundException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ResourceInUseExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ResourceInUseException(body, context);
        contents = __assign({ name: "ResourceInUseException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ResourceLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ResourceLimitExceededException(body, context);
        contents = __assign({ name: "ResourceLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1ServiceSettingNotFoundResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1ServiceSettingNotFound(body, context);
        contents = __assign({ name: "ServiceSettingNotFound", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1StatusUnchangedResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1StatusUnchanged(body, context);
        contents = __assign({ name: "StatusUnchanged", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1SubTypeCountLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1SubTypeCountLimitExceededException(body, context);
        contents = __assign({ name: "SubTypeCountLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1TargetInUseExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1TargetInUseException(body, context);
        contents = __assign({ name: "TargetInUseException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1TargetNotConnectedResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1TargetNotConnected(body, context);
        contents = __assign({ name: "TargetNotConnected", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1TooManyTagsErrorResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1TooManyTagsError(body, context);
        contents = __assign({ name: "TooManyTagsError", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1TooManyUpdatesResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1TooManyUpdates(body, context);
        contents = __assign({ name: "TooManyUpdates", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1TotalSizeLimitExceededExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1TotalSizeLimitExceededException(body, context);
        contents = __assign({ name: "TotalSizeLimitExceededException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1UnsupportedCalendarExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1UnsupportedCalendarException(body, context);
        contents = __assign({ name: "UnsupportedCalendarException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1UnsupportedFeatureRequiredExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1UnsupportedFeatureRequiredException(body, context);
        contents = __assign({ name: "UnsupportedFeatureRequiredException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1UnsupportedInventoryItemContextExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1UnsupportedInventoryItemContextException(body, context);
        contents = __assign({ name: "UnsupportedInventoryItemContextException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1UnsupportedInventorySchemaVersionExceptionResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1UnsupportedInventorySchemaVersionException(body, context);
        contents = __assign({ name: "UnsupportedInventorySchemaVersionException", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1UnsupportedOperatingSystemResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1UnsupportedOperatingSystem(body, context);
        contents = __assign({ name: "UnsupportedOperatingSystem", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1UnsupportedParameterTypeResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1UnsupportedParameterType(body, context);
        contents = __assign({ name: "UnsupportedParameterType", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var deserializeAws_json1_1UnsupportedPlatformTypeResponse = function (parsedOutput, context) { return __awaiter(void 0, void 0, void 0, function () {
    var body, deserialized, contents;
    return __generator(this, function (_a) {
        body = parsedOutput.body;
        deserialized = deserializeAws_json1_1UnsupportedPlatformType(body, context);
        contents = __assign({ name: "UnsupportedPlatformType", $fault: "client", $metadata: deserializeMetadata(parsedOutput) }, deserialized);
        return [2, contents];
    });
}); };
var serializeAws_json1_1AccountIdList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1Accounts = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1AddTagsToResourceRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.ResourceId !== undefined && input.ResourceId !== null && { ResourceId: input.ResourceId })), (input.ResourceType !== undefined && input.ResourceType !== null && { ResourceType: input.ResourceType })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_1TagList(input.Tags, context) }));
};
var serializeAws_json1_1AssociateOpsItemRelatedItemRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.AssociationType !== undefined &&
        input.AssociationType !== null && { AssociationType: input.AssociationType })), (input.OpsItemId !== undefined && input.OpsItemId !== null && { OpsItemId: input.OpsItemId })), (input.ResourceType !== undefined && input.ResourceType !== null && { ResourceType: input.ResourceType })), (input.ResourceUri !== undefined && input.ResourceUri !== null && { ResourceUri: input.ResourceUri }));
};
var serializeAws_json1_1AssociationExecutionFilter = function (input, context) {
    return __assign(__assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Type !== undefined && input.Type !== null && { Type: input.Type })), (input.Value !== undefined && input.Value !== null && { Value: input.Value }));
};
var serializeAws_json1_1AssociationExecutionFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1AssociationExecutionFilter(entry, context);
    });
};
var serializeAws_json1_1AssociationExecutionTargetsFilter = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Value !== undefined && input.Value !== null && { Value: input.Value }));
};
var serializeAws_json1_1AssociationExecutionTargetsFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1AssociationExecutionTargetsFilter(entry, context);
    });
};
var serializeAws_json1_1AssociationFilter = function (input, context) {
    return __assign(__assign({}, (input.key !== undefined && input.key !== null && { key: input.key })), (input.value !== undefined && input.value !== null && { value: input.value }));
};
var serializeAws_json1_1AssociationFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1AssociationFilter(entry, context);
    });
};
var serializeAws_json1_1AssociationIdList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1AssociationStatus = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.AdditionalInfo !== undefined &&
        input.AdditionalInfo !== null && { AdditionalInfo: input.AdditionalInfo })), (input.Date !== undefined && input.Date !== null && { Date: Math.round(input.Date.getTime() / 1000) })), (input.Message !== undefined && input.Message !== null && { Message: input.Message })), (input.Name !== undefined && input.Name !== null && { Name: input.Name }));
};
var serializeAws_json1_1AttachmentsSource = function (input, context) {
    return __assign(__assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1AttachmentsSourceValues(input.Values, context) }));
};
var serializeAws_json1_1AttachmentsSourceList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1AttachmentsSource(entry, context);
    });
};
var serializeAws_json1_1AttachmentsSourceValues = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1AutomationExecutionFilter = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Values !== undefined &&
        input.Values !== null && {
        Values: serializeAws_json1_1AutomationExecutionFilterValueList(input.Values, context),
    }));
};
var serializeAws_json1_1AutomationExecutionFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1AutomationExecutionFilter(entry, context);
    });
};
var serializeAws_json1_1AutomationExecutionFilterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1AutomationParameterMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_1AutomationParameterValueList(value, context), _b));
    }, {});
};
var serializeAws_json1_1AutomationParameterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1BaselineOverride = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ApprovalRules !== undefined &&
        input.ApprovalRules !== null && {
        ApprovalRules: serializeAws_json1_1PatchRuleGroup(input.ApprovalRules, context),
    })), (input.ApprovedPatches !== undefined &&
        input.ApprovedPatches !== null && {
        ApprovedPatches: serializeAws_json1_1PatchIdList(input.ApprovedPatches, context),
    })), (input.ApprovedPatchesComplianceLevel !== undefined &&
        input.ApprovedPatchesComplianceLevel !== null && {
        ApprovedPatchesComplianceLevel: input.ApprovedPatchesComplianceLevel,
    })), (input.ApprovedPatchesEnableNonSecurity !== undefined &&
        input.ApprovedPatchesEnableNonSecurity !== null && {
        ApprovedPatchesEnableNonSecurity: input.ApprovedPatchesEnableNonSecurity,
    })), (input.GlobalFilters !== undefined &&
        input.GlobalFilters !== null && {
        GlobalFilters: serializeAws_json1_1PatchFilterGroup(input.GlobalFilters, context),
    })), (input.OperatingSystem !== undefined &&
        input.OperatingSystem !== null && { OperatingSystem: input.OperatingSystem })), (input.RejectedPatches !== undefined &&
        input.RejectedPatches !== null && {
        RejectedPatches: serializeAws_json1_1PatchIdList(input.RejectedPatches, context),
    })), (input.RejectedPatchesAction !== undefined &&
        input.RejectedPatchesAction !== null && { RejectedPatchesAction: input.RejectedPatchesAction })), (input.Sources !== undefined &&
        input.Sources !== null && { Sources: serializeAws_json1_1PatchSourceList(input.Sources, context) }));
};
var serializeAws_json1_1CalendarNameOrARNList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1CancelCommandRequest = function (input, context) {
    return __assign(__assign({}, (input.CommandId !== undefined && input.CommandId !== null && { CommandId: input.CommandId })), (input.InstanceIds !== undefined &&
        input.InstanceIds !== null && { InstanceIds: serializeAws_json1_1InstanceIdList(input.InstanceIds, context) }));
};
var serializeAws_json1_1CancelMaintenanceWindowExecutionRequest = function (input, context) {
    return __assign({}, (input.WindowExecutionId !== undefined &&
        input.WindowExecutionId !== null && { WindowExecutionId: input.WindowExecutionId }));
};
var serializeAws_json1_1CloudWatchOutputConfig = function (input, context) {
    return __assign(__assign({}, (input.CloudWatchLogGroupName !== undefined &&
        input.CloudWatchLogGroupName !== null && { CloudWatchLogGroupName: input.CloudWatchLogGroupName })), (input.CloudWatchOutputEnabled !== undefined &&
        input.CloudWatchOutputEnabled !== null && { CloudWatchOutputEnabled: input.CloudWatchOutputEnabled }));
};
var serializeAws_json1_1CommandFilter = function (input, context) {
    return __assign(__assign({}, (input.key !== undefined && input.key !== null && { key: input.key })), (input.value !== undefined && input.value !== null && { value: input.value }));
};
var serializeAws_json1_1CommandFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1CommandFilter(entry, context);
    });
};
var serializeAws_json1_1ComplianceExecutionSummary = function (input, context) {
    return __assign(__assign(__assign({}, (input.ExecutionId !== undefined && input.ExecutionId !== null && { ExecutionId: input.ExecutionId })), (input.ExecutionTime !== undefined &&
        input.ExecutionTime !== null && { ExecutionTime: Math.round(input.ExecutionTime.getTime() / 1000) })), (input.ExecutionType !== undefined && input.ExecutionType !== null && { ExecutionType: input.ExecutionType }));
};
var serializeAws_json1_1ComplianceItemDetails = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
    }, {});
};
var serializeAws_json1_1ComplianceItemEntry = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Details !== undefined &&
        input.Details !== null && { Details: serializeAws_json1_1ComplianceItemDetails(input.Details, context) })), (input.Id !== undefined && input.Id !== null && { Id: input.Id })), (input.Severity !== undefined && input.Severity !== null && { Severity: input.Severity })), (input.Status !== undefined && input.Status !== null && { Status: input.Status })), (input.Title !== undefined && input.Title !== null && { Title: input.Title }));
};
var serializeAws_json1_1ComplianceItemEntryList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1ComplianceItemEntry(entry, context);
    });
};
var serializeAws_json1_1ComplianceResourceIdList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1ComplianceResourceTypeList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1ComplianceStringFilter = function (input, context) {
    return __assign(__assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Type !== undefined && input.Type !== null && { Type: input.Type })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1ComplianceStringFilterValueList(input.Values, context) }));
};
var serializeAws_json1_1ComplianceStringFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1ComplianceStringFilter(entry, context);
    });
};
var serializeAws_json1_1ComplianceStringFilterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1CreateActivationRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.DefaultInstanceName !== undefined &&
        input.DefaultInstanceName !== null && { DefaultInstanceName: input.DefaultInstanceName })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.ExpirationDate !== undefined &&
        input.ExpirationDate !== null && { ExpirationDate: Math.round(input.ExpirationDate.getTime() / 1000) })), (input.IamRole !== undefined && input.IamRole !== null && { IamRole: input.IamRole })), (input.RegistrationLimit !== undefined &&
        input.RegistrationLimit !== null && { RegistrationLimit: input.RegistrationLimit })), (input.RegistrationMetadata !== undefined &&
        input.RegistrationMetadata !== null && {
        RegistrationMetadata: serializeAws_json1_1RegistrationMetadataList(input.RegistrationMetadata, context),
    })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_1TagList(input.Tags, context) }));
};
var serializeAws_json1_1CreateAssociationBatchRequest = function (input, context) {
    return __assign({}, (input.Entries !== undefined &&
        input.Entries !== null && {
        Entries: serializeAws_json1_1CreateAssociationBatchRequestEntries(input.Entries, context),
    }));
};
var serializeAws_json1_1CreateAssociationBatchRequestEntries = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1CreateAssociationBatchRequestEntry(entry, context);
    });
};
var serializeAws_json1_1CreateAssociationBatchRequestEntry = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ApplyOnlyAtCronInterval !== undefined &&
        input.ApplyOnlyAtCronInterval !== null && { ApplyOnlyAtCronInterval: input.ApplyOnlyAtCronInterval })), (input.AssociationName !== undefined &&
        input.AssociationName !== null && { AssociationName: input.AssociationName })), (input.AutomationTargetParameterName !== undefined &&
        input.AutomationTargetParameterName !== null && {
        AutomationTargetParameterName: input.AutomationTargetParameterName,
    })), (input.CalendarNames !== undefined &&
        input.CalendarNames !== null && {
        CalendarNames: serializeAws_json1_1CalendarNameOrARNList(input.CalendarNames, context),
    })), (input.ComplianceSeverity !== undefined &&
        input.ComplianceSeverity !== null && { ComplianceSeverity: input.ComplianceSeverity })), (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.MaxConcurrency !== undefined &&
        input.MaxConcurrency !== null && { MaxConcurrency: input.MaxConcurrency })), (input.MaxErrors !== undefined && input.MaxErrors !== null && { MaxErrors: input.MaxErrors })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.OutputLocation !== undefined &&
        input.OutputLocation !== null && {
        OutputLocation: serializeAws_json1_1InstanceAssociationOutputLocation(input.OutputLocation, context),
    })), (input.Parameters !== undefined &&
        input.Parameters !== null && { Parameters: serializeAws_json1_1Parameters(input.Parameters, context) })), (input.ScheduleExpression !== undefined &&
        input.ScheduleExpression !== null && { ScheduleExpression: input.ScheduleExpression })), (input.SyncCompliance !== undefined &&
        input.SyncCompliance !== null && { SyncCompliance: input.SyncCompliance })), (input.TargetLocations !== undefined &&
        input.TargetLocations !== null && {
        TargetLocations: serializeAws_json1_1TargetLocations(input.TargetLocations, context),
    })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) }));
};
var serializeAws_json1_1CreateAssociationRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ApplyOnlyAtCronInterval !== undefined &&
        input.ApplyOnlyAtCronInterval !== null && { ApplyOnlyAtCronInterval: input.ApplyOnlyAtCronInterval })), (input.AssociationName !== undefined &&
        input.AssociationName !== null && { AssociationName: input.AssociationName })), (input.AutomationTargetParameterName !== undefined &&
        input.AutomationTargetParameterName !== null && {
        AutomationTargetParameterName: input.AutomationTargetParameterName,
    })), (input.CalendarNames !== undefined &&
        input.CalendarNames !== null && {
        CalendarNames: serializeAws_json1_1CalendarNameOrARNList(input.CalendarNames, context),
    })), (input.ComplianceSeverity !== undefined &&
        input.ComplianceSeverity !== null && { ComplianceSeverity: input.ComplianceSeverity })), (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.MaxConcurrency !== undefined &&
        input.MaxConcurrency !== null && { MaxConcurrency: input.MaxConcurrency })), (input.MaxErrors !== undefined && input.MaxErrors !== null && { MaxErrors: input.MaxErrors })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.OutputLocation !== undefined &&
        input.OutputLocation !== null && {
        OutputLocation: serializeAws_json1_1InstanceAssociationOutputLocation(input.OutputLocation, context),
    })), (input.Parameters !== undefined &&
        input.Parameters !== null && { Parameters: serializeAws_json1_1Parameters(input.Parameters, context) })), (input.ScheduleExpression !== undefined &&
        input.ScheduleExpression !== null && { ScheduleExpression: input.ScheduleExpression })), (input.SyncCompliance !== undefined &&
        input.SyncCompliance !== null && { SyncCompliance: input.SyncCompliance })), (input.TargetLocations !== undefined &&
        input.TargetLocations !== null && {
        TargetLocations: serializeAws_json1_1TargetLocations(input.TargetLocations, context),
    })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) }));
};
var serializeAws_json1_1CreateDocumentRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Attachments !== undefined &&
        input.Attachments !== null && {
        Attachments: serializeAws_json1_1AttachmentsSourceList(input.Attachments, context),
    })), (input.Content !== undefined && input.Content !== null && { Content: input.Content })), (input.DisplayName !== undefined && input.DisplayName !== null && { DisplayName: input.DisplayName })), (input.DocumentFormat !== undefined &&
        input.DocumentFormat !== null && { DocumentFormat: input.DocumentFormat })), (input.DocumentType !== undefined && input.DocumentType !== null && { DocumentType: input.DocumentType })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.Requires !== undefined &&
        input.Requires !== null && { Requires: serializeAws_json1_1DocumentRequiresList(input.Requires, context) })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_1TagList(input.Tags, context) })), (input.TargetType !== undefined && input.TargetType !== null && { TargetType: input.TargetType })), (input.VersionName !== undefined && input.VersionName !== null && { VersionName: input.VersionName }));
};
var serializeAws_json1_1CreateMaintenanceWindowRequest = function (input, context) {
    var _a;
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AllowUnassociatedTargets !== undefined &&
        input.AllowUnassociatedTargets !== null && { AllowUnassociatedTargets: input.AllowUnassociatedTargets })), { ClientToken: (_a = input.ClientToken) !== null && _a !== void 0 ? _a : generateIdempotencyToken() }), (input.Cutoff !== undefined && input.Cutoff !== null && { Cutoff: input.Cutoff })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.Duration !== undefined && input.Duration !== null && { Duration: input.Duration })), (input.EndDate !== undefined && input.EndDate !== null && { EndDate: input.EndDate })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.Schedule !== undefined && input.Schedule !== null && { Schedule: input.Schedule })), (input.ScheduleOffset !== undefined &&
        input.ScheduleOffset !== null && { ScheduleOffset: input.ScheduleOffset })), (input.ScheduleTimezone !== undefined &&
        input.ScheduleTimezone !== null && { ScheduleTimezone: input.ScheduleTimezone })), (input.StartDate !== undefined && input.StartDate !== null && { StartDate: input.StartDate })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_1TagList(input.Tags, context) }));
};
var serializeAws_json1_1CreateOpsItemRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ActualEndTime !== undefined &&
        input.ActualEndTime !== null && { ActualEndTime: Math.round(input.ActualEndTime.getTime() / 1000) })), (input.ActualStartTime !== undefined &&
        input.ActualStartTime !== null && { ActualStartTime: Math.round(input.ActualStartTime.getTime() / 1000) })), (input.Category !== undefined && input.Category !== null && { Category: input.Category })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.Notifications !== undefined &&
        input.Notifications !== null && {
        Notifications: serializeAws_json1_1OpsItemNotifications(input.Notifications, context),
    })), (input.OperationalData !== undefined &&
        input.OperationalData !== null && {
        OperationalData: serializeAws_json1_1OpsItemOperationalData(input.OperationalData, context),
    })), (input.OpsItemType !== undefined && input.OpsItemType !== null && { OpsItemType: input.OpsItemType })), (input.PlannedEndTime !== undefined &&
        input.PlannedEndTime !== null && { PlannedEndTime: Math.round(input.PlannedEndTime.getTime() / 1000) })), (input.PlannedStartTime !== undefined &&
        input.PlannedStartTime !== null && { PlannedStartTime: Math.round(input.PlannedStartTime.getTime() / 1000) })), (input.Priority !== undefined && input.Priority !== null && { Priority: input.Priority })), (input.RelatedOpsItems !== undefined &&
        input.RelatedOpsItems !== null && {
        RelatedOpsItems: serializeAws_json1_1RelatedOpsItems(input.RelatedOpsItems, context),
    })), (input.Severity !== undefined && input.Severity !== null && { Severity: input.Severity })), (input.Source !== undefined && input.Source !== null && { Source: input.Source })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_1TagList(input.Tags, context) })), (input.Title !== undefined && input.Title !== null && { Title: input.Title }));
};
var serializeAws_json1_1CreateOpsMetadataRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Metadata !== undefined &&
        input.Metadata !== null && { Metadata: serializeAws_json1_1MetadataMap(input.Metadata, context) })), (input.ResourceId !== undefined && input.ResourceId !== null && { ResourceId: input.ResourceId })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_1TagList(input.Tags, context) }));
};
var serializeAws_json1_1CreatePatchBaselineRequest = function (input, context) {
    var _a;
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ApprovalRules !== undefined &&
        input.ApprovalRules !== null && {
        ApprovalRules: serializeAws_json1_1PatchRuleGroup(input.ApprovalRules, context),
    })), (input.ApprovedPatches !== undefined &&
        input.ApprovedPatches !== null && {
        ApprovedPatches: serializeAws_json1_1PatchIdList(input.ApprovedPatches, context),
    })), (input.ApprovedPatchesComplianceLevel !== undefined &&
        input.ApprovedPatchesComplianceLevel !== null && {
        ApprovedPatchesComplianceLevel: input.ApprovedPatchesComplianceLevel,
    })), (input.ApprovedPatchesEnableNonSecurity !== undefined &&
        input.ApprovedPatchesEnableNonSecurity !== null && {
        ApprovedPatchesEnableNonSecurity: input.ApprovedPatchesEnableNonSecurity,
    })), { ClientToken: (_a = input.ClientToken) !== null && _a !== void 0 ? _a : generateIdempotencyToken() }), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.GlobalFilters !== undefined &&
        input.GlobalFilters !== null && {
        GlobalFilters: serializeAws_json1_1PatchFilterGroup(input.GlobalFilters, context),
    })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.OperatingSystem !== undefined &&
        input.OperatingSystem !== null && { OperatingSystem: input.OperatingSystem })), (input.RejectedPatches !== undefined &&
        input.RejectedPatches !== null && {
        RejectedPatches: serializeAws_json1_1PatchIdList(input.RejectedPatches, context),
    })), (input.RejectedPatchesAction !== undefined &&
        input.RejectedPatchesAction !== null && { RejectedPatchesAction: input.RejectedPatchesAction })), (input.Sources !== undefined &&
        input.Sources !== null && { Sources: serializeAws_json1_1PatchSourceList(input.Sources, context) })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_1TagList(input.Tags, context) }));
};
var serializeAws_json1_1CreateResourceDataSyncRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.S3Destination !== undefined &&
        input.S3Destination !== null && {
        S3Destination: serializeAws_json1_1ResourceDataSyncS3Destination(input.S3Destination, context),
    })), (input.SyncName !== undefined && input.SyncName !== null && { SyncName: input.SyncName })), (input.SyncSource !== undefined &&
        input.SyncSource !== null && {
        SyncSource: serializeAws_json1_1ResourceDataSyncSource(input.SyncSource, context),
    })), (input.SyncType !== undefined && input.SyncType !== null && { SyncType: input.SyncType }));
};
var serializeAws_json1_1DeleteActivationRequest = function (input, context) {
    return __assign({}, (input.ActivationId !== undefined && input.ActivationId !== null && { ActivationId: input.ActivationId }));
};
var serializeAws_json1_1DeleteAssociationRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.AssociationId !== undefined && input.AssociationId !== null && { AssociationId: input.AssociationId })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.Name !== undefined && input.Name !== null && { Name: input.Name }));
};
var serializeAws_json1_1DeleteDocumentRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.Force !== undefined && input.Force !== null && { Force: input.Force })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.VersionName !== undefined && input.VersionName !== null && { VersionName: input.VersionName }));
};
var serializeAws_json1_1DeleteInventoryRequest = function (input, context) {
    var _a;
    return __assign(__assign(__assign({ ClientToken: (_a = input.ClientToken) !== null && _a !== void 0 ? _a : generateIdempotencyToken() }, (input.DryRun !== undefined && input.DryRun !== null && { DryRun: input.DryRun })), (input.SchemaDeleteOption !== undefined &&
        input.SchemaDeleteOption !== null && { SchemaDeleteOption: input.SchemaDeleteOption })), (input.TypeName !== undefined && input.TypeName !== null && { TypeName: input.TypeName }));
};
var serializeAws_json1_1DeleteMaintenanceWindowRequest = function (input, context) {
    return __assign({}, (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId }));
};
var serializeAws_json1_1DeleteOpsMetadataRequest = function (input, context) {
    return __assign({}, (input.OpsMetadataArn !== undefined &&
        input.OpsMetadataArn !== null && { OpsMetadataArn: input.OpsMetadataArn }));
};
var serializeAws_json1_1DeleteParameterRequest = function (input, context) {
    return __assign({}, (input.Name !== undefined && input.Name !== null && { Name: input.Name }));
};
var serializeAws_json1_1DeleteParametersRequest = function (input, context) {
    return __assign({}, (input.Names !== undefined &&
        input.Names !== null && { Names: serializeAws_json1_1ParameterNameList(input.Names, context) }));
};
var serializeAws_json1_1DeletePatchBaselineRequest = function (input, context) {
    return __assign({}, (input.BaselineId !== undefined && input.BaselineId !== null && { BaselineId: input.BaselineId }));
};
var serializeAws_json1_1DeleteResourceDataSyncRequest = function (input, context) {
    return __assign(__assign({}, (input.SyncName !== undefined && input.SyncName !== null && { SyncName: input.SyncName })), (input.SyncType !== undefined && input.SyncType !== null && { SyncType: input.SyncType }));
};
var serializeAws_json1_1DeregisterManagedInstanceRequest = function (input, context) {
    return __assign({}, (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId }));
};
var serializeAws_json1_1DeregisterPatchBaselineForPatchGroupRequest = function (input, context) {
    return __assign(__assign({}, (input.BaselineId !== undefined && input.BaselineId !== null && { BaselineId: input.BaselineId })), (input.PatchGroup !== undefined && input.PatchGroup !== null && { PatchGroup: input.PatchGroup }));
};
var serializeAws_json1_1DeregisterTargetFromMaintenanceWindowRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Safe !== undefined && input.Safe !== null && { Safe: input.Safe })), (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId })), (input.WindowTargetId !== undefined &&
        input.WindowTargetId !== null && { WindowTargetId: input.WindowTargetId }));
};
var serializeAws_json1_1DeregisterTaskFromMaintenanceWindowRequest = function (input, context) {
    return __assign(__assign({}, (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId })), (input.WindowTaskId !== undefined && input.WindowTaskId !== null && { WindowTaskId: input.WindowTaskId }));
};
var serializeAws_json1_1DescribeActivationsFilter = function (input, context) {
    return __assign(__assign({}, (input.FilterKey !== undefined && input.FilterKey !== null && { FilterKey: input.FilterKey })), (input.FilterValues !== undefined &&
        input.FilterValues !== null && { FilterValues: serializeAws_json1_1StringList(input.FilterValues, context) }));
};
var serializeAws_json1_1DescribeActivationsFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1DescribeActivationsFilter(entry, context);
    });
};
var serializeAws_json1_1DescribeActivationsRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1DescribeActivationsFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeAssociationExecutionsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.AssociationId !== undefined && input.AssociationId !== null && { AssociationId: input.AssociationId })), (input.Filters !== undefined &&
        input.Filters !== null && {
        Filters: serializeAws_json1_1AssociationExecutionFilterList(input.Filters, context),
    })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeAssociationExecutionTargetsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.AssociationId !== undefined && input.AssociationId !== null && { AssociationId: input.AssociationId })), (input.ExecutionId !== undefined && input.ExecutionId !== null && { ExecutionId: input.ExecutionId })), (input.Filters !== undefined &&
        input.Filters !== null && {
        Filters: serializeAws_json1_1AssociationExecutionTargetsFilterList(input.Filters, context),
    })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeAssociationRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.AssociationId !== undefined && input.AssociationId !== null && { AssociationId: input.AssociationId })), (input.AssociationVersion !== undefined &&
        input.AssociationVersion !== null && { AssociationVersion: input.AssociationVersion })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.Name !== undefined && input.Name !== null && { Name: input.Name }));
};
var serializeAws_json1_1DescribeAutomationExecutionsRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1AutomationExecutionFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeAutomationStepExecutionsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.AutomationExecutionId !== undefined &&
        input.AutomationExecutionId !== null && { AutomationExecutionId: input.AutomationExecutionId })), (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1StepExecutionFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.ReverseOrder !== undefined && input.ReverseOrder !== null && { ReverseOrder: input.ReverseOrder }));
};
var serializeAws_json1_1DescribeAvailablePatchesRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1PatchOrchestratorFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeDocumentPermissionRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.PermissionType !== undefined &&
        input.PermissionType !== null && { PermissionType: input.PermissionType }));
};
var serializeAws_json1_1DescribeDocumentRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.VersionName !== undefined && input.VersionName !== null && { VersionName: input.VersionName }));
};
var serializeAws_json1_1DescribeEffectiveInstanceAssociationsRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeEffectivePatchesForPatchBaselineRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.BaselineId !== undefined && input.BaselineId !== null && { BaselineId: input.BaselineId })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeInstanceAssociationsStatusRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeInstanceInformationRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && {
        Filters: serializeAws_json1_1InstanceInformationStringFilterList(input.Filters, context),
    })), (input.InstanceInformationFilterList !== undefined &&
        input.InstanceInformationFilterList !== null && {
        InstanceInformationFilterList: serializeAws_json1_1InstanceInformationFilterList(input.InstanceInformationFilterList, context),
    })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeInstancePatchesRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1PatchOrchestratorFilterList(input.Filters, context) })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeInstancePatchStatesForPatchGroupRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1InstancePatchStateFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.PatchGroup !== undefined && input.PatchGroup !== null && { PatchGroup: input.PatchGroup }));
};
var serializeAws_json1_1DescribeInstancePatchStatesRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.InstanceIds !== undefined &&
        input.InstanceIds !== null && { InstanceIds: serializeAws_json1_1InstanceIdList(input.InstanceIds, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeInventoryDeletionsRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.DeletionId !== undefined && input.DeletionId !== null && { DeletionId: input.DeletionId })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeMaintenanceWindowExecutionsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1MaintenanceWindowFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId }));
};
var serializeAws_json1_1DescribeMaintenanceWindowExecutionTaskInvocationsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1MaintenanceWindowFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.TaskId !== undefined && input.TaskId !== null && { TaskId: input.TaskId })), (input.WindowExecutionId !== undefined &&
        input.WindowExecutionId !== null && { WindowExecutionId: input.WindowExecutionId }));
};
var serializeAws_json1_1DescribeMaintenanceWindowExecutionTasksRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1MaintenanceWindowFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.WindowExecutionId !== undefined &&
        input.WindowExecutionId !== null && { WindowExecutionId: input.WindowExecutionId }));
};
var serializeAws_json1_1DescribeMaintenanceWindowScheduleRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1PatchOrchestratorFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.ResourceType !== undefined && input.ResourceType !== null && { ResourceType: input.ResourceType })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) })), (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId }));
};
var serializeAws_json1_1DescribeMaintenanceWindowsForTargetRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.ResourceType !== undefined && input.ResourceType !== null && { ResourceType: input.ResourceType })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) }));
};
var serializeAws_json1_1DescribeMaintenanceWindowsRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1MaintenanceWindowFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribeMaintenanceWindowTargetsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1MaintenanceWindowFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId }));
};
var serializeAws_json1_1DescribeMaintenanceWindowTasksRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1MaintenanceWindowFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId }));
};
var serializeAws_json1_1DescribeOpsItemsRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.OpsItemFilters !== undefined &&
        input.OpsItemFilters !== null && {
        OpsItemFilters: serializeAws_json1_1OpsItemFilters(input.OpsItemFilters, context),
    }));
};
var serializeAws_json1_1DescribeParametersRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1ParametersFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.ParameterFilters !== undefined &&
        input.ParameterFilters !== null && {
        ParameterFilters: serializeAws_json1_1ParameterStringFilterList(input.ParameterFilters, context),
    }));
};
var serializeAws_json1_1DescribePatchBaselinesRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1PatchOrchestratorFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribePatchGroupsRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1PatchOrchestratorFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1DescribePatchGroupStateRequest = function (input, context) {
    return __assign({}, (input.PatchGroup !== undefined && input.PatchGroup !== null && { PatchGroup: input.PatchGroup }));
};
var serializeAws_json1_1DescribePatchPropertiesRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.OperatingSystem !== undefined &&
        input.OperatingSystem !== null && { OperatingSystem: input.OperatingSystem })), (input.PatchSet !== undefined && input.PatchSet !== null && { PatchSet: input.PatchSet })), (input.Property !== undefined && input.Property !== null && { Property: input.Property }));
};
var serializeAws_json1_1DescribeSessionsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1SessionFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.State !== undefined && input.State !== null && { State: input.State }));
};
var serializeAws_json1_1DisassociateOpsItemRelatedItemRequest = function (input, context) {
    return __assign(__assign({}, (input.AssociationId !== undefined && input.AssociationId !== null && { AssociationId: input.AssociationId })), (input.OpsItemId !== undefined && input.OpsItemId !== null && { OpsItemId: input.OpsItemId }));
};
var serializeAws_json1_1DocumentFilter = function (input, context) {
    return __assign(__assign({}, (input.key !== undefined && input.key !== null && { key: input.key })), (input.value !== undefined && input.value !== null && { value: input.value }));
};
var serializeAws_json1_1DocumentFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1DocumentFilter(entry, context);
    });
};
var serializeAws_json1_1DocumentKeyValuesFilter = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1DocumentKeyValuesFilterValues(input.Values, context) }));
};
var serializeAws_json1_1DocumentKeyValuesFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1DocumentKeyValuesFilter(entry, context);
    });
};
var serializeAws_json1_1DocumentKeyValuesFilterValues = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1DocumentRequires = function (input, context) {
    return __assign(__assign({}, (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.Version !== undefined && input.Version !== null && { Version: input.Version }));
};
var serializeAws_json1_1DocumentRequiresList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1DocumentRequires(entry, context);
    });
};
var serializeAws_json1_1DocumentReviewCommentList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1DocumentReviewCommentSource(entry, context);
    });
};
var serializeAws_json1_1DocumentReviewCommentSource = function (input, context) {
    return __assign(__assign({}, (input.Content !== undefined && input.Content !== null && { Content: input.Content })), (input.Type !== undefined && input.Type !== null && { Type: input.Type }));
};
var serializeAws_json1_1DocumentReviews = function (input, context) {
    return __assign(__assign({}, (input.Action !== undefined && input.Action !== null && { Action: input.Action })), (input.Comment !== undefined &&
        input.Comment !== null && { Comment: serializeAws_json1_1DocumentReviewCommentList(input.Comment, context) }));
};
var serializeAws_json1_1GetAutomationExecutionRequest = function (input, context) {
    return __assign({}, (input.AutomationExecutionId !== undefined &&
        input.AutomationExecutionId !== null && { AutomationExecutionId: input.AutomationExecutionId }));
};
var serializeAws_json1_1GetCalendarStateRequest = function (input, context) {
    return __assign(__assign({}, (input.AtTime !== undefined && input.AtTime !== null && { AtTime: input.AtTime })), (input.CalendarNames !== undefined &&
        input.CalendarNames !== null && {
        CalendarNames: serializeAws_json1_1CalendarNameOrARNList(input.CalendarNames, context),
    }));
};
var serializeAws_json1_1GetCommandInvocationRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.CommandId !== undefined && input.CommandId !== null && { CommandId: input.CommandId })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.PluginName !== undefined && input.PluginName !== null && { PluginName: input.PluginName }));
};
var serializeAws_json1_1GetConnectionStatusRequest = function (input, context) {
    return __assign({}, (input.Target !== undefined && input.Target !== null && { Target: input.Target }));
};
var serializeAws_json1_1GetDefaultPatchBaselineRequest = function (input, context) {
    return __assign({}, (input.OperatingSystem !== undefined &&
        input.OperatingSystem !== null && { OperatingSystem: input.OperatingSystem }));
};
var serializeAws_json1_1GetDeployablePatchSnapshotForInstanceRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.BaselineOverride !== undefined &&
        input.BaselineOverride !== null && {
        BaselineOverride: serializeAws_json1_1BaselineOverride(input.BaselineOverride, context),
    })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.SnapshotId !== undefined && input.SnapshotId !== null && { SnapshotId: input.SnapshotId }));
};
var serializeAws_json1_1GetDocumentRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.DocumentFormat !== undefined &&
        input.DocumentFormat !== null && { DocumentFormat: input.DocumentFormat })), (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.VersionName !== undefined && input.VersionName !== null && { VersionName: input.VersionName }));
};
var serializeAws_json1_1GetInventoryRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Aggregators !== undefined &&
        input.Aggregators !== null && {
        Aggregators: serializeAws_json1_1InventoryAggregatorList(input.Aggregators, context),
    })), (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1InventoryFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.ResultAttributes !== undefined &&
        input.ResultAttributes !== null && {
        ResultAttributes: serializeAws_json1_1ResultAttributeList(input.ResultAttributes, context),
    }));
};
var serializeAws_json1_1GetInventorySchemaRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Aggregator !== undefined && input.Aggregator !== null && { Aggregator: input.Aggregator })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.SubType !== undefined && input.SubType !== null && { SubType: input.SubType })), (input.TypeName !== undefined && input.TypeName !== null && { TypeName: input.TypeName }));
};
var serializeAws_json1_1GetMaintenanceWindowExecutionRequest = function (input, context) {
    return __assign({}, (input.WindowExecutionId !== undefined &&
        input.WindowExecutionId !== null && { WindowExecutionId: input.WindowExecutionId }));
};
var serializeAws_json1_1GetMaintenanceWindowExecutionTaskInvocationRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.InvocationId !== undefined && input.InvocationId !== null && { InvocationId: input.InvocationId })), (input.TaskId !== undefined && input.TaskId !== null && { TaskId: input.TaskId })), (input.WindowExecutionId !== undefined &&
        input.WindowExecutionId !== null && { WindowExecutionId: input.WindowExecutionId }));
};
var serializeAws_json1_1GetMaintenanceWindowExecutionTaskRequest = function (input, context) {
    return __assign(__assign({}, (input.TaskId !== undefined && input.TaskId !== null && { TaskId: input.TaskId })), (input.WindowExecutionId !== undefined &&
        input.WindowExecutionId !== null && { WindowExecutionId: input.WindowExecutionId }));
};
var serializeAws_json1_1GetMaintenanceWindowRequest = function (input, context) {
    return __assign({}, (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId }));
};
var serializeAws_json1_1GetMaintenanceWindowTaskRequest = function (input, context) {
    return __assign(__assign({}, (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId })), (input.WindowTaskId !== undefined && input.WindowTaskId !== null && { WindowTaskId: input.WindowTaskId }));
};
var serializeAws_json1_1GetOpsItemRequest = function (input, context) {
    return __assign({}, (input.OpsItemId !== undefined && input.OpsItemId !== null && { OpsItemId: input.OpsItemId }));
};
var serializeAws_json1_1GetOpsMetadataRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.OpsMetadataArn !== undefined &&
        input.OpsMetadataArn !== null && { OpsMetadataArn: input.OpsMetadataArn }));
};
var serializeAws_json1_1GetOpsSummaryRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.Aggregators !== undefined &&
        input.Aggregators !== null && { Aggregators: serializeAws_json1_1OpsAggregatorList(input.Aggregators, context) })), (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1OpsFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.ResultAttributes !== undefined &&
        input.ResultAttributes !== null && {
        ResultAttributes: serializeAws_json1_1OpsResultAttributeList(input.ResultAttributes, context),
    })), (input.SyncName !== undefined && input.SyncName !== null && { SyncName: input.SyncName }));
};
var serializeAws_json1_1GetParameterHistoryRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.WithDecryption !== undefined &&
        input.WithDecryption !== null && { WithDecryption: input.WithDecryption }));
};
var serializeAws_json1_1GetParameterRequest = function (input, context) {
    return __assign(__assign({}, (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.WithDecryption !== undefined &&
        input.WithDecryption !== null && { WithDecryption: input.WithDecryption }));
};
var serializeAws_json1_1GetParametersByPathRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.ParameterFilters !== undefined &&
        input.ParameterFilters !== null && {
        ParameterFilters: serializeAws_json1_1ParameterStringFilterList(input.ParameterFilters, context),
    })), (input.Path !== undefined && input.Path !== null && { Path: input.Path })), (input.Recursive !== undefined && input.Recursive !== null && { Recursive: input.Recursive })), (input.WithDecryption !== undefined &&
        input.WithDecryption !== null && { WithDecryption: input.WithDecryption }));
};
var serializeAws_json1_1GetParametersRequest = function (input, context) {
    return __assign(__assign({}, (input.Names !== undefined &&
        input.Names !== null && { Names: serializeAws_json1_1ParameterNameList(input.Names, context) })), (input.WithDecryption !== undefined &&
        input.WithDecryption !== null && { WithDecryption: input.WithDecryption }));
};
var serializeAws_json1_1GetPatchBaselineForPatchGroupRequest = function (input, context) {
    return __assign(__assign({}, (input.OperatingSystem !== undefined &&
        input.OperatingSystem !== null && { OperatingSystem: input.OperatingSystem })), (input.PatchGroup !== undefined && input.PatchGroup !== null && { PatchGroup: input.PatchGroup }));
};
var serializeAws_json1_1GetPatchBaselineRequest = function (input, context) {
    return __assign({}, (input.BaselineId !== undefined && input.BaselineId !== null && { BaselineId: input.BaselineId }));
};
var serializeAws_json1_1GetServiceSettingRequest = function (input, context) {
    return __assign({}, (input.SettingId !== undefined && input.SettingId !== null && { SettingId: input.SettingId }));
};
var serializeAws_json1_1InstanceAssociationOutputLocation = function (input, context) {
    return __assign({}, (input.S3Location !== undefined &&
        input.S3Location !== null && { S3Location: serializeAws_json1_1S3OutputLocation(input.S3Location, context) }));
};
var serializeAws_json1_1InstanceIdList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1InstanceInformationFilter = function (input, context) {
    return __assign(__assign({}, (input.key !== undefined && input.key !== null && { key: input.key })), (input.valueSet !== undefined &&
        input.valueSet !== null && {
        valueSet: serializeAws_json1_1InstanceInformationFilterValueSet(input.valueSet, context),
    }));
};
var serializeAws_json1_1InstanceInformationFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1InstanceInformationFilter(entry, context);
    });
};
var serializeAws_json1_1InstanceInformationFilterValueSet = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1InstanceInformationStringFilter = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Values !== undefined &&
        input.Values !== null && {
        Values: serializeAws_json1_1InstanceInformationFilterValueSet(input.Values, context),
    }));
};
var serializeAws_json1_1InstanceInformationStringFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1InstanceInformationStringFilter(entry, context);
    });
};
var serializeAws_json1_1InstancePatchStateFilter = function (input, context) {
    return __assign(__assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Type !== undefined && input.Type !== null && { Type: input.Type })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1InstancePatchStateFilterValues(input.Values, context) }));
};
var serializeAws_json1_1InstancePatchStateFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1InstancePatchStateFilter(entry, context);
    });
};
var serializeAws_json1_1InstancePatchStateFilterValues = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1InventoryAggregator = function (input, context) {
    return __assign(__assign(__assign({}, (input.Aggregators !== undefined &&
        input.Aggregators !== null && {
        Aggregators: serializeAws_json1_1InventoryAggregatorList(input.Aggregators, context),
    })), (input.Expression !== undefined && input.Expression !== null && { Expression: input.Expression })), (input.Groups !== undefined &&
        input.Groups !== null && { Groups: serializeAws_json1_1InventoryGroupList(input.Groups, context) }));
};
var serializeAws_json1_1InventoryAggregatorList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1InventoryAggregator(entry, context);
    });
};
var serializeAws_json1_1InventoryFilter = function (input, context) {
    return __assign(__assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Type !== undefined && input.Type !== null && { Type: input.Type })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1InventoryFilterValueList(input.Values, context) }));
};
var serializeAws_json1_1InventoryFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1InventoryFilter(entry, context);
    });
};
var serializeAws_json1_1InventoryFilterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1InventoryGroup = function (input, context) {
    return __assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1InventoryFilterList(input.Filters, context) })), (input.Name !== undefined && input.Name !== null && { Name: input.Name }));
};
var serializeAws_json1_1InventoryGroupList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1InventoryGroup(entry, context);
    });
};
var serializeAws_json1_1InventoryItem = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.CaptureTime !== undefined && input.CaptureTime !== null && { CaptureTime: input.CaptureTime })), (input.Content !== undefined &&
        input.Content !== null && { Content: serializeAws_json1_1InventoryItemEntryList(input.Content, context) })), (input.ContentHash !== undefined && input.ContentHash !== null && { ContentHash: input.ContentHash })), (input.Context !== undefined &&
        input.Context !== null && { Context: serializeAws_json1_1InventoryItemContentContext(input.Context, context) })), (input.SchemaVersion !== undefined && input.SchemaVersion !== null && { SchemaVersion: input.SchemaVersion })), (input.TypeName !== undefined && input.TypeName !== null && { TypeName: input.TypeName }));
};
var serializeAws_json1_1InventoryItemContentContext = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
    }, {});
};
var serializeAws_json1_1InventoryItemEntry = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
    }, {});
};
var serializeAws_json1_1InventoryItemEntryList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1InventoryItemEntry(entry, context);
    });
};
var serializeAws_json1_1InventoryItemList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1InventoryItem(entry, context);
    });
};
var serializeAws_json1_1KeyList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1LabelParameterVersionRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Labels !== undefined &&
        input.Labels !== null && { Labels: serializeAws_json1_1ParameterLabelList(input.Labels, context) })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.ParameterVersion !== undefined &&
        input.ParameterVersion !== null && { ParameterVersion: input.ParameterVersion }));
};
var serializeAws_json1_1ListAssociationsRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.AssociationFilterList !== undefined &&
        input.AssociationFilterList !== null && {
        AssociationFilterList: serializeAws_json1_1AssociationFilterList(input.AssociationFilterList, context),
    })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1ListAssociationVersionsRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.AssociationId !== undefined && input.AssociationId !== null && { AssociationId: input.AssociationId })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1ListCommandInvocationsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.CommandId !== undefined && input.CommandId !== null && { CommandId: input.CommandId })), (input.Details !== undefined && input.Details !== null && { Details: input.Details })), (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1CommandFilterList(input.Filters, context) })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1ListCommandsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.CommandId !== undefined && input.CommandId !== null && { CommandId: input.CommandId })), (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1CommandFilterList(input.Filters, context) })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1ListComplianceItemsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1ComplianceStringFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.ResourceIds !== undefined &&
        input.ResourceIds !== null && {
        ResourceIds: serializeAws_json1_1ComplianceResourceIdList(input.ResourceIds, context),
    })), (input.ResourceTypes !== undefined &&
        input.ResourceTypes !== null && {
        ResourceTypes: serializeAws_json1_1ComplianceResourceTypeList(input.ResourceTypes, context),
    }));
};
var serializeAws_json1_1ListComplianceSummariesRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1ComplianceStringFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1ListDocumentMetadataHistoryRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.Metadata !== undefined && input.Metadata !== null && { Metadata: input.Metadata })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1ListDocumentsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.DocumentFilterList !== undefined &&
        input.DocumentFilterList !== null && {
        DocumentFilterList: serializeAws_json1_1DocumentFilterList(input.DocumentFilterList, context),
    })), (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1DocumentKeyValuesFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1ListDocumentVersionsRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1ListInventoryEntriesRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1InventoryFilterList(input.Filters, context) })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.TypeName !== undefined && input.TypeName !== null && { TypeName: input.TypeName }));
};
var serializeAws_json1_1ListOpsItemEventsRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1OpsItemEventFilters(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1ListOpsItemRelatedItemsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1OpsItemRelatedItemsFilters(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.OpsItemId !== undefined && input.OpsItemId !== null && { OpsItemId: input.OpsItemId }));
};
var serializeAws_json1_1ListOpsMetadataRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1OpsMetadataFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1ListResourceComplianceSummariesRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1ComplianceStringFilterList(input.Filters, context) })), (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken }));
};
var serializeAws_json1_1ListResourceDataSyncRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.MaxResults !== undefined && input.MaxResults !== null && { MaxResults: input.MaxResults })), (input.NextToken !== undefined && input.NextToken !== null && { NextToken: input.NextToken })), (input.SyncType !== undefined && input.SyncType !== null && { SyncType: input.SyncType }));
};
var serializeAws_json1_1ListTagsForResourceRequest = function (input, context) {
    return __assign(__assign({}, (input.ResourceId !== undefined && input.ResourceId !== null && { ResourceId: input.ResourceId })), (input.ResourceType !== undefined && input.ResourceType !== null && { ResourceType: input.ResourceType }));
};
var serializeAws_json1_1LoggingInfo = function (input, context) {
    return __assign(__assign(__assign({}, (input.S3BucketName !== undefined && input.S3BucketName !== null && { S3BucketName: input.S3BucketName })), (input.S3KeyPrefix !== undefined && input.S3KeyPrefix !== null && { S3KeyPrefix: input.S3KeyPrefix })), (input.S3Region !== undefined && input.S3Region !== null && { S3Region: input.S3Region }));
};
var serializeAws_json1_1MaintenanceWindowAutomationParameters = function (input, context) {
    return __assign(__assign({}, (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.Parameters !== undefined &&
        input.Parameters !== null && {
        Parameters: serializeAws_json1_1AutomationParameterMap(input.Parameters, context),
    }));
};
var serializeAws_json1_1MaintenanceWindowFilter = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1MaintenanceWindowFilterValues(input.Values, context) }));
};
var serializeAws_json1_1MaintenanceWindowFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1MaintenanceWindowFilter(entry, context);
    });
};
var serializeAws_json1_1MaintenanceWindowFilterValues = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1MaintenanceWindowLambdaParameters = function (input, context) {
    return __assign(__assign(__assign({}, (input.ClientContext !== undefined && input.ClientContext !== null && { ClientContext: input.ClientContext })), (input.Payload !== undefined && input.Payload !== null && { Payload: context.base64Encoder(input.Payload) })), (input.Qualifier !== undefined && input.Qualifier !== null && { Qualifier: input.Qualifier }));
};
var serializeAws_json1_1MaintenanceWindowRunCommandParameters = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.CloudWatchOutputConfig !== undefined &&
        input.CloudWatchOutputConfig !== null && {
        CloudWatchOutputConfig: serializeAws_json1_1CloudWatchOutputConfig(input.CloudWatchOutputConfig, context),
    })), (input.Comment !== undefined && input.Comment !== null && { Comment: input.Comment })), (input.DocumentHash !== undefined && input.DocumentHash !== null && { DocumentHash: input.DocumentHash })), (input.DocumentHashType !== undefined &&
        input.DocumentHashType !== null && { DocumentHashType: input.DocumentHashType })), (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.NotificationConfig !== undefined &&
        input.NotificationConfig !== null && {
        NotificationConfig: serializeAws_json1_1NotificationConfig(input.NotificationConfig, context),
    })), (input.OutputS3BucketName !== undefined &&
        input.OutputS3BucketName !== null && { OutputS3BucketName: input.OutputS3BucketName })), (input.OutputS3KeyPrefix !== undefined &&
        input.OutputS3KeyPrefix !== null && { OutputS3KeyPrefix: input.OutputS3KeyPrefix })), (input.Parameters !== undefined &&
        input.Parameters !== null && { Parameters: serializeAws_json1_1Parameters(input.Parameters, context) })), (input.ServiceRoleArn !== undefined &&
        input.ServiceRoleArn !== null && { ServiceRoleArn: input.ServiceRoleArn })), (input.TimeoutSeconds !== undefined &&
        input.TimeoutSeconds !== null && { TimeoutSeconds: input.TimeoutSeconds }));
};
var serializeAws_json1_1MaintenanceWindowStepFunctionsParameters = function (input, context) {
    return __assign(__assign({}, (input.Input !== undefined && input.Input !== null && { Input: input.Input })), (input.Name !== undefined && input.Name !== null && { Name: input.Name }));
};
var serializeAws_json1_1MaintenanceWindowTaskInvocationParameters = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.Automation !== undefined &&
        input.Automation !== null && {
        Automation: serializeAws_json1_1MaintenanceWindowAutomationParameters(input.Automation, context),
    })), (input.Lambda !== undefined &&
        input.Lambda !== null && {
        Lambda: serializeAws_json1_1MaintenanceWindowLambdaParameters(input.Lambda, context),
    })), (input.RunCommand !== undefined &&
        input.RunCommand !== null && {
        RunCommand: serializeAws_json1_1MaintenanceWindowRunCommandParameters(input.RunCommand, context),
    })), (input.StepFunctions !== undefined &&
        input.StepFunctions !== null && {
        StepFunctions: serializeAws_json1_1MaintenanceWindowStepFunctionsParameters(input.StepFunctions, context),
    }));
};
var serializeAws_json1_1MaintenanceWindowTaskParameters = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_1MaintenanceWindowTaskParameterValueExpression(value, context), _b));
    }, {});
};
var serializeAws_json1_1MaintenanceWindowTaskParameterValueExpression = function (input, context) {
    return __assign({}, (input.Values !== undefined &&
        input.Values !== null && {
        Values: serializeAws_json1_1MaintenanceWindowTaskParameterValueList(input.Values, context),
    }));
};
var serializeAws_json1_1MaintenanceWindowTaskParameterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1MetadataKeysToDeleteList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1MetadataMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_1MetadataValue(value, context), _b));
    }, {});
};
var serializeAws_json1_1MetadataValue = function (input, context) {
    return __assign({}, (input.Value !== undefined && input.Value !== null && { Value: input.Value }));
};
var serializeAws_json1_1ModifyDocumentPermissionRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.AccountIdsToAdd !== undefined &&
        input.AccountIdsToAdd !== null && {
        AccountIdsToAdd: serializeAws_json1_1AccountIdList(input.AccountIdsToAdd, context),
    })), (input.AccountIdsToRemove !== undefined &&
        input.AccountIdsToRemove !== null && {
        AccountIdsToRemove: serializeAws_json1_1AccountIdList(input.AccountIdsToRemove, context),
    })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.PermissionType !== undefined &&
        input.PermissionType !== null && { PermissionType: input.PermissionType })), (input.SharedDocumentVersion !== undefined &&
        input.SharedDocumentVersion !== null && { SharedDocumentVersion: input.SharedDocumentVersion }));
};
var serializeAws_json1_1NotificationConfig = function (input, context) {
    return __assign(__assign(__assign({}, (input.NotificationArn !== undefined &&
        input.NotificationArn !== null && { NotificationArn: input.NotificationArn })), (input.NotificationEvents !== undefined &&
        input.NotificationEvents !== null && {
        NotificationEvents: serializeAws_json1_1NotificationEventList(input.NotificationEvents, context),
    })), (input.NotificationType !== undefined &&
        input.NotificationType !== null && { NotificationType: input.NotificationType }));
};
var serializeAws_json1_1NotificationEventList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1OpsAggregator = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.AggregatorType !== undefined &&
        input.AggregatorType !== null && { AggregatorType: input.AggregatorType })), (input.Aggregators !== undefined &&
        input.Aggregators !== null && { Aggregators: serializeAws_json1_1OpsAggregatorList(input.Aggregators, context) })), (input.AttributeName !== undefined && input.AttributeName !== null && { AttributeName: input.AttributeName })), (input.Filters !== undefined &&
        input.Filters !== null && { Filters: serializeAws_json1_1OpsFilterList(input.Filters, context) })), (input.TypeName !== undefined && input.TypeName !== null && { TypeName: input.TypeName })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1OpsAggregatorValueMap(input.Values, context) }));
};
var serializeAws_json1_1OpsAggregatorList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1OpsAggregator(entry, context);
    });
};
var serializeAws_json1_1OpsAggregatorValueMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = value, _b));
    }, {});
};
var serializeAws_json1_1OpsFilter = function (input, context) {
    return __assign(__assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Type !== undefined && input.Type !== null && { Type: input.Type })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1OpsFilterValueList(input.Values, context) }));
};
var serializeAws_json1_1OpsFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1OpsFilter(entry, context);
    });
};
var serializeAws_json1_1OpsFilterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1OpsItemDataValue = function (input, context) {
    return __assign(__assign({}, (input.Type !== undefined && input.Type !== null && { Type: input.Type })), (input.Value !== undefined && input.Value !== null && { Value: input.Value }));
};
var serializeAws_json1_1OpsItemEventFilter = function (input, context) {
    return __assign(__assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Operator !== undefined && input.Operator !== null && { Operator: input.Operator })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1OpsItemEventFilterValues(input.Values, context) }));
};
var serializeAws_json1_1OpsItemEventFilters = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1OpsItemEventFilter(entry, context);
    });
};
var serializeAws_json1_1OpsItemEventFilterValues = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1OpsItemFilter = function (input, context) {
    return __assign(__assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Operator !== undefined && input.Operator !== null && { Operator: input.Operator })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1OpsItemFilterValues(input.Values, context) }));
};
var serializeAws_json1_1OpsItemFilters = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1OpsItemFilter(entry, context);
    });
};
var serializeAws_json1_1OpsItemFilterValues = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1OpsItemNotification = function (input, context) {
    return __assign({}, (input.Arn !== undefined && input.Arn !== null && { Arn: input.Arn }));
};
var serializeAws_json1_1OpsItemNotifications = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1OpsItemNotification(entry, context);
    });
};
var serializeAws_json1_1OpsItemOperationalData = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_1OpsItemDataValue(value, context), _b));
    }, {});
};
var serializeAws_json1_1OpsItemOpsDataKeysList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1OpsItemRelatedItemsFilter = function (input, context) {
    return __assign(__assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Operator !== undefined && input.Operator !== null && { Operator: input.Operator })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1OpsItemRelatedItemsFilterValues(input.Values, context) }));
};
var serializeAws_json1_1OpsItemRelatedItemsFilters = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1OpsItemRelatedItemsFilter(entry, context);
    });
};
var serializeAws_json1_1OpsItemRelatedItemsFilterValues = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1OpsMetadataFilter = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1OpsMetadataFilterValueList(input.Values, context) }));
};
var serializeAws_json1_1OpsMetadataFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1OpsMetadataFilter(entry, context);
    });
};
var serializeAws_json1_1OpsMetadataFilterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1OpsResultAttribute = function (input, context) {
    return __assign({}, (input.TypeName !== undefined && input.TypeName !== null && { TypeName: input.TypeName }));
};
var serializeAws_json1_1OpsResultAttributeList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1OpsResultAttribute(entry, context);
    });
};
var serializeAws_json1_1ParameterLabelList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1ParameterNameList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1Parameters = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_1ParameterValueList(value, context), _b));
    }, {});
};
var serializeAws_json1_1ParametersFilter = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1ParametersFilterValueList(input.Values, context) }));
};
var serializeAws_json1_1ParametersFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1ParametersFilter(entry, context);
    });
};
var serializeAws_json1_1ParametersFilterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1ParameterStringFilter = function (input, context) {
    return __assign(__assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Option !== undefined && input.Option !== null && { Option: input.Option })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1ParameterStringFilterValueList(input.Values, context) }));
};
var serializeAws_json1_1ParameterStringFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1ParameterStringFilter(entry, context);
    });
};
var serializeAws_json1_1ParameterStringFilterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1ParameterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1PatchFilter = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1PatchFilterValueList(input.Values, context) }));
};
var serializeAws_json1_1PatchFilterGroup = function (input, context) {
    return __assign({}, (input.PatchFilters !== undefined &&
        input.PatchFilters !== null && {
        PatchFilters: serializeAws_json1_1PatchFilterList(input.PatchFilters, context),
    }));
};
var serializeAws_json1_1PatchFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1PatchFilter(entry, context);
    });
};
var serializeAws_json1_1PatchFilterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1PatchIdList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1PatchOrchestratorFilter = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1PatchOrchestratorFilterValues(input.Values, context) }));
};
var serializeAws_json1_1PatchOrchestratorFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1PatchOrchestratorFilter(entry, context);
    });
};
var serializeAws_json1_1PatchOrchestratorFilterValues = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1PatchRule = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.ApproveAfterDays !== undefined &&
        input.ApproveAfterDays !== null && { ApproveAfterDays: input.ApproveAfterDays })), (input.ApproveUntilDate !== undefined &&
        input.ApproveUntilDate !== null && { ApproveUntilDate: input.ApproveUntilDate })), (input.ComplianceLevel !== undefined &&
        input.ComplianceLevel !== null && { ComplianceLevel: input.ComplianceLevel })), (input.EnableNonSecurity !== undefined &&
        input.EnableNonSecurity !== null && { EnableNonSecurity: input.EnableNonSecurity })), (input.PatchFilterGroup !== undefined &&
        input.PatchFilterGroup !== null && {
        PatchFilterGroup: serializeAws_json1_1PatchFilterGroup(input.PatchFilterGroup, context),
    }));
};
var serializeAws_json1_1PatchRuleGroup = function (input, context) {
    return __assign({}, (input.PatchRules !== undefined &&
        input.PatchRules !== null && { PatchRules: serializeAws_json1_1PatchRuleList(input.PatchRules, context) }));
};
var serializeAws_json1_1PatchRuleList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1PatchRule(entry, context);
    });
};
var serializeAws_json1_1PatchSource = function (input, context) {
    return __assign(__assign(__assign({}, (input.Configuration !== undefined && input.Configuration !== null && { Configuration: input.Configuration })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.Products !== undefined &&
        input.Products !== null && { Products: serializeAws_json1_1PatchSourceProductList(input.Products, context) }));
};
var serializeAws_json1_1PatchSourceList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1PatchSource(entry, context);
    });
};
var serializeAws_json1_1PatchSourceProductList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1PutComplianceItemsRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ComplianceType !== undefined &&
        input.ComplianceType !== null && { ComplianceType: input.ComplianceType })), (input.ExecutionSummary !== undefined &&
        input.ExecutionSummary !== null && {
        ExecutionSummary: serializeAws_json1_1ComplianceExecutionSummary(input.ExecutionSummary, context),
    })), (input.ItemContentHash !== undefined &&
        input.ItemContentHash !== null && { ItemContentHash: input.ItemContentHash })), (input.Items !== undefined &&
        input.Items !== null && { Items: serializeAws_json1_1ComplianceItemEntryList(input.Items, context) })), (input.ResourceId !== undefined && input.ResourceId !== null && { ResourceId: input.ResourceId })), (input.ResourceType !== undefined && input.ResourceType !== null && { ResourceType: input.ResourceType })), (input.UploadType !== undefined && input.UploadType !== null && { UploadType: input.UploadType }));
};
var serializeAws_json1_1PutInventoryRequest = function (input, context) {
    return __assign(__assign({}, (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.Items !== undefined &&
        input.Items !== null && { Items: serializeAws_json1_1InventoryItemList(input.Items, context) }));
};
var serializeAws_json1_1PutParameterRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AllowedPattern !== undefined &&
        input.AllowedPattern !== null && { AllowedPattern: input.AllowedPattern })), (input.DataType !== undefined && input.DataType !== null && { DataType: input.DataType })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.KeyId !== undefined && input.KeyId !== null && { KeyId: input.KeyId })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.Overwrite !== undefined && input.Overwrite !== null && { Overwrite: input.Overwrite })), (input.Policies !== undefined && input.Policies !== null && { Policies: input.Policies })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_1TagList(input.Tags, context) })), (input.Tier !== undefined && input.Tier !== null && { Tier: input.Tier })), (input.Type !== undefined && input.Type !== null && { Type: input.Type })), (input.Value !== undefined && input.Value !== null && { Value: input.Value }));
};
var serializeAws_json1_1Regions = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1RegisterDefaultPatchBaselineRequest = function (input, context) {
    return __assign({}, (input.BaselineId !== undefined && input.BaselineId !== null && { BaselineId: input.BaselineId }));
};
var serializeAws_json1_1RegisterPatchBaselineForPatchGroupRequest = function (input, context) {
    return __assign(__assign({}, (input.BaselineId !== undefined && input.BaselineId !== null && { BaselineId: input.BaselineId })), (input.PatchGroup !== undefined && input.PatchGroup !== null && { PatchGroup: input.PatchGroup }));
};
var serializeAws_json1_1RegisterTargetWithMaintenanceWindowRequest = function (input, context) {
    var _a;
    return __assign(__assign(__assign(__assign(__assign(__assign({ ClientToken: (_a = input.ClientToken) !== null && _a !== void 0 ? _a : generateIdempotencyToken() }, (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.OwnerInformation !== undefined &&
        input.OwnerInformation !== null && { OwnerInformation: input.OwnerInformation })), (input.ResourceType !== undefined && input.ResourceType !== null && { ResourceType: input.ResourceType })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) })), (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId }));
};
var serializeAws_json1_1RegisterTaskWithMaintenanceWindowRequest = function (input, context) {
    var _a;
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ ClientToken: (_a = input.ClientToken) !== null && _a !== void 0 ? _a : generateIdempotencyToken() }, (input.CutoffBehavior !== undefined &&
        input.CutoffBehavior !== null && { CutoffBehavior: input.CutoffBehavior })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.LoggingInfo !== undefined &&
        input.LoggingInfo !== null && { LoggingInfo: serializeAws_json1_1LoggingInfo(input.LoggingInfo, context) })), (input.MaxConcurrency !== undefined &&
        input.MaxConcurrency !== null && { MaxConcurrency: input.MaxConcurrency })), (input.MaxErrors !== undefined && input.MaxErrors !== null && { MaxErrors: input.MaxErrors })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.Priority !== undefined && input.Priority !== null && { Priority: input.Priority })), (input.ServiceRoleArn !== undefined &&
        input.ServiceRoleArn !== null && { ServiceRoleArn: input.ServiceRoleArn })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) })), (input.TaskArn !== undefined && input.TaskArn !== null && { TaskArn: input.TaskArn })), (input.TaskInvocationParameters !== undefined &&
        input.TaskInvocationParameters !== null && {
        TaskInvocationParameters: serializeAws_json1_1MaintenanceWindowTaskInvocationParameters(input.TaskInvocationParameters, context),
    })), (input.TaskParameters !== undefined &&
        input.TaskParameters !== null && {
        TaskParameters: serializeAws_json1_1MaintenanceWindowTaskParameters(input.TaskParameters, context),
    })), (input.TaskType !== undefined && input.TaskType !== null && { TaskType: input.TaskType })), (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId }));
};
var serializeAws_json1_1RegistrationMetadataItem = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Value !== undefined && input.Value !== null && { Value: input.Value }));
};
var serializeAws_json1_1RegistrationMetadataList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1RegistrationMetadataItem(entry, context);
    });
};
var serializeAws_json1_1RelatedOpsItem = function (input, context) {
    return __assign({}, (input.OpsItemId !== undefined && input.OpsItemId !== null && { OpsItemId: input.OpsItemId }));
};
var serializeAws_json1_1RelatedOpsItems = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1RelatedOpsItem(entry, context);
    });
};
var serializeAws_json1_1RemoveTagsFromResourceRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.ResourceId !== undefined && input.ResourceId !== null && { ResourceId: input.ResourceId })), (input.ResourceType !== undefined && input.ResourceType !== null && { ResourceType: input.ResourceType })), (input.TagKeys !== undefined &&
        input.TagKeys !== null && { TagKeys: serializeAws_json1_1KeyList(input.TagKeys, context) }));
};
var serializeAws_json1_1ResetServiceSettingRequest = function (input, context) {
    return __assign({}, (input.SettingId !== undefined && input.SettingId !== null && { SettingId: input.SettingId }));
};
var serializeAws_json1_1ResourceDataSyncAwsOrganizationsSource = function (input, context) {
    return __assign(__assign({}, (input.OrganizationSourceType !== undefined &&
        input.OrganizationSourceType !== null && { OrganizationSourceType: input.OrganizationSourceType })), (input.OrganizationalUnits !== undefined &&
        input.OrganizationalUnits !== null && {
        OrganizationalUnits: serializeAws_json1_1ResourceDataSyncOrganizationalUnitList(input.OrganizationalUnits, context),
    }));
};
var serializeAws_json1_1ResourceDataSyncDestinationDataSharing = function (input, context) {
    return __assign({}, (input.DestinationDataSharingType !== undefined &&
        input.DestinationDataSharingType !== null && { DestinationDataSharingType: input.DestinationDataSharingType }));
};
var serializeAws_json1_1ResourceDataSyncOrganizationalUnit = function (input, context) {
    return __assign({}, (input.OrganizationalUnitId !== undefined &&
        input.OrganizationalUnitId !== null && { OrganizationalUnitId: input.OrganizationalUnitId }));
};
var serializeAws_json1_1ResourceDataSyncOrganizationalUnitList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1ResourceDataSyncOrganizationalUnit(entry, context);
    });
};
var serializeAws_json1_1ResourceDataSyncS3Destination = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign({}, (input.AWSKMSKeyARN !== undefined && input.AWSKMSKeyARN !== null && { AWSKMSKeyARN: input.AWSKMSKeyARN })), (input.BucketName !== undefined && input.BucketName !== null && { BucketName: input.BucketName })), (input.DestinationDataSharing !== undefined &&
        input.DestinationDataSharing !== null && {
        DestinationDataSharing: serializeAws_json1_1ResourceDataSyncDestinationDataSharing(input.DestinationDataSharing, context),
    })), (input.Prefix !== undefined && input.Prefix !== null && { Prefix: input.Prefix })), (input.Region !== undefined && input.Region !== null && { Region: input.Region })), (input.SyncFormat !== undefined && input.SyncFormat !== null && { SyncFormat: input.SyncFormat }));
};
var serializeAws_json1_1ResourceDataSyncSource = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.AwsOrganizationsSource !== undefined &&
        input.AwsOrganizationsSource !== null && {
        AwsOrganizationsSource: serializeAws_json1_1ResourceDataSyncAwsOrganizationsSource(input.AwsOrganizationsSource, context),
    })), (input.EnableAllOpsDataSources !== undefined &&
        input.EnableAllOpsDataSources !== null && { EnableAllOpsDataSources: input.EnableAllOpsDataSources })), (input.IncludeFutureRegions !== undefined &&
        input.IncludeFutureRegions !== null && { IncludeFutureRegions: input.IncludeFutureRegions })), (input.SourceRegions !== undefined &&
        input.SourceRegions !== null && {
        SourceRegions: serializeAws_json1_1ResourceDataSyncSourceRegionList(input.SourceRegions, context),
    })), (input.SourceType !== undefined && input.SourceType !== null && { SourceType: input.SourceType }));
};
var serializeAws_json1_1ResourceDataSyncSourceRegionList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1ResultAttribute = function (input, context) {
    return __assign({}, (input.TypeName !== undefined && input.TypeName !== null && { TypeName: input.TypeName }));
};
var serializeAws_json1_1ResultAttributeList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1ResultAttribute(entry, context);
    });
};
var serializeAws_json1_1ResumeSessionRequest = function (input, context) {
    return __assign({}, (input.SessionId !== undefined && input.SessionId !== null && { SessionId: input.SessionId }));
};
var serializeAws_json1_1Runbook = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.DocumentName !== undefined && input.DocumentName !== null && { DocumentName: input.DocumentName })), (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.MaxConcurrency !== undefined &&
        input.MaxConcurrency !== null && { MaxConcurrency: input.MaxConcurrency })), (input.MaxErrors !== undefined && input.MaxErrors !== null && { MaxErrors: input.MaxErrors })), (input.Parameters !== undefined &&
        input.Parameters !== null && {
        Parameters: serializeAws_json1_1AutomationParameterMap(input.Parameters, context),
    })), (input.TargetLocations !== undefined &&
        input.TargetLocations !== null && {
        TargetLocations: serializeAws_json1_1TargetLocations(input.TargetLocations, context),
    })), (input.TargetParameterName !== undefined &&
        input.TargetParameterName !== null && { TargetParameterName: input.TargetParameterName })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) }));
};
var serializeAws_json1_1Runbooks = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1Runbook(entry, context);
    });
};
var serializeAws_json1_1S3OutputLocation = function (input, context) {
    return __assign(__assign(__assign({}, (input.OutputS3BucketName !== undefined &&
        input.OutputS3BucketName !== null && { OutputS3BucketName: input.OutputS3BucketName })), (input.OutputS3KeyPrefix !== undefined &&
        input.OutputS3KeyPrefix !== null && { OutputS3KeyPrefix: input.OutputS3KeyPrefix })), (input.OutputS3Region !== undefined &&
        input.OutputS3Region !== null && { OutputS3Region: input.OutputS3Region }));
};
var serializeAws_json1_1SendAutomationSignalRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.AutomationExecutionId !== undefined &&
        input.AutomationExecutionId !== null && { AutomationExecutionId: input.AutomationExecutionId })), (input.Payload !== undefined &&
        input.Payload !== null && { Payload: serializeAws_json1_1AutomationParameterMap(input.Payload, context) })), (input.SignalType !== undefined && input.SignalType !== null && { SignalType: input.SignalType }));
};
var serializeAws_json1_1SendCommandRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.CloudWatchOutputConfig !== undefined &&
        input.CloudWatchOutputConfig !== null && {
        CloudWatchOutputConfig: serializeAws_json1_1CloudWatchOutputConfig(input.CloudWatchOutputConfig, context),
    })), (input.Comment !== undefined && input.Comment !== null && { Comment: input.Comment })), (input.DocumentHash !== undefined && input.DocumentHash !== null && { DocumentHash: input.DocumentHash })), (input.DocumentHashType !== undefined &&
        input.DocumentHashType !== null && { DocumentHashType: input.DocumentHashType })), (input.DocumentName !== undefined && input.DocumentName !== null && { DocumentName: input.DocumentName })), (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.InstanceIds !== undefined &&
        input.InstanceIds !== null && { InstanceIds: serializeAws_json1_1InstanceIdList(input.InstanceIds, context) })), (input.MaxConcurrency !== undefined &&
        input.MaxConcurrency !== null && { MaxConcurrency: input.MaxConcurrency })), (input.MaxErrors !== undefined && input.MaxErrors !== null && { MaxErrors: input.MaxErrors })), (input.NotificationConfig !== undefined &&
        input.NotificationConfig !== null && {
        NotificationConfig: serializeAws_json1_1NotificationConfig(input.NotificationConfig, context),
    })), (input.OutputS3BucketName !== undefined &&
        input.OutputS3BucketName !== null && { OutputS3BucketName: input.OutputS3BucketName })), (input.OutputS3KeyPrefix !== undefined &&
        input.OutputS3KeyPrefix !== null && { OutputS3KeyPrefix: input.OutputS3KeyPrefix })), (input.OutputS3Region !== undefined &&
        input.OutputS3Region !== null && { OutputS3Region: input.OutputS3Region })), (input.Parameters !== undefined &&
        input.Parameters !== null && { Parameters: serializeAws_json1_1Parameters(input.Parameters, context) })), (input.ServiceRoleArn !== undefined &&
        input.ServiceRoleArn !== null && { ServiceRoleArn: input.ServiceRoleArn })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) })), (input.TimeoutSeconds !== undefined &&
        input.TimeoutSeconds !== null && { TimeoutSeconds: input.TimeoutSeconds }));
};
var serializeAws_json1_1SessionFilter = function (input, context) {
    return __assign(__assign({}, (input.key !== undefined && input.key !== null && { key: input.key })), (input.value !== undefined && input.value !== null && { value: input.value }));
};
var serializeAws_json1_1SessionFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1SessionFilter(entry, context);
    });
};
var serializeAws_json1_1SessionManagerParameters = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_1SessionManagerParameterValueList(value, context), _b));
    }, {});
};
var serializeAws_json1_1SessionManagerParameterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1StartAssociationsOnceRequest = function (input, context) {
    return __assign({}, (input.AssociationIds !== undefined &&
        input.AssociationIds !== null && {
        AssociationIds: serializeAws_json1_1AssociationIdList(input.AssociationIds, context),
    }));
};
var serializeAws_json1_1StartAutomationExecutionRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ClientToken !== undefined && input.ClientToken !== null && { ClientToken: input.ClientToken })), (input.DocumentName !== undefined && input.DocumentName !== null && { DocumentName: input.DocumentName })), (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.MaxConcurrency !== undefined &&
        input.MaxConcurrency !== null && { MaxConcurrency: input.MaxConcurrency })), (input.MaxErrors !== undefined && input.MaxErrors !== null && { MaxErrors: input.MaxErrors })), (input.Mode !== undefined && input.Mode !== null && { Mode: input.Mode })), (input.Parameters !== undefined &&
        input.Parameters !== null && {
        Parameters: serializeAws_json1_1AutomationParameterMap(input.Parameters, context),
    })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_1TagList(input.Tags, context) })), (input.TargetLocations !== undefined &&
        input.TargetLocations !== null && {
        TargetLocations: serializeAws_json1_1TargetLocations(input.TargetLocations, context),
    })), (input.TargetMaps !== undefined &&
        input.TargetMaps !== null && { TargetMaps: serializeAws_json1_1TargetMaps(input.TargetMaps, context) })), (input.TargetParameterName !== undefined &&
        input.TargetParameterName !== null && { TargetParameterName: input.TargetParameterName })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) }));
};
var serializeAws_json1_1StartChangeRequestExecutionRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AutoApprove !== undefined && input.AutoApprove !== null && { AutoApprove: input.AutoApprove })), (input.ChangeDetails !== undefined && input.ChangeDetails !== null && { ChangeDetails: input.ChangeDetails })), (input.ChangeRequestName !== undefined &&
        input.ChangeRequestName !== null && { ChangeRequestName: input.ChangeRequestName })), (input.ClientToken !== undefined && input.ClientToken !== null && { ClientToken: input.ClientToken })), (input.DocumentName !== undefined && input.DocumentName !== null && { DocumentName: input.DocumentName })), (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.Parameters !== undefined &&
        input.Parameters !== null && {
        Parameters: serializeAws_json1_1AutomationParameterMap(input.Parameters, context),
    })), (input.Runbooks !== undefined &&
        input.Runbooks !== null && { Runbooks: serializeAws_json1_1Runbooks(input.Runbooks, context) })), (input.ScheduledEndTime !== undefined &&
        input.ScheduledEndTime !== null && { ScheduledEndTime: Math.round(input.ScheduledEndTime.getTime() / 1000) })), (input.ScheduledTime !== undefined &&
        input.ScheduledTime !== null && { ScheduledTime: Math.round(input.ScheduledTime.getTime() / 1000) })), (input.Tags !== undefined && input.Tags !== null && { Tags: serializeAws_json1_1TagList(input.Tags, context) }));
};
var serializeAws_json1_1StartSessionRequest = function (input, context) {
    return __assign(__assign(__assign(__assign({}, (input.DocumentName !== undefined && input.DocumentName !== null && { DocumentName: input.DocumentName })), (input.Parameters !== undefined &&
        input.Parameters !== null && {
        Parameters: serializeAws_json1_1SessionManagerParameters(input.Parameters, context),
    })), (input.Reason !== undefined && input.Reason !== null && { Reason: input.Reason })), (input.Target !== undefined && input.Target !== null && { Target: input.Target }));
};
var serializeAws_json1_1StepExecutionFilter = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1StepExecutionFilterValueList(input.Values, context) }));
};
var serializeAws_json1_1StepExecutionFilterList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1StepExecutionFilter(entry, context);
    });
};
var serializeAws_json1_1StepExecutionFilterValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1StopAutomationExecutionRequest = function (input, context) {
    return __assign(__assign({}, (input.AutomationExecutionId !== undefined &&
        input.AutomationExecutionId !== null && { AutomationExecutionId: input.AutomationExecutionId })), (input.Type !== undefined && input.Type !== null && { Type: input.Type }));
};
var serializeAws_json1_1StringList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1Tag = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Value !== undefined && input.Value !== null && { Value: input.Value }));
};
var serializeAws_json1_1TagList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1Tag(entry, context);
    });
};
var serializeAws_json1_1Target = function (input, context) {
    return __assign(__assign({}, (input.Key !== undefined && input.Key !== null && { Key: input.Key })), (input.Values !== undefined &&
        input.Values !== null && { Values: serializeAws_json1_1TargetValues(input.Values, context) }));
};
var serializeAws_json1_1TargetLocation = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign({}, (input.Accounts !== undefined &&
        input.Accounts !== null && { Accounts: serializeAws_json1_1Accounts(input.Accounts, context) })), (input.ExecutionRoleName !== undefined &&
        input.ExecutionRoleName !== null && { ExecutionRoleName: input.ExecutionRoleName })), (input.Regions !== undefined &&
        input.Regions !== null && { Regions: serializeAws_json1_1Regions(input.Regions, context) })), (input.TargetLocationMaxConcurrency !== undefined &&
        input.TargetLocationMaxConcurrency !== null && {
        TargetLocationMaxConcurrency: input.TargetLocationMaxConcurrency,
    })), (input.TargetLocationMaxErrors !== undefined &&
        input.TargetLocationMaxErrors !== null && { TargetLocationMaxErrors: input.TargetLocationMaxErrors }));
};
var serializeAws_json1_1TargetLocations = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1TargetLocation(entry, context);
    });
};
var serializeAws_json1_1TargetMap = function (input, context) {
    return Object.entries(input).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = serializeAws_json1_1TargetMapValueList(value, context), _b));
    }, {});
};
var serializeAws_json1_1TargetMaps = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1TargetMap(entry, context);
    });
};
var serializeAws_json1_1TargetMapValueList = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1Targets = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return serializeAws_json1_1Target(entry, context);
    });
};
var serializeAws_json1_1TargetValues = function (input, context) {
    return input
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return entry;
    });
};
var serializeAws_json1_1TerminateSessionRequest = function (input, context) {
    return __assign({}, (input.SessionId !== undefined && input.SessionId !== null && { SessionId: input.SessionId }));
};
var serializeAws_json1_1UnlabelParameterVersionRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.Labels !== undefined &&
        input.Labels !== null && { Labels: serializeAws_json1_1ParameterLabelList(input.Labels, context) })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.ParameterVersion !== undefined &&
        input.ParameterVersion !== null && { ParameterVersion: input.ParameterVersion }));
};
var serializeAws_json1_1UpdateAssociationRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ApplyOnlyAtCronInterval !== undefined &&
        input.ApplyOnlyAtCronInterval !== null && { ApplyOnlyAtCronInterval: input.ApplyOnlyAtCronInterval })), (input.AssociationId !== undefined && input.AssociationId !== null && { AssociationId: input.AssociationId })), (input.AssociationName !== undefined &&
        input.AssociationName !== null && { AssociationName: input.AssociationName })), (input.AssociationVersion !== undefined &&
        input.AssociationVersion !== null && { AssociationVersion: input.AssociationVersion })), (input.AutomationTargetParameterName !== undefined &&
        input.AutomationTargetParameterName !== null && {
        AutomationTargetParameterName: input.AutomationTargetParameterName,
    })), (input.CalendarNames !== undefined &&
        input.CalendarNames !== null && {
        CalendarNames: serializeAws_json1_1CalendarNameOrARNList(input.CalendarNames, context),
    })), (input.ComplianceSeverity !== undefined &&
        input.ComplianceSeverity !== null && { ComplianceSeverity: input.ComplianceSeverity })), (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.MaxConcurrency !== undefined &&
        input.MaxConcurrency !== null && { MaxConcurrency: input.MaxConcurrency })), (input.MaxErrors !== undefined && input.MaxErrors !== null && { MaxErrors: input.MaxErrors })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.OutputLocation !== undefined &&
        input.OutputLocation !== null && {
        OutputLocation: serializeAws_json1_1InstanceAssociationOutputLocation(input.OutputLocation, context),
    })), (input.Parameters !== undefined &&
        input.Parameters !== null && { Parameters: serializeAws_json1_1Parameters(input.Parameters, context) })), (input.ScheduleExpression !== undefined &&
        input.ScheduleExpression !== null && { ScheduleExpression: input.ScheduleExpression })), (input.SyncCompliance !== undefined &&
        input.SyncCompliance !== null && { SyncCompliance: input.SyncCompliance })), (input.TargetLocations !== undefined &&
        input.TargetLocations !== null && {
        TargetLocations: serializeAws_json1_1TargetLocations(input.TargetLocations, context),
    })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) }));
};
var serializeAws_json1_1UpdateAssociationStatusRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.AssociationStatus !== undefined &&
        input.AssociationStatus !== null && {
        AssociationStatus: serializeAws_json1_1AssociationStatus(input.AssociationStatus, context),
    })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId })), (input.Name !== undefined && input.Name !== null && { Name: input.Name }));
};
var serializeAws_json1_1UpdateDocumentDefaultVersionRequest = function (input, context) {
    return __assign(__assign({}, (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.Name !== undefined && input.Name !== null && { Name: input.Name }));
};
var serializeAws_json1_1UpdateDocumentMetadataRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.DocumentReviews !== undefined &&
        input.DocumentReviews !== null && {
        DocumentReviews: serializeAws_json1_1DocumentReviews(input.DocumentReviews, context),
    })), (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.Name !== undefined && input.Name !== null && { Name: input.Name }));
};
var serializeAws_json1_1UpdateDocumentRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Attachments !== undefined &&
        input.Attachments !== null && {
        Attachments: serializeAws_json1_1AttachmentsSourceList(input.Attachments, context),
    })), (input.Content !== undefined && input.Content !== null && { Content: input.Content })), (input.DisplayName !== undefined && input.DisplayName !== null && { DisplayName: input.DisplayName })), (input.DocumentFormat !== undefined &&
        input.DocumentFormat !== null && { DocumentFormat: input.DocumentFormat })), (input.DocumentVersion !== undefined &&
        input.DocumentVersion !== null && { DocumentVersion: input.DocumentVersion })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.TargetType !== undefined && input.TargetType !== null && { TargetType: input.TargetType })), (input.VersionName !== undefined && input.VersionName !== null && { VersionName: input.VersionName }));
};
var serializeAws_json1_1UpdateMaintenanceWindowRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.AllowUnassociatedTargets !== undefined &&
        input.AllowUnassociatedTargets !== null && { AllowUnassociatedTargets: input.AllowUnassociatedTargets })), (input.Cutoff !== undefined && input.Cutoff !== null && { Cutoff: input.Cutoff })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.Duration !== undefined && input.Duration !== null && { Duration: input.Duration })), (input.Enabled !== undefined && input.Enabled !== null && { Enabled: input.Enabled })), (input.EndDate !== undefined && input.EndDate !== null && { EndDate: input.EndDate })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.Replace !== undefined && input.Replace !== null && { Replace: input.Replace })), (input.Schedule !== undefined && input.Schedule !== null && { Schedule: input.Schedule })), (input.ScheduleOffset !== undefined &&
        input.ScheduleOffset !== null && { ScheduleOffset: input.ScheduleOffset })), (input.ScheduleTimezone !== undefined &&
        input.ScheduleTimezone !== null && { ScheduleTimezone: input.ScheduleTimezone })), (input.StartDate !== undefined && input.StartDate !== null && { StartDate: input.StartDate })), (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId }));
};
var serializeAws_json1_1UpdateMaintenanceWindowTargetRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.OwnerInformation !== undefined &&
        input.OwnerInformation !== null && { OwnerInformation: input.OwnerInformation })), (input.Replace !== undefined && input.Replace !== null && { Replace: input.Replace })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) })), (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId })), (input.WindowTargetId !== undefined &&
        input.WindowTargetId !== null && { WindowTargetId: input.WindowTargetId }));
};
var serializeAws_json1_1UpdateMaintenanceWindowTaskRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.CutoffBehavior !== undefined &&
        input.CutoffBehavior !== null && { CutoffBehavior: input.CutoffBehavior })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.LoggingInfo !== undefined &&
        input.LoggingInfo !== null && { LoggingInfo: serializeAws_json1_1LoggingInfo(input.LoggingInfo, context) })), (input.MaxConcurrency !== undefined &&
        input.MaxConcurrency !== null && { MaxConcurrency: input.MaxConcurrency })), (input.MaxErrors !== undefined && input.MaxErrors !== null && { MaxErrors: input.MaxErrors })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.Priority !== undefined && input.Priority !== null && { Priority: input.Priority })), (input.Replace !== undefined && input.Replace !== null && { Replace: input.Replace })), (input.ServiceRoleArn !== undefined &&
        input.ServiceRoleArn !== null && { ServiceRoleArn: input.ServiceRoleArn })), (input.Targets !== undefined &&
        input.Targets !== null && { Targets: serializeAws_json1_1Targets(input.Targets, context) })), (input.TaskArn !== undefined && input.TaskArn !== null && { TaskArn: input.TaskArn })), (input.TaskInvocationParameters !== undefined &&
        input.TaskInvocationParameters !== null && {
        TaskInvocationParameters: serializeAws_json1_1MaintenanceWindowTaskInvocationParameters(input.TaskInvocationParameters, context),
    })), (input.TaskParameters !== undefined &&
        input.TaskParameters !== null && {
        TaskParameters: serializeAws_json1_1MaintenanceWindowTaskParameters(input.TaskParameters, context),
    })), (input.WindowId !== undefined && input.WindowId !== null && { WindowId: input.WindowId })), (input.WindowTaskId !== undefined && input.WindowTaskId !== null && { WindowTaskId: input.WindowTaskId }));
};
var serializeAws_json1_1UpdateManagedInstanceRoleRequest = function (input, context) {
    return __assign(__assign({}, (input.IamRole !== undefined && input.IamRole !== null && { IamRole: input.IamRole })), (input.InstanceId !== undefined && input.InstanceId !== null && { InstanceId: input.InstanceId }));
};
var serializeAws_json1_1UpdateOpsItemRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ActualEndTime !== undefined &&
        input.ActualEndTime !== null && { ActualEndTime: Math.round(input.ActualEndTime.getTime() / 1000) })), (input.ActualStartTime !== undefined &&
        input.ActualStartTime !== null && { ActualStartTime: Math.round(input.ActualStartTime.getTime() / 1000) })), (input.Category !== undefined && input.Category !== null && { Category: input.Category })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.Notifications !== undefined &&
        input.Notifications !== null && {
        Notifications: serializeAws_json1_1OpsItemNotifications(input.Notifications, context),
    })), (input.OperationalData !== undefined &&
        input.OperationalData !== null && {
        OperationalData: serializeAws_json1_1OpsItemOperationalData(input.OperationalData, context),
    })), (input.OperationalDataToDelete !== undefined &&
        input.OperationalDataToDelete !== null && {
        OperationalDataToDelete: serializeAws_json1_1OpsItemOpsDataKeysList(input.OperationalDataToDelete, context),
    })), (input.OpsItemId !== undefined && input.OpsItemId !== null && { OpsItemId: input.OpsItemId })), (input.PlannedEndTime !== undefined &&
        input.PlannedEndTime !== null && { PlannedEndTime: Math.round(input.PlannedEndTime.getTime() / 1000) })), (input.PlannedStartTime !== undefined &&
        input.PlannedStartTime !== null && { PlannedStartTime: Math.round(input.PlannedStartTime.getTime() / 1000) })), (input.Priority !== undefined && input.Priority !== null && { Priority: input.Priority })), (input.RelatedOpsItems !== undefined &&
        input.RelatedOpsItems !== null && {
        RelatedOpsItems: serializeAws_json1_1RelatedOpsItems(input.RelatedOpsItems, context),
    })), (input.Severity !== undefined && input.Severity !== null && { Severity: input.Severity })), (input.Status !== undefined && input.Status !== null && { Status: input.Status })), (input.Title !== undefined && input.Title !== null && { Title: input.Title }));
};
var serializeAws_json1_1UpdateOpsMetadataRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.KeysToDelete !== undefined &&
        input.KeysToDelete !== null && {
        KeysToDelete: serializeAws_json1_1MetadataKeysToDeleteList(input.KeysToDelete, context),
    })), (input.MetadataToUpdate !== undefined &&
        input.MetadataToUpdate !== null && {
        MetadataToUpdate: serializeAws_json1_1MetadataMap(input.MetadataToUpdate, context),
    })), (input.OpsMetadataArn !== undefined &&
        input.OpsMetadataArn !== null && { OpsMetadataArn: input.OpsMetadataArn }));
};
var serializeAws_json1_1UpdatePatchBaselineRequest = function (input, context) {
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (input.ApprovalRules !== undefined &&
        input.ApprovalRules !== null && {
        ApprovalRules: serializeAws_json1_1PatchRuleGroup(input.ApprovalRules, context),
    })), (input.ApprovedPatches !== undefined &&
        input.ApprovedPatches !== null && {
        ApprovedPatches: serializeAws_json1_1PatchIdList(input.ApprovedPatches, context),
    })), (input.ApprovedPatchesComplianceLevel !== undefined &&
        input.ApprovedPatchesComplianceLevel !== null && {
        ApprovedPatchesComplianceLevel: input.ApprovedPatchesComplianceLevel,
    })), (input.ApprovedPatchesEnableNonSecurity !== undefined &&
        input.ApprovedPatchesEnableNonSecurity !== null && {
        ApprovedPatchesEnableNonSecurity: input.ApprovedPatchesEnableNonSecurity,
    })), (input.BaselineId !== undefined && input.BaselineId !== null && { BaselineId: input.BaselineId })), (input.Description !== undefined && input.Description !== null && { Description: input.Description })), (input.GlobalFilters !== undefined &&
        input.GlobalFilters !== null && {
        GlobalFilters: serializeAws_json1_1PatchFilterGroup(input.GlobalFilters, context),
    })), (input.Name !== undefined && input.Name !== null && { Name: input.Name })), (input.RejectedPatches !== undefined &&
        input.RejectedPatches !== null && {
        RejectedPatches: serializeAws_json1_1PatchIdList(input.RejectedPatches, context),
    })), (input.RejectedPatchesAction !== undefined &&
        input.RejectedPatchesAction !== null && { RejectedPatchesAction: input.RejectedPatchesAction })), (input.Replace !== undefined && input.Replace !== null && { Replace: input.Replace })), (input.Sources !== undefined &&
        input.Sources !== null && { Sources: serializeAws_json1_1PatchSourceList(input.Sources, context) }));
};
var serializeAws_json1_1UpdateResourceDataSyncRequest = function (input, context) {
    return __assign(__assign(__assign({}, (input.SyncName !== undefined && input.SyncName !== null && { SyncName: input.SyncName })), (input.SyncSource !== undefined &&
        input.SyncSource !== null && {
        SyncSource: serializeAws_json1_1ResourceDataSyncSource(input.SyncSource, context),
    })), (input.SyncType !== undefined && input.SyncType !== null && { SyncType: input.SyncType }));
};
var serializeAws_json1_1UpdateServiceSettingRequest = function (input, context) {
    return __assign(__assign({}, (input.SettingId !== undefined && input.SettingId !== null && { SettingId: input.SettingId })), (input.SettingValue !== undefined && input.SettingValue !== null && { SettingValue: input.SettingValue }));
};
var deserializeAws_json1_1AccountIdList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1Accounts = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1AccountSharingInfo = function (output, context) {
    return {
        AccountId: __expectString(output.AccountId),
        SharedDocumentVersion: __expectString(output.SharedDocumentVersion),
    };
};
var deserializeAws_json1_1AccountSharingInfoList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1AccountSharingInfo(entry, context);
    });
};
var deserializeAws_json1_1Activation = function (output, context) {
    return {
        ActivationId: __expectString(output.ActivationId),
        CreatedDate: output.CreatedDate !== undefined && output.CreatedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedDate)))
            : undefined,
        DefaultInstanceName: __expectString(output.DefaultInstanceName),
        Description: __expectString(output.Description),
        ExpirationDate: output.ExpirationDate !== undefined && output.ExpirationDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ExpirationDate)))
            : undefined,
        Expired: __expectBoolean(output.Expired),
        IamRole: __expectString(output.IamRole),
        RegistrationLimit: __expectInt32(output.RegistrationLimit),
        RegistrationsCount: __expectInt32(output.RegistrationsCount),
        Tags: output.Tags !== undefined && output.Tags !== null
            ? deserializeAws_json1_1TagList(output.Tags, context)
            : undefined,
    };
};
var deserializeAws_json1_1ActivationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1Activation(entry, context);
    });
};
var deserializeAws_json1_1AddTagsToResourceResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1AlreadyExistsException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1AssociatedInstances = function (output, context) {
    return {};
};
var deserializeAws_json1_1AssociateOpsItemRelatedItemResponse = function (output, context) {
    return {
        AssociationId: __expectString(output.AssociationId),
    };
};
var deserializeAws_json1_1Association = function (output, context) {
    return {
        AssociationId: __expectString(output.AssociationId),
        AssociationName: __expectString(output.AssociationName),
        AssociationVersion: __expectString(output.AssociationVersion),
        DocumentVersion: __expectString(output.DocumentVersion),
        InstanceId: __expectString(output.InstanceId),
        LastExecutionDate: output.LastExecutionDate !== undefined && output.LastExecutionDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastExecutionDate)))
            : undefined,
        Name: __expectString(output.Name),
        Overview: output.Overview !== undefined && output.Overview !== null
            ? deserializeAws_json1_1AssociationOverview(output.Overview, context)
            : undefined,
        ScheduleExpression: __expectString(output.ScheduleExpression),
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
    };
};
var deserializeAws_json1_1AssociationAlreadyExists = function (output, context) {
    return {};
};
var deserializeAws_json1_1AssociationDescription = function (output, context) {
    return {
        ApplyOnlyAtCronInterval: __expectBoolean(output.ApplyOnlyAtCronInterval),
        AssociationId: __expectString(output.AssociationId),
        AssociationName: __expectString(output.AssociationName),
        AssociationVersion: __expectString(output.AssociationVersion),
        AutomationTargetParameterName: __expectString(output.AutomationTargetParameterName),
        CalendarNames: output.CalendarNames !== undefined && output.CalendarNames !== null
            ? deserializeAws_json1_1CalendarNameOrARNList(output.CalendarNames, context)
            : undefined,
        ComplianceSeverity: __expectString(output.ComplianceSeverity),
        Date: output.Date !== undefined && output.Date !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.Date)))
            : undefined,
        DocumentVersion: __expectString(output.DocumentVersion),
        InstanceId: __expectString(output.InstanceId),
        LastExecutionDate: output.LastExecutionDate !== undefined && output.LastExecutionDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastExecutionDate)))
            : undefined,
        LastSuccessfulExecutionDate: output.LastSuccessfulExecutionDate !== undefined && output.LastSuccessfulExecutionDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastSuccessfulExecutionDate)))
            : undefined,
        LastUpdateAssociationDate: output.LastUpdateAssociationDate !== undefined && output.LastUpdateAssociationDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastUpdateAssociationDate)))
            : undefined,
        MaxConcurrency: __expectString(output.MaxConcurrency),
        MaxErrors: __expectString(output.MaxErrors),
        Name: __expectString(output.Name),
        OutputLocation: output.OutputLocation !== undefined && output.OutputLocation !== null
            ? deserializeAws_json1_1InstanceAssociationOutputLocation(output.OutputLocation, context)
            : undefined,
        Overview: output.Overview !== undefined && output.Overview !== null
            ? deserializeAws_json1_1AssociationOverview(output.Overview, context)
            : undefined,
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1Parameters(output.Parameters, context)
            : undefined,
        ScheduleExpression: __expectString(output.ScheduleExpression),
        Status: output.Status !== undefined && output.Status !== null
            ? deserializeAws_json1_1AssociationStatus(output.Status, context)
            : undefined,
        SyncCompliance: __expectString(output.SyncCompliance),
        TargetLocations: output.TargetLocations !== undefined && output.TargetLocations !== null
            ? deserializeAws_json1_1TargetLocations(output.TargetLocations, context)
            : undefined,
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
    };
};
var deserializeAws_json1_1AssociationDescriptionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1AssociationDescription(entry, context);
    });
};
var deserializeAws_json1_1AssociationDoesNotExist = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1AssociationExecution = function (output, context) {
    return {
        AssociationId: __expectString(output.AssociationId),
        AssociationVersion: __expectString(output.AssociationVersion),
        CreatedTime: output.CreatedTime !== undefined && output.CreatedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedTime)))
            : undefined,
        DetailedStatus: __expectString(output.DetailedStatus),
        ExecutionId: __expectString(output.ExecutionId),
        LastExecutionDate: output.LastExecutionDate !== undefined && output.LastExecutionDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastExecutionDate)))
            : undefined,
        ResourceCountByStatus: __expectString(output.ResourceCountByStatus),
        Status: __expectString(output.Status),
    };
};
var deserializeAws_json1_1AssociationExecutionDoesNotExist = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1AssociationExecutionsList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1AssociationExecution(entry, context);
    });
};
var deserializeAws_json1_1AssociationExecutionTarget = function (output, context) {
    return {
        AssociationId: __expectString(output.AssociationId),
        AssociationVersion: __expectString(output.AssociationVersion),
        DetailedStatus: __expectString(output.DetailedStatus),
        ExecutionId: __expectString(output.ExecutionId),
        LastExecutionDate: output.LastExecutionDate !== undefined && output.LastExecutionDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastExecutionDate)))
            : undefined,
        OutputSource: output.OutputSource !== undefined && output.OutputSource !== null
            ? deserializeAws_json1_1OutputSource(output.OutputSource, context)
            : undefined,
        ResourceId: __expectString(output.ResourceId),
        ResourceType: __expectString(output.ResourceType),
        Status: __expectString(output.Status),
    };
};
var deserializeAws_json1_1AssociationExecutionTargetsList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1AssociationExecutionTarget(entry, context);
    });
};
var deserializeAws_json1_1AssociationLimitExceeded = function (output, context) {
    return {};
};
var deserializeAws_json1_1AssociationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1Association(entry, context);
    });
};
var deserializeAws_json1_1AssociationOverview = function (output, context) {
    return {
        AssociationStatusAggregatedCount: output.AssociationStatusAggregatedCount !== undefined && output.AssociationStatusAggregatedCount !== null
            ? deserializeAws_json1_1AssociationStatusAggregatedCount(output.AssociationStatusAggregatedCount, context)
            : undefined,
        DetailedStatus: __expectString(output.DetailedStatus),
        Status: __expectString(output.Status),
    };
};
var deserializeAws_json1_1AssociationStatus = function (output, context) {
    return {
        AdditionalInfo: __expectString(output.AdditionalInfo),
        Date: output.Date !== undefined && output.Date !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.Date)))
            : undefined,
        Message: __expectString(output.Message),
        Name: __expectString(output.Name),
    };
};
var deserializeAws_json1_1AssociationStatusAggregatedCount = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectInt32(value), _b));
    }, {});
};
var deserializeAws_json1_1AssociationVersionInfo = function (output, context) {
    return {
        ApplyOnlyAtCronInterval: __expectBoolean(output.ApplyOnlyAtCronInterval),
        AssociationId: __expectString(output.AssociationId),
        AssociationName: __expectString(output.AssociationName),
        AssociationVersion: __expectString(output.AssociationVersion),
        CalendarNames: output.CalendarNames !== undefined && output.CalendarNames !== null
            ? deserializeAws_json1_1CalendarNameOrARNList(output.CalendarNames, context)
            : undefined,
        ComplianceSeverity: __expectString(output.ComplianceSeverity),
        CreatedDate: output.CreatedDate !== undefined && output.CreatedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedDate)))
            : undefined,
        DocumentVersion: __expectString(output.DocumentVersion),
        MaxConcurrency: __expectString(output.MaxConcurrency),
        MaxErrors: __expectString(output.MaxErrors),
        Name: __expectString(output.Name),
        OutputLocation: output.OutputLocation !== undefined && output.OutputLocation !== null
            ? deserializeAws_json1_1InstanceAssociationOutputLocation(output.OutputLocation, context)
            : undefined,
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1Parameters(output.Parameters, context)
            : undefined,
        ScheduleExpression: __expectString(output.ScheduleExpression),
        SyncCompliance: __expectString(output.SyncCompliance),
        TargetLocations: output.TargetLocations !== undefined && output.TargetLocations !== null
            ? deserializeAws_json1_1TargetLocations(output.TargetLocations, context)
            : undefined,
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
    };
};
var deserializeAws_json1_1AssociationVersionLimitExceeded = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1AssociationVersionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1AssociationVersionInfo(entry, context);
    });
};
var deserializeAws_json1_1AttachmentContent = function (output, context) {
    return {
        Hash: __expectString(output.Hash),
        HashType: __expectString(output.HashType),
        Name: __expectString(output.Name),
        Size: __expectLong(output.Size),
        Url: __expectString(output.Url),
    };
};
var deserializeAws_json1_1AttachmentContentList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1AttachmentContent(entry, context);
    });
};
var deserializeAws_json1_1AttachmentInformation = function (output, context) {
    return {
        Name: __expectString(output.Name),
    };
};
var deserializeAws_json1_1AttachmentInformationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1AttachmentInformation(entry, context);
    });
};
var deserializeAws_json1_1AutomationDefinitionNotApprovedException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1AutomationDefinitionNotFoundException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1AutomationDefinitionVersionNotFoundException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1AutomationExecution = function (output, context) {
    return {
        AssociationId: __expectString(output.AssociationId),
        AutomationExecutionId: __expectString(output.AutomationExecutionId),
        AutomationExecutionStatus: __expectString(output.AutomationExecutionStatus),
        AutomationSubtype: __expectString(output.AutomationSubtype),
        ChangeRequestName: __expectString(output.ChangeRequestName),
        CurrentAction: __expectString(output.CurrentAction),
        CurrentStepName: __expectString(output.CurrentStepName),
        DocumentName: __expectString(output.DocumentName),
        DocumentVersion: __expectString(output.DocumentVersion),
        ExecutedBy: __expectString(output.ExecutedBy),
        ExecutionEndTime: output.ExecutionEndTime !== undefined && output.ExecutionEndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ExecutionEndTime)))
            : undefined,
        ExecutionStartTime: output.ExecutionStartTime !== undefined && output.ExecutionStartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ExecutionStartTime)))
            : undefined,
        FailureMessage: __expectString(output.FailureMessage),
        MaxConcurrency: __expectString(output.MaxConcurrency),
        MaxErrors: __expectString(output.MaxErrors),
        Mode: __expectString(output.Mode),
        OpsItemId: __expectString(output.OpsItemId),
        Outputs: output.Outputs !== undefined && output.Outputs !== null
            ? deserializeAws_json1_1AutomationParameterMap(output.Outputs, context)
            : undefined,
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1AutomationParameterMap(output.Parameters, context)
            : undefined,
        ParentAutomationExecutionId: __expectString(output.ParentAutomationExecutionId),
        ProgressCounters: output.ProgressCounters !== undefined && output.ProgressCounters !== null
            ? deserializeAws_json1_1ProgressCounters(output.ProgressCounters, context)
            : undefined,
        ResolvedTargets: output.ResolvedTargets !== undefined && output.ResolvedTargets !== null
            ? deserializeAws_json1_1ResolvedTargets(output.ResolvedTargets, context)
            : undefined,
        Runbooks: output.Runbooks !== undefined && output.Runbooks !== null
            ? deserializeAws_json1_1Runbooks(output.Runbooks, context)
            : undefined,
        ScheduledTime: output.ScheduledTime !== undefined && output.ScheduledTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ScheduledTime)))
            : undefined,
        StepExecutions: output.StepExecutions !== undefined && output.StepExecutions !== null
            ? deserializeAws_json1_1StepExecutionList(output.StepExecutions, context)
            : undefined,
        StepExecutionsTruncated: __expectBoolean(output.StepExecutionsTruncated),
        Target: __expectString(output.Target),
        TargetLocations: output.TargetLocations !== undefined && output.TargetLocations !== null
            ? deserializeAws_json1_1TargetLocations(output.TargetLocations, context)
            : undefined,
        TargetMaps: output.TargetMaps !== undefined && output.TargetMaps !== null
            ? deserializeAws_json1_1TargetMaps(output.TargetMaps, context)
            : undefined,
        TargetParameterName: __expectString(output.TargetParameterName),
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
    };
};
var deserializeAws_json1_1AutomationExecutionLimitExceededException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1AutomationExecutionMetadata = function (output, context) {
    return {
        AssociationId: __expectString(output.AssociationId),
        AutomationExecutionId: __expectString(output.AutomationExecutionId),
        AutomationExecutionStatus: __expectString(output.AutomationExecutionStatus),
        AutomationSubtype: __expectString(output.AutomationSubtype),
        AutomationType: __expectString(output.AutomationType),
        ChangeRequestName: __expectString(output.ChangeRequestName),
        CurrentAction: __expectString(output.CurrentAction),
        CurrentStepName: __expectString(output.CurrentStepName),
        DocumentName: __expectString(output.DocumentName),
        DocumentVersion: __expectString(output.DocumentVersion),
        ExecutedBy: __expectString(output.ExecutedBy),
        ExecutionEndTime: output.ExecutionEndTime !== undefined && output.ExecutionEndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ExecutionEndTime)))
            : undefined,
        ExecutionStartTime: output.ExecutionStartTime !== undefined && output.ExecutionStartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ExecutionStartTime)))
            : undefined,
        FailureMessage: __expectString(output.FailureMessage),
        LogFile: __expectString(output.LogFile),
        MaxConcurrency: __expectString(output.MaxConcurrency),
        MaxErrors: __expectString(output.MaxErrors),
        Mode: __expectString(output.Mode),
        OpsItemId: __expectString(output.OpsItemId),
        Outputs: output.Outputs !== undefined && output.Outputs !== null
            ? deserializeAws_json1_1AutomationParameterMap(output.Outputs, context)
            : undefined,
        ParentAutomationExecutionId: __expectString(output.ParentAutomationExecutionId),
        ResolvedTargets: output.ResolvedTargets !== undefined && output.ResolvedTargets !== null
            ? deserializeAws_json1_1ResolvedTargets(output.ResolvedTargets, context)
            : undefined,
        Runbooks: output.Runbooks !== undefined && output.Runbooks !== null
            ? deserializeAws_json1_1Runbooks(output.Runbooks, context)
            : undefined,
        ScheduledTime: output.ScheduledTime !== undefined && output.ScheduledTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ScheduledTime)))
            : undefined,
        Target: __expectString(output.Target),
        TargetMaps: output.TargetMaps !== undefined && output.TargetMaps !== null
            ? deserializeAws_json1_1TargetMaps(output.TargetMaps, context)
            : undefined,
        TargetParameterName: __expectString(output.TargetParameterName),
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
    };
};
var deserializeAws_json1_1AutomationExecutionMetadataList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1AutomationExecutionMetadata(entry, context);
    });
};
var deserializeAws_json1_1AutomationExecutionNotFoundException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1AutomationParameterMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_1AutomationParameterValueList(value, context), _b));
    }, {});
};
var deserializeAws_json1_1AutomationParameterValueList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1AutomationStepNotFoundException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1CalendarNameOrARNList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1CancelCommandResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1CancelMaintenanceWindowExecutionResult = function (output, context) {
    return {
        WindowExecutionId: __expectString(output.WindowExecutionId),
    };
};
var deserializeAws_json1_1CloudWatchOutputConfig = function (output, context) {
    return {
        CloudWatchLogGroupName: __expectString(output.CloudWatchLogGroupName),
        CloudWatchOutputEnabled: __expectBoolean(output.CloudWatchOutputEnabled),
    };
};
var deserializeAws_json1_1Command = function (output, context) {
    return {
        CloudWatchOutputConfig: output.CloudWatchOutputConfig !== undefined && output.CloudWatchOutputConfig !== null
            ? deserializeAws_json1_1CloudWatchOutputConfig(output.CloudWatchOutputConfig, context)
            : undefined,
        CommandId: __expectString(output.CommandId),
        Comment: __expectString(output.Comment),
        CompletedCount: __expectInt32(output.CompletedCount),
        DeliveryTimedOutCount: __expectInt32(output.DeliveryTimedOutCount),
        DocumentName: __expectString(output.DocumentName),
        DocumentVersion: __expectString(output.DocumentVersion),
        ErrorCount: __expectInt32(output.ErrorCount),
        ExpiresAfter: output.ExpiresAfter !== undefined && output.ExpiresAfter !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ExpiresAfter)))
            : undefined,
        InstanceIds: output.InstanceIds !== undefined && output.InstanceIds !== null
            ? deserializeAws_json1_1InstanceIdList(output.InstanceIds, context)
            : undefined,
        MaxConcurrency: __expectString(output.MaxConcurrency),
        MaxErrors: __expectString(output.MaxErrors),
        NotificationConfig: output.NotificationConfig !== undefined && output.NotificationConfig !== null
            ? deserializeAws_json1_1NotificationConfig(output.NotificationConfig, context)
            : undefined,
        OutputS3BucketName: __expectString(output.OutputS3BucketName),
        OutputS3KeyPrefix: __expectString(output.OutputS3KeyPrefix),
        OutputS3Region: __expectString(output.OutputS3Region),
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1Parameters(output.Parameters, context)
            : undefined,
        RequestedDateTime: output.RequestedDateTime !== undefined && output.RequestedDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.RequestedDateTime)))
            : undefined,
        ServiceRole: __expectString(output.ServiceRole),
        Status: __expectString(output.Status),
        StatusDetails: __expectString(output.StatusDetails),
        TargetCount: __expectInt32(output.TargetCount),
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
        TimeoutSeconds: __expectInt32(output.TimeoutSeconds),
    };
};
var deserializeAws_json1_1CommandInvocation = function (output, context) {
    return {
        CloudWatchOutputConfig: output.CloudWatchOutputConfig !== undefined && output.CloudWatchOutputConfig !== null
            ? deserializeAws_json1_1CloudWatchOutputConfig(output.CloudWatchOutputConfig, context)
            : undefined,
        CommandId: __expectString(output.CommandId),
        CommandPlugins: output.CommandPlugins !== undefined && output.CommandPlugins !== null
            ? deserializeAws_json1_1CommandPluginList(output.CommandPlugins, context)
            : undefined,
        Comment: __expectString(output.Comment),
        DocumentName: __expectString(output.DocumentName),
        DocumentVersion: __expectString(output.DocumentVersion),
        InstanceId: __expectString(output.InstanceId),
        InstanceName: __expectString(output.InstanceName),
        NotificationConfig: output.NotificationConfig !== undefined && output.NotificationConfig !== null
            ? deserializeAws_json1_1NotificationConfig(output.NotificationConfig, context)
            : undefined,
        RequestedDateTime: output.RequestedDateTime !== undefined && output.RequestedDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.RequestedDateTime)))
            : undefined,
        ServiceRole: __expectString(output.ServiceRole),
        StandardErrorUrl: __expectString(output.StandardErrorUrl),
        StandardOutputUrl: __expectString(output.StandardOutputUrl),
        Status: __expectString(output.Status),
        StatusDetails: __expectString(output.StatusDetails),
        TraceOutput: __expectString(output.TraceOutput),
    };
};
var deserializeAws_json1_1CommandInvocationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1CommandInvocation(entry, context);
    });
};
var deserializeAws_json1_1CommandList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1Command(entry, context);
    });
};
var deserializeAws_json1_1CommandPlugin = function (output, context) {
    return {
        Name: __expectString(output.Name),
        Output: __expectString(output.Output),
        OutputS3BucketName: __expectString(output.OutputS3BucketName),
        OutputS3KeyPrefix: __expectString(output.OutputS3KeyPrefix),
        OutputS3Region: __expectString(output.OutputS3Region),
        ResponseCode: __expectInt32(output.ResponseCode),
        ResponseFinishDateTime: output.ResponseFinishDateTime !== undefined && output.ResponseFinishDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ResponseFinishDateTime)))
            : undefined,
        ResponseStartDateTime: output.ResponseStartDateTime !== undefined && output.ResponseStartDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ResponseStartDateTime)))
            : undefined,
        StandardErrorUrl: __expectString(output.StandardErrorUrl),
        StandardOutputUrl: __expectString(output.StandardOutputUrl),
        Status: __expectString(output.Status),
        StatusDetails: __expectString(output.StatusDetails),
    };
};
var deserializeAws_json1_1CommandPluginList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1CommandPlugin(entry, context);
    });
};
var deserializeAws_json1_1ComplianceExecutionSummary = function (output, context) {
    return {
        ExecutionId: __expectString(output.ExecutionId),
        ExecutionTime: output.ExecutionTime !== undefined && output.ExecutionTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ExecutionTime)))
            : undefined,
        ExecutionType: __expectString(output.ExecutionType),
    };
};
var deserializeAws_json1_1ComplianceItem = function (output, context) {
    return {
        ComplianceType: __expectString(output.ComplianceType),
        Details: output.Details !== undefined && output.Details !== null
            ? deserializeAws_json1_1ComplianceItemDetails(output.Details, context)
            : undefined,
        ExecutionSummary: output.ExecutionSummary !== undefined && output.ExecutionSummary !== null
            ? deserializeAws_json1_1ComplianceExecutionSummary(output.ExecutionSummary, context)
            : undefined,
        Id: __expectString(output.Id),
        ResourceId: __expectString(output.ResourceId),
        ResourceType: __expectString(output.ResourceType),
        Severity: __expectString(output.Severity),
        Status: __expectString(output.Status),
        Title: __expectString(output.Title),
    };
};
var deserializeAws_json1_1ComplianceItemDetails = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectString(value), _b));
    }, {});
};
var deserializeAws_json1_1ComplianceItemList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1ComplianceItem(entry, context);
    });
};
var deserializeAws_json1_1ComplianceSummaryItem = function (output, context) {
    return {
        ComplianceType: __expectString(output.ComplianceType),
        CompliantSummary: output.CompliantSummary !== undefined && output.CompliantSummary !== null
            ? deserializeAws_json1_1CompliantSummary(output.CompliantSummary, context)
            : undefined,
        NonCompliantSummary: output.NonCompliantSummary !== undefined && output.NonCompliantSummary !== null
            ? deserializeAws_json1_1NonCompliantSummary(output.NonCompliantSummary, context)
            : undefined,
    };
};
var deserializeAws_json1_1ComplianceSummaryItemList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1ComplianceSummaryItem(entry, context);
    });
};
var deserializeAws_json1_1ComplianceTypeCountLimitExceededException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1CompliantSummary = function (output, context) {
    return {
        CompliantCount: __expectInt32(output.CompliantCount),
        SeveritySummary: output.SeveritySummary !== undefined && output.SeveritySummary !== null
            ? deserializeAws_json1_1SeveritySummary(output.SeveritySummary, context)
            : undefined,
    };
};
var deserializeAws_json1_1CreateActivationResult = function (output, context) {
    return {
        ActivationCode: __expectString(output.ActivationCode),
        ActivationId: __expectString(output.ActivationId),
    };
};
var deserializeAws_json1_1CreateAssociationBatchRequestEntry = function (output, context) {
    return {
        ApplyOnlyAtCronInterval: __expectBoolean(output.ApplyOnlyAtCronInterval),
        AssociationName: __expectString(output.AssociationName),
        AutomationTargetParameterName: __expectString(output.AutomationTargetParameterName),
        CalendarNames: output.CalendarNames !== undefined && output.CalendarNames !== null
            ? deserializeAws_json1_1CalendarNameOrARNList(output.CalendarNames, context)
            : undefined,
        ComplianceSeverity: __expectString(output.ComplianceSeverity),
        DocumentVersion: __expectString(output.DocumentVersion),
        InstanceId: __expectString(output.InstanceId),
        MaxConcurrency: __expectString(output.MaxConcurrency),
        MaxErrors: __expectString(output.MaxErrors),
        Name: __expectString(output.Name),
        OutputLocation: output.OutputLocation !== undefined && output.OutputLocation !== null
            ? deserializeAws_json1_1InstanceAssociationOutputLocation(output.OutputLocation, context)
            : undefined,
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1Parameters(output.Parameters, context)
            : undefined,
        ScheduleExpression: __expectString(output.ScheduleExpression),
        SyncCompliance: __expectString(output.SyncCompliance),
        TargetLocations: output.TargetLocations !== undefined && output.TargetLocations !== null
            ? deserializeAws_json1_1TargetLocations(output.TargetLocations, context)
            : undefined,
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
    };
};
var deserializeAws_json1_1CreateAssociationBatchResult = function (output, context) {
    return {
        Failed: output.Failed !== undefined && output.Failed !== null
            ? deserializeAws_json1_1FailedCreateAssociationList(output.Failed, context)
            : undefined,
        Successful: output.Successful !== undefined && output.Successful !== null
            ? deserializeAws_json1_1AssociationDescriptionList(output.Successful, context)
            : undefined,
    };
};
var deserializeAws_json1_1CreateAssociationResult = function (output, context) {
    return {
        AssociationDescription: output.AssociationDescription !== undefined && output.AssociationDescription !== null
            ? deserializeAws_json1_1AssociationDescription(output.AssociationDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_1CreateDocumentResult = function (output, context) {
    return {
        DocumentDescription: output.DocumentDescription !== undefined && output.DocumentDescription !== null
            ? deserializeAws_json1_1DocumentDescription(output.DocumentDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_1CreateMaintenanceWindowResult = function (output, context) {
    return {
        WindowId: __expectString(output.WindowId),
    };
};
var deserializeAws_json1_1CreateOpsItemResponse = function (output, context) {
    return {
        OpsItemId: __expectString(output.OpsItemId),
    };
};
var deserializeAws_json1_1CreateOpsMetadataResult = function (output, context) {
    return {
        OpsMetadataArn: __expectString(output.OpsMetadataArn),
    };
};
var deserializeAws_json1_1CreatePatchBaselineResult = function (output, context) {
    return {
        BaselineId: __expectString(output.BaselineId),
    };
};
var deserializeAws_json1_1CreateResourceDataSyncResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1CustomSchemaCountLimitExceededException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1DeleteActivationResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1DeleteAssociationResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1DeleteDocumentResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1DeleteInventoryResult = function (output, context) {
    return {
        DeletionId: __expectString(output.DeletionId),
        DeletionSummary: output.DeletionSummary !== undefined && output.DeletionSummary !== null
            ? deserializeAws_json1_1InventoryDeletionSummary(output.DeletionSummary, context)
            : undefined,
        TypeName: __expectString(output.TypeName),
    };
};
var deserializeAws_json1_1DeleteMaintenanceWindowResult = function (output, context) {
    return {
        WindowId: __expectString(output.WindowId),
    };
};
var deserializeAws_json1_1DeleteOpsMetadataResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1DeleteParameterResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1DeleteParametersResult = function (output, context) {
    return {
        DeletedParameters: output.DeletedParameters !== undefined && output.DeletedParameters !== null
            ? deserializeAws_json1_1ParameterNameList(output.DeletedParameters, context)
            : undefined,
        InvalidParameters: output.InvalidParameters !== undefined && output.InvalidParameters !== null
            ? deserializeAws_json1_1ParameterNameList(output.InvalidParameters, context)
            : undefined,
    };
};
var deserializeAws_json1_1DeletePatchBaselineResult = function (output, context) {
    return {
        BaselineId: __expectString(output.BaselineId),
    };
};
var deserializeAws_json1_1DeleteResourceDataSyncResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1DeregisterManagedInstanceResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1DeregisterPatchBaselineForPatchGroupResult = function (output, context) {
    return {
        BaselineId: __expectString(output.BaselineId),
        PatchGroup: __expectString(output.PatchGroup),
    };
};
var deserializeAws_json1_1DeregisterTargetFromMaintenanceWindowResult = function (output, context) {
    return {
        WindowId: __expectString(output.WindowId),
        WindowTargetId: __expectString(output.WindowTargetId),
    };
};
var deserializeAws_json1_1DeregisterTaskFromMaintenanceWindowResult = function (output, context) {
    return {
        WindowId: __expectString(output.WindowId),
        WindowTaskId: __expectString(output.WindowTaskId),
    };
};
var deserializeAws_json1_1DescribeActivationsResult = function (output, context) {
    return {
        ActivationList: output.ActivationList !== undefined && output.ActivationList !== null
            ? deserializeAws_json1_1ActivationList(output.ActivationList, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeAssociationExecutionsResult = function (output, context) {
    return {
        AssociationExecutions: output.AssociationExecutions !== undefined && output.AssociationExecutions !== null
            ? deserializeAws_json1_1AssociationExecutionsList(output.AssociationExecutions, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeAssociationExecutionTargetsResult = function (output, context) {
    return {
        AssociationExecutionTargets: output.AssociationExecutionTargets !== undefined && output.AssociationExecutionTargets !== null
            ? deserializeAws_json1_1AssociationExecutionTargetsList(output.AssociationExecutionTargets, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeAssociationResult = function (output, context) {
    return {
        AssociationDescription: output.AssociationDescription !== undefined && output.AssociationDescription !== null
            ? deserializeAws_json1_1AssociationDescription(output.AssociationDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeAutomationExecutionsResult = function (output, context) {
    return {
        AutomationExecutionMetadataList: output.AutomationExecutionMetadataList !== undefined && output.AutomationExecutionMetadataList !== null
            ? deserializeAws_json1_1AutomationExecutionMetadataList(output.AutomationExecutionMetadataList, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeAutomationStepExecutionsResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        StepExecutions: output.StepExecutions !== undefined && output.StepExecutions !== null
            ? deserializeAws_json1_1StepExecutionList(output.StepExecutions, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeAvailablePatchesResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Patches: output.Patches !== undefined && output.Patches !== null
            ? deserializeAws_json1_1PatchList(output.Patches, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeDocumentPermissionResponse = function (output, context) {
    return {
        AccountIds: output.AccountIds !== undefined && output.AccountIds !== null
            ? deserializeAws_json1_1AccountIdList(output.AccountIds, context)
            : undefined,
        AccountSharingInfoList: output.AccountSharingInfoList !== undefined && output.AccountSharingInfoList !== null
            ? deserializeAws_json1_1AccountSharingInfoList(output.AccountSharingInfoList, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeDocumentResult = function (output, context) {
    return {
        Document: output.Document !== undefined && output.Document !== null
            ? deserializeAws_json1_1DocumentDescription(output.Document, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeEffectiveInstanceAssociationsResult = function (output, context) {
    return {
        Associations: output.Associations !== undefined && output.Associations !== null
            ? deserializeAws_json1_1InstanceAssociationList(output.Associations, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeEffectivePatchesForPatchBaselineResult = function (output, context) {
    return {
        EffectivePatches: output.EffectivePatches !== undefined && output.EffectivePatches !== null
            ? deserializeAws_json1_1EffectivePatchList(output.EffectivePatches, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeInstanceAssociationsStatusResult = function (output, context) {
    return {
        InstanceAssociationStatusInfos: output.InstanceAssociationStatusInfos !== undefined && output.InstanceAssociationStatusInfos !== null
            ? deserializeAws_json1_1InstanceAssociationStatusInfos(output.InstanceAssociationStatusInfos, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeInstanceInformationResult = function (output, context) {
    return {
        InstanceInformationList: output.InstanceInformationList !== undefined && output.InstanceInformationList !== null
            ? deserializeAws_json1_1InstanceInformationList(output.InstanceInformationList, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeInstancePatchesResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Patches: output.Patches !== undefined && output.Patches !== null
            ? deserializeAws_json1_1PatchComplianceDataList(output.Patches, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeInstancePatchStatesForPatchGroupResult = function (output, context) {
    return {
        InstancePatchStates: output.InstancePatchStates !== undefined && output.InstancePatchStates !== null
            ? deserializeAws_json1_1InstancePatchStatesList(output.InstancePatchStates, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeInstancePatchStatesResult = function (output, context) {
    return {
        InstancePatchStates: output.InstancePatchStates !== undefined && output.InstancePatchStates !== null
            ? deserializeAws_json1_1InstancePatchStateList(output.InstancePatchStates, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeInventoryDeletionsResult = function (output, context) {
    return {
        InventoryDeletions: output.InventoryDeletions !== undefined && output.InventoryDeletions !== null
            ? deserializeAws_json1_1InventoryDeletionsList(output.InventoryDeletions, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribeMaintenanceWindowExecutionsResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        WindowExecutions: output.WindowExecutions !== undefined && output.WindowExecutions !== null
            ? deserializeAws_json1_1MaintenanceWindowExecutionList(output.WindowExecutions, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeMaintenanceWindowExecutionTaskInvocationsResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        WindowExecutionTaskInvocationIdentities: output.WindowExecutionTaskInvocationIdentities !== undefined &&
            output.WindowExecutionTaskInvocationIdentities !== null
            ? deserializeAws_json1_1MaintenanceWindowExecutionTaskInvocationIdentityList(output.WindowExecutionTaskInvocationIdentities, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeMaintenanceWindowExecutionTasksResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        WindowExecutionTaskIdentities: output.WindowExecutionTaskIdentities !== undefined && output.WindowExecutionTaskIdentities !== null
            ? deserializeAws_json1_1MaintenanceWindowExecutionTaskIdentityList(output.WindowExecutionTaskIdentities, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeMaintenanceWindowScheduleResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        ScheduledWindowExecutions: output.ScheduledWindowExecutions !== undefined && output.ScheduledWindowExecutions !== null
            ? deserializeAws_json1_1ScheduledWindowExecutionList(output.ScheduledWindowExecutions, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeMaintenanceWindowsForTargetResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        WindowIdentities: output.WindowIdentities !== undefined && output.WindowIdentities !== null
            ? deserializeAws_json1_1MaintenanceWindowsForTargetList(output.WindowIdentities, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeMaintenanceWindowsResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        WindowIdentities: output.WindowIdentities !== undefined && output.WindowIdentities !== null
            ? deserializeAws_json1_1MaintenanceWindowIdentityList(output.WindowIdentities, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeMaintenanceWindowTargetsResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1MaintenanceWindowTargetList(output.Targets, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeMaintenanceWindowTasksResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Tasks: output.Tasks !== undefined && output.Tasks !== null
            ? deserializeAws_json1_1MaintenanceWindowTaskList(output.Tasks, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeOpsItemsResponse = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        OpsItemSummaries: output.OpsItemSummaries !== undefined && output.OpsItemSummaries !== null
            ? deserializeAws_json1_1OpsItemSummaries(output.OpsItemSummaries, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeParametersResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1ParameterMetadataList(output.Parameters, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribePatchBaselinesResult = function (output, context) {
    return {
        BaselineIdentities: output.BaselineIdentities !== undefined && output.BaselineIdentities !== null
            ? deserializeAws_json1_1PatchBaselineIdentityList(output.BaselineIdentities, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribePatchGroupsResult = function (output, context) {
    return {
        Mappings: output.Mappings !== undefined && output.Mappings !== null
            ? deserializeAws_json1_1PatchGroupPatchBaselineMappingList(output.Mappings, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1DescribePatchGroupStateResult = function (output, context) {
    return {
        Instances: __expectInt32(output.Instances),
        InstancesWithCriticalNonCompliantPatches: __expectInt32(output.InstancesWithCriticalNonCompliantPatches),
        InstancesWithFailedPatches: __expectInt32(output.InstancesWithFailedPatches),
        InstancesWithInstalledOtherPatches: __expectInt32(output.InstancesWithInstalledOtherPatches),
        InstancesWithInstalledPatches: __expectInt32(output.InstancesWithInstalledPatches),
        InstancesWithInstalledPendingRebootPatches: __expectInt32(output.InstancesWithInstalledPendingRebootPatches),
        InstancesWithInstalledRejectedPatches: __expectInt32(output.InstancesWithInstalledRejectedPatches),
        InstancesWithMissingPatches: __expectInt32(output.InstancesWithMissingPatches),
        InstancesWithNotApplicablePatches: __expectInt32(output.InstancesWithNotApplicablePatches),
        InstancesWithOtherNonCompliantPatches: __expectInt32(output.InstancesWithOtherNonCompliantPatches),
        InstancesWithSecurityNonCompliantPatches: __expectInt32(output.InstancesWithSecurityNonCompliantPatches),
        InstancesWithUnreportedNotApplicablePatches: __expectInt32(output.InstancesWithUnreportedNotApplicablePatches),
    };
};
var deserializeAws_json1_1DescribePatchPropertiesResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Properties: output.Properties !== undefined && output.Properties !== null
            ? deserializeAws_json1_1PatchPropertiesList(output.Properties, context)
            : undefined,
    };
};
var deserializeAws_json1_1DescribeSessionsResponse = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Sessions: output.Sessions !== undefined && output.Sessions !== null
            ? deserializeAws_json1_1SessionList(output.Sessions, context)
            : undefined,
    };
};
var deserializeAws_json1_1DisassociateOpsItemRelatedItemResponse = function (output, context) {
    return {};
};
var deserializeAws_json1_1DocumentAlreadyExists = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1DocumentDefaultVersionDescription = function (output, context) {
    return {
        DefaultVersion: __expectString(output.DefaultVersion),
        DefaultVersionName: __expectString(output.DefaultVersionName),
        Name: __expectString(output.Name),
    };
};
var deserializeAws_json1_1DocumentDescription = function (output, context) {
    return {
        ApprovedVersion: __expectString(output.ApprovedVersion),
        AttachmentsInformation: output.AttachmentsInformation !== undefined && output.AttachmentsInformation !== null
            ? deserializeAws_json1_1AttachmentInformationList(output.AttachmentsInformation, context)
            : undefined,
        Author: __expectString(output.Author),
        CreatedDate: output.CreatedDate !== undefined && output.CreatedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedDate)))
            : undefined,
        DefaultVersion: __expectString(output.DefaultVersion),
        Description: __expectString(output.Description),
        DisplayName: __expectString(output.DisplayName),
        DocumentFormat: __expectString(output.DocumentFormat),
        DocumentType: __expectString(output.DocumentType),
        DocumentVersion: __expectString(output.DocumentVersion),
        Hash: __expectString(output.Hash),
        HashType: __expectString(output.HashType),
        LatestVersion: __expectString(output.LatestVersion),
        Name: __expectString(output.Name),
        Owner: __expectString(output.Owner),
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1DocumentParameterList(output.Parameters, context)
            : undefined,
        PendingReviewVersion: __expectString(output.PendingReviewVersion),
        PlatformTypes: output.PlatformTypes !== undefined && output.PlatformTypes !== null
            ? deserializeAws_json1_1PlatformTypeList(output.PlatformTypes, context)
            : undefined,
        Requires: output.Requires !== undefined && output.Requires !== null
            ? deserializeAws_json1_1DocumentRequiresList(output.Requires, context)
            : undefined,
        ReviewInformation: output.ReviewInformation !== undefined && output.ReviewInformation !== null
            ? deserializeAws_json1_1ReviewInformationList(output.ReviewInformation, context)
            : undefined,
        ReviewStatus: __expectString(output.ReviewStatus),
        SchemaVersion: __expectString(output.SchemaVersion),
        Sha1: __expectString(output.Sha1),
        Status: __expectString(output.Status),
        StatusInformation: __expectString(output.StatusInformation),
        Tags: output.Tags !== undefined && output.Tags !== null
            ? deserializeAws_json1_1TagList(output.Tags, context)
            : undefined,
        TargetType: __expectString(output.TargetType),
        VersionName: __expectString(output.VersionName),
    };
};
var deserializeAws_json1_1DocumentIdentifier = function (output, context) {
    return {
        Author: __expectString(output.Author),
        CreatedDate: output.CreatedDate !== undefined && output.CreatedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedDate)))
            : undefined,
        DisplayName: __expectString(output.DisplayName),
        DocumentFormat: __expectString(output.DocumentFormat),
        DocumentType: __expectString(output.DocumentType),
        DocumentVersion: __expectString(output.DocumentVersion),
        Name: __expectString(output.Name),
        Owner: __expectString(output.Owner),
        PlatformTypes: output.PlatformTypes !== undefined && output.PlatformTypes !== null
            ? deserializeAws_json1_1PlatformTypeList(output.PlatformTypes, context)
            : undefined,
        Requires: output.Requires !== undefined && output.Requires !== null
            ? deserializeAws_json1_1DocumentRequiresList(output.Requires, context)
            : undefined,
        ReviewStatus: __expectString(output.ReviewStatus),
        SchemaVersion: __expectString(output.SchemaVersion),
        Tags: output.Tags !== undefined && output.Tags !== null
            ? deserializeAws_json1_1TagList(output.Tags, context)
            : undefined,
        TargetType: __expectString(output.TargetType),
        VersionName: __expectString(output.VersionName),
    };
};
var deserializeAws_json1_1DocumentIdentifierList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1DocumentIdentifier(entry, context);
    });
};
var deserializeAws_json1_1DocumentLimitExceeded = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1DocumentMetadataResponseInfo = function (output, context) {
    return {
        ReviewerResponse: output.ReviewerResponse !== undefined && output.ReviewerResponse !== null
            ? deserializeAws_json1_1DocumentReviewerResponseList(output.ReviewerResponse, context)
            : undefined,
    };
};
var deserializeAws_json1_1DocumentParameter = function (output, context) {
    return {
        DefaultValue: __expectString(output.DefaultValue),
        Description: __expectString(output.Description),
        Name: __expectString(output.Name),
        Type: __expectString(output.Type),
    };
};
var deserializeAws_json1_1DocumentParameterList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1DocumentParameter(entry, context);
    });
};
var deserializeAws_json1_1DocumentPermissionLimit = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1DocumentRequires = function (output, context) {
    return {
        Name: __expectString(output.Name),
        Version: __expectString(output.Version),
    };
};
var deserializeAws_json1_1DocumentRequiresList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1DocumentRequires(entry, context);
    });
};
var deserializeAws_json1_1DocumentReviewCommentList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1DocumentReviewCommentSource(entry, context);
    });
};
var deserializeAws_json1_1DocumentReviewCommentSource = function (output, context) {
    return {
        Content: __expectString(output.Content),
        Type: __expectString(output.Type),
    };
};
var deserializeAws_json1_1DocumentReviewerResponseList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1DocumentReviewerResponseSource(entry, context);
    });
};
var deserializeAws_json1_1DocumentReviewerResponseSource = function (output, context) {
    return {
        Comment: output.Comment !== undefined && output.Comment !== null
            ? deserializeAws_json1_1DocumentReviewCommentList(output.Comment, context)
            : undefined,
        CreateTime: output.CreateTime !== undefined && output.CreateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreateTime)))
            : undefined,
        ReviewStatus: __expectString(output.ReviewStatus),
        Reviewer: __expectString(output.Reviewer),
        UpdatedTime: output.UpdatedTime !== undefined && output.UpdatedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.UpdatedTime)))
            : undefined,
    };
};
var deserializeAws_json1_1DocumentVersionInfo = function (output, context) {
    return {
        CreatedDate: output.CreatedDate !== undefined && output.CreatedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedDate)))
            : undefined,
        DisplayName: __expectString(output.DisplayName),
        DocumentFormat: __expectString(output.DocumentFormat),
        DocumentVersion: __expectString(output.DocumentVersion),
        IsDefaultVersion: __expectBoolean(output.IsDefaultVersion),
        Name: __expectString(output.Name),
        ReviewStatus: __expectString(output.ReviewStatus),
        Status: __expectString(output.Status),
        StatusInformation: __expectString(output.StatusInformation),
        VersionName: __expectString(output.VersionName),
    };
};
var deserializeAws_json1_1DocumentVersionLimitExceeded = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1DocumentVersionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1DocumentVersionInfo(entry, context);
    });
};
var deserializeAws_json1_1DoesNotExistException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1DuplicateDocumentContent = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1DuplicateDocumentVersionName = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1DuplicateInstanceId = function (output, context) {
    return {};
};
var deserializeAws_json1_1EffectivePatch = function (output, context) {
    return {
        Patch: output.Patch !== undefined && output.Patch !== null
            ? deserializeAws_json1_1Patch(output.Patch, context)
            : undefined,
        PatchStatus: output.PatchStatus !== undefined && output.PatchStatus !== null
            ? deserializeAws_json1_1PatchStatus(output.PatchStatus, context)
            : undefined,
    };
};
var deserializeAws_json1_1EffectivePatchList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1EffectivePatch(entry, context);
    });
};
var deserializeAws_json1_1FailedCreateAssociation = function (output, context) {
    return {
        Entry: output.Entry !== undefined && output.Entry !== null
            ? deserializeAws_json1_1CreateAssociationBatchRequestEntry(output.Entry, context)
            : undefined,
        Fault: __expectString(output.Fault),
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1FailedCreateAssociationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1FailedCreateAssociation(entry, context);
    });
};
var deserializeAws_json1_1FailureDetails = function (output, context) {
    return {
        Details: output.Details !== undefined && output.Details !== null
            ? deserializeAws_json1_1AutomationParameterMap(output.Details, context)
            : undefined,
        FailureStage: __expectString(output.FailureStage),
        FailureType: __expectString(output.FailureType),
    };
};
var deserializeAws_json1_1FeatureNotAvailableException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1GetAutomationExecutionResult = function (output, context) {
    return {
        AutomationExecution: output.AutomationExecution !== undefined && output.AutomationExecution !== null
            ? deserializeAws_json1_1AutomationExecution(output.AutomationExecution, context)
            : undefined,
    };
};
var deserializeAws_json1_1GetCalendarStateResponse = function (output, context) {
    return {
        AtTime: __expectString(output.AtTime),
        NextTransitionTime: __expectString(output.NextTransitionTime),
        State: __expectString(output.State),
    };
};
var deserializeAws_json1_1GetCommandInvocationResult = function (output, context) {
    return {
        CloudWatchOutputConfig: output.CloudWatchOutputConfig !== undefined && output.CloudWatchOutputConfig !== null
            ? deserializeAws_json1_1CloudWatchOutputConfig(output.CloudWatchOutputConfig, context)
            : undefined,
        CommandId: __expectString(output.CommandId),
        Comment: __expectString(output.Comment),
        DocumentName: __expectString(output.DocumentName),
        DocumentVersion: __expectString(output.DocumentVersion),
        ExecutionElapsedTime: __expectString(output.ExecutionElapsedTime),
        ExecutionEndDateTime: __expectString(output.ExecutionEndDateTime),
        ExecutionStartDateTime: __expectString(output.ExecutionStartDateTime),
        InstanceId: __expectString(output.InstanceId),
        PluginName: __expectString(output.PluginName),
        ResponseCode: __expectInt32(output.ResponseCode),
        StandardErrorContent: __expectString(output.StandardErrorContent),
        StandardErrorUrl: __expectString(output.StandardErrorUrl),
        StandardOutputContent: __expectString(output.StandardOutputContent),
        StandardOutputUrl: __expectString(output.StandardOutputUrl),
        Status: __expectString(output.Status),
        StatusDetails: __expectString(output.StatusDetails),
    };
};
var deserializeAws_json1_1GetConnectionStatusResponse = function (output, context) {
    return {
        Status: __expectString(output.Status),
        Target: __expectString(output.Target),
    };
};
var deserializeAws_json1_1GetDefaultPatchBaselineResult = function (output, context) {
    return {
        BaselineId: __expectString(output.BaselineId),
        OperatingSystem: __expectString(output.OperatingSystem),
    };
};
var deserializeAws_json1_1GetDeployablePatchSnapshotForInstanceResult = function (output, context) {
    return {
        InstanceId: __expectString(output.InstanceId),
        Product: __expectString(output.Product),
        SnapshotDownloadUrl: __expectString(output.SnapshotDownloadUrl),
        SnapshotId: __expectString(output.SnapshotId),
    };
};
var deserializeAws_json1_1GetDocumentResult = function (output, context) {
    return {
        AttachmentsContent: output.AttachmentsContent !== undefined && output.AttachmentsContent !== null
            ? deserializeAws_json1_1AttachmentContentList(output.AttachmentsContent, context)
            : undefined,
        Content: __expectString(output.Content),
        CreatedDate: output.CreatedDate !== undefined && output.CreatedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedDate)))
            : undefined,
        DisplayName: __expectString(output.DisplayName),
        DocumentFormat: __expectString(output.DocumentFormat),
        DocumentType: __expectString(output.DocumentType),
        DocumentVersion: __expectString(output.DocumentVersion),
        Name: __expectString(output.Name),
        Requires: output.Requires !== undefined && output.Requires !== null
            ? deserializeAws_json1_1DocumentRequiresList(output.Requires, context)
            : undefined,
        ReviewStatus: __expectString(output.ReviewStatus),
        Status: __expectString(output.Status),
        StatusInformation: __expectString(output.StatusInformation),
        VersionName: __expectString(output.VersionName),
    };
};
var deserializeAws_json1_1GetInventoryResult = function (output, context) {
    return {
        Entities: output.Entities !== undefined && output.Entities !== null
            ? deserializeAws_json1_1InventoryResultEntityList(output.Entities, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1GetInventorySchemaResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Schemas: output.Schemas !== undefined && output.Schemas !== null
            ? deserializeAws_json1_1InventoryItemSchemaResultList(output.Schemas, context)
            : undefined,
    };
};
var deserializeAws_json1_1GetMaintenanceWindowExecutionResult = function (output, context) {
    return {
        EndTime: output.EndTime !== undefined && output.EndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.EndTime)))
            : undefined,
        StartTime: output.StartTime !== undefined && output.StartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.StartTime)))
            : undefined,
        Status: __expectString(output.Status),
        StatusDetails: __expectString(output.StatusDetails),
        TaskIds: output.TaskIds !== undefined && output.TaskIds !== null
            ? deserializeAws_json1_1MaintenanceWindowExecutionTaskIdList(output.TaskIds, context)
            : undefined,
        WindowExecutionId: __expectString(output.WindowExecutionId),
    };
};
var deserializeAws_json1_1GetMaintenanceWindowExecutionTaskInvocationResult = function (output, context) {
    return {
        EndTime: output.EndTime !== undefined && output.EndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.EndTime)))
            : undefined,
        ExecutionId: __expectString(output.ExecutionId),
        InvocationId: __expectString(output.InvocationId),
        OwnerInformation: __expectString(output.OwnerInformation),
        Parameters: __expectString(output.Parameters),
        StartTime: output.StartTime !== undefined && output.StartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.StartTime)))
            : undefined,
        Status: __expectString(output.Status),
        StatusDetails: __expectString(output.StatusDetails),
        TaskExecutionId: __expectString(output.TaskExecutionId),
        TaskType: __expectString(output.TaskType),
        WindowExecutionId: __expectString(output.WindowExecutionId),
        WindowTargetId: __expectString(output.WindowTargetId),
    };
};
var deserializeAws_json1_1GetMaintenanceWindowExecutionTaskResult = function (output, context) {
    return {
        EndTime: output.EndTime !== undefined && output.EndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.EndTime)))
            : undefined,
        MaxConcurrency: __expectString(output.MaxConcurrency),
        MaxErrors: __expectString(output.MaxErrors),
        Priority: __expectInt32(output.Priority),
        ServiceRole: __expectString(output.ServiceRole),
        StartTime: output.StartTime !== undefined && output.StartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.StartTime)))
            : undefined,
        Status: __expectString(output.Status),
        StatusDetails: __expectString(output.StatusDetails),
        TaskArn: __expectString(output.TaskArn),
        TaskExecutionId: __expectString(output.TaskExecutionId),
        TaskParameters: output.TaskParameters !== undefined && output.TaskParameters !== null
            ? deserializeAws_json1_1MaintenanceWindowTaskParametersList(output.TaskParameters, context)
            : undefined,
        Type: __expectString(output.Type),
        WindowExecutionId: __expectString(output.WindowExecutionId),
    };
};
var deserializeAws_json1_1GetMaintenanceWindowResult = function (output, context) {
    return {
        AllowUnassociatedTargets: __expectBoolean(output.AllowUnassociatedTargets),
        CreatedDate: output.CreatedDate !== undefined && output.CreatedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedDate)))
            : undefined,
        Cutoff: __expectInt32(output.Cutoff),
        Description: __expectString(output.Description),
        Duration: __expectInt32(output.Duration),
        Enabled: __expectBoolean(output.Enabled),
        EndDate: __expectString(output.EndDate),
        ModifiedDate: output.ModifiedDate !== undefined && output.ModifiedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ModifiedDate)))
            : undefined,
        Name: __expectString(output.Name),
        NextExecutionTime: __expectString(output.NextExecutionTime),
        Schedule: __expectString(output.Schedule),
        ScheduleOffset: __expectInt32(output.ScheduleOffset),
        ScheduleTimezone: __expectString(output.ScheduleTimezone),
        StartDate: __expectString(output.StartDate),
        WindowId: __expectString(output.WindowId),
    };
};
var deserializeAws_json1_1GetMaintenanceWindowTaskResult = function (output, context) {
    return {
        CutoffBehavior: __expectString(output.CutoffBehavior),
        Description: __expectString(output.Description),
        LoggingInfo: output.LoggingInfo !== undefined && output.LoggingInfo !== null
            ? deserializeAws_json1_1LoggingInfo(output.LoggingInfo, context)
            : undefined,
        MaxConcurrency: __expectString(output.MaxConcurrency),
        MaxErrors: __expectString(output.MaxErrors),
        Name: __expectString(output.Name),
        Priority: __expectInt32(output.Priority),
        ServiceRoleArn: __expectString(output.ServiceRoleArn),
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
        TaskArn: __expectString(output.TaskArn),
        TaskInvocationParameters: output.TaskInvocationParameters !== undefined && output.TaskInvocationParameters !== null
            ? deserializeAws_json1_1MaintenanceWindowTaskInvocationParameters(output.TaskInvocationParameters, context)
            : undefined,
        TaskParameters: output.TaskParameters !== undefined && output.TaskParameters !== null
            ? deserializeAws_json1_1MaintenanceWindowTaskParameters(output.TaskParameters, context)
            : undefined,
        TaskType: __expectString(output.TaskType),
        WindowId: __expectString(output.WindowId),
        WindowTaskId: __expectString(output.WindowTaskId),
    };
};
var deserializeAws_json1_1GetOpsItemResponse = function (output, context) {
    return {
        OpsItem: output.OpsItem !== undefined && output.OpsItem !== null
            ? deserializeAws_json1_1OpsItem(output.OpsItem, context)
            : undefined,
    };
};
var deserializeAws_json1_1GetOpsMetadataResult = function (output, context) {
    return {
        Metadata: output.Metadata !== undefined && output.Metadata !== null
            ? deserializeAws_json1_1MetadataMap(output.Metadata, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
        ResourceId: __expectString(output.ResourceId),
    };
};
var deserializeAws_json1_1GetOpsSummaryResult = function (output, context) {
    return {
        Entities: output.Entities !== undefined && output.Entities !== null
            ? deserializeAws_json1_1OpsEntityList(output.Entities, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1GetParameterHistoryResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1ParameterHistoryList(output.Parameters, context)
            : undefined,
    };
};
var deserializeAws_json1_1GetParameterResult = function (output, context) {
    return {
        Parameter: output.Parameter !== undefined && output.Parameter !== null
            ? deserializeAws_json1_1Parameter(output.Parameter, context)
            : undefined,
    };
};
var deserializeAws_json1_1GetParametersByPathResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1ParameterList(output.Parameters, context)
            : undefined,
    };
};
var deserializeAws_json1_1GetParametersResult = function (output, context) {
    return {
        InvalidParameters: output.InvalidParameters !== undefined && output.InvalidParameters !== null
            ? deserializeAws_json1_1ParameterNameList(output.InvalidParameters, context)
            : undefined,
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1ParameterList(output.Parameters, context)
            : undefined,
    };
};
var deserializeAws_json1_1GetPatchBaselineForPatchGroupResult = function (output, context) {
    return {
        BaselineId: __expectString(output.BaselineId),
        OperatingSystem: __expectString(output.OperatingSystem),
        PatchGroup: __expectString(output.PatchGroup),
    };
};
var deserializeAws_json1_1GetPatchBaselineResult = function (output, context) {
    return {
        ApprovalRules: output.ApprovalRules !== undefined && output.ApprovalRules !== null
            ? deserializeAws_json1_1PatchRuleGroup(output.ApprovalRules, context)
            : undefined,
        ApprovedPatches: output.ApprovedPatches !== undefined && output.ApprovedPatches !== null
            ? deserializeAws_json1_1PatchIdList(output.ApprovedPatches, context)
            : undefined,
        ApprovedPatchesComplianceLevel: __expectString(output.ApprovedPatchesComplianceLevel),
        ApprovedPatchesEnableNonSecurity: __expectBoolean(output.ApprovedPatchesEnableNonSecurity),
        BaselineId: __expectString(output.BaselineId),
        CreatedDate: output.CreatedDate !== undefined && output.CreatedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedDate)))
            : undefined,
        Description: __expectString(output.Description),
        GlobalFilters: output.GlobalFilters !== undefined && output.GlobalFilters !== null
            ? deserializeAws_json1_1PatchFilterGroup(output.GlobalFilters, context)
            : undefined,
        ModifiedDate: output.ModifiedDate !== undefined && output.ModifiedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ModifiedDate)))
            : undefined,
        Name: __expectString(output.Name),
        OperatingSystem: __expectString(output.OperatingSystem),
        PatchGroups: output.PatchGroups !== undefined && output.PatchGroups !== null
            ? deserializeAws_json1_1PatchGroupList(output.PatchGroups, context)
            : undefined,
        RejectedPatches: output.RejectedPatches !== undefined && output.RejectedPatches !== null
            ? deserializeAws_json1_1PatchIdList(output.RejectedPatches, context)
            : undefined,
        RejectedPatchesAction: __expectString(output.RejectedPatchesAction),
        Sources: output.Sources !== undefined && output.Sources !== null
            ? deserializeAws_json1_1PatchSourceList(output.Sources, context)
            : undefined,
    };
};
var deserializeAws_json1_1GetServiceSettingResult = function (output, context) {
    return {
        ServiceSetting: output.ServiceSetting !== undefined && output.ServiceSetting !== null
            ? deserializeAws_json1_1ServiceSetting(output.ServiceSetting, context)
            : undefined,
    };
};
var deserializeAws_json1_1HierarchyLevelLimitExceededException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1HierarchyTypeMismatchException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1IdempotentParameterMismatch = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1IncompatiblePolicyException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1InstanceAggregatedAssociationOverview = function (output, context) {
    return {
        DetailedStatus: __expectString(output.DetailedStatus),
        InstanceAssociationStatusAggregatedCount: output.InstanceAssociationStatusAggregatedCount !== undefined &&
            output.InstanceAssociationStatusAggregatedCount !== null
            ? deserializeAws_json1_1InstanceAssociationStatusAggregatedCount(output.InstanceAssociationStatusAggregatedCount, context)
            : undefined,
    };
};
var deserializeAws_json1_1InstanceAssociation = function (output, context) {
    return {
        AssociationId: __expectString(output.AssociationId),
        AssociationVersion: __expectString(output.AssociationVersion),
        Content: __expectString(output.Content),
        InstanceId: __expectString(output.InstanceId),
    };
};
var deserializeAws_json1_1InstanceAssociationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1InstanceAssociation(entry, context);
    });
};
var deserializeAws_json1_1InstanceAssociationOutputLocation = function (output, context) {
    return {
        S3Location: output.S3Location !== undefined && output.S3Location !== null
            ? deserializeAws_json1_1S3OutputLocation(output.S3Location, context)
            : undefined,
    };
};
var deserializeAws_json1_1InstanceAssociationOutputUrl = function (output, context) {
    return {
        S3OutputUrl: output.S3OutputUrl !== undefined && output.S3OutputUrl !== null
            ? deserializeAws_json1_1S3OutputUrl(output.S3OutputUrl, context)
            : undefined,
    };
};
var deserializeAws_json1_1InstanceAssociationStatusAggregatedCount = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectInt32(value), _b));
    }, {});
};
var deserializeAws_json1_1InstanceAssociationStatusInfo = function (output, context) {
    return {
        AssociationId: __expectString(output.AssociationId),
        AssociationName: __expectString(output.AssociationName),
        AssociationVersion: __expectString(output.AssociationVersion),
        DetailedStatus: __expectString(output.DetailedStatus),
        DocumentVersion: __expectString(output.DocumentVersion),
        ErrorCode: __expectString(output.ErrorCode),
        ExecutionDate: output.ExecutionDate !== undefined && output.ExecutionDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ExecutionDate)))
            : undefined,
        ExecutionSummary: __expectString(output.ExecutionSummary),
        InstanceId: __expectString(output.InstanceId),
        Name: __expectString(output.Name),
        OutputUrl: output.OutputUrl !== undefined && output.OutputUrl !== null
            ? deserializeAws_json1_1InstanceAssociationOutputUrl(output.OutputUrl, context)
            : undefined,
        Status: __expectString(output.Status),
    };
};
var deserializeAws_json1_1InstanceAssociationStatusInfos = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1InstanceAssociationStatusInfo(entry, context);
    });
};
var deserializeAws_json1_1InstanceIdList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1InstanceInformation = function (output, context) {
    return {
        ActivationId: __expectString(output.ActivationId),
        AgentVersion: __expectString(output.AgentVersion),
        AssociationOverview: output.AssociationOverview !== undefined && output.AssociationOverview !== null
            ? deserializeAws_json1_1InstanceAggregatedAssociationOverview(output.AssociationOverview, context)
            : undefined,
        AssociationStatus: __expectString(output.AssociationStatus),
        ComputerName: __expectString(output.ComputerName),
        IPAddress: __expectString(output.IPAddress),
        IamRole: __expectString(output.IamRole),
        InstanceId: __expectString(output.InstanceId),
        IsLatestVersion: __expectBoolean(output.IsLatestVersion),
        LastAssociationExecutionDate: output.LastAssociationExecutionDate !== undefined && output.LastAssociationExecutionDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastAssociationExecutionDate)))
            : undefined,
        LastPingDateTime: output.LastPingDateTime !== undefined && output.LastPingDateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastPingDateTime)))
            : undefined,
        LastSuccessfulAssociationExecutionDate: output.LastSuccessfulAssociationExecutionDate !== undefined &&
            output.LastSuccessfulAssociationExecutionDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastSuccessfulAssociationExecutionDate)))
            : undefined,
        Name: __expectString(output.Name),
        PingStatus: __expectString(output.PingStatus),
        PlatformName: __expectString(output.PlatformName),
        PlatformType: __expectString(output.PlatformType),
        PlatformVersion: __expectString(output.PlatformVersion),
        RegistrationDate: output.RegistrationDate !== undefined && output.RegistrationDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.RegistrationDate)))
            : undefined,
        ResourceType: __expectString(output.ResourceType),
        SourceId: __expectString(output.SourceId),
        SourceType: __expectString(output.SourceType),
    };
};
var deserializeAws_json1_1InstanceInformationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1InstanceInformation(entry, context);
    });
};
var deserializeAws_json1_1InstancePatchState = function (output, context) {
    return {
        BaselineId: __expectString(output.BaselineId),
        CriticalNonCompliantCount: __expectInt32(output.CriticalNonCompliantCount),
        FailedCount: __expectInt32(output.FailedCount),
        InstallOverrideList: __expectString(output.InstallOverrideList),
        InstalledCount: __expectInt32(output.InstalledCount),
        InstalledOtherCount: __expectInt32(output.InstalledOtherCount),
        InstalledPendingRebootCount: __expectInt32(output.InstalledPendingRebootCount),
        InstalledRejectedCount: __expectInt32(output.InstalledRejectedCount),
        InstanceId: __expectString(output.InstanceId),
        LastNoRebootInstallOperationTime: output.LastNoRebootInstallOperationTime !== undefined && output.LastNoRebootInstallOperationTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastNoRebootInstallOperationTime)))
            : undefined,
        MissingCount: __expectInt32(output.MissingCount),
        NotApplicableCount: __expectInt32(output.NotApplicableCount),
        Operation: __expectString(output.Operation),
        OperationEndTime: output.OperationEndTime !== undefined && output.OperationEndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.OperationEndTime)))
            : undefined,
        OperationStartTime: output.OperationStartTime !== undefined && output.OperationStartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.OperationStartTime)))
            : undefined,
        OtherNonCompliantCount: __expectInt32(output.OtherNonCompliantCount),
        OwnerInformation: __expectString(output.OwnerInformation),
        PatchGroup: __expectString(output.PatchGroup),
        RebootOption: __expectString(output.RebootOption),
        SecurityNonCompliantCount: __expectInt32(output.SecurityNonCompliantCount),
        SnapshotId: __expectString(output.SnapshotId),
        UnreportedNotApplicableCount: __expectInt32(output.UnreportedNotApplicableCount),
    };
};
var deserializeAws_json1_1InstancePatchStateList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1InstancePatchState(entry, context);
    });
};
var deserializeAws_json1_1InstancePatchStatesList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1InstancePatchState(entry, context);
    });
};
var deserializeAws_json1_1InternalServerError = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidActivation = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidActivationId = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidAggregatorException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidAllowedPatternException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1InvalidAssociation = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidAssociationVersion = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidAutomationExecutionParametersException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidAutomationSignalException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidAutomationStatusUpdateException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidCommandId = function (output, context) {
    return {};
};
var deserializeAws_json1_1InvalidDeleteInventoryParametersException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidDeletionIdException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidDocument = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidDocumentContent = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidDocumentOperation = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidDocumentSchemaVersion = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidDocumentType = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidDocumentVersion = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidFilter = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidFilterKey = function (output, context) {
    return {};
};
var deserializeAws_json1_1InvalidFilterOption = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1InvalidFilterValue = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidInstanceId = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidInstanceInformationFilterValue = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1InvalidInventoryGroupException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidInventoryItemContextException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidInventoryRequestException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidItemContentException = function (output, context) {
    return {
        Message: __expectString(output.Message),
        TypeName: __expectString(output.TypeName),
    };
};
var deserializeAws_json1_1InvalidKeyId = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1InvalidNextToken = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidNotificationConfig = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidOptionException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidOutputFolder = function (output, context) {
    return {};
};
var deserializeAws_json1_1InvalidOutputLocation = function (output, context) {
    return {};
};
var deserializeAws_json1_1InvalidParameters = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidPermissionType = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidPluginName = function (output, context) {
    return {};
};
var deserializeAws_json1_1InvalidPolicyAttributeException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1InvalidPolicyTypeException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1InvalidResourceId = function (output, context) {
    return {};
};
var deserializeAws_json1_1InvalidResourceType = function (output, context) {
    return {};
};
var deserializeAws_json1_1InvalidResultAttributeException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidRole = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidSchedule = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidTarget = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidTypeNameException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InvalidUpdate = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1InventoryDeletionsList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1InventoryDeletionStatusItem(entry, context);
    });
};
var deserializeAws_json1_1InventoryDeletionStatusItem = function (output, context) {
    return {
        DeletionId: __expectString(output.DeletionId),
        DeletionStartTime: output.DeletionStartTime !== undefined && output.DeletionStartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.DeletionStartTime)))
            : undefined,
        DeletionSummary: output.DeletionSummary !== undefined && output.DeletionSummary !== null
            ? deserializeAws_json1_1InventoryDeletionSummary(output.DeletionSummary, context)
            : undefined,
        LastStatus: __expectString(output.LastStatus),
        LastStatusMessage: __expectString(output.LastStatusMessage),
        LastStatusUpdateTime: output.LastStatusUpdateTime !== undefined && output.LastStatusUpdateTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastStatusUpdateTime)))
            : undefined,
        TypeName: __expectString(output.TypeName),
    };
};
var deserializeAws_json1_1InventoryDeletionSummary = function (output, context) {
    return {
        RemainingCount: __expectInt32(output.RemainingCount),
        SummaryItems: output.SummaryItems !== undefined && output.SummaryItems !== null
            ? deserializeAws_json1_1InventoryDeletionSummaryItems(output.SummaryItems, context)
            : undefined,
        TotalCount: __expectInt32(output.TotalCount),
    };
};
var deserializeAws_json1_1InventoryDeletionSummaryItem = function (output, context) {
    return {
        Count: __expectInt32(output.Count),
        RemainingCount: __expectInt32(output.RemainingCount),
        Version: __expectString(output.Version),
    };
};
var deserializeAws_json1_1InventoryDeletionSummaryItems = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1InventoryDeletionSummaryItem(entry, context);
    });
};
var deserializeAws_json1_1InventoryItemAttribute = function (output, context) {
    return {
        DataType: __expectString(output.DataType),
        Name: __expectString(output.Name),
    };
};
var deserializeAws_json1_1InventoryItemAttributeList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1InventoryItemAttribute(entry, context);
    });
};
var deserializeAws_json1_1InventoryItemEntry = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectString(value), _b));
    }, {});
};
var deserializeAws_json1_1InventoryItemEntryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1InventoryItemEntry(entry, context);
    });
};
var deserializeAws_json1_1InventoryItemSchema = function (output, context) {
    return {
        Attributes: output.Attributes !== undefined && output.Attributes !== null
            ? deserializeAws_json1_1InventoryItemAttributeList(output.Attributes, context)
            : undefined,
        DisplayName: __expectString(output.DisplayName),
        TypeName: __expectString(output.TypeName),
        Version: __expectString(output.Version),
    };
};
var deserializeAws_json1_1InventoryItemSchemaResultList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1InventoryItemSchema(entry, context);
    });
};
var deserializeAws_json1_1InventoryResultEntity = function (output, context) {
    return {
        Data: output.Data !== undefined && output.Data !== null
            ? deserializeAws_json1_1InventoryResultItemMap(output.Data, context)
            : undefined,
        Id: __expectString(output.Id),
    };
};
var deserializeAws_json1_1InventoryResultEntityList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1InventoryResultEntity(entry, context);
    });
};
var deserializeAws_json1_1InventoryResultItem = function (output, context) {
    return {
        CaptureTime: __expectString(output.CaptureTime),
        Content: output.Content !== undefined && output.Content !== null
            ? deserializeAws_json1_1InventoryItemEntryList(output.Content, context)
            : undefined,
        ContentHash: __expectString(output.ContentHash),
        SchemaVersion: __expectString(output.SchemaVersion),
        TypeName: __expectString(output.TypeName),
    };
};
var deserializeAws_json1_1InventoryResultItemMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_1InventoryResultItem(value, context), _b));
    }, {});
};
var deserializeAws_json1_1InvocationDoesNotExist = function (output, context) {
    return {};
};
var deserializeAws_json1_1ItemContentMismatchException = function (output, context) {
    return {
        Message: __expectString(output.Message),
        TypeName: __expectString(output.TypeName),
    };
};
var deserializeAws_json1_1ItemSizeLimitExceededException = function (output, context) {
    return {
        Message: __expectString(output.Message),
        TypeName: __expectString(output.TypeName),
    };
};
var deserializeAws_json1_1LabelParameterVersionResult = function (output, context) {
    return {
        InvalidLabels: output.InvalidLabels !== undefined && output.InvalidLabels !== null
            ? deserializeAws_json1_1ParameterLabelList(output.InvalidLabels, context)
            : undefined,
        ParameterVersion: __expectLong(output.ParameterVersion),
    };
};
var deserializeAws_json1_1ListAssociationsResult = function (output, context) {
    return {
        Associations: output.Associations !== undefined && output.Associations !== null
            ? deserializeAws_json1_1AssociationList(output.Associations, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1ListAssociationVersionsResult = function (output, context) {
    return {
        AssociationVersions: output.AssociationVersions !== undefined && output.AssociationVersions !== null
            ? deserializeAws_json1_1AssociationVersionList(output.AssociationVersions, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1ListCommandInvocationsResult = function (output, context) {
    return {
        CommandInvocations: output.CommandInvocations !== undefined && output.CommandInvocations !== null
            ? deserializeAws_json1_1CommandInvocationList(output.CommandInvocations, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1ListCommandsResult = function (output, context) {
    return {
        Commands: output.Commands !== undefined && output.Commands !== null
            ? deserializeAws_json1_1CommandList(output.Commands, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1ListComplianceItemsResult = function (output, context) {
    return {
        ComplianceItems: output.ComplianceItems !== undefined && output.ComplianceItems !== null
            ? deserializeAws_json1_1ComplianceItemList(output.ComplianceItems, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1ListComplianceSummariesResult = function (output, context) {
    return {
        ComplianceSummaryItems: output.ComplianceSummaryItems !== undefined && output.ComplianceSummaryItems !== null
            ? deserializeAws_json1_1ComplianceSummaryItemList(output.ComplianceSummaryItems, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1ListDocumentMetadataHistoryResponse = function (output, context) {
    return {
        Author: __expectString(output.Author),
        DocumentVersion: __expectString(output.DocumentVersion),
        Metadata: output.Metadata !== undefined && output.Metadata !== null
            ? deserializeAws_json1_1DocumentMetadataResponseInfo(output.Metadata, context)
            : undefined,
        Name: __expectString(output.Name),
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1ListDocumentsResult = function (output, context) {
    return {
        DocumentIdentifiers: output.DocumentIdentifiers !== undefined && output.DocumentIdentifiers !== null
            ? deserializeAws_json1_1DocumentIdentifierList(output.DocumentIdentifiers, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1ListDocumentVersionsResult = function (output, context) {
    return {
        DocumentVersions: output.DocumentVersions !== undefined && output.DocumentVersions !== null
            ? deserializeAws_json1_1DocumentVersionList(output.DocumentVersions, context)
            : undefined,
        NextToken: __expectString(output.NextToken),
    };
};
var deserializeAws_json1_1ListInventoryEntriesResult = function (output, context) {
    return {
        CaptureTime: __expectString(output.CaptureTime),
        Entries: output.Entries !== undefined && output.Entries !== null
            ? deserializeAws_json1_1InventoryItemEntryList(output.Entries, context)
            : undefined,
        InstanceId: __expectString(output.InstanceId),
        NextToken: __expectString(output.NextToken),
        SchemaVersion: __expectString(output.SchemaVersion),
        TypeName: __expectString(output.TypeName),
    };
};
var deserializeAws_json1_1ListOpsItemEventsResponse = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Summaries: output.Summaries !== undefined && output.Summaries !== null
            ? deserializeAws_json1_1OpsItemEventSummaries(output.Summaries, context)
            : undefined,
    };
};
var deserializeAws_json1_1ListOpsItemRelatedItemsResponse = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        Summaries: output.Summaries !== undefined && output.Summaries !== null
            ? deserializeAws_json1_1OpsItemRelatedItemSummaries(output.Summaries, context)
            : undefined,
    };
};
var deserializeAws_json1_1ListOpsMetadataResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        OpsMetadataList: output.OpsMetadataList !== undefined && output.OpsMetadataList !== null
            ? deserializeAws_json1_1OpsMetadataList(output.OpsMetadataList, context)
            : undefined,
    };
};
var deserializeAws_json1_1ListResourceComplianceSummariesResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        ResourceComplianceSummaryItems: output.ResourceComplianceSummaryItems !== undefined && output.ResourceComplianceSummaryItems !== null
            ? deserializeAws_json1_1ResourceComplianceSummaryItemList(output.ResourceComplianceSummaryItems, context)
            : undefined,
    };
};
var deserializeAws_json1_1ListResourceDataSyncResult = function (output, context) {
    return {
        NextToken: __expectString(output.NextToken),
        ResourceDataSyncItems: output.ResourceDataSyncItems !== undefined && output.ResourceDataSyncItems !== null
            ? deserializeAws_json1_1ResourceDataSyncItemList(output.ResourceDataSyncItems, context)
            : undefined,
    };
};
var deserializeAws_json1_1ListTagsForResourceResult = function (output, context) {
    return {
        TagList: output.TagList !== undefined && output.TagList !== null
            ? deserializeAws_json1_1TagList(output.TagList, context)
            : undefined,
    };
};
var deserializeAws_json1_1LoggingInfo = function (output, context) {
    return {
        S3BucketName: __expectString(output.S3BucketName),
        S3KeyPrefix: __expectString(output.S3KeyPrefix),
        S3Region: __expectString(output.S3Region),
    };
};
var deserializeAws_json1_1MaintenanceWindowAutomationParameters = function (output, context) {
    return {
        DocumentVersion: __expectString(output.DocumentVersion),
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1AutomationParameterMap(output.Parameters, context)
            : undefined,
    };
};
var deserializeAws_json1_1MaintenanceWindowExecution = function (output, context) {
    return {
        EndTime: output.EndTime !== undefined && output.EndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.EndTime)))
            : undefined,
        StartTime: output.StartTime !== undefined && output.StartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.StartTime)))
            : undefined,
        Status: __expectString(output.Status),
        StatusDetails: __expectString(output.StatusDetails),
        WindowExecutionId: __expectString(output.WindowExecutionId),
        WindowId: __expectString(output.WindowId),
    };
};
var deserializeAws_json1_1MaintenanceWindowExecutionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1MaintenanceWindowExecution(entry, context);
    });
};
var deserializeAws_json1_1MaintenanceWindowExecutionTaskIdentity = function (output, context) {
    return {
        EndTime: output.EndTime !== undefined && output.EndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.EndTime)))
            : undefined,
        StartTime: output.StartTime !== undefined && output.StartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.StartTime)))
            : undefined,
        Status: __expectString(output.Status),
        StatusDetails: __expectString(output.StatusDetails),
        TaskArn: __expectString(output.TaskArn),
        TaskExecutionId: __expectString(output.TaskExecutionId),
        TaskType: __expectString(output.TaskType),
        WindowExecutionId: __expectString(output.WindowExecutionId),
    };
};
var deserializeAws_json1_1MaintenanceWindowExecutionTaskIdentityList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1MaintenanceWindowExecutionTaskIdentity(entry, context);
    });
};
var deserializeAws_json1_1MaintenanceWindowExecutionTaskIdList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1MaintenanceWindowExecutionTaskInvocationIdentity = function (output, context) {
    return {
        EndTime: output.EndTime !== undefined && output.EndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.EndTime)))
            : undefined,
        ExecutionId: __expectString(output.ExecutionId),
        InvocationId: __expectString(output.InvocationId),
        OwnerInformation: __expectString(output.OwnerInformation),
        Parameters: __expectString(output.Parameters),
        StartTime: output.StartTime !== undefined && output.StartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.StartTime)))
            : undefined,
        Status: __expectString(output.Status),
        StatusDetails: __expectString(output.StatusDetails),
        TaskExecutionId: __expectString(output.TaskExecutionId),
        TaskType: __expectString(output.TaskType),
        WindowExecutionId: __expectString(output.WindowExecutionId),
        WindowTargetId: __expectString(output.WindowTargetId),
    };
};
var deserializeAws_json1_1MaintenanceWindowExecutionTaskInvocationIdentityList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1MaintenanceWindowExecutionTaskInvocationIdentity(entry, context);
    });
};
var deserializeAws_json1_1MaintenanceWindowIdentity = function (output, context) {
    return {
        Cutoff: __expectInt32(output.Cutoff),
        Description: __expectString(output.Description),
        Duration: __expectInt32(output.Duration),
        Enabled: __expectBoolean(output.Enabled),
        EndDate: __expectString(output.EndDate),
        Name: __expectString(output.Name),
        NextExecutionTime: __expectString(output.NextExecutionTime),
        Schedule: __expectString(output.Schedule),
        ScheduleOffset: __expectInt32(output.ScheduleOffset),
        ScheduleTimezone: __expectString(output.ScheduleTimezone),
        StartDate: __expectString(output.StartDate),
        WindowId: __expectString(output.WindowId),
    };
};
var deserializeAws_json1_1MaintenanceWindowIdentityForTarget = function (output, context) {
    return {
        Name: __expectString(output.Name),
        WindowId: __expectString(output.WindowId),
    };
};
var deserializeAws_json1_1MaintenanceWindowIdentityList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1MaintenanceWindowIdentity(entry, context);
    });
};
var deserializeAws_json1_1MaintenanceWindowLambdaParameters = function (output, context) {
    return {
        ClientContext: __expectString(output.ClientContext),
        Payload: output.Payload !== undefined && output.Payload !== null ? context.base64Decoder(output.Payload) : undefined,
        Qualifier: __expectString(output.Qualifier),
    };
};
var deserializeAws_json1_1MaintenanceWindowRunCommandParameters = function (output, context) {
    return {
        CloudWatchOutputConfig: output.CloudWatchOutputConfig !== undefined && output.CloudWatchOutputConfig !== null
            ? deserializeAws_json1_1CloudWatchOutputConfig(output.CloudWatchOutputConfig, context)
            : undefined,
        Comment: __expectString(output.Comment),
        DocumentHash: __expectString(output.DocumentHash),
        DocumentHashType: __expectString(output.DocumentHashType),
        DocumentVersion: __expectString(output.DocumentVersion),
        NotificationConfig: output.NotificationConfig !== undefined && output.NotificationConfig !== null
            ? deserializeAws_json1_1NotificationConfig(output.NotificationConfig, context)
            : undefined,
        OutputS3BucketName: __expectString(output.OutputS3BucketName),
        OutputS3KeyPrefix: __expectString(output.OutputS3KeyPrefix),
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1Parameters(output.Parameters, context)
            : undefined,
        ServiceRoleArn: __expectString(output.ServiceRoleArn),
        TimeoutSeconds: __expectInt32(output.TimeoutSeconds),
    };
};
var deserializeAws_json1_1MaintenanceWindowsForTargetList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1MaintenanceWindowIdentityForTarget(entry, context);
    });
};
var deserializeAws_json1_1MaintenanceWindowStepFunctionsParameters = function (output, context) {
    return {
        Input: __expectString(output.Input),
        Name: __expectString(output.Name),
    };
};
var deserializeAws_json1_1MaintenanceWindowTarget = function (output, context) {
    return {
        Description: __expectString(output.Description),
        Name: __expectString(output.Name),
        OwnerInformation: __expectString(output.OwnerInformation),
        ResourceType: __expectString(output.ResourceType),
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
        WindowId: __expectString(output.WindowId),
        WindowTargetId: __expectString(output.WindowTargetId),
    };
};
var deserializeAws_json1_1MaintenanceWindowTargetList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1MaintenanceWindowTarget(entry, context);
    });
};
var deserializeAws_json1_1MaintenanceWindowTask = function (output, context) {
    return {
        CutoffBehavior: __expectString(output.CutoffBehavior),
        Description: __expectString(output.Description),
        LoggingInfo: output.LoggingInfo !== undefined && output.LoggingInfo !== null
            ? deserializeAws_json1_1LoggingInfo(output.LoggingInfo, context)
            : undefined,
        MaxConcurrency: __expectString(output.MaxConcurrency),
        MaxErrors: __expectString(output.MaxErrors),
        Name: __expectString(output.Name),
        Priority: __expectInt32(output.Priority),
        ServiceRoleArn: __expectString(output.ServiceRoleArn),
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
        TaskArn: __expectString(output.TaskArn),
        TaskParameters: output.TaskParameters !== undefined && output.TaskParameters !== null
            ? deserializeAws_json1_1MaintenanceWindowTaskParameters(output.TaskParameters, context)
            : undefined,
        Type: __expectString(output.Type),
        WindowId: __expectString(output.WindowId),
        WindowTaskId: __expectString(output.WindowTaskId),
    };
};
var deserializeAws_json1_1MaintenanceWindowTaskInvocationParameters = function (output, context) {
    return {
        Automation: output.Automation !== undefined && output.Automation !== null
            ? deserializeAws_json1_1MaintenanceWindowAutomationParameters(output.Automation, context)
            : undefined,
        Lambda: output.Lambda !== undefined && output.Lambda !== null
            ? deserializeAws_json1_1MaintenanceWindowLambdaParameters(output.Lambda, context)
            : undefined,
        RunCommand: output.RunCommand !== undefined && output.RunCommand !== null
            ? deserializeAws_json1_1MaintenanceWindowRunCommandParameters(output.RunCommand, context)
            : undefined,
        StepFunctions: output.StepFunctions !== undefined && output.StepFunctions !== null
            ? deserializeAws_json1_1MaintenanceWindowStepFunctionsParameters(output.StepFunctions, context)
            : undefined,
    };
};
var deserializeAws_json1_1MaintenanceWindowTaskList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1MaintenanceWindowTask(entry, context);
    });
};
var deserializeAws_json1_1MaintenanceWindowTaskParameters = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_1MaintenanceWindowTaskParameterValueExpression(value, context), _b));
    }, {});
};
var deserializeAws_json1_1MaintenanceWindowTaskParametersList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1MaintenanceWindowTaskParameters(entry, context);
    });
};
var deserializeAws_json1_1MaintenanceWindowTaskParameterValueExpression = function (output, context) {
    return {
        Values: output.Values !== undefined && output.Values !== null
            ? deserializeAws_json1_1MaintenanceWindowTaskParameterValueList(output.Values, context)
            : undefined,
    };
};
var deserializeAws_json1_1MaintenanceWindowTaskParameterValueList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1MaxDocumentSizeExceeded = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1MetadataMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_1MetadataValue(value, context), _b));
    }, {});
};
var deserializeAws_json1_1MetadataValue = function (output, context) {
    return {
        Value: __expectString(output.Value),
    };
};
var deserializeAws_json1_1ModifyDocumentPermissionResponse = function (output, context) {
    return {};
};
var deserializeAws_json1_1NonCompliantSummary = function (output, context) {
    return {
        NonCompliantCount: __expectInt32(output.NonCompliantCount),
        SeveritySummary: output.SeveritySummary !== undefined && output.SeveritySummary !== null
            ? deserializeAws_json1_1SeveritySummary(output.SeveritySummary, context)
            : undefined,
    };
};
var deserializeAws_json1_1NormalStringMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectString(value), _b));
    }, {});
};
var deserializeAws_json1_1NotificationConfig = function (output, context) {
    return {
        NotificationArn: __expectString(output.NotificationArn),
        NotificationEvents: output.NotificationEvents !== undefined && output.NotificationEvents !== null
            ? deserializeAws_json1_1NotificationEventList(output.NotificationEvents, context)
            : undefined,
        NotificationType: __expectString(output.NotificationType),
    };
};
var deserializeAws_json1_1NotificationEventList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1OpsEntity = function (output, context) {
    return {
        Data: output.Data !== undefined && output.Data !== null
            ? deserializeAws_json1_1OpsEntityItemMap(output.Data, context)
            : undefined,
        Id: __expectString(output.Id),
    };
};
var deserializeAws_json1_1OpsEntityItem = function (output, context) {
    return {
        CaptureTime: __expectString(output.CaptureTime),
        Content: output.Content !== undefined && output.Content !== null
            ? deserializeAws_json1_1OpsEntityItemEntryList(output.Content, context)
            : undefined,
    };
};
var deserializeAws_json1_1OpsEntityItemEntry = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectString(value), _b));
    }, {});
};
var deserializeAws_json1_1OpsEntityItemEntryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1OpsEntityItemEntry(entry, context);
    });
};
var deserializeAws_json1_1OpsEntityItemMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_1OpsEntityItem(value, context), _b));
    }, {});
};
var deserializeAws_json1_1OpsEntityList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1OpsEntity(entry, context);
    });
};
var deserializeAws_json1_1OpsItem = function (output, context) {
    return {
        ActualEndTime: output.ActualEndTime !== undefined && output.ActualEndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ActualEndTime)))
            : undefined,
        ActualStartTime: output.ActualStartTime !== undefined && output.ActualStartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ActualStartTime)))
            : undefined,
        Category: __expectString(output.Category),
        CreatedBy: __expectString(output.CreatedBy),
        CreatedTime: output.CreatedTime !== undefined && output.CreatedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedTime)))
            : undefined,
        Description: __expectString(output.Description),
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedTime: output.LastModifiedTime !== undefined && output.LastModifiedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastModifiedTime)))
            : undefined,
        Notifications: output.Notifications !== undefined && output.Notifications !== null
            ? deserializeAws_json1_1OpsItemNotifications(output.Notifications, context)
            : undefined,
        OperationalData: output.OperationalData !== undefined && output.OperationalData !== null
            ? deserializeAws_json1_1OpsItemOperationalData(output.OperationalData, context)
            : undefined,
        OpsItemId: __expectString(output.OpsItemId),
        OpsItemType: __expectString(output.OpsItemType),
        PlannedEndTime: output.PlannedEndTime !== undefined && output.PlannedEndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.PlannedEndTime)))
            : undefined,
        PlannedStartTime: output.PlannedStartTime !== undefined && output.PlannedStartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.PlannedStartTime)))
            : undefined,
        Priority: __expectInt32(output.Priority),
        RelatedOpsItems: output.RelatedOpsItems !== undefined && output.RelatedOpsItems !== null
            ? deserializeAws_json1_1RelatedOpsItems(output.RelatedOpsItems, context)
            : undefined,
        Severity: __expectString(output.Severity),
        Source: __expectString(output.Source),
        Status: __expectString(output.Status),
        Title: __expectString(output.Title),
        Version: __expectString(output.Version),
    };
};
var deserializeAws_json1_1OpsItemAlreadyExistsException = function (output, context) {
    return {
        Message: __expectString(output.Message),
        OpsItemId: __expectString(output.OpsItemId),
    };
};
var deserializeAws_json1_1OpsItemDataValue = function (output, context) {
    return {
        Type: __expectString(output.Type),
        Value: __expectString(output.Value),
    };
};
var deserializeAws_json1_1OpsItemEventSummaries = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1OpsItemEventSummary(entry, context);
    });
};
var deserializeAws_json1_1OpsItemEventSummary = function (output, context) {
    return {
        CreatedBy: output.CreatedBy !== undefined && output.CreatedBy !== null
            ? deserializeAws_json1_1OpsItemIdentity(output.CreatedBy, context)
            : undefined,
        CreatedTime: output.CreatedTime !== undefined && output.CreatedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedTime)))
            : undefined,
        Detail: __expectString(output.Detail),
        DetailType: __expectString(output.DetailType),
        EventId: __expectString(output.EventId),
        OpsItemId: __expectString(output.OpsItemId),
        Source: __expectString(output.Source),
    };
};
var deserializeAws_json1_1OpsItemIdentity = function (output, context) {
    return {
        Arn: __expectString(output.Arn),
    };
};
var deserializeAws_json1_1OpsItemInvalidParameterException = function (output, context) {
    return {
        Message: __expectString(output.Message),
        ParameterNames: output.ParameterNames !== undefined && output.ParameterNames !== null
            ? deserializeAws_json1_1OpsItemParameterNamesList(output.ParameterNames, context)
            : undefined,
    };
};
var deserializeAws_json1_1OpsItemLimitExceededException = function (output, context) {
    return {
        Limit: __expectInt32(output.Limit),
        LimitType: __expectString(output.LimitType),
        Message: __expectString(output.Message),
        ResourceTypes: output.ResourceTypes !== undefined && output.ResourceTypes !== null
            ? deserializeAws_json1_1OpsItemParameterNamesList(output.ResourceTypes, context)
            : undefined,
    };
};
var deserializeAws_json1_1OpsItemNotFoundException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1OpsItemNotification = function (output, context) {
    return {
        Arn: __expectString(output.Arn),
    };
};
var deserializeAws_json1_1OpsItemNotifications = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1OpsItemNotification(entry, context);
    });
};
var deserializeAws_json1_1OpsItemOperationalData = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_1OpsItemDataValue(value, context), _b));
    }, {});
};
var deserializeAws_json1_1OpsItemParameterNamesList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1OpsItemRelatedItemAlreadyExistsException = function (output, context) {
    return {
        Message: __expectString(output.Message),
        OpsItemId: __expectString(output.OpsItemId),
        ResourceUri: __expectString(output.ResourceUri),
    };
};
var deserializeAws_json1_1OpsItemRelatedItemAssociationNotFoundException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1OpsItemRelatedItemSummaries = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1OpsItemRelatedItemSummary(entry, context);
    });
};
var deserializeAws_json1_1OpsItemRelatedItemSummary = function (output, context) {
    return {
        AssociationId: __expectString(output.AssociationId),
        AssociationType: __expectString(output.AssociationType),
        CreatedBy: output.CreatedBy !== undefined && output.CreatedBy !== null
            ? deserializeAws_json1_1OpsItemIdentity(output.CreatedBy, context)
            : undefined,
        CreatedTime: output.CreatedTime !== undefined && output.CreatedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedTime)))
            : undefined,
        LastModifiedBy: output.LastModifiedBy !== undefined && output.LastModifiedBy !== null
            ? deserializeAws_json1_1OpsItemIdentity(output.LastModifiedBy, context)
            : undefined,
        LastModifiedTime: output.LastModifiedTime !== undefined && output.LastModifiedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastModifiedTime)))
            : undefined,
        OpsItemId: __expectString(output.OpsItemId),
        ResourceType: __expectString(output.ResourceType),
        ResourceUri: __expectString(output.ResourceUri),
    };
};
var deserializeAws_json1_1OpsItemSummaries = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1OpsItemSummary(entry, context);
    });
};
var deserializeAws_json1_1OpsItemSummary = function (output, context) {
    return {
        ActualEndTime: output.ActualEndTime !== undefined && output.ActualEndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ActualEndTime)))
            : undefined,
        ActualStartTime: output.ActualStartTime !== undefined && output.ActualStartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ActualStartTime)))
            : undefined,
        Category: __expectString(output.Category),
        CreatedBy: __expectString(output.CreatedBy),
        CreatedTime: output.CreatedTime !== undefined && output.CreatedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedTime)))
            : undefined,
        LastModifiedBy: __expectString(output.LastModifiedBy),
        LastModifiedTime: output.LastModifiedTime !== undefined && output.LastModifiedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastModifiedTime)))
            : undefined,
        OperationalData: output.OperationalData !== undefined && output.OperationalData !== null
            ? deserializeAws_json1_1OpsItemOperationalData(output.OperationalData, context)
            : undefined,
        OpsItemId: __expectString(output.OpsItemId),
        OpsItemType: __expectString(output.OpsItemType),
        PlannedEndTime: output.PlannedEndTime !== undefined && output.PlannedEndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.PlannedEndTime)))
            : undefined,
        PlannedStartTime: output.PlannedStartTime !== undefined && output.PlannedStartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.PlannedStartTime)))
            : undefined,
        Priority: __expectInt32(output.Priority),
        Severity: __expectString(output.Severity),
        Source: __expectString(output.Source),
        Status: __expectString(output.Status),
        Title: __expectString(output.Title),
    };
};
var deserializeAws_json1_1OpsMetadata = function (output, context) {
    return {
        CreationDate: output.CreationDate !== undefined && output.CreationDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreationDate)))
            : undefined,
        LastModifiedDate: output.LastModifiedDate !== undefined && output.LastModifiedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastModifiedDate)))
            : undefined,
        LastModifiedUser: __expectString(output.LastModifiedUser),
        OpsMetadataArn: __expectString(output.OpsMetadataArn),
        ResourceId: __expectString(output.ResourceId),
    };
};
var deserializeAws_json1_1OpsMetadataAlreadyExistsException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1OpsMetadataInvalidArgumentException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1OpsMetadataKeyLimitExceededException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1OpsMetadataLimitExceededException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1OpsMetadataList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1OpsMetadata(entry, context);
    });
};
var deserializeAws_json1_1OpsMetadataNotFoundException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1OpsMetadataTooManyUpdatesException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1OutputSource = function (output, context) {
    return {
        OutputSourceId: __expectString(output.OutputSourceId),
        OutputSourceType: __expectString(output.OutputSourceType),
    };
};
var deserializeAws_json1_1Parameter = function (output, context) {
    return {
        ARN: __expectString(output.ARN),
        DataType: __expectString(output.DataType),
        LastModifiedDate: output.LastModifiedDate !== undefined && output.LastModifiedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastModifiedDate)))
            : undefined,
        Name: __expectString(output.Name),
        Selector: __expectString(output.Selector),
        SourceResult: __expectString(output.SourceResult),
        Type: __expectString(output.Type),
        Value: __expectString(output.Value),
        Version: __expectLong(output.Version),
    };
};
var deserializeAws_json1_1ParameterAlreadyExists = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1ParameterHistory = function (output, context) {
    return {
        AllowedPattern: __expectString(output.AllowedPattern),
        DataType: __expectString(output.DataType),
        Description: __expectString(output.Description),
        KeyId: __expectString(output.KeyId),
        Labels: output.Labels !== undefined && output.Labels !== null
            ? deserializeAws_json1_1ParameterLabelList(output.Labels, context)
            : undefined,
        LastModifiedDate: output.LastModifiedDate !== undefined && output.LastModifiedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastModifiedDate)))
            : undefined,
        LastModifiedUser: __expectString(output.LastModifiedUser),
        Name: __expectString(output.Name),
        Policies: output.Policies !== undefined && output.Policies !== null
            ? deserializeAws_json1_1ParameterPolicyList(output.Policies, context)
            : undefined,
        Tier: __expectString(output.Tier),
        Type: __expectString(output.Type),
        Value: __expectString(output.Value),
        Version: __expectLong(output.Version),
    };
};
var deserializeAws_json1_1ParameterHistoryList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1ParameterHistory(entry, context);
    });
};
var deserializeAws_json1_1ParameterInlinePolicy = function (output, context) {
    return {
        PolicyStatus: __expectString(output.PolicyStatus),
        PolicyText: __expectString(output.PolicyText),
        PolicyType: __expectString(output.PolicyType),
    };
};
var deserializeAws_json1_1ParameterLabelList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1ParameterLimitExceeded = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1ParameterList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1Parameter(entry, context);
    });
};
var deserializeAws_json1_1ParameterMaxVersionLimitExceeded = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1ParameterMetadata = function (output, context) {
    return {
        AllowedPattern: __expectString(output.AllowedPattern),
        DataType: __expectString(output.DataType),
        Description: __expectString(output.Description),
        KeyId: __expectString(output.KeyId),
        LastModifiedDate: output.LastModifiedDate !== undefined && output.LastModifiedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastModifiedDate)))
            : undefined,
        LastModifiedUser: __expectString(output.LastModifiedUser),
        Name: __expectString(output.Name),
        Policies: output.Policies !== undefined && output.Policies !== null
            ? deserializeAws_json1_1ParameterPolicyList(output.Policies, context)
            : undefined,
        Tier: __expectString(output.Tier),
        Type: __expectString(output.Type),
        Version: __expectLong(output.Version),
    };
};
var deserializeAws_json1_1ParameterMetadataList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1ParameterMetadata(entry, context);
    });
};
var deserializeAws_json1_1ParameterNameList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1ParameterNotFound = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1ParameterPatternMismatchException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1ParameterPolicyList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1ParameterInlinePolicy(entry, context);
    });
};
var deserializeAws_json1_1Parameters = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_1ParameterValueList(value, context), _b));
    }, {});
};
var deserializeAws_json1_1ParameterValueList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1ParameterVersionLabelLimitExceeded = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1ParameterVersionNotFound = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1Patch = function (output, context) {
    return {
        AdvisoryIds: output.AdvisoryIds !== undefined && output.AdvisoryIds !== null
            ? deserializeAws_json1_1PatchAdvisoryIdList(output.AdvisoryIds, context)
            : undefined,
        Arch: __expectString(output.Arch),
        BugzillaIds: output.BugzillaIds !== undefined && output.BugzillaIds !== null
            ? deserializeAws_json1_1PatchBugzillaIdList(output.BugzillaIds, context)
            : undefined,
        CVEIds: output.CVEIds !== undefined && output.CVEIds !== null
            ? deserializeAws_json1_1PatchCVEIdList(output.CVEIds, context)
            : undefined,
        Classification: __expectString(output.Classification),
        ContentUrl: __expectString(output.ContentUrl),
        Description: __expectString(output.Description),
        Epoch: __expectInt32(output.Epoch),
        Id: __expectString(output.Id),
        KbNumber: __expectString(output.KbNumber),
        Language: __expectString(output.Language),
        MsrcNumber: __expectString(output.MsrcNumber),
        MsrcSeverity: __expectString(output.MsrcSeverity),
        Name: __expectString(output.Name),
        Product: __expectString(output.Product),
        ProductFamily: __expectString(output.ProductFamily),
        Release: __expectString(output.Release),
        ReleaseDate: output.ReleaseDate !== undefined && output.ReleaseDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ReleaseDate)))
            : undefined,
        Repository: __expectString(output.Repository),
        Severity: __expectString(output.Severity),
        Title: __expectString(output.Title),
        Vendor: __expectString(output.Vendor),
        Version: __expectString(output.Version),
    };
};
var deserializeAws_json1_1PatchAdvisoryIdList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1PatchBaselineIdentity = function (output, context) {
    return {
        BaselineDescription: __expectString(output.BaselineDescription),
        BaselineId: __expectString(output.BaselineId),
        BaselineName: __expectString(output.BaselineName),
        DefaultBaseline: __expectBoolean(output.DefaultBaseline),
        OperatingSystem: __expectString(output.OperatingSystem),
    };
};
var deserializeAws_json1_1PatchBaselineIdentityList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1PatchBaselineIdentity(entry, context);
    });
};
var deserializeAws_json1_1PatchBugzillaIdList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1PatchComplianceData = function (output, context) {
    return {
        CVEIds: __expectString(output.CVEIds),
        Classification: __expectString(output.Classification),
        InstalledTime: output.InstalledTime !== undefined && output.InstalledTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.InstalledTime)))
            : undefined,
        KBId: __expectString(output.KBId),
        Severity: __expectString(output.Severity),
        State: __expectString(output.State),
        Title: __expectString(output.Title),
    };
};
var deserializeAws_json1_1PatchComplianceDataList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1PatchComplianceData(entry, context);
    });
};
var deserializeAws_json1_1PatchCVEIdList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1PatchFilter = function (output, context) {
    return {
        Key: __expectString(output.Key),
        Values: output.Values !== undefined && output.Values !== null
            ? deserializeAws_json1_1PatchFilterValueList(output.Values, context)
            : undefined,
    };
};
var deserializeAws_json1_1PatchFilterGroup = function (output, context) {
    return {
        PatchFilters: output.PatchFilters !== undefined && output.PatchFilters !== null
            ? deserializeAws_json1_1PatchFilterList(output.PatchFilters, context)
            : undefined,
    };
};
var deserializeAws_json1_1PatchFilterList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1PatchFilter(entry, context);
    });
};
var deserializeAws_json1_1PatchFilterValueList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1PatchGroupList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1PatchGroupPatchBaselineMapping = function (output, context) {
    return {
        BaselineIdentity: output.BaselineIdentity !== undefined && output.BaselineIdentity !== null
            ? deserializeAws_json1_1PatchBaselineIdentity(output.BaselineIdentity, context)
            : undefined,
        PatchGroup: __expectString(output.PatchGroup),
    };
};
var deserializeAws_json1_1PatchGroupPatchBaselineMappingList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1PatchGroupPatchBaselineMapping(entry, context);
    });
};
var deserializeAws_json1_1PatchIdList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1PatchList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1Patch(entry, context);
    });
};
var deserializeAws_json1_1PatchPropertiesList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1PatchPropertyEntry(entry, context);
    });
};
var deserializeAws_json1_1PatchPropertyEntry = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = __expectString(value), _b));
    }, {});
};
var deserializeAws_json1_1PatchRule = function (output, context) {
    return {
        ApproveAfterDays: __expectInt32(output.ApproveAfterDays),
        ApproveUntilDate: __expectString(output.ApproveUntilDate),
        ComplianceLevel: __expectString(output.ComplianceLevel),
        EnableNonSecurity: __expectBoolean(output.EnableNonSecurity),
        PatchFilterGroup: output.PatchFilterGroup !== undefined && output.PatchFilterGroup !== null
            ? deserializeAws_json1_1PatchFilterGroup(output.PatchFilterGroup, context)
            : undefined,
    };
};
var deserializeAws_json1_1PatchRuleGroup = function (output, context) {
    return {
        PatchRules: output.PatchRules !== undefined && output.PatchRules !== null
            ? deserializeAws_json1_1PatchRuleList(output.PatchRules, context)
            : undefined,
    };
};
var deserializeAws_json1_1PatchRuleList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1PatchRule(entry, context);
    });
};
var deserializeAws_json1_1PatchSource = function (output, context) {
    return {
        Configuration: __expectString(output.Configuration),
        Name: __expectString(output.Name),
        Products: output.Products !== undefined && output.Products !== null
            ? deserializeAws_json1_1PatchSourceProductList(output.Products, context)
            : undefined,
    };
};
var deserializeAws_json1_1PatchSourceList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1PatchSource(entry, context);
    });
};
var deserializeAws_json1_1PatchSourceProductList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1PatchStatus = function (output, context) {
    return {
        ApprovalDate: output.ApprovalDate !== undefined && output.ApprovalDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ApprovalDate)))
            : undefined,
        ComplianceLevel: __expectString(output.ComplianceLevel),
        DeploymentStatus: __expectString(output.DeploymentStatus),
    };
};
var deserializeAws_json1_1PlatformTypeList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1PoliciesLimitExceededException = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1ProgressCounters = function (output, context) {
    return {
        CancelledSteps: __expectInt32(output.CancelledSteps),
        FailedSteps: __expectInt32(output.FailedSteps),
        SuccessSteps: __expectInt32(output.SuccessSteps),
        TimedOutSteps: __expectInt32(output.TimedOutSteps),
        TotalSteps: __expectInt32(output.TotalSteps),
    };
};
var deserializeAws_json1_1PutComplianceItemsResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1PutInventoryResult = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1PutParameterResult = function (output, context) {
    return {
        Tier: __expectString(output.Tier),
        Version: __expectLong(output.Version),
    };
};
var deserializeAws_json1_1Regions = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1RegisterDefaultPatchBaselineResult = function (output, context) {
    return {
        BaselineId: __expectString(output.BaselineId),
    };
};
var deserializeAws_json1_1RegisterPatchBaselineForPatchGroupResult = function (output, context) {
    return {
        BaselineId: __expectString(output.BaselineId),
        PatchGroup: __expectString(output.PatchGroup),
    };
};
var deserializeAws_json1_1RegisterTargetWithMaintenanceWindowResult = function (output, context) {
    return {
        WindowTargetId: __expectString(output.WindowTargetId),
    };
};
var deserializeAws_json1_1RegisterTaskWithMaintenanceWindowResult = function (output, context) {
    return {
        WindowTaskId: __expectString(output.WindowTaskId),
    };
};
var deserializeAws_json1_1RelatedOpsItem = function (output, context) {
    return {
        OpsItemId: __expectString(output.OpsItemId),
    };
};
var deserializeAws_json1_1RelatedOpsItems = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1RelatedOpsItem(entry, context);
    });
};
var deserializeAws_json1_1RemoveTagsFromResourceResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1ResetServiceSettingResult = function (output, context) {
    return {
        ServiceSetting: output.ServiceSetting !== undefined && output.ServiceSetting !== null
            ? deserializeAws_json1_1ServiceSetting(output.ServiceSetting, context)
            : undefined,
    };
};
var deserializeAws_json1_1ResolvedTargets = function (output, context) {
    return {
        ParameterValues: output.ParameterValues !== undefined && output.ParameterValues !== null
            ? deserializeAws_json1_1TargetParameterList(output.ParameterValues, context)
            : undefined,
        Truncated: __expectBoolean(output.Truncated),
    };
};
var deserializeAws_json1_1ResourceComplianceSummaryItem = function (output, context) {
    return {
        ComplianceType: __expectString(output.ComplianceType),
        CompliantSummary: output.CompliantSummary !== undefined && output.CompliantSummary !== null
            ? deserializeAws_json1_1CompliantSummary(output.CompliantSummary, context)
            : undefined,
        ExecutionSummary: output.ExecutionSummary !== undefined && output.ExecutionSummary !== null
            ? deserializeAws_json1_1ComplianceExecutionSummary(output.ExecutionSummary, context)
            : undefined,
        NonCompliantSummary: output.NonCompliantSummary !== undefined && output.NonCompliantSummary !== null
            ? deserializeAws_json1_1NonCompliantSummary(output.NonCompliantSummary, context)
            : undefined,
        OverallSeverity: __expectString(output.OverallSeverity),
        ResourceId: __expectString(output.ResourceId),
        ResourceType: __expectString(output.ResourceType),
        Status: __expectString(output.Status),
    };
};
var deserializeAws_json1_1ResourceComplianceSummaryItemList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1ResourceComplianceSummaryItem(entry, context);
    });
};
var deserializeAws_json1_1ResourceDataSyncAlreadyExistsException = function (output, context) {
    return {
        SyncName: __expectString(output.SyncName),
    };
};
var deserializeAws_json1_1ResourceDataSyncAwsOrganizationsSource = function (output, context) {
    return {
        OrganizationSourceType: __expectString(output.OrganizationSourceType),
        OrganizationalUnits: output.OrganizationalUnits !== undefined && output.OrganizationalUnits !== null
            ? deserializeAws_json1_1ResourceDataSyncOrganizationalUnitList(output.OrganizationalUnits, context)
            : undefined,
    };
};
var deserializeAws_json1_1ResourceDataSyncConflictException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1ResourceDataSyncCountExceededException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1ResourceDataSyncDestinationDataSharing = function (output, context) {
    return {
        DestinationDataSharingType: __expectString(output.DestinationDataSharingType),
    };
};
var deserializeAws_json1_1ResourceDataSyncInvalidConfigurationException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1ResourceDataSyncItem = function (output, context) {
    return {
        LastStatus: __expectString(output.LastStatus),
        LastSuccessfulSyncTime: output.LastSuccessfulSyncTime !== undefined && output.LastSuccessfulSyncTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastSuccessfulSyncTime)))
            : undefined,
        LastSyncStatusMessage: __expectString(output.LastSyncStatusMessage),
        LastSyncTime: output.LastSyncTime !== undefined && output.LastSyncTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastSyncTime)))
            : undefined,
        S3Destination: output.S3Destination !== undefined && output.S3Destination !== null
            ? deserializeAws_json1_1ResourceDataSyncS3Destination(output.S3Destination, context)
            : undefined,
        SyncCreatedTime: output.SyncCreatedTime !== undefined && output.SyncCreatedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.SyncCreatedTime)))
            : undefined,
        SyncLastModifiedTime: output.SyncLastModifiedTime !== undefined && output.SyncLastModifiedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.SyncLastModifiedTime)))
            : undefined,
        SyncName: __expectString(output.SyncName),
        SyncSource: output.SyncSource !== undefined && output.SyncSource !== null
            ? deserializeAws_json1_1ResourceDataSyncSourceWithState(output.SyncSource, context)
            : undefined,
        SyncType: __expectString(output.SyncType),
    };
};
var deserializeAws_json1_1ResourceDataSyncItemList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1ResourceDataSyncItem(entry, context);
    });
};
var deserializeAws_json1_1ResourceDataSyncNotFoundException = function (output, context) {
    return {
        Message: __expectString(output.Message),
        SyncName: __expectString(output.SyncName),
        SyncType: __expectString(output.SyncType),
    };
};
var deserializeAws_json1_1ResourceDataSyncOrganizationalUnit = function (output, context) {
    return {
        OrganizationalUnitId: __expectString(output.OrganizationalUnitId),
    };
};
var deserializeAws_json1_1ResourceDataSyncOrganizationalUnitList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1ResourceDataSyncOrganizationalUnit(entry, context);
    });
};
var deserializeAws_json1_1ResourceDataSyncS3Destination = function (output, context) {
    return {
        AWSKMSKeyARN: __expectString(output.AWSKMSKeyARN),
        BucketName: __expectString(output.BucketName),
        DestinationDataSharing: output.DestinationDataSharing !== undefined && output.DestinationDataSharing !== null
            ? deserializeAws_json1_1ResourceDataSyncDestinationDataSharing(output.DestinationDataSharing, context)
            : undefined,
        Prefix: __expectString(output.Prefix),
        Region: __expectString(output.Region),
        SyncFormat: __expectString(output.SyncFormat),
    };
};
var deserializeAws_json1_1ResourceDataSyncSourceRegionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1ResourceDataSyncSourceWithState = function (output, context) {
    return {
        AwsOrganizationsSource: output.AwsOrganizationsSource !== undefined && output.AwsOrganizationsSource !== null
            ? deserializeAws_json1_1ResourceDataSyncAwsOrganizationsSource(output.AwsOrganizationsSource, context)
            : undefined,
        EnableAllOpsDataSources: __expectBoolean(output.EnableAllOpsDataSources),
        IncludeFutureRegions: __expectBoolean(output.IncludeFutureRegions),
        SourceRegions: output.SourceRegions !== undefined && output.SourceRegions !== null
            ? deserializeAws_json1_1ResourceDataSyncSourceRegionList(output.SourceRegions, context)
            : undefined,
        SourceType: __expectString(output.SourceType),
        State: __expectString(output.State),
    };
};
var deserializeAws_json1_1ResourceInUseException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1ResourceLimitExceededException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1ResumeSessionResponse = function (output, context) {
    return {
        SessionId: __expectString(output.SessionId),
        StreamUrl: __expectString(output.StreamUrl),
        TokenValue: __expectString(output.TokenValue),
    };
};
var deserializeAws_json1_1ReviewInformation = function (output, context) {
    return {
        ReviewedTime: output.ReviewedTime !== undefined && output.ReviewedTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ReviewedTime)))
            : undefined,
        Reviewer: __expectString(output.Reviewer),
        Status: __expectString(output.Status),
    };
};
var deserializeAws_json1_1ReviewInformationList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1ReviewInformation(entry, context);
    });
};
var deserializeAws_json1_1Runbook = function (output, context) {
    return {
        DocumentName: __expectString(output.DocumentName),
        DocumentVersion: __expectString(output.DocumentVersion),
        MaxConcurrency: __expectString(output.MaxConcurrency),
        MaxErrors: __expectString(output.MaxErrors),
        Parameters: output.Parameters !== undefined && output.Parameters !== null
            ? deserializeAws_json1_1AutomationParameterMap(output.Parameters, context)
            : undefined,
        TargetLocations: output.TargetLocations !== undefined && output.TargetLocations !== null
            ? deserializeAws_json1_1TargetLocations(output.TargetLocations, context)
            : undefined,
        TargetParameterName: __expectString(output.TargetParameterName),
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
    };
};
var deserializeAws_json1_1Runbooks = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1Runbook(entry, context);
    });
};
var deserializeAws_json1_1S3OutputLocation = function (output, context) {
    return {
        OutputS3BucketName: __expectString(output.OutputS3BucketName),
        OutputS3KeyPrefix: __expectString(output.OutputS3KeyPrefix),
        OutputS3Region: __expectString(output.OutputS3Region),
    };
};
var deserializeAws_json1_1S3OutputUrl = function (output, context) {
    return {
        OutputUrl: __expectString(output.OutputUrl),
    };
};
var deserializeAws_json1_1ScheduledWindowExecution = function (output, context) {
    return {
        ExecutionTime: __expectString(output.ExecutionTime),
        Name: __expectString(output.Name),
        WindowId: __expectString(output.WindowId),
    };
};
var deserializeAws_json1_1ScheduledWindowExecutionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1ScheduledWindowExecution(entry, context);
    });
};
var deserializeAws_json1_1SendAutomationSignalResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1SendCommandResult = function (output, context) {
    return {
        Command: output.Command !== undefined && output.Command !== null
            ? deserializeAws_json1_1Command(output.Command, context)
            : undefined,
    };
};
var deserializeAws_json1_1ServiceSetting = function (output, context) {
    return {
        ARN: __expectString(output.ARN),
        LastModifiedDate: output.LastModifiedDate !== undefined && output.LastModifiedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.LastModifiedDate)))
            : undefined,
        LastModifiedUser: __expectString(output.LastModifiedUser),
        SettingId: __expectString(output.SettingId),
        SettingValue: __expectString(output.SettingValue),
        Status: __expectString(output.Status),
    };
};
var deserializeAws_json1_1ServiceSettingNotFound = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1Session = function (output, context) {
    return {
        Details: __expectString(output.Details),
        DocumentName: __expectString(output.DocumentName),
        EndDate: output.EndDate !== undefined && output.EndDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.EndDate)))
            : undefined,
        MaxSessionDuration: __expectString(output.MaxSessionDuration),
        OutputUrl: output.OutputUrl !== undefined && output.OutputUrl !== null
            ? deserializeAws_json1_1SessionManagerOutputUrl(output.OutputUrl, context)
            : undefined,
        Owner: __expectString(output.Owner),
        Reason: __expectString(output.Reason),
        SessionId: __expectString(output.SessionId),
        StartDate: output.StartDate !== undefined && output.StartDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.StartDate)))
            : undefined,
        Status: __expectString(output.Status),
        Target: __expectString(output.Target),
    };
};
var deserializeAws_json1_1SessionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1Session(entry, context);
    });
};
var deserializeAws_json1_1SessionManagerOutputUrl = function (output, context) {
    return {
        CloudWatchOutputUrl: __expectString(output.CloudWatchOutputUrl),
        S3OutputUrl: __expectString(output.S3OutputUrl),
    };
};
var deserializeAws_json1_1SeveritySummary = function (output, context) {
    return {
        CriticalCount: __expectInt32(output.CriticalCount),
        HighCount: __expectInt32(output.HighCount),
        InformationalCount: __expectInt32(output.InformationalCount),
        LowCount: __expectInt32(output.LowCount),
        MediumCount: __expectInt32(output.MediumCount),
        UnspecifiedCount: __expectInt32(output.UnspecifiedCount),
    };
};
var deserializeAws_json1_1StartAssociationsOnceResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1StartAutomationExecutionResult = function (output, context) {
    return {
        AutomationExecutionId: __expectString(output.AutomationExecutionId),
    };
};
var deserializeAws_json1_1StartChangeRequestExecutionResult = function (output, context) {
    return {
        AutomationExecutionId: __expectString(output.AutomationExecutionId),
    };
};
var deserializeAws_json1_1StartSessionResponse = function (output, context) {
    return {
        SessionId: __expectString(output.SessionId),
        StreamUrl: __expectString(output.StreamUrl),
        TokenValue: __expectString(output.TokenValue),
    };
};
var deserializeAws_json1_1StatusUnchanged = function (output, context) {
    return {};
};
var deserializeAws_json1_1StepExecution = function (output, context) {
    return {
        Action: __expectString(output.Action),
        ExecutionEndTime: output.ExecutionEndTime !== undefined && output.ExecutionEndTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ExecutionEndTime)))
            : undefined,
        ExecutionStartTime: output.ExecutionStartTime !== undefined && output.ExecutionStartTime !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ExecutionStartTime)))
            : undefined,
        FailureDetails: output.FailureDetails !== undefined && output.FailureDetails !== null
            ? deserializeAws_json1_1FailureDetails(output.FailureDetails, context)
            : undefined,
        FailureMessage: __expectString(output.FailureMessage),
        Inputs: output.Inputs !== undefined && output.Inputs !== null
            ? deserializeAws_json1_1NormalStringMap(output.Inputs, context)
            : undefined,
        IsCritical: __expectBoolean(output.IsCritical),
        IsEnd: __expectBoolean(output.IsEnd),
        MaxAttempts: __expectInt32(output.MaxAttempts),
        NextStep: __expectString(output.NextStep),
        OnFailure: __expectString(output.OnFailure),
        Outputs: output.Outputs !== undefined && output.Outputs !== null
            ? deserializeAws_json1_1AutomationParameterMap(output.Outputs, context)
            : undefined,
        OverriddenParameters: output.OverriddenParameters !== undefined && output.OverriddenParameters !== null
            ? deserializeAws_json1_1AutomationParameterMap(output.OverriddenParameters, context)
            : undefined,
        Response: __expectString(output.Response),
        ResponseCode: __expectString(output.ResponseCode),
        StepExecutionId: __expectString(output.StepExecutionId),
        StepName: __expectString(output.StepName),
        StepStatus: __expectString(output.StepStatus),
        TargetLocation: output.TargetLocation !== undefined && output.TargetLocation !== null
            ? deserializeAws_json1_1TargetLocation(output.TargetLocation, context)
            : undefined,
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
        TimeoutSeconds: __expectLong(output.TimeoutSeconds),
        ValidNextSteps: output.ValidNextSteps !== undefined && output.ValidNextSteps !== null
            ? deserializeAws_json1_1ValidNextStepList(output.ValidNextSteps, context)
            : undefined,
    };
};
var deserializeAws_json1_1StepExecutionList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1StepExecution(entry, context);
    });
};
var deserializeAws_json1_1StopAutomationExecutionResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1SubTypeCountLimitExceededException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1Tag = function (output, context) {
    return {
        Key: __expectString(output.Key),
        Value: __expectString(output.Value),
    };
};
var deserializeAws_json1_1TagList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1Tag(entry, context);
    });
};
var deserializeAws_json1_1Target = function (output, context) {
    return {
        Key: __expectString(output.Key),
        Values: output.Values !== undefined && output.Values !== null
            ? deserializeAws_json1_1TargetValues(output.Values, context)
            : undefined,
    };
};
var deserializeAws_json1_1TargetInUseException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1TargetLocation = function (output, context) {
    return {
        Accounts: output.Accounts !== undefined && output.Accounts !== null
            ? deserializeAws_json1_1Accounts(output.Accounts, context)
            : undefined,
        ExecutionRoleName: __expectString(output.ExecutionRoleName),
        Regions: output.Regions !== undefined && output.Regions !== null
            ? deserializeAws_json1_1Regions(output.Regions, context)
            : undefined,
        TargetLocationMaxConcurrency: __expectString(output.TargetLocationMaxConcurrency),
        TargetLocationMaxErrors: __expectString(output.TargetLocationMaxErrors),
    };
};
var deserializeAws_json1_1TargetLocations = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1TargetLocation(entry, context);
    });
};
var deserializeAws_json1_1TargetMap = function (output, context) {
    return Object.entries(output).reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), key = _c[0], value = _c[1];
        if (value === null) {
            return acc;
        }
        return __assign(__assign({}, acc), (_b = {}, _b[key] = deserializeAws_json1_1TargetMapValueList(value, context), _b));
    }, {});
};
var deserializeAws_json1_1TargetMaps = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1TargetMap(entry, context);
    });
};
var deserializeAws_json1_1TargetMapValueList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1TargetNotConnected = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1TargetParameterList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1Targets = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return deserializeAws_json1_1Target(entry, context);
    });
};
var deserializeAws_json1_1TargetValues = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeAws_json1_1TerminateSessionResponse = function (output, context) {
    return {
        SessionId: __expectString(output.SessionId),
    };
};
var deserializeAws_json1_1TooManyTagsError = function (output, context) {
    return {};
};
var deserializeAws_json1_1TooManyUpdates = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1TotalSizeLimitExceededException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1UnlabelParameterVersionResult = function (output, context) {
    return {
        InvalidLabels: output.InvalidLabels !== undefined && output.InvalidLabels !== null
            ? deserializeAws_json1_1ParameterLabelList(output.InvalidLabels, context)
            : undefined,
        RemovedLabels: output.RemovedLabels !== undefined && output.RemovedLabels !== null
            ? deserializeAws_json1_1ParameterLabelList(output.RemovedLabels, context)
            : undefined,
    };
};
var deserializeAws_json1_1UnsupportedCalendarException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1UnsupportedFeatureRequiredException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1UnsupportedInventoryItemContextException = function (output, context) {
    return {
        Message: __expectString(output.Message),
        TypeName: __expectString(output.TypeName),
    };
};
var deserializeAws_json1_1UnsupportedInventorySchemaVersionException = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1UnsupportedOperatingSystem = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1UnsupportedParameterType = function (output, context) {
    return {
        message: __expectString(output.message),
    };
};
var deserializeAws_json1_1UnsupportedPlatformType = function (output, context) {
    return {
        Message: __expectString(output.Message),
    };
};
var deserializeAws_json1_1UpdateAssociationResult = function (output, context) {
    return {
        AssociationDescription: output.AssociationDescription !== undefined && output.AssociationDescription !== null
            ? deserializeAws_json1_1AssociationDescription(output.AssociationDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_1UpdateAssociationStatusResult = function (output, context) {
    return {
        AssociationDescription: output.AssociationDescription !== undefined && output.AssociationDescription !== null
            ? deserializeAws_json1_1AssociationDescription(output.AssociationDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_1UpdateDocumentDefaultVersionResult = function (output, context) {
    return {
        Description: output.Description !== undefined && output.Description !== null
            ? deserializeAws_json1_1DocumentDefaultVersionDescription(output.Description, context)
            : undefined,
    };
};
var deserializeAws_json1_1UpdateDocumentMetadataResponse = function (output, context) {
    return {};
};
var deserializeAws_json1_1UpdateDocumentResult = function (output, context) {
    return {
        DocumentDescription: output.DocumentDescription !== undefined && output.DocumentDescription !== null
            ? deserializeAws_json1_1DocumentDescription(output.DocumentDescription, context)
            : undefined,
    };
};
var deserializeAws_json1_1UpdateMaintenanceWindowResult = function (output, context) {
    return {
        AllowUnassociatedTargets: __expectBoolean(output.AllowUnassociatedTargets),
        Cutoff: __expectInt32(output.Cutoff),
        Description: __expectString(output.Description),
        Duration: __expectInt32(output.Duration),
        Enabled: __expectBoolean(output.Enabled),
        EndDate: __expectString(output.EndDate),
        Name: __expectString(output.Name),
        Schedule: __expectString(output.Schedule),
        ScheduleOffset: __expectInt32(output.ScheduleOffset),
        ScheduleTimezone: __expectString(output.ScheduleTimezone),
        StartDate: __expectString(output.StartDate),
        WindowId: __expectString(output.WindowId),
    };
};
var deserializeAws_json1_1UpdateMaintenanceWindowTargetResult = function (output, context) {
    return {
        Description: __expectString(output.Description),
        Name: __expectString(output.Name),
        OwnerInformation: __expectString(output.OwnerInformation),
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
        WindowId: __expectString(output.WindowId),
        WindowTargetId: __expectString(output.WindowTargetId),
    };
};
var deserializeAws_json1_1UpdateMaintenanceWindowTaskResult = function (output, context) {
    return {
        CutoffBehavior: __expectString(output.CutoffBehavior),
        Description: __expectString(output.Description),
        LoggingInfo: output.LoggingInfo !== undefined && output.LoggingInfo !== null
            ? deserializeAws_json1_1LoggingInfo(output.LoggingInfo, context)
            : undefined,
        MaxConcurrency: __expectString(output.MaxConcurrency),
        MaxErrors: __expectString(output.MaxErrors),
        Name: __expectString(output.Name),
        Priority: __expectInt32(output.Priority),
        ServiceRoleArn: __expectString(output.ServiceRoleArn),
        Targets: output.Targets !== undefined && output.Targets !== null
            ? deserializeAws_json1_1Targets(output.Targets, context)
            : undefined,
        TaskArn: __expectString(output.TaskArn),
        TaskInvocationParameters: output.TaskInvocationParameters !== undefined && output.TaskInvocationParameters !== null
            ? deserializeAws_json1_1MaintenanceWindowTaskInvocationParameters(output.TaskInvocationParameters, context)
            : undefined,
        TaskParameters: output.TaskParameters !== undefined && output.TaskParameters !== null
            ? deserializeAws_json1_1MaintenanceWindowTaskParameters(output.TaskParameters, context)
            : undefined,
        WindowId: __expectString(output.WindowId),
        WindowTaskId: __expectString(output.WindowTaskId),
    };
};
var deserializeAws_json1_1UpdateManagedInstanceRoleResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1UpdateOpsItemResponse = function (output, context) {
    return {};
};
var deserializeAws_json1_1UpdateOpsMetadataResult = function (output, context) {
    return {
        OpsMetadataArn: __expectString(output.OpsMetadataArn),
    };
};
var deserializeAws_json1_1UpdatePatchBaselineResult = function (output, context) {
    return {
        ApprovalRules: output.ApprovalRules !== undefined && output.ApprovalRules !== null
            ? deserializeAws_json1_1PatchRuleGroup(output.ApprovalRules, context)
            : undefined,
        ApprovedPatches: output.ApprovedPatches !== undefined && output.ApprovedPatches !== null
            ? deserializeAws_json1_1PatchIdList(output.ApprovedPatches, context)
            : undefined,
        ApprovedPatchesComplianceLevel: __expectString(output.ApprovedPatchesComplianceLevel),
        ApprovedPatchesEnableNonSecurity: __expectBoolean(output.ApprovedPatchesEnableNonSecurity),
        BaselineId: __expectString(output.BaselineId),
        CreatedDate: output.CreatedDate !== undefined && output.CreatedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.CreatedDate)))
            : undefined,
        Description: __expectString(output.Description),
        GlobalFilters: output.GlobalFilters !== undefined && output.GlobalFilters !== null
            ? deserializeAws_json1_1PatchFilterGroup(output.GlobalFilters, context)
            : undefined,
        ModifiedDate: output.ModifiedDate !== undefined && output.ModifiedDate !== null
            ? __expectNonNull(__parseEpochTimestamp(__expectNumber(output.ModifiedDate)))
            : undefined,
        Name: __expectString(output.Name),
        OperatingSystem: __expectString(output.OperatingSystem),
        RejectedPatches: output.RejectedPatches !== undefined && output.RejectedPatches !== null
            ? deserializeAws_json1_1PatchIdList(output.RejectedPatches, context)
            : undefined,
        RejectedPatchesAction: __expectString(output.RejectedPatchesAction),
        Sources: output.Sources !== undefined && output.Sources !== null
            ? deserializeAws_json1_1PatchSourceList(output.Sources, context)
            : undefined,
    };
};
var deserializeAws_json1_1UpdateResourceDataSyncResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1UpdateServiceSettingResult = function (output, context) {
    return {};
};
var deserializeAws_json1_1ValidNextStepList = function (output, context) {
    return (output || [])
        .filter(function (e) { return e != null; })
        .map(function (entry) {
        if (entry === null) {
            return null;
        }
        return __expectString(entry);
    });
};
var deserializeMetadata = function (output) {
    var _a;
    return ({
        httpStatusCode: output.statusCode,
        requestId: (_a = output.headers["x-amzn-requestid"]) !== null && _a !== void 0 ? _a : output.headers["x-amzn-request-id"],
        extendedRequestId: output.headers["x-amz-id-2"],
        cfId: output.headers["x-amz-cf-id"],
    });
};
var collectBody = function (streamBody, context) {
    if (streamBody === void 0) { streamBody = new Uint8Array(); }
    if (streamBody instanceof Uint8Array) {
        return Promise.resolve(streamBody);
    }
    return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
var collectBodyString = function (streamBody, context) {
    return collectBody(streamBody, context).then(function (body) { return context.utf8Encoder(body); });
};
var buildHttpRpcRequest = function (context, headers, path, resolvedHostname, body) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, hostname, _b, protocol, port, basePath, contents;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4, context.endpoint()];
            case 1:
                _a = _c.sent(), hostname = _a.hostname, _b = _a.protocol, protocol = _b === void 0 ? "https" : _b, port = _a.port, basePath = _a.path;
                contents = {
                    protocol: protocol,
                    hostname: hostname,
                    port: port,
                    method: "POST",
                    path: basePath.endsWith("/") ? basePath.slice(0, -1) + path : basePath + path,
                    headers: headers,
                };
                if (resolvedHostname !== undefined) {
                    contents.hostname = resolvedHostname;
                }
                if (body !== undefined) {
                    contents.body = body;
                }
                return [2, new __HttpRequest(contents)];
        }
    });
}); };
var parseBody = function (streamBody, context) {
    return collectBodyString(streamBody, context).then(function (encoded) {
        if (encoded.length) {
            return JSON.parse(encoded);
        }
        return {};
    });
};
var loadRestJsonErrorCode = function (output, data) {
    var findKey = function (object, key) { return Object.keys(object).find(function (k) { return k.toLowerCase() === key.toLowerCase(); }); };
    var sanitizeErrorCode = function (rawValue) {
        var cleanValue = rawValue;
        if (cleanValue.indexOf(":") >= 0) {
            cleanValue = cleanValue.split(":")[0];
        }
        if (cleanValue.indexOf("#") >= 0) {
            cleanValue = cleanValue.split("#")[1];
        }
        return cleanValue;
    };
    var headerKey = findKey(output.headers, "x-amzn-errortype");
    if (headerKey !== undefined) {
        return sanitizeErrorCode(output.headers[headerKey]);
    }
    if (data.code !== undefined) {
        return sanitizeErrorCode(data.code);
    }
    if (data["__type"] !== undefined) {
        return sanitizeErrorCode(data["__type"]);
    }
    return "";
};
