import React, { useContext } from 'react';
import FluxesContext, { FluxesContextInterface } from '../../context/Fluxes';
import ExportItem from './ExportItem';

function ExportsTable() {
    const { exports } = useContext<FluxesContextInterface>(FluxesContext);
    
    if (exports.length === 0) {
        return <div>Aucun export disponible</div>;
    }
    return (
        <table className="c-table c-table_border_top_none c-table_number">
            <thead className="c-table__header">
                <tr className="c-table__row">
                    <th className="c-table__cell" style={{width: '70%'}}>
                        Nom
                    </th>
                    <th className="c-table__cell u-text-align__center">
                        Date d'achèvement
                    </th>
                    <th className="c-table__cell u-text-align__center">
                        Téléchargement
                    </th>
                </tr>
            </thead>
            <tbody className="c-table__body">
            {exports.sort((a, b) => b - a).map((exportFile) => (
                <ExportItem key={exportFile.name} exportFile={exportFile} />
            ))}
            </tbody>
        </table>
    );
}

export default ExportsTable;
