import { getRangeFromPage } from "../helpers/paginationHelpers";
import createToken, { GrantType, PostTokenResponse } from "./apiv1Authentication";
import { Config } from "./getConfig";

interface Event {
    id: number;
    name: string;
    begin: number;
    end: number;
    orga: {
        id: number;
    };
}

export async function getEvents(apiV1Config: Config['apiV1Config'], accessToken: string): Promise<Event[] | PostTokenResponse> {
    let events: Event[] = [];
    let eventsRemaining = false;
    let page = 0;
    const pageSize = 30;
    do {
        const range = getRangeFromPage(page, pageSize);

        try {            
            const rawResult = await fetch(`${apiV1Config.url}/events?range=${range}&end_after_day=180&orderBy=begin&is_draft=false`, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                credentials: 'include',
            })
            .then((res) => res.json());
            
            if (rawResult.status === 401) {
                const apiv1Result = await createToken({
                    clientId: apiV1Config.clientId,
                    clientSecret: apiV1Config.clientSecret,
                    grantType: GrantType.CLIENT_CREDENTIALS,
                    scope: apiV1Config.scope,
                }, apiV1Config.url);
                return apiv1Result;
            }
    
            events = [
                ...events,
                ...rawResult.results,
            ];
            eventsRemaining = (rawResult.paging?.total ?? 0) > events.length;
            page += 1;
        } catch (error) {
            console.error('getEvents error', error);
        }

    } while (eventsRemaining);

    return events;
}
