
import { ECSClient, RunTaskCommand } from "@aws-sdk/client-ecs";
import  { Config } from "./getConfig";

export default async function generateExport(
    ecsClient: ECSClient,
    eventId: number,
    config: Config,
    date: Date,
    sendToTarget: boolean,
): Promise<boolean> {
    const utcTimestamp = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
    ) / 1000;
    const cliCommand = (sendToTarget ? [] : ['--no-send-sftp']).concat(["--since", `${utcTimestamp}`, `${eventId}`]);
    const command = new RunTaskCommand({
        cluster: config.awsEcs.Cluster,
        taskDefinition: `${config.awsTaskDefinitionNamePrefix}${eventId}`,
        overrides: {
            containerOverrides: [{
                name: 'php',
                command: cliCommand,
            }],
        },
        networkConfiguration: {
            awsvpcConfiguration: {
                assignPublicIp: config.awsEcs.NetworkConfiguration.awsvpcConfiguration.AssignPublicIp,
                securityGroups: config.awsEcs.NetworkConfiguration.awsvpcConfiguration.SecurityGroups,
                subnets: config.awsEcs.NetworkConfiguration.awsvpcConfiguration.Subnets,
            },
        },
        launchType: config.awsEcs.LaunchType,
    });
    try {
        await ecsClient.send(command);
        return true;
    } catch (e) {
        console.error(e);
        return false;
    }
}