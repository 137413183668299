
import { HttpRequest } from "@aws-sdk/protocol-http";
import { S3RequestPresigner } from "@aws-sdk/s3-request-presigner";
import { parseUrl } from "@aws-sdk/url-parser";
import { formatUrl } from "@aws-sdk/util-format-url";
import { Config } from "../api/getConfig";

const region = process.env.AWS_REGION;

export default async function downloadExport(
    s3RequestPresigner: S3RequestPresigner,
    exportFileKey: string,
    config: Config,
) {
    const s3ObjectUrl = parseUrl(`https://${config.awsS3ExportBucketName}.s3.${region}.amazonaws.com/${exportFileKey}`);
    const url = await s3RequestPresigner.presign(new HttpRequest(s3ObjectUrl));
    return formatUrl(url);
}
