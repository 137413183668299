import React, { useContext } from 'react';
import { Event } from '../../context/Fluxes';
import { RoutesEnum } from '../../routes';
import RoutingContext, { RoutingContextInterface } from '../../context/Routing';
import FluxesContext, { FluxesContextInterface } from '../../context/Fluxes';
import { createExportStatus, deleteExportStatus, getEventSchedule } from '../../api/exportStatus';
import generateExport from '../../api/generateExport';
import AwsClientsContext, { AwsClientsContextInterface } from '../../context/AwsClients';
import { useConfig } from '../../context/App';

function toInputFormat(date: Date): string {
    return new Date(date.getTime() + new Date().getTimezoneOffset() * -60 * 1000).toISOString().slice(0, 19);
}

function FluxItem({ event }: { event: Event }) {
    const config = useConfig();
    const { ecs, scheduler } = useContext<AwsClientsContextInterface>(AwsClientsContext);
    const { generatePath } = useContext<RoutingContextInterface>(RoutingContext);
    const { upsertEvent, schedules, upsertSchedule, setSchedules } = useContext<FluxesContextInterface>(FluxesContext);

    const [isStatusChanging, setIsStatusChanging] = React.useState(false);
    const [isExporting, setIsExporting] = React.useState(false);
    const [generateExportFormDisplayed, setGenerateExportFormDisplayed] = React.useState(false);
    const [successMessageDisplayed, setSuccessMessageDisplayed] = React.useState(false);
    const [exportDate, setExportDate] = React.useState(new Date());
    const [exportToTarget, setExportToTarget] = React.useState(process.env.NODE_ENV === 'production');

    const onStatusChanged: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
        e.preventDefault();
        if (scheduler === null) {
            return;
        }
        if (config === null) {
            return;
        }
        const newStatus = e.currentTarget.checked;
        setIsStatusChanging(true);
        let success = false;
        if (newStatus === true) {
            success = await createExportStatus(scheduler, event, config, schedules.length);
            const schedule = await getEventSchedule(scheduler, config.awsSchedule.NamePrefix, event.id, config.awsSchedule.GroupName);
            if (schedule === null) {
                return;
            }
            upsertSchedule(schedule, event.id);
        } else {
            const currentSchedule = schedules.find((s) => s.id === event.id);
            if (!currentSchedule) {
                return;
            }
            success = await deleteExportStatus(scheduler, currentSchedule, schedules.filter((s) => s.id !== currentSchedule.id), config);
            setSchedules((prevState) => [
                ...prevState.filter((s) => s.id !== currentSchedule.id),
            ]);
        }
        setSuccessMessageDisplayed(true);
        window.setTimeout(() => {
            setSuccessMessageDisplayed(false);
        }, 3000);
        if (success) {
            upsertEvent({
                ...event,
                isEnabled: newStatus,
            });
        }
        setIsStatusChanging(false);
    };

    function onExportDateChanged(e) {
        e.preventDefault();
        setExportDate(new Date(e.currentTarget.value));
    }

    function toggleExportFrmDisplayed(e) {
        e.preventDefault();
        setGenerateExportFormDisplayed(true);
    }

    async function onGenerateExport(e) {
        e.preventDefault();
        if (config === null) {
            return;
        }
        if (ecs === null) {
            return;
        }
        if (isExporting) return;
        setIsExporting(true);
        await generateExport(ecs, event.id, config, exportDate, exportToTarget);
        setIsExporting(false);
        setGenerateExportFormDisplayed(false);
    }

    const generateExportForm = (
        <form
            onSubmit={onGenerateExport}
            className="c-form mt-3 p-3"
            style={{ border: '1px solid #e1e9ff' }}
        >
            <div className="c-form__line mb-2">
                <label
                    htmlFor="export-date"
                    className="c-form__label"
                >
                    Commandes à partir du
                </label>
                <input
                    type="datetime-local"
                    id="export-date"
                    name="export-date"
                    value={toInputFormat(exportDate)}
                    onChange={onExportDateChanged}
                    className="c-form__input"
                />
            </div>
            <div className="c-form__line mb-2">
                <div className="c-form__field c-form__field_toggle u-float_right">
                    <input
                        type="checkbox"
                        name={`export-to-target-${event.id}`}
                        id={`export-to-target-${event.id}`}
                        checked={exportToTarget}
                        onChange={e => setExportToTarget(e.currentTarget.checked)}
                        disabled={isStatusChanging}
                        className="c-form__input"
                    />
                <label
                    htmlFor={`export-to-target-${event.id}`}
                    className="c-form__label"
                ></label>
            </div>
            <label
                htmlFor={`export-to-target-${event.id}`}
                className="c-form__label"
            >
                Envoyer sur le dépôt
            </label>
            </div>
            <div className="c-grid__row c-grid__row--no-col">
                <button
                    type="submit"
                    disabled={isExporting}
                    className={`c-btn c-btn--primary c-btn--fluid ${isExporting ? 'c-btn--disabled' : ''}`}
                >
                    Générer un export
                </button>

                <button
                    onClick={() => setGenerateExportFormDisplayed(false)}
                    disabled={isExporting}
                    className={`c-btn c-btn--secondary c-btn--fluid ${isExporting ? 'c-btn--disabled' : ''}`}
                >
                    Annuler
                </button>
            </div>
        </form>
    );

    return (
        <tr className="c-table__row">
            <td className="c-table__cell">
                <div className="c-table__info">
                    <a
                        href={`https://pro.yurplan.com/event/${event.id}`}
                        target="_blank"
                        title="Voir sur Yurplan"
                        className="c-link c-link--primary"
                    >
                        {event.id} ↗
                    </a>
                </div>
            </td>
            <td className="c-table__cell">
                <a
                    href={generatePath(RoutesEnum.EXPORTS, { eventId: event.id })}
                    className="c-table__title"
                >
                    {event.name}
                </a>
                <div className="c-table__info">
                    <a href={generatePath(RoutesEnum.EXPORTS, { eventId: event.id })}>
                        Voir la liste des exports
                    </a>
                </div>
            </td>
            <td className="c-table__cell u-text-align__center">
                <div
                    className="c-form__field c-form__field_toggle"
                    style={{ display: successMessageDisplayed ? 'none' : 'block' }}
                >
                    <input
                        type="checkbox"
                        name={`export-enable-${event.id}`}
                        id={`export-enable-${event.id}`}
                        checked={event.isEnabled}
                        onChange={onStatusChanged}
                        disabled={isStatusChanging}
                        className="c-form__input"
                    />
                    <label
                        htmlFor={`export-enable-${event.id}`}
                        className="c-form__label mb-0"
                    ></label>
                </div>
                <div
                    className="c-table__info"
                    style={{ display: successMessageDisplayed ? 'block' : 'none' }}
                >
                    <div className="s-label s-label--success" title="Sauvegardé">
                        Sauvegardée
                    </div>
                </div>
            </td>
            <td className="c-table__cell u-text-align__right">
                <a href={generatePath(RoutesEnum.CONFIGURE, { eventId: event.id })} className="c-btn c-btn--secondary">
                    <i className="yp_font yp_font_swap" aria-hidden="true">{"=>"}</i>
                </a>
                {generateExportFormDisplayed ? generateExportForm : (
                    <a href="#" className="c-btn c-btn--secondary" onClick={toggleExportFrmDisplayed}>
                        Générer un export
                    </a>)
                }
            </td>
        </tr>
    );
}

export default FluxItem;
