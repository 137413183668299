import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
    UserPoolId: process.env.AWS_USER_POOL_ID,
    ClientId: process.env.AWS_USER_POOL_CLIENT_ID,
});

export default async function login(
    username: string,
    password: string,
): Promise<CognitoUserSession> {
    const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
    });
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });
    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onFailure: (err) => reject(err.message || JSON.stringify(err)),
            onSuccess: resolve,
        });
    });
}
