import React, { useContext } from 'react';
import {setEventConfiguration} from '../../api/eventConfiguration';
import { useConfig } from '../../context/App';
import AwsClientsContext, { AwsClientsContextInterface } from '../../context/AwsClients';
import FluxesContext, { FluxesContextInterface } from '../../context/Fluxes';
import RoutingContext, { RoutingContextInterface } from '../../context/Routing';
import { RoutesEnum } from '../../routes';
import QuestionsListItem from './QuestionsListItem';

export default function QuestionsList() {
    const config = useConfig();
    const { ssm } = useContext<AwsClientsContextInterface>(AwsClientsContext);
    const { questions, configuration, currentEvent } = useContext<FluxesContextInterface>(FluxesContext);
    const { setRoute } = useContext<RoutingContextInterface>(RoutingContext);

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();
        if (ssm === null) {
            return;
        }
        if (currentEvent === null) {
            return;
        }
        if (configuration === null) {
            return;
        }
        if (config === null) {
            return;
        }
        await setEventConfiguration(ssm, config.awsSsmParameterEventPrefix, configuration, currentEvent.id);
        setRoute(RoutesEnum.HOME);
    };

    return (
        <form onSubmit={handleSubmit}>
            {questions.length > 0 ? (
                questions.map((question) => (
                    <QuestionsListItem key={question.id} question={question} />
                ))
            ) : (
                <div className="c-grid c-grid--fluid py-3" style={{ border: '1px solid #d2d8eb' }}>
                    Aucune question
                </div>
            )}
            {questions.length > 0 && (
                <div style={{ display: 'flex' }}>
                    <button type="submit" className="c-btn c-btn--primary my-3 ml-a">
                        Sauvegarder
                    </button>
                </div>
            )}
        </form>
    );
}