import { ScheduleState } from '@aws-sdk/client-scheduler';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getEvents } from '../../api/apiv1Events';
import { getEventSchedule } from '../../api/exportStatus';
import createTaskDefinition from '../../api/taskDefinition';
import { useConfig } from '../../context/App';
import AuthenticationContext from '../../context/Authentication';
import AwsClientsContext, { AwsClientsContextInterface } from '../../context/AwsClients';
import FluxesContext, { FluxesContextInterface } from '../../context/Fluxes';
import FluxTable from './FluxTable';

function Home() {
    const config = useConfig();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { ecs, ssm, scheduler } = useContext<AwsClientsContextInterface>(AwsClientsContext);
    const { setEvents, upsertSchedule } = useContext<FluxesContextInterface>(FluxesContext);
    const { ypAccessToken, setYpAccessToken } = useContext(AuthenticationContext);

    const loadEvents = useCallback(async (e) => {
        if (e) {
          e.preventDefault();
        }
        if (!config || !ypAccessToken || !ecs || !ssm || !scheduler) {
          return;
        }
        setIsLoading(true);
        const events = await getEvents(config.apiV1Config, ypAccessToken);
        if ('access_token' in events) {
          setYpAccessToken(events.access_token, Date.now() + (events.expires_in * 1000));
          return;
        }
        const newEvents = await Promise.all(events.map(async (event) => {
            const schedule = await getEventSchedule(
              scheduler,
              config.awsSchedule.NamePrefix,
              event.id,
              config.awsSchedule.GroupName
            );
            const newEvent = {
              id: event.id,
              name: event.name,
              begin: event.begin,
              end: event.end,
              orgaId: event.orga.id,
              isEnabled: schedule?.State === ScheduleState.ENABLED ?? false,
              taskDefinition: null,
            };
            if (schedule !== null) {
              upsertSchedule(schedule, event.id);
            }
            return newEvent;
        }));
        const filteredEvents = await Promise.all(newEvents
          .filter((event) => event.isEnabled || (event.end * 1000) > (Date.now() - (1000 * 60 * 60 * 24 * 5)))
          .map(async (event) => {
          const taskDefinition = await createTaskDefinition(
            ssm,
            ecs,
            config.awsTaskDefinitionNamePrefix,
            event.id,
            config.awsSsmParameterTaskTemplateName
          );
          if (taskDefinition === undefined) {
            return event;
          }
          return {
            ...event,
            taskDefinition,
          };
        }));

        setEvents(filteredEvents);
        setIsLoading(false);
      }, [scheduler, ssm, ecs, config, ypAccessToken]);

    useEffect(() => {
        loadEvents(null);
    }, [loadEvents]);

    return (
        <div className="c-grid c-grid--m" style={{ marginTop: 'calc(60px + 1.875rem)' }}>
            <div className="c-block c-block_shadow">
                <div className="c-block__header">
                    <div className="pos_relative">
                        <div className="c-type-title--3 py-3 m-0">
                            Vos évènements
                        </div>
                        {!isLoading && (<div className="c-grid__row c-grid__row--no-col pos_absolute t50p r0">
                            <button
                                className={`c-btn c-btn--secondary ${isLoading ? 'c-btn--disabled' : ''}`}
                                onClick={loadEvents}
                            >
                                ⟳ Rafraichir
                            </button>
                        </div>)}
                    </div>
                </div>
                <hr className="s-hr"/>
                {
                    isLoading
                        ? (
                            <div className="c-grid c-grid--fluid">
                                <div className="c-grid__row c-grid__row--align-hcenter">
                                    <div className="u-col--m-6 u-col--s-12 u-text-align__center my-3">
                                        <div>
                                            Chargement en cours...
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : <FluxTable />
                }
            </div>
        </div>
    );
}

export default Home;
