import { SchedulerServiceException as __BaseException } from "./SchedulerServiceException";
export var AssignPublicIp;
(function (AssignPublicIp) {
    AssignPublicIp["DISABLED"] = "DISABLED";
    AssignPublicIp["ENABLED"] = "ENABLED";
})(AssignPublicIp || (AssignPublicIp = {}));
export class InternalServerException extends __BaseException {
    constructor(opts) {
        super({
            name: "InternalServerException",
            $fault: "server",
            ...opts,
        });
        this.name = "InternalServerException";
        this.$fault = "server";
        Object.setPrototypeOf(this, InternalServerException.prototype);
        this.Message = opts.Message;
    }
}
export class ResourceNotFoundException extends __BaseException {
    constructor(opts) {
        super({
            name: "ResourceNotFoundException",
            $fault: "client",
            ...opts,
        });
        this.name = "ResourceNotFoundException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ResourceNotFoundException.prototype);
        this.Message = opts.Message;
    }
}
export class ThrottlingException extends __BaseException {
    constructor(opts) {
        super({
            name: "ThrottlingException",
            $fault: "client",
            ...opts,
        });
        this.name = "ThrottlingException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ThrottlingException.prototype);
        this.Message = opts.Message;
    }
}
export class ValidationException extends __BaseException {
    constructor(opts) {
        super({
            name: "ValidationException",
            $fault: "client",
            ...opts,
        });
        this.name = "ValidationException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ValidationException.prototype);
        this.Message = opts.Message;
    }
}
export class ConflictException extends __BaseException {
    constructor(opts) {
        super({
            name: "ConflictException",
            $fault: "client",
            ...opts,
        });
        this.name = "ConflictException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ConflictException.prototype);
        this.Message = opts.Message;
    }
}
export var FlexibleTimeWindowMode;
(function (FlexibleTimeWindowMode) {
    FlexibleTimeWindowMode["FLEXIBLE"] = "FLEXIBLE";
    FlexibleTimeWindowMode["OFF"] = "OFF";
})(FlexibleTimeWindowMode || (FlexibleTimeWindowMode = {}));
export var ScheduleState;
(function (ScheduleState) {
    ScheduleState["DISABLED"] = "DISABLED";
    ScheduleState["ENABLED"] = "ENABLED";
})(ScheduleState || (ScheduleState = {}));
export var LaunchType;
(function (LaunchType) {
    LaunchType["EC2"] = "EC2";
    LaunchType["EXTERNAL"] = "EXTERNAL";
    LaunchType["FARGATE"] = "FARGATE";
})(LaunchType || (LaunchType = {}));
export var PlacementConstraintType;
(function (PlacementConstraintType) {
    PlacementConstraintType["DISTINCT_INSTANCE"] = "distinctInstance";
    PlacementConstraintType["MEMBER_OF"] = "memberOf";
})(PlacementConstraintType || (PlacementConstraintType = {}));
export var PlacementStrategyType;
(function (PlacementStrategyType) {
    PlacementStrategyType["BINPACK"] = "binpack";
    PlacementStrategyType["RANDOM"] = "random";
    PlacementStrategyType["SPREAD"] = "spread";
})(PlacementStrategyType || (PlacementStrategyType = {}));
export var PropagateTags;
(function (PropagateTags) {
    PropagateTags["TASK_DEFINITION"] = "TASK_DEFINITION";
})(PropagateTags || (PropagateTags = {}));
export class ServiceQuotaExceededException extends __BaseException {
    constructor(opts) {
        super({
            name: "ServiceQuotaExceededException",
            $fault: "client",
            ...opts,
        });
        this.name = "ServiceQuotaExceededException";
        this.$fault = "client";
        Object.setPrototypeOf(this, ServiceQuotaExceededException.prototype);
        this.Message = opts.Message;
    }
}
export var ScheduleGroupState;
(function (ScheduleGroupState) {
    ScheduleGroupState["ACTIVE"] = "ACTIVE";
    ScheduleGroupState["DELETING"] = "DELETING";
})(ScheduleGroupState || (ScheduleGroupState = {}));
export const ListTagsForResourceInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const TagFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const ListTagsForResourceOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const FlexibleTimeWindowFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const DeadLetterConfigFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const CapacityProviderStrategyItemFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const AwsVpcConfigurationFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const NetworkConfigurationFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const PlacementConstraintFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const PlacementStrategyFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const EcsParametersFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const EventBridgeParametersFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const KinesisParametersFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const RetryPolicyFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const SageMakerPipelineParameterFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const SageMakerPipelineParametersFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const SqsParametersFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const TargetFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const CreateScheduleInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const CreateScheduleOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const DeleteScheduleInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const DeleteScheduleOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetScheduleInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetScheduleOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const ListSchedulesInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const TargetSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const ScheduleSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const ListSchedulesOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const UpdateScheduleInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const UpdateScheduleOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const CreateScheduleGroupInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const CreateScheduleGroupOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const DeleteScheduleGroupInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const DeleteScheduleGroupOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetScheduleGroupInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const GetScheduleGroupOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const ListScheduleGroupsInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const ScheduleGroupSummaryFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const ListScheduleGroupsOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const TagResourceInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const TagResourceOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const UntagResourceInputFilterSensitiveLog = (obj) => ({
    ...obj,
});
export const UntagResourceOutputFilterSensitiveLog = (obj) => ({
    ...obj,
});
