import React, { useContext } from 'react';
import FluxesContext, { FluxesContextInterface } from '../../context/Fluxes';
import FluxItem from './FluxItem';

function FluxTable() {
    const { events } = useContext<FluxesContextInterface>(FluxesContext);
    if (events.length === 0) {
        return <div>Aucun évènement disponible</div>;
    }
    return (
        <table className="c-table c-table_border_top_none">
            <thead className="c-table__header">
                <tr className="c-table__row">
                    <th className="c-table__cell" style={{width: '10%'}}>
                        ID
                    </th>
                    <th className="c-table__cell">
                        Evènements
                    </th>
                    <th className="c-table__cell u-text-align__center" style={{width: '10%'}}>
                        Activation
                    </th>
                    <th className="c-table__cell u-text-align__right" style={{width: '35%'}}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody className="c-table__body">
                {events.map((event) => (
                    <FluxItem key={event.id} event={event} />
                ))}
            </tbody>
        </table>
    );
}

export default FluxTable;
