import React, { useState } from 'react';

export default function useInputState(
    initialValue: string,
): [string, (e: React.ChangeEvent<HTMLInputElement>) => void] {
    const [value, setValue] = useState(initialValue);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
    };
    return [value, handleChange];
}
