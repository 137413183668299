import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getQuestions } from '../../api/apiv1Questions';
import { EventConfiguration, getEventConfiguration } from '../../api/eventConfiguration';
import { useConfig } from '../../context/App';
import AuthenticationContext from '../../context/Authentication';
import AwsClientsContext, { AwsClientsContextInterface } from '../../context/AwsClients';
import FluxesContext, { FluxesContextInterface } from '../../context/Fluxes';
import RoutingContext, { RoutingContextInterface } from '../../context/Routing';
import { RoutesEnum } from '../../routes';
import QuestionsList from './QuestionsList';

export default function Configure() {
    const config = useConfig();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { ssm } = useContext<AwsClientsContextInterface>(AwsClientsContext);
    const { currentEvent, setQuestions, setConfiguration } = useContext<FluxesContextInterface>(FluxesContext);
    const { generatePath } = useContext<RoutingContextInterface>(RoutingContext);
    const { ypAccessToken, setYpAccessToken } = useContext(AuthenticationContext);

    const loadQuestions = useCallback(async () => {
        if (config === null) {
            return;
        }
        if (ypAccessToken === null) {
            return;
        }
        if (currentEvent === null) {
            return;
        }
        if (ssm === null) {
            return;
        }     
        setIsLoading(true);
        const questions = await getQuestions({ orgaId: currentEvent.orgaId, eventId: currentEvent.id }, config.apiV1Config, ypAccessToken);        
        if ('access_token' in questions) {
            setYpAccessToken(questions.access_token, Date.now() + (questions.expires_in * 1000));
            return;
        }
        setQuestions(questions);
        if (questions.length < 1) {
            setIsLoading(false);
            return;
        }
        const eventConfiguration = await getEventConfiguration(ssm, config.awsSsmParameterEventPrefix, currentEvent.id);                
        const ctcAttributeContact: EventConfiguration['amctc_code'] = [];
        questions.forEach((question) => {
            const existingQuestion = eventConfiguration?.amctc_code?.find((q) => q.id === question.id) ?? null;
            let isActivated = true;
            if (existingQuestion === null) {
                isActivated = false;
            }
            const q = {
                id: question.id,
                label: question.label,
                isActivated,
                choices: question.choice_answers.map((answer) => {
                    let target = '';
                    if (existingQuestion !== null) {
                        target = existingQuestion.choices.find((a) => a.id === answer.id)?.target ?? '';
                    }
                    return {
                        id: answer.id,
                        source: answer.label,
                        target,
                    }
                }),
            };
            ctcAttributeContact.push(q);
        });
        setConfiguration({ amctc_code: ctcAttributeContact });
        setIsLoading(false);
    }, [ssm, config, currentEvent, ypAccessToken, getQuestions, setQuestions, setConfiguration, getEventConfiguration]);

    useEffect(() => {
        loadQuestions();
    }, [loadQuestions]);

    return (
        <div className="c-grid c-grid--s" style={{ marginTop: 'calc(60px + 1.875rem)' }}>
            <div className="c-block c-block_shadow">
                <div className="c-block__header">
                    <div className="pos_relative">
                        <div className="c-type-title--3 py-3 m-0">
                            {`Configuration ${currentEvent?.name ?? ''}`}
                        </div>
                        <div className="c-grid__row c-grid__row--no-col pos_absolute t50p r0">
                            <a
                                className={`c-btn c-btn--secondary`}
                                href={generatePath(RoutesEnum.HOME)}
                            >
                                Retour
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ? (
                <div className="c-grid c-grid--fluid">
                    <div className="c-grid__row c-grid__row--align-hcenter">
                        <div className="u-col--m-6 u-col--s-12 u-text-align__center my-3">
                            <div>
                                Chargement en cours...
                            </div>
                        </div>
                    </div>
                </div>
            ) : <QuestionsList />}
        </div>
    );
}
